// @flow

import React from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import RestoreFromTrashIcon from "@material-ui/icons/RestoreFromTrash";
import axios from "../../../utils/axios";
import { enqueueSnackbar } from "../../../redux/notification/notification-actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  imageWrapper: {
    width: "100%",
    height: "100%",
    position: "relative"
  },
  topRightControls: {
    position: "absolute",
    top: 0,
    right: 0
  },
  centerControls: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  input: {
    display: "none"
  }
}));

const ControlledImage = ({
  children,
  isControlled,
  url,
  onRemove,
  onAdd,
  deleted = false,
  onRestore,
  onAddUploadFiles = false,
  addMultiple = true
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const uploadFiles = event => {
    const files = [...event.target.files] || [];
    if (!onAddUploadFiles) {
      onAdd(files);
    } else {
      if (files?.length > 0) {
        // const newImages = [];
        // const file = event.target.files[0];
        files.forEach(file => {
          const newImageUrl = URL.createObjectURL(file);

          // Uploadujeme image
          const formData = new FormData();
          formData.append("image", file);
          axios({
            url: `/v1/upload/images/`,
            method: "POST",
            data: formData
          })
            .then(response => {
              // newImages.push({ id: response.data.id, url: newImageUrl });
              dispatch(
                enqueueSnackbar({
                  message: "Nahrání obrázku proběhlo úspěšně.",
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "success"
                  }
                })
              );
              if (onAdd) onAdd(response.data.id, newImageUrl); // TODO: return url from server and use response.data.url instead of newImage.url
            })
            .catch(error => {
              dispatch(
                enqueueSnackbar({
                  message: "V průběhu nahrávání obrázku došlo k chybě!",
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: "error"
                  }
                })
              );
            });
        });
        // if (onAdd) onAdd(newImages);
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.imageWrapper}>
        <a href={url} target={"_blank"} rel="noopener noreferrer">
          {children}
        </a>
        {isControlled ? (
          <>
            {onRemove ? (
              <div className={classes.topRightControls}>
                {deleted ? (
                  <IconButton
                    color={"secondary"}
                    title={"Obnovit"}
                    onClick={() => onRestore()}
                  >
                    <RestoreFromTrashIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    color={"secondary"}
                    title={"Odstranit"}
                    onClick={() => onRemove()}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            ) : null}
            {onAdd ? (
              <div className={classes.centerControls}>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="icon-button-file"
                  multiple={addMultiple}
                  type="file"
                  onChange={uploadFiles}
                />
                <label htmlFor="icon-button-file">
                  <IconButton
                    color={"secondary"}
                    title={"Přidat"}
                    aria-label="upload picture"
                    component="span"
                  >
                    <AddAPhotoIcon fontSize={"large"} />
                  </IconButton>
                </label>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ControlledImage;
