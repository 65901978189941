// @flow

import * as types from "redux/auth/types";
import { VERIFICATION_STATUS_VERIFYING } from "../../constants/verification_status";

/**
 * Flow types
 */
type AuthState = {
  // authentication
  userId: ?number,
  isLoading: boolean,
  isAuthenticated: boolean,
  fullname: ?string,
  firstCheckDone: boolean,
  verificationEmailSentDialogOpen: boolean,
  verificationStatus: string,
  // authorization
  isSystemAdmin: boolean,
  resourceType: ?string,
  resourceId: ?number,
  isResourceMember: boolean,
  isResourceAdmin: boolean
};

/**
 * Initial auth state
 */
const INITIAL_STATE = {
  // authentication
  userId: null,
  isLoading: true,
  isAuthenticated: false,
  fullname: null,
  firstCheckDone: false,
  verificationEmailSentDialogOpen: false,
  verificationStatus: VERIFICATION_STATUS_VERIFYING,
  // authorization
  isSystemAdmin: false,
  resourceType: null,
  resourceId: null,
  isResourceMember: false,
  isResourceAdmin: false
};

/**
 * Auth reducer
 */
const AuthReducer = (state: AuthState = INITIAL_STATE, action: Object) => {
  switch (action.type) {
    case types.SET_LOG_IN:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        userId: action.payload.userId,
        fullname: action.payload.fullname
      };
    case types.SET_LOG_OUT:
      return {
        ...state,
        userId: INITIAL_STATE.userId,
        isAuthenticated: INITIAL_STATE.isAuthenticated,
        fullname: INITIAL_STATE.fullname,
        firstCheckDone: INITIAL_STATE.firstCheckDone,
        isSystemAdmin: INITIAL_STATE.isSystemAdmin,
        resourceType: INITIAL_STATE.resourceType,
        resourceId: INITIAL_STATE.resourceId,
        isResourceMember: INITIAL_STATE.isResourceMember,
        isResourceAdmin: INITIAL_STATE.isResourceAdmin,
        // ...INITIAL_STATE,

        isLoading: false
      };
    case types.FIRST_CHECK_DONE:
      if (!state.firstCheckDone) {
        return {
          ...state,
          firstCheckDone: true
        };
      }
      return state;
    case types.SET_VERIFICATION_EMAIL_SENT_DIALOG_OPEN:
      return {
        ...state,
        verificationEmailSentDialogOpen: action.payload
      };
    case types.SET_EMAIL_VERIFICATION_STATUS:
      return {
        ...state,
        verificationStatus: action.payload
      };
    // authorization
    case types.SET_GLOBAL_AUTHORIZATION:
      return {
        ...state,
        isSystemAdmin: action.payload.isSystemAdmin
      };
    case types.SET_RESOURCE_AUTHORIZATION:
      return {
        ...state,
        resourceType: action.payload.resourceType,
        resourceId: action.payload.resourceId,
        isResourceMember: action.payload.isResourceMember,
        isResourceAdmin: action.payload.isResourceAdmin
      };
    default:
      return state;
  }
};

export default AuthReducer;
