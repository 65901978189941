// @flow

// redux
import { createStore, applyMiddleware, compose } from "redux";

// redux middlewares
import logger from "redux-logger";
import thunk from "redux-thunk";

import rootReducer from "redux/root-reducer";

const middlewares = [logger, thunk];

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

export default createStore(rootReducer, enhancer);
