import React from "react";
import SearchBox from "./searchBox";

const PersonSearchBox = ({
  initialValues,
  onChange,
  label,
  placeholder,
  multiple = true,
  disabled = false,
  baseParams,
  findByEmail = false,
  helperText
}) => {
  const handleChange = (e, value, result) => {
    let changedValue;
    if (multiple) {
      changedValue = value.map(option => {
        if (typeof option === "string") {
          return { value: option, creatable: true };
        }
        return option;
      });
    } else {
      if (typeof value === "string") {
        changedValue = { value: value, creatable: true };
      } else {
        changedValue = value;
      }
    }
    onChange(changedValue);
  };

  return (
    <SearchBox
      label={label}
      placeholder={placeholder}
      getOptionSelected={(option, value) => {
        return option.value === value.value;
      }}
      requestUrl={`/v1/persons/find-by-email`}
      requestMethod={"POST"}
      transformResponse={responseData =>
        responseData.persons.map(person => ({
          // label: person.email,
          value: findByEmail
            ? person.email
            : `${person.fullName} (id: ${person.id})`,
          userId: person.id
          // email: findByEmail ? person.email : undefined
        }))
      }
      transformRequestData={value => ({
        fulltext: findByEmail ? undefined : value,
        email: findByEmail ? value : undefined
      })}
      requestErrorMessage={"V průběhu vyhledání osob došlo k chybě."}
      onChange={handleChange}
      creatable={true}
      freeSolo={true}
      multiple={multiple}
      defaultValue={
        multiple
          ? initialValues?.length
            ? initialValues.map(person => ({
                ...person,
                value: person.fullname
              }))
            : []
          : { ...initialValues, value: initialValues?.fullname || "" }
      }
      filterOptionsFilterEnabled={false}
      disabled={disabled}
      baseParams={baseParams}
      helperText={helperText}
    />
  );
};

export default PersonSearchBox;
