import React from "react";
import { Grid } from "@material-ui/core";

import { Form } from "formik";
import EmailField from "../login-components/fields/email";
import PasswordField from "../login-components/fields/password";
import FormActions from "../../../common/components/forms/actions";
import FirstNameField from "./fields/firstName";
import LastNameField from "./fields/lastName";
import CityField from "./fields/city";
import StreetField from "./fields/street";
import ZipNumberField from "./fields/zipNumber";
import PhoneNumberField from "./fields/phoneNumber";
import BirthDateField from "../../../common/components/forms/fields/birthDate";
import GdprApprove from "./fields/gdprApprove";

const RegisterFormLayout = ({
  values,
  errors,
  formDiagnostics,
  touched,
  isSubmitting,
  setFieldValue
}) => {
  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <EmailField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <PasswordField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FirstNameField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <LastNameField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CityField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <StreetField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={12}>
          <ZipNumberField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <PhoneNumberField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <BirthDateField
            value={values.birthDate}
            setFieldValue={setFieldValue}
            baseParams={{ errors, formDiagnostics, touched }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <GdprApprove
            value={values.gdprApprove}
            setFieldValue={setFieldValue}
            baseParams={{ errors, formDiagnostics, touched }}
          />
        </Grid>
      </Grid>
      <FormActions
        isSubmitting={isSubmitting}
        errors={errors}
        onSubmitButtonText={"Registrovat"}
        onSubmitButtonTextSubmitting={"Registrování"}
        dense={true}
      />

      {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(errors, null, 2)}</pre>*/}
    </Form>
  );
};

export default RegisterFormLayout;
