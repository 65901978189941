import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import cs from "date-fns/locale/cs";

import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { resetFormDiagnostics } from "../../../../../redux/common/actions";
import { createArticle } from "../../../../../redux/article/actions";
import { YUP_TITLE_REQUIRED } from "./fields/title";
import { YUP_CONTENT_REQUIRED } from "./fields/content";
import ArticleCreateEditFormLayout from "./formLayout";
import {
  draftJsStateToRawString,
  rawDraftJsStringToState
} from "../../../draftjsEditor/utils";

const FORM_NAME = "ArticleCreateForm";
export const SUBMITTING_FORM_LOADER = "SUBMITTING_ARTICLE_CREATE_FORM_LOADER";

const validationSchema = Yup.object().shape({
  title: YUP_TITLE_REQUIRED,
  content: YUP_CONTENT_REQUIRED
});

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  }
}));

const ArticleCreateForm = ({
  initialValues,
  actualImages,
  onClose,
  onSubmitSuccessfully,
  resourceType,
  resourceId
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(resetFormDiagnostics(FORM_NAME));
    })();
  }, [dispatch]);

  const [imageIds, setImageIds] = useState(actualImages.map(image => image.id));

  // const loaders = useSelector(state => state.CommonReducer.loaders);

  const isSubmitting = false;
  // loaders[SUBMITTING_FORM_LOADER] || false;

  const formDiagnostics =
    useSelector(state => state.CommonReducer.formDiagnostics)[FORM_NAME] || {};

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
        <Formik
          initialValues={{
            ...initialValues,
            content: rawDraftJsStringToState(initialValues["content"])
          }}
          onSubmit={async values => {
            dispatch(
              createArticle({
                resourceType,
                resourceId,
                values: {
                  ...values,
                  content: draftJsStateToRawString(values["content"])
                },
                formName: FORM_NAME,
                onSuccess: onSubmitSuccessfully,
                imageIds: imageIds
              })
            );
          }}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, setFieldValue }) => {
            return (
              <ArticleCreateEditFormLayout
                values={values}
                errors={errors}
                formDiagnostics={formDiagnostics}
                touched={touched}
                isSubmitting={isSubmitting}
                onSetImageIds={setImageIds}
                actualImages={actualImages}
                onClose={onClose}
                setFieldValue={setFieldValue}
              />
            );
          }}
        </Formik>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default ArticleCreateForm;
