import React from "react";
import { makeStyles } from "@material-ui/core";
import ControlledHtmlEditor from "../../htmlEditor/controled";
import { openModal } from "../../../../redux/common/actions";
import { useDispatch } from "react-redux";
import CustomPageSectionEditFormModal, {
  CUSTOM_PAGE_SECTION_FORM_MODAL_NAME_PREFIX
} from "./editFormModal";
import SectionPreview from "../sectionPreview";
import { deleteCustomPageSection } from "../../../../redux/customPage/actions";
import SectionAction from "../components/sectionAction";
import AuthorizedOnly from "../../authorization/authorizedOnly";
import { rawDraftJsStringToHtml } from "../../draftjsEditor/utils";

export const COMPONENT_NAME = "Section";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    position: "relative"
  },
  title: {
    marginBottom: theme.spacing(1) // because of floating edit|delete button group
  },
  mediaContainer: {
    [theme.breakpoints.up("md")]: {
      width: "60%",
      float: "right",
      maxWidth: "600px",
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    width: "100%",
    height: "auto",
    marginBottom: theme.spacing(1)
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    overflow: "hidden"
  },
  content: {
    fontSize: "1rem",
    textAlign: "justify"
  },
  clear: {
    clear: "both"
  },
  editButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1)
  }
}));

const Section = ({
  customPageSectionId,
  title,
  fullHeight,
  imageUrl,
  imageId,
  content,
  sectionOrder,
  resourceType,
  resourceId,
  onReload,
  minOrder,
  maxOrder,
  inPreviewPage
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleEditModalOpen = index => {
    dispatch(
      openModal(
        CUSTOM_PAGE_SECTION_FORM_MODAL_NAME_PREFIX +
          customPageSectionId.toString()
      )
    );
  };
  const handleRemoveSection = () => {
    dispatch(
      deleteCustomPageSection({
        customPageSectionId: customPageSectionId,
        resourceType,
        resourceId,
        onSuccess: onReload,
        loaderName: "TODO_LOADER_NAME",
        modalName: "TODO_MOADL_NAME"
      })
    );
  };

  return (
    <div className={classes.root}>
      <SectionPreview
        title={title}
        titleTextAlign={inPreviewPage ? "center" : "left"}
        fullHeight={fullHeight || false}
      >
        {customPageSectionId && (
          <AuthorizedOnly
            expectedRole={"isResourceAdmin"}
            resourceType={resourceType}
            resourceId={resourceId}
          >
            <CustomPageSectionEditFormModal
              customPageSectionId={customPageSectionId}
              initialValues={{
                title: title || "",
                fullHeight: fullHeight || false,
                content: content || null,
                imageId: imageId || "",
                order: sectionOrder || ""
              }}
              actualImageUrl={imageUrl}
              onSubmitSuccessfully={onReload}
              resourceType={resourceType}
              resourceId={resourceId}
              minOrder={minOrder}
              maxOrder={maxOrder}
            />
            <div className={classes.editButton}>
              <SectionAction
                title={"Akční možnosti této sekce"}
                onEdit={handleEditModalOpen}
                onRemove={handleRemoveSection}
              />
            </div>
          </AuthorizedOnly>
        )}
        <div className={classes.contentWrapper}>
          {imageUrl && (
            <div className={classes.mediaContainer}>
              <img
                className={classes.image}
                src={imageUrl}
                alt={"section multimedia"}
              />
            </div>
          )}
          <ControlledHtmlEditor
            editorShown={false}
            html={rawDraftJsStringToHtml(content)}
            contentClass={classes.content}
          />
          <div className={classes.clear} />
        </div>
      </SectionPreview>
    </div>
  );
};

export default Section;
