import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Form } from "formik";

import FormActions from "../../../../../../common/components/forms/actions";
import ImageUploader from "../../../../../../common/components/imageUploader";
import NameField from "./fields/name";
import ShortDescriptionField from "./fields/shortDescription";
import TermField from "./fields/term";
import DeadlineField from "./fields/deadline";
import PriceField from "./fields/price";
import PersonSearchBox from "../../../../../../common/components/forms/PersonSearchBox";
import CategoriesField from "./fields/categories";
import CustomInputLabel from "../../../../../../common/components/forms/customInputLabel";

const EventSummaryCreateEditFormLayout = ({
  values,
  actualProfileImageUrl, // only for edit form
  errors,
  formDiagnostics,
  touched,
  isSubmitting,
  onClose,
  setFieldValue,
  createForm = false,
  allPossibleEventCategories
}) => {
  useEffect(() => {
    if (values.isVirtual && values.isAdmin) setFieldValue("isAdmin", false);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [values]);

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomInputLabel label={"Hlavní obrázek akce"} />
          <ImageUploader
            initImage={{
              url: actualProfileImageUrl,
              id: values.imageId
            }}
            onChange={id => setFieldValue("imageId", id)}
            useCrop={false}
            alt={"Hlavní obrázek akce"}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <NameField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={12}>
          <ShortDescriptionField
            baseParams={{ errors, formDiagnostics, touched }}
          />
        </Grid>
        {/*<Grid item xs={12} md={6}>*/}
        {/*  <Field*/}
        {/*    component={TextField}*/}
        {/*    name="venue"*/}
        {/*    label="Místo konání"*/}
        {/*    className={classes.textInput}*/}
        {/*    fullWidth={true}*/}
        {/*    type="text"*/}
        {/*    placeholder="Zde vložte místo konání"*/}
        {/*    helperText={localCombineFormErrorMessages("venue")}*/}
        {/*    error={!!localCombineFormErrorMessages("venue")}*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item xs={12} md={6}>
          <TermField
            values={values}
            setFieldValue={setFieldValue}
            baseParams={{ errors, formDiagnostics, touched }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DeadlineField
            values={values}
            setFieldValue={setFieldValue}
            baseParams={{ errors, formDiagnostics, touched }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <PriceField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <PersonSearchBox
            initialValues={values.judges}
            onChange={value => setFieldValue("judges", value)}
            label={"Rozhodčí"}
            placeholder={"Zde vložte celé jméno rozhodčího"}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PersonSearchBox
            initialValues={values.helpers}
            onChange={value => setFieldValue("helpers", value)}
            label={"Figuranti"}
            placeholder={"Zde vložte celé jméno figuranta"}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <CategoriesField
            baseParams={{ errors, formDiagnostics, touched }}
            values={values}
            setFieldValue={setFieldValue}
            allPossibleEventCategories={allPossibleEventCategories}
          />
        </Grid>
      </Grid>

      <FormActions
        isSubmitting={isSubmitting}
        errors={errors}
        onClose={onClose}
      />
      {/*  ONLY FOR DEBUG PURPOSES  */}
      {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(errors, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(touched, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(imageIds, null, 2)}</pre>*/}
    </Form>
  );
};

export default EventSummaryCreateEditFormLayout;
