import React, { useEffect } from "react";

import Moment from "react-moment";
import { Link as RouterLink } from "react-router-dom";
import MainMultimedia from "common/components/main-multimedia";
import { Typography, Grid, makeStyles, Link } from "@material-ui/core";
import PlaceIcon from "@material-ui/icons/Place";
import ScheduleIcon from "@material-ui/icons/Schedule";
import EventIcon from "@material-ui/icons/Event";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import CategoryIcon from "@material-ui/icons/Category";

import MainContentContainer from "common/components/main-content-container";
import Section from "common/components/page/section";
import { useDispatch, useSelector } from "react-redux";
import { getEvent } from "redux/event/actions";
import { NOT_SET_PLACEHOLDER } from "../../../../../constants";
import noImage from "images/noImage.png";
import { closeModal, openModal } from "../../../../../redux/common/actions";
import Button from "@material-ui/core/Button";
import AuthorizedOnly from "../../../../../common/components/authorization/authorizedOnly";
import { EVENT_SUMMARY_EDIT_FORM_MODAL_NAME } from "./forms/editForm";
import EventStaff from "./staff";
import EventSummaryEditForm from "./forms/editForm";
import ModalFormWrapper from "../../../../../common/components/modalForm/wrapper";
import { printAddressString } from "../../../../../utils/dataTransformation";

const useStyles = makeStyles(theme => ({
  icons: {
    marginRight: theme.spacing(1)
  },
  coloredLists: {
    color: theme.palette.secondary.main
  },
  editButton: {
    float: "right",
    marginTop: theme.spacing(4)
  }
}));

const EventSummary = ({ resourceId: eventId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const reloadEvent = () => {
    dispatch(getEvent(eventId));
  };

  useEffect(() => {
    (async () => {
      await reloadEvent();
    })();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, eventId]);

  const event = useSelector(state => state.EventReducer.event);

  const handleEditFormSubmitSuccessfully = () => {
    reloadEvent();
  };
  const handleEditModalOpen = () => {
    dispatch(openModal(EVENT_SUMMARY_EDIT_FORM_MODAL_NAME));
  };

  const categoryDetails = (maximumParticipants, note) => {
    let details = [];
    if (maximumParticipants)
      details.push(`max. účastníků: ${maximumParticipants}`);
    if (note) details.push(`poznámka: ${note}`);
    const detailsString = details.join(", ");
    return detailsString ? `(${detailsString})` : "";
  };

  const handleCloseSummaryEditModalForm = () => {
    dispatch(closeModal(EVENT_SUMMARY_EDIT_FORM_MODAL_NAME));
  };

  return event ? (
    <div>
      <MainMultimedia
        title={event.name}
        subtitle={event.shortDescription}
        image={event.imageUrl || noImage}
      />
      <MainContentContainer>
        <AuthorizedOnly
          expectedRole={"isResourceAdmin"}
          resourceType={"events"}
          resourceId={eventId}
        >
          <ModalFormWrapper
            modalName={EVENT_SUMMARY_EDIT_FORM_MODAL_NAME}
            title={"Upravit informace akci"}
          >
            <EventSummaryEditForm
              eventId={eventId}
              initialValues={{
                name: event.name,
                shortDescription: event.shortDescription,
                venue: event.venue,
                term: event.term,
                deadline: event.deadline,
                price: event.price,
                helpers: event.helpers?.length
                  ? event.helpers.map(helper => ({
                      participant_id: helper.id,
                      userId: helper.user.id,
                      fullname: helper.user.fullname
                    }))
                  : [],
                judges: event.judges?.length
                  ? event.judges.map(judge => ({
                      participant_id: judge.id,
                      userId: judge.user.id,
                      fullname: judge.user.fullname
                    }))
                  : [],
                categories: event.categories.length
                  ? event.categories.map(category => ({
                      id: category.id,
                      name: category.name,
                      note: category.note || "",
                      maximumParticipants: category.maximumParticipants || ""
                    }))
                  : [],
                organizerClub: event?.organizerClub?.id || null
                // organizerUser: event.organizerUser,
              }}
              onClose={handleCloseSummaryEditModalForm}
              actualProfileImageUrl={event.imageUrl}
              onSubmitSuccessfully={handleEditFormSubmitSuccessfully}
            />
          </ModalFormWrapper>
          <Button
            className={classes.editButton}
            onClick={handleEditModalOpen}
            color={"primary"}
            variant={"outlined"}
          >
            Upravit
          </Button>
        </AuthorizedOnly>
        <Section title={"Základní informace:"}>
          <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant={"body1"}>
                <PlaceIcon
                  color="secondary"
                  fontSize={"default"}
                  className={classes.icons}
                />
                <strong>Místo konání:</strong>{" "}
                {event.address
                  ? printAddressString(
                      event.address.street,
                      event.address.city,
                      event.address.zipNumber,
                      event.address.description
                    ) || NOT_SET_PLACEHOLDER
                  : NOT_SET_PLACEHOLDER}
                {/*{event.venue}*/}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={"body1"}>
                <EventIcon
                  color="secondary"
                  fontSize={"default"}
                  className={classes.icons}
                />
                <strong>Datum konání:</strong>{" "}
                {event.term ? (
                  <Moment date={event.term} />
                ) : (
                  NOT_SET_PLACEHOLDER
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={"body1"}>
                <ScheduleIcon
                  color="secondary"
                  fontSize={"default"}
                  className={classes.icons}
                />
                <strong>Uzávěrka přihlášek:</strong>{" "}
                {event.deadline ? (
                  <Moment date={event.deadline} />
                ) : (
                  NOT_SET_PLACEHOLDER
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={"body1"}>
                <LocalOfferIcon
                  color="secondary"
                  fontSize={"default"}
                  className={classes.icons}
                />
                <strong>Cena:</strong> {event.price} {"Kč"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={"body1"}>
                <RecordVoiceOverIcon
                  color="secondary"
                  fontSize={"default"}
                  className={classes.icons}
                />
                <strong>Organizator:</strong>{" "}
                {event.organizerClub ? (
                  <Link
                    component={event.organizerClub.id ? RouterLink : "span"}
                    to={`/clubs/${event.organizerClub.id}/summary`}
                  >
                    {event.organizerClub.name}
                  </Link>
                ) : (
                  NOT_SET_PLACEHOLDER
                )}
              </Typography>
            </Grid>
          </Grid>
        </Section>
        <Section>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant={"body1"}>
                <LocalLibraryIcon
                  color="secondary"
                  fontSize={"default"}
                  className={classes.icons}
                />
                <strong>Rozhodčí:</strong>{" "}
                {event.judges && event.judges.length ? null : "nebyli zadáni"}
              </Typography>
              <ul>
                {event.judges &&
                  event.judges.map((judge, index) => (
                    <li className={classes.coloredLists} key={index}>
                      <Typography variant={"body1"} color={"textPrimary"}>
                        {judge.user.fullname}
                      </Typography>
                    </li>
                  ))}
              </ul>
            </Grid>
            {event.helpers && event.helpers.length ? (
              <Grid item xs={12} md={6}>
                <Typography variant={"body1"}>
                  <DirectionsRunIcon
                    color="secondary"
                    fontSize={"default"}
                    className={classes.icons}
                  />
                  <strong>Figuranti:</strong>{" "}
                </Typography>
                <ul>
                  {event.helpers &&
                    event.helpers.map((helper, index) => (
                      <li className={classes.coloredLists} key={index}>
                        <Typography variant={"body1"} color={"textPrimary"}>
                          {helper.user.fullname}
                        </Typography>
                      </li>
                    ))}
                </ul>
              </Grid>
            ) : null}
          </Grid>
        </Section>
        <Section bottomDivider={false}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant={"body1"}>
                <CategoryIcon
                  color="secondary"
                  fontSize={"default"}
                  className={classes.icons}
                />
                <strong>Kategorie:</strong>{" "}
                {event.categories && event.categories.length
                  ? null
                  : "nebyly zadány"}
              </Typography>
              <ul>
                {event.categories &&
                  event.categories.map((category, index) => (
                    <li className={classes.coloredLists} key={index}>
                      <Typography variant={"body1"} color={"textPrimary"}>
                        {category.name}{" "}
                        {categoryDetails(
                          category.maximumParticipants,
                          category.note
                        )}
                      </Typography>
                    </li>
                  ))}
              </ul>
            </Grid>
          </Grid>
        </Section>
        <EventStaff eventId={eventId} />
      </MainContentContainer>
    </div>
  ) : null;
};

export default EventSummary;
