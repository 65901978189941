import React from "react";
import { makeStyles } from "@material-ui/core";

import MainContentContainer from "../../main-content-container";
import { Typography } from "@material-ui/core";
import SectionPreview from "../sectionPreview";
import EmailIcon from "@material-ui/icons/Email";
import Link from "@material-ui/core/Link";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import CallIcon from "@material-ui/icons/Call";
import Map from "common/components/map/";

const useStyles = makeStyles(theme => ({
  root: {},
  icons: {
    marginRight: theme.spacing(1)
  },
  mapGrid: {
    height: "100vh",
    width: "100%"
  },
  avatar: {
    width: theme.spacing(30),
    height: theme.spacing(30),
    marginLeft: "auto",
    marginRight: "auto"
  },
  contactPerson: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10)
  },
  contactPersonData: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap"
  }
}));

const ContactPreview = ({ parameters }) => {
  const classes = useStyles();

  const { email, contactPersons, address } = parameters;

  return (
    (email || contactPersons || address.gps || false) && (
      <SectionPreview
        title={parameters.title}
        fullHeight={parameters.fullHeight || false}
        titleBackgroundColor={parameters.titleBackgroundColor}
        titleColor={parameters.titleColor}
        noMainContentContainerForContent
      >
        <MainContentContainer>
          <div style={{ textAlign: "center" }}>
            {email && (
              <Typography variant={"h4"}>
                <EmailIcon
                  color="secondary"
                  fontSize={"large"}
                  className={classes.icons}
                />
                <Link
                  component={"a"}
                  href={`mailto:${email}`}
                  target={"_blank"}
                >
                  {email}
                </Link>
              </Typography>
            )}
            {contactPersons &&
              contactPersons.constructor === Array &&
              contactPersons.map(
                (contactPerson, index) =>
                  contactPerson.fullname && (
                    <div className={classes.contactPerson} key={index}>
                      <Avatar
                        className={classes.avatar}
                        src={contactPerson.imageUrl}
                      />
                      <div className={classes.contactPersonData}>
                        <Typography variant={"h4"}>
                          <PersonIcon
                            color="secondary"
                            fontSize={"large"}
                            className={classes.icons}
                          />
                          {contactPerson.fullname}
                        </Typography>
                        {contactPerson.email && (
                          <Typography variant={"h4"}>
                            <EmailIcon
                              color="secondary"
                              fontSize={"large"}
                              className={classes.icons}
                            />
                            <Link
                              component={"a"}
                              href={`mailto:${contactPerson.email}`}
                              target={"_blank"}
                            >
                              {contactPerson.email}
                            </Link>
                          </Typography>
                        )}
                        {contactPerson.phoneNumber && (
                          <Typography variant={"h4"}>
                            <CallIcon
                              color="secondary"
                              fontSize={"large"}
                              className={classes.icons}
                            />
                            {contactPerson.phoneNumber}
                          </Typography>
                        )}
                      </div>
                    </div>
                  )
              )}
          </div>
        </MainContentContainer>
        {address.gps && (
          <div className={classes.mapGrid}>
            <Map
              gpsString={address ? address.gps : null}
              destinationDescription={address ? address.description || "" : ""}
              scrollWheelZoom={false}
            />
          </div>
        )}
      </SectionPreview>
    )
  );
};

export default ContactPreview;
