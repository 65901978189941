import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LONG,
  TOO_SHORT
} from "../../../../../constants/dictionary/validation";

const FIELD_NAME = "order";

export const YUP_ORDER = Yup.number()
  .min(2, TOO_SHORT)
  .max(100, TOO_LONG);

export const YUP_ORDER_REQUIRED = YUP_ORDER.required(REQUIRED);

const OrderField = ({ baseParams, minOrder, maxOrder }) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      label="Číslo pořadí"
      fullWidth={true}
      type="number"
      placeholder="Zde vložte číslo pořadí sekce"
      baseParams={baseParams}
      minValue={minOrder}
      maxValue={maxOrder}
    />
  );
};

export default OrderField;
