import React from "react";
import { Field } from "formik";
import { combineFormErrorMessages } from "../../../utils/diagnostics";
import { makeStyles } from "@material-ui/core";
import { KeyboardDatePicker } from "formik-material-ui-pickers";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  moreSpaceAround: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  }
}));

const DateFieldBase = ({
  value,
  onChange,
  name,
  component,
  label,
  fullWidth = true,
  type,
  inputProps,
  baseParams,
  disableFuture = false,
  openTo = "year",
  format = "dd.MM.yyyy",
  autoOk = false,
  invalidDateMessage = "Neplatný format",
  minDate = "1920-01-01",
  minDateMessage = "Překročen minimální datum",
  maxDate = "2121-01-01",
  maxDateMessage = "Překročen maximální datum",
  initialFocusedDate = "2021-01-01",
  disabled
}) => {
  const classes = useStyles();
  const { errors, formDiagnostics, touched } = baseParams;
  const localCombineFormErrorMessages = () => {
    return combineFormErrorMessages(errors, formDiagnostics, name, touched);
  };

  return (
    <div className={classes.moreSpaceAround}>
      <Field
        // Same as FieldBase
        component={component || KeyboardDatePicker}
        name={name}
        label={label}
        fullWidth={fullWidth}
        type={type}
        inputProps={inputProps}
        helperText={localCombineFormErrorMessages(name)}
        error={!!localCombineFormErrorMessages(name)}
        // Custom date
        disableFuture={disableFuture}
        openTo={openTo}
        format={format}
        animateYearScrolling={false} // nefunguje s openTo="year", nenascroluje na aktualni rok
        autoOk={autoOk} // snadno se dá ukliknout, nepouzivat s openTo="year"
        disableToolbar={false}
        invalidDateMessage={invalidDateMessage}
        minDate={minDate}
        minDateMessage={minDateMessage}
        maxDate={maxDate}
        maxDateMessage={maxDateMessage}
        initialFocusedDate={initialFocusedDate}
        value={value}
        onChange={onChange}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default DateFieldBase;
