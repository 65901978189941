import React from "react";
import { makeStyles } from "@material-ui/core";
import Modal from "../../../components/modalForm/modal";

import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../../redux/common/actions";
import CustomPageSectionEditForm from "./editForm";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  }
}));

export const CUSTOM_PAGE_SECTION_FORM_MODAL_NAME_PREFIX =
  "CustomPageSectionFormModal";

const CustomPageSectionEditFormModal = ({
  customPageSectionId,
  initialValues,
  actualImageUrl,
  onSubmitSuccessfully,
  resourceType,
  resourceId,
  minOrder,
  maxOrder
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const open =
    useSelector(state => state.CommonReducer.modals)[
      CUSTOM_PAGE_SECTION_FORM_MODAL_NAME_PREFIX +
        customPageSectionId.toString()
    ] || false;

  const handleModalClose = () => {
    dispatch(
      closeModal(
        CUSTOM_PAGE_SECTION_FORM_MODAL_NAME_PREFIX +
          customPageSectionId.toString()
      )
    );
  };

  return (
    <div className={classes.root}>
      <Modal
        open={open}
        title={"Upravit sekci"}
        maxWidth={"md"}
        fullWidth={true}
        onClose={handleModalClose}
      >
        <CustomPageSectionEditForm
          customPageSectionId={customPageSectionId}
          initialValues={initialValues}
          onClose={handleModalClose}
          actualImageUrl={actualImageUrl}
          onSubmitSuccessfully={onSubmitSuccessfully}
          resourceType={resourceType}
          resourceId={resourceId}
          minOrder={minOrder}
          maxOrder={maxOrder}
        />
      </Modal>
    </div>
  );
};

export default CustomPageSectionEditFormModal;
