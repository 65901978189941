import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LONG,
  TOO_SHORT
} from "../../../../constants/dictionary/validation";
import { InputAdornment } from "@material-ui/core";
import LockOpenIcon from "@material-ui/icons/LockOpen";

const FIELD_NAME = "password";

export const YUP_NAME = Yup.string()
  .min(5, TOO_SHORT)
  .max(100, TOO_LONG);

export const YUP_PASSWORD_REQUIRED = YUP_NAME.required(REQUIRED);

const PasswordField = ({ baseParams }) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      // autoComplete={"new-password"}
      label="Heslo"
      fullWidth={true}
      type="password"
      placeholder="Zde vložte heslo"
      inputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LockOpenIcon />
          </InputAdornment>
        )
      }}
      baseParams={baseParams}
    />
  );
};

export default PasswordField;
