import { diagnosticIdsToTextsDictionary } from "../constants/diagnosticIdsToTextsDictionary";

export const getFormFieldDiagnosticIds = (
  formDiagnostics,
  formName,
  inputName
) => {
  const diagnostics = formDiagnostics[formName];
  const fieldDiagnostics = diagnostics ? diagnostics[inputName] : undefined;
  return fieldDiagnostics ? fieldDiagnostics : [];
};

export const getFormNonFieldDiagnosticIds = (formDiagnostics, formName) => {
  const diagnostics = formDiagnostics[formName];
  const nonFieldDiagnostics = diagnostics ? diagnostics.nonField : undefined;
  return nonFieldDiagnostics ? nonFieldDiagnostics : [];
};

export const diagnosticIdsToTexts = ids => {
  if (ids && ids.constructor === Array) {
    return [
      ...new Set(
        ids.map(id => {
          const text = diagnosticIdsToTextsDictionary[id];
          return text ? text : diagnosticIdsToTextsDictionary.unknownError;
        })
      )
    ];
  } else {
    return [];
  }
};

export const combineFormErrorMessages = (
  formErrors,
  backendErrors,
  field,
  touched = []
) => {
  return formErrors[field] && touched[field]
    ? formErrors[field]
    : diagnosticIdsToTexts(backendErrors[field]).join(" ");
};
