import React, { useEffect } from "react";
import { Container, makeStyles, Typography, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  setEmailVerificationStatus,
  verifyEmail
} from "../../../redux/auth/actions";
import {
  VERIFICATION_STATUS_VERIFYING,
  VERIFICATION_STATUS_INVALID_HASH,
  VERIFICATION_STATUS_NOT_VERIFIED,
  VERIFICATION_STATUS_VERIFIED_SUCCESSFULLY
} from "../../../constants/verification_status";

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: "900",
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    fontSize: 13,
    color: "grey"
  },
  verificationMessage: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  button: {
    width: "100%",
    margin: theme.spacing(3, 0, 2)
  },
  header: {
    marginBottom: theme.spacing(3)
  }
}));

const EmailVerificationPage = ({ activationHash }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (activationHash?.length > 10) {
        await dispatch(verifyEmail(activationHash));
      } else {
        await dispatch(
          setEmailVerificationStatus(VERIFICATION_STATUS_INVALID_HASH)
        );
      }
    })();
  }, [dispatch, activationHash]);

  const verificationStatus = useSelector(
    state => state.AuthReducer.verificationStatus
  );

  const setStatusMessage = status => {
    // console.log("Calling set status message, ", status);
    let text = "";
    let error = false;

    switch (status) {
      case VERIFICATION_STATUS_VERIFYING:
        text = "Probíhá ověřování emailové adresy...";
        break;
      case VERIFICATION_STATUS_VERIFIED_SUCCESSFULLY:
        text = "Účet byl úspěšně aktivován. Můžete se přihlásit";
        break;
      case VERIFICATION_STATUS_NOT_VERIFIED:
        text =
          "Ověření uživatele pomocí emailu se nezdařilo, zkuste se registrovat znovu.";
        error = true;
        break;
      case VERIFICATION_STATUS_INVALID_HASH:
        text = "Nevalidní ověřovací kód: " + activationHash;
        error = true;
        break;
      default:
        text = "Neočekávaná chyba";
        error = true;
    }

    return (
      <Typography
        variant={"body1"}
        color={error ? "error" : undefined}
        className={classes.verificationMessage}
      >
        {text}
      </Typography>
    );
  };

  return (
    <Container component="main" maxWidth="md">
      <div className={classes.header}>
        <Typography className={classes.title} variant="h5">
          Ověření uživatete
        </Typography>
        <Typography className={classes.subtitle} variant="caption">
          Zabezpečení proti neoprávněnému vytváření účtů pod cizími emaily...
        </Typography>
      </div>
      {setStatusMessage(verificationStatus)}
      {verificationStatus === VERIFICATION_STATUS_VERIFIED_SUCCESSFULLY && (
        <Button
          component={Link}
          to={"/login"}
          className={classes.button}
          variant="outlined"
        >
          Přihlásit
        </Button>
      )}
      {verificationStatus === VERIFICATION_STATUS_NOT_VERIFIED && (
        <Button
          component={Link}
          to={"/register"}
          className={classes.button}
          variant="outlined"
        >
          Registrovat znovu
        </Button>
      )}
    </Container>
  );
};

export default EmailVerificationPage;
