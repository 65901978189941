// @flow

import * as types from "redux/notification/types";

/**
 * Flow types
 */
type Options = {
  key: number,
  variant: "error" | "info" | "warning" | "error" | "success"
};
type Notification = {
  key: string,
  message: string,
  options: Options
};
type State = {
  notifications: Array<?Notification>
};

const INITIAL_STATE = {
  notifications: []
};

export default (state: State = INITIAL_STATE, action: Object) => {
  switch (action.type) {
    case types.ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification
          }
        ]
      };
    case types.REMOVE_SNACKBAR:
      const notifications: Array<Notification> = state.notifications
        .filter(Boolean)
        .filter(
          (notification: Notification) => notification.key !== action.key
        );
      return {
        ...state,
        notifications
      };
    default:
      return state;
  }
};
