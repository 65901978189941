// @flow

import React, { useEffect } from "react";
import { makeStyles, Grid, Typography, Button } from "@material-ui/core";
import {
  loadPhotoGalleries,
  removePhotoGallery
} from "../../../redux/photo-gallery/actions";
import { useDispatch, useSelector } from "react-redux";
import { LOAD_PHOTO_GALLERIES_LOADER_NAME } from "../../../redux/photo-gallery/actions";
import PhotoGalleryPreview from "./photoGalleryPreview";
import AuthorizedOnly from "../authorization/authorizedOnly";
import { authorize } from "../../../utils/authorize";
import ModalFormWrapper from "../modalForm/wrapper";
import PhotoGalleryCreateForm from "./forms/create";
import { closeModal, openModal } from "../../../redux/common/actions";

const createPhotoGalleryModalName = "PHOTO_GALLERY_CREATE_MODAL_NAME"; // TODO: presunout asi do ModalFormWrapper nebo nekde do te slozky

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1)
  },
  loadingMessageWrapper: {
    marginTop: theme.spacing(2)
  }
}));

const PhotoGalleriesContainer = ({
  resourceType,
  resourceId,
  history,
  linkPrefix
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const reload = () => {
    dispatch(loadPhotoGalleries(resourceType, resourceId));
  };

  useEffect(() => {
    reload();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, resourceId, resourceType]);

  const authorization = useSelector(store => store.AuthReducer);
  const editable = authorize(
    authorization,
    "isResourceAdmin",
    resourceType,
    resourceId
  );

  const photoGalleries = useSelector(
    state => state.PhotoGalleryReducer.photoGalleries
  );

  const loading =
    useSelector(state => state.CommonReducer.loaders)[
      LOAD_PHOTO_GALLERIES_LOADER_NAME
    ] || false;

  const onPhotoGalleryClick = photoGalleryId => {
    history.push(`${linkPrefix}/photo-galleries/${photoGalleryId}`);
  };

  const handleRemove = photoGalleryId => {
    dispatch(removePhotoGallery(photoGalleryId, reload));
  };

  const handleCreateFormClose = () => {
    dispatch(closeModal(createPhotoGalleryModalName));
  };

  const handleCreateFormOpen = () => {
    dispatch(openModal(createPhotoGalleryModalName));
  };

  const handleNewPhotoGalleryCreated = () => {
    handleCreateFormClose();
    reload();
  };

  return (
    <div className={classes.root}>
      <AuthorizedOnly
        expectedRole={"isResourceAdmin"}
        resourceType={resourceType}
        resourceId={resourceId}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={handleCreateFormOpen}
        >
          Vytvořit novou fotogalerii
        </Button>
        <ModalFormWrapper
          modalName={createPhotoGalleryModalName}
          title={"Přidat fotogalerii"}
        >
          <PhotoGalleryCreateForm
            initialValues={{ name: "" }}
            actualImages={[]}
            onClose={handleCreateFormClose}
            onSubmitSuccessfully={handleNewPhotoGalleryCreated}
            resourceType={resourceType}
            resourceId={resourceId}
          />
        </ModalFormWrapper>
      </AuthorizedOnly>
      <Grid container spacing={3}>
        {photoGalleries &&
        photoGalleries.constructor === Array &&
        photoGalleries.length > 0 ? (
          photoGalleries.map((photoGallery, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <PhotoGalleryPreview
                id={photoGallery.id}
                name={photoGallery.name}
                createDatetime={photoGallery.createDatetime}
                previewImages={photoGallery.images}
                imagesCount={photoGallery.imagesCount}
                onClick={onPhotoGalleryClick}
                editable={editable}
                onDelete={() => handleRemove(photoGallery.id)}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} className={classes.loadingMessageWrapper}>
            <Typography variant={"body1"}>
              {loading ? "načítání..." : "žádné fotogalerie nebyly vloženy"}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default PhotoGalleriesContainer;
