import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import MediaCard from "../../mediaCard";
import SectionPreview from "../sectionPreview";
import PreviewPageMediaCardsEditFormModal, {
  CUSTOM_PAGE_MEDIA_CARDS_FORM_MODAL_NAME
} from "./editFormModal";
import { openModal } from "../../../../redux/common/actions";
import { useDispatch } from "react-redux";
import { deleteCustomPageSection } from "../../../../redux/customPage/actions";
import SectionAction from "../components/sectionAction";
import AuthorizedOnly from "../../authorization/authorizedOnly";
import { rawDraftJsStringToHtml } from "../../draftjsEditor/utils";

export const COMPONENT_NAME = "MediaCardsPreview";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative"
  },
  editButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1)
  }
}));

const MediaCardsPreview = ({
  customPageSectionId,
  parameters,
  sectionOrder,
  resourceType,
  resourceId,
  onReload,
  minOrder,
  maxOrder
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleEditModalOpen = () => {
    dispatch(
      openModal(
        CUSTOM_PAGE_MEDIA_CARDS_FORM_MODAL_NAME + customPageSectionId.toString()
      )
    );
  };
  const handleRemoveSection = () => {
    dispatch(
      deleteCustomPageSection({
        customPageSectionId: customPageSectionId,
        resourceType,
        resourceId,
        onSuccess: onReload,
        loaderName: "TODO_LOADER_NAME",
        modalName: "TODO_MOADL_NAME"
      })
    );
  };

  const { mediaItems } = parameters;

  return (
    <div className={classes.root}>
      <SectionPreview
        title={parameters.title}
        fullHeight={parameters.fullHeight || false}
        titleBackgroundColor={parameters.titleBackgroundColor}
        titleColor={parameters.titleColor}
      >
        {customPageSectionId && (
          <AuthorizedOnly
            expectedRole={"isResourceAdmin"}
            resourceType={resourceType}
            resourceId={resourceId}
          >
            <PreviewPageMediaCardsEditFormModal
              customPageSectionId={customPageSectionId}
              initialValues={{
                title: (parameters && parameters.title) || "",
                fullHeight: (parameters && parameters.fullHeight) || false,
                order: sectionOrder || "",
                title1:
                  (mediaItems &&
                    mediaItems.constructor === Array &&
                    mediaItems[0]?.title) ||
                  "",
                content1:
                  (mediaItems &&
                    mediaItems.constructor === Array &&
                    mediaItems[0]?.description) ||
                  "",
                imageId1: mediaItems[0]?.image?.id || "",
                title2:
                  (mediaItems &&
                    mediaItems.constructor === Array &&
                    mediaItems[1]?.title) ||
                  "",
                content2:
                  (mediaItems &&
                    mediaItems.constructor === Array &&
                    mediaItems[1]?.description) ||
                  "",
                imageId2: mediaItems[1]?.image?.id || "",
                title3:
                  (mediaItems &&
                    mediaItems.constructor === Array &&
                    mediaItems[2]?.title) ||
                  "",
                content3:
                  (mediaItems &&
                    mediaItems.constructor === Array &&
                    mediaItems[2]?.description) ||
                  "",
                imageId3: mediaItems[2]?.image?.id || ""
              }}
              actualImageUrl1={
                (mediaItems &&
                  mediaItems.constructor === Array &&
                  mediaItems[0]?.image?.url) ||
                ""
              }
              actualImageUrl2={
                (mediaItems &&
                  mediaItems.constructor === Array &&
                  mediaItems[1]?.image?.url) ||
                ""
              }
              actualImageUrl3={
                (mediaItems &&
                  mediaItems.constructor === Array &&
                  mediaItems[2]?.image?.url) ||
                ""
              }
              onSubmitSuccessfully={onReload}
              resourceType={resourceType}
              resourceId={resourceId}
              minOrder={minOrder}
              maxOrder={maxOrder}
            />
            <div className={classes.editButton}>
              <SectionAction
                title={"Akční možnosti této sekce"}
                onEdit={handleEditModalOpen}
                onRemove={handleRemoveSection}
              />
            </div>
          </AuthorizedOnly>
        )}
        {mediaItems && mediaItems.constructor === Array ? (
          <Grid container spacing={3}>
            {mediaItems.map((mediaItem, index) => (
              <Grid item sm={12} lg={4} md={6} key={index}>
                <MediaCard
                  fullHeight={true}
                  title={mediaItem.title}
                  centerTitle={mediaItem.centerTitle}
                  descriptionHtml={rawDraftJsStringToHtml(
                    mediaItem.description
                  )}
                  imageUrl={mediaItem.image && mediaItem.image.url}
                  // imageTitle={mediaItem.imageTitle}
                  cardMediaComponent={mediaItem.cardMediaComponent}
                  cardMediaChildren={mediaItem.cardMediaChildren}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant={"body2"}>obsah této sekce chybí</Typography>
        )}
      </SectionPreview>
    </div>
  );
};

export default MediaCardsPreview;
