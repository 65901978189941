export const LOAD_PROPOSITION_CUSTOM_PAGE =
  "EVENT_LOAD_PROPOSITION_CUSTOM_PAGE";
export const GET_EVENTS = "EVENT_GET_EVENTS";
export const GET_EVENT = "EVENT_GET_EVENT";
export const GET_EVENT_PARTICIPANTS = "EVENT_GET_EVENT_PARTICIPANTS";
export const GET_EVENT_CONTACT = "EVENT_GET_EVENT_CONTACT";
export const GET_EVENT_SPONSORS = "EVENT_GET_EVENT_SPONSORS";
export const LOAD_ALL_POSSIBLE_EVENT_CATEGORIES =
  "LOAD_ALL_POSSIBLE_EVENT_CATEGORIES";
export const GET_EVENT_STAFFS = "GET_EVENT_STAFFS";
export const RESET_EVENT_STATE = "RESET_EVENT_STATE";
