import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LARGE,
  TOO_LOW
} from "../../../../../../../constants/dictionary/validation";

const FIELD_NAME = "pointsC";

export const YUP_POINTS_C = Yup.number()
  .nullable(true)
  .min(0, TOO_LOW)
  .max(100, TOO_LARGE);

export const YUP_POINTS_C_REQUIRED = YUP_POINTS_C.required(REQUIRED);

const PointsCField = ({ baseParams }) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      label="Oddíl C (obrana)"
      fullWidth={true}
      type="number"
      baseParams={baseParams}
    />
  );
};

export default PointsCField;
