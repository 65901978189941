import React from "react";
import { makeStyles } from "@material-ui/core";
import Modal from "../../../../../../common/components/modalForm/modal";

import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../../../../redux/common/actions";
import EventCompetitorEditForm from "./editForm";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  }
}));

export const EVENT_COMPETITOR_EDIT_FORM_MODAL_NAME_PREFIX =
  "EventCompetitorEditFormModal";

const EventCompetitorEditFormModal = ({
  competitorId,
  eventId,
  initialValues,
  actualProfileImageUrl,
  onSubmitSuccessfully
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const open = useSelector(state => state.PersonReducer.editFormOpen);
  const open =
    useSelector(state => state.CommonReducer.modals)[
      EVENT_COMPETITOR_EDIT_FORM_MODAL_NAME_PREFIX + competitorId.toString()
    ] || false;

  const handleModalClose = () => {
    dispatch(
      closeModal(
        EVENT_COMPETITOR_EDIT_FORM_MODAL_NAME_PREFIX + competitorId.toString()
      )
    );
  };

  return (
    <div className={classes.root}>
      <Modal
        open={open}
        title={"Upravit závodníka"}
        maxWidth={"md"}
        fullWidth={true}
        onClose={handleModalClose}
      >
        <EventCompetitorEditForm
          competitorId={competitorId}
          eventId={eventId}
          initialValues={initialValues}
          onClose={handleModalClose}
          actualProfileImageUrl={actualProfileImageUrl}
          onSubmitSuccessfully={onSubmitSuccessfully}
        />
      </Modal>
    </div>
  );
};

export default EventCompetitorEditFormModal;
