// @flow
import React, { useEffect } from "react";

// material-ui
// import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";

// react-router
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";

// components
import DrawerMenu from "pages/main-page/menu-component/drawer-menu/drawerMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  isOneOfRoutesActive,
  RouteWithSubRoutes
} from "../../../../utils/routes";
// const useStyles = makeStyles(theme => ({
// }));

import { getRoutes } from "pages/contentPages/trainingClubPages/sub-pages/routes";
import NotFoundPage from "../../../main-page/notFound";
import { authorizeResource } from "../../../../redux/auth/actions";
import { resetClubRedux } from "../../../../redux/trainingClub/actions";
import { resetArticleState } from "../../../../redux/article/actions";
import { resetPhotoGalleryState } from "../../../../redux/photo-gallery/actions";
import { resetMenuState } from "../../../../redux/menu/actions";

const TrainingClubPages = ({
  location,
  mobileOpen,
  handleDrawerToggle,
  isMainPage
}) => {
  // const classes = useStyles();
  const dispatch = useDispatch();

  let { id: paramId } = useParams();
  paramId = parseInt(paramId);

  const customDomain = useSelector(state => state.CommonReducer.customDomain);
  const isAuthenticated = useSelector(
    state => state.AuthReducer.isAuthenticated
  );

  let id = isMainPage && customDomain ? customDomain.resourceId : paramId;

  useEffect(() => {
    if (isAuthenticated) {
      const dispatchAuthorizeResource = async () => {
        await dispatch(authorizeResource("clubs", id));
      };
      dispatchAuthorizeResource();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, id, isAuthenticated]);

  useEffect(() => {
    dispatch(resetClubRedux());
    dispatch(resetArticleState());
    dispatch(resetPhotoGalleryState());
    dispatch(resetMenuState());
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, id]);

  const linkPrefix = isMainPage ? "" : `/clubs/${id}`;
  const pathPrefix = isMainPage ? "" : "/clubs/:id";
  const routes = getRoutes(id, linkPrefix, pathPrefix);
  return isOneOfRoutesActive(location.pathname, routes) ? (
    <>
      <DrawerMenu
        resourceType={"trainingClub"}
        items={routes}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        useMobileVersion={isOneOfRoutesActive(
          location.pathname,
          routes.filter(route => route.mobileVersionMenu)
        )}
      />
      {/* CONTENT */}
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} linkPrefix={linkPrefix} />
        ))}
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </>
  ) : (
    <NotFoundPage />
  );
};

export default withRouter(TrainingClubPages);
