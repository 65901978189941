import React from "react";
import { makeStyles, Paper } from "@material-ui/core";
import ControlledImage from "../../controlledImage";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%"
  },
  imageWrapper: {
    width: "150px",
    height: "150px"
  },
  deletedImage: {
    opacity: 0.3
  }
}));

const Image = ({
  imageId,
  url,
  articleEditorShown = false,
  newImagePlaceholder = false,
  onAdd = undefined,
  deleted = false,
  onRemove = undefined,
  onRestore = undefined,
  onClick = undefined
}) => {
  const classes = useStyles();

  const removeThisImage = () => {
    onRemove(imageId);
  };
  const restoreThisImage = () => {
    onRestore(imageId);
  };

  return (
    <div className={classes.root}>
      <Paper elevation={3} className={classes.imageWrapper}>
        <ControlledImage
          url={url}
          isControlled={articleEditorShown}
          onRemove={!newImagePlaceholder ? removeThisImage : undefined}
          deleted={deleted}
          onRestore={restoreThisImage}
          onAdd={onAdd}
        >
          {!newImagePlaceholder ? (
            <img
              src={url}
              className={clsx(classes.image, deleted && classes.deletedImage)}
              alt={"article"}
              onClick={onClick}
            />
          ) : null}
        </ControlledImage>
      </Paper>
    </div>
  );
};

export default Image;
