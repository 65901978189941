import React from "react";
import { makeStyles } from "@material-ui/core";
import Modal from "../../../common/components/modalForm/modal";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  paragraph: {
    marginBottom: theme.spacing(2)
  }
}));

const VerificationEmailSentDialog = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      title={"Ověřovací email byl odeslán"}
      onClose={onClose}
      contentText={
        <div>
          <Typography variant={"body1"} className={classes.paragraph}>
            Na Vaši emailovou adresu byl odeslán ověřovací email.
          </Typography>
          <Typography variant={"body1"} className={classes.paragraph}>
            Nyní je potřeba ověři, že uvedená emailová adresa je platná a je
            Vaše. Přejděte prosím do svého emailového účtu, otevřete email od
            nás a postupujte podle pokynů v tomto emailu.
          </Typography>
          <Typography variant={"body1"} className={classes.paragraph}>
            V případě, že jste od nás žádný email neobdrželi, zkontrolujte ještě
            prosím složku spam a hromadné zprávy. Pokud ani tam nebude, zkuste
            registraci opakovat po půl hodině. Kdyby ani potom email nepřišel,
            kontaktujte nás a my Vám email ověříme.
          </Typography>
          <Typography variant={"body1"}>Děkujeme za spolupráci.</Typography>
        </div>
      }
      buttonsProperties={[
        {
          onClick: onClose,
          text: "Zavřít"
        }
      ]}
    />
  );
};

export default VerificationEmailSentDialog;
