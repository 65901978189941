import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(theme => ({
  fullHeight: {
    height: "100%"
  },
  media: {
    height: 300
  },
}));

const CategoryCard = props => {
  const classes = useStyles();
  const { name, description, imageUrl, navigationPath, imageTitle } = props;

  return (
    <Card className={classes.fullHeight}>
      <CardActionArea className={classes.fullHeight} component={"div"}>
        <Link
          component={RouterLink}
          to={navigationPath}
          underline="none"
          color="inherit"
        >
          <CardMedia
            className={classes.media}
            image={imageUrl}
            title={imageTitle}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {description}
            </Typography>
          </CardContent>
        </Link>
      </CardActionArea>
      {/*<CardActions>*/}
      {/*  <Button size="small" color="primary">*/}
      {/*    Share*/}
      {/*  </Button>*/}
      {/*  <Button size="small" color="primary">*/}
      {/*    Learn More*/}
      {/*  </Button>*/}
      {/*</CardActions>*/}
    </Card>
  );
};

export default CategoryCard;
