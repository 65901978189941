// @flow

import React from "react";
import { Link as RouterLink } from "react-router-dom";

// material-ui
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import CopyrightIcon from "@material-ui/icons/Copyright";
import Link from "@material-ui/core/Link";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "white",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: "center",
    bottom: "0px",
    right: "0px",
    left: "0px",
    position: "absolute"
  },
  iconInText: {
    verticalAlign: "bottom"
  }
}));

const Footer = () => {
  const classes = useStyles();

  const customDomain = useSelector(state => state.CommonReducer.customDomain);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={4}>
          {customDomain && [
            <Typography variant={"body1"} color={"textSecondary"} key={1}>
              Vytvořeno v aplikaci
              <Link
                href="https://kynologove.cz"
                component={"a"}
                target={"_blank"}
              >
                {" "}
                kynologove.cz
              </Link>
            </Typography>,
            <Typography variant={"body2"} color={"textSecondary"} key={2}>
              kynologický informační systém
            </Typography>
          ]}
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant={"body2"} color="textSecondary">
            Copyright{" "}
            <CopyrightIcon
              fontSize={"small"}
              color={"secondary"}
              className={classes.iconInText}
            />{" "}
            2021 Kynologove.cz
          </Typography>
          <Typography variant={"body2"} color="textSecondary">
            Chyby, připomínky a návrhy k webu posílejte prosím na email{" "}
            <Link
              component={"a"}
              href={"mailto:kynologove@gmail.com"}
              target={"_blank"}
            >
              kynologove@gmail.com
            </Link>
            .
          </Typography>
          <Typography variant={"body2"} color="textSecondary">
            <RouterLink to="/gdpr">Ochrana osobních údajů</RouterLink>{" "}
            {/*<RouterLink to="/terms-and-conditions">*/}
            {/*  Všeobecné obchodní podmínky*/}
            {/*</RouterLink>*/}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          {" "}
        </Grid>
      </Grid>
      {/*fb: https://fb.me/kynologove.cz messenger: https://m.me/kynologove.cz*/}
    </div>
  );
};

export default Footer;
