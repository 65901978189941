import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LONG,
  TOO_SHORT
} from "../../../../../../../constants/dictionary/validation";
// import { InputAdornment } from "@material-ui/core";
// import {Home, Person} from "@material-ui/icons";

const FIELD_NAME = "websiteUrl";

export const YUP_WEBSITE_URL = Yup.string()
  .min(2, TOO_SHORT)
  .max(200, TOO_LONG);

export const YUP_WEBSITE_URL_REQUIRED = YUP_WEBSITE_URL.required(REQUIRED);

const WebsiteUrlField = ({ baseParams }) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      label="Odkaz na webové stránky klubu"
      fullWidth={true}
      type="text"
      placeholder="Zde vložte odkaz na webové stránky klubu"
      // InputProps={{
      //   startAdornment: (
      //     <InputAdornment position="start">
      //       <Home />
      //     </InputAdornment>
      //   )
      // }}
      baseParams={baseParams}
    />
  );
};

export default WebsiteUrlField;
