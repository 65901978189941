import React from "react";
import {
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText
} from "@material-ui/core";

import { Switch } from "formik-material-ui";
import { Field } from "formik";
import { REQUIRED } from "../../../constants/dictionary/validation";

import FirstNameField from "./fields/firstName";
import LastNameField from "./fields/lastName";
import CityField from "./fields/city";
import StreetField from "./fields/street";
import ZipNumberField from "./fields/zipNumber";
import PhoneNumberField from "./fields/phoneNumber";
import BirthDateField, {
  FIELD_NAME as BIRTH_DATE_FIELD_NAME
} from "./fields/birthDate";
import PersonSearchBox from "./PersonSearchBox";

export const personCreateEditFormDynamicValidation = ({
  values,
  realUserSelected,
  createForm = false
}) => {
  const errors = {};
  if (createForm && !values.isVirtual && !values.email && !realUserSelected) {
    errors.email =
      "Email skutečného uživatele musí být zadaný. Pokud email neznáte, vytvořte prosím virtuálního uživatele.";
  }
  if ((values.isVirtual || !realUserSelected) && !values.firstName) {
    // Virtualni uzivatel musi mit zadane jmeno
    errors.firstName = REQUIRED;
  }
  if ((values.isVirtual || !realUserSelected) && !values.lastName) {
    // Virtualni uzivatel musi mit zadane jmeno
    errors.lastName = REQUIRED;
  }
  return errors;
};

const PersonCreateEditFormLayout = ({
  values,
  errors,
  formDiagnostics,
  touched,
  setFieldValue,
  createForm,
  realUserSelected,
  setRealUserSelected,
  showBirthDate = false
}) => {
  const handlePersonSelect = value => {
    if (!value) {
      setFieldValue("email", "");
      setFieldValue("userId", null);
      setRealUserSelected(false);
      return;
    }
    setFieldValue("email", value.value);
    setFieldValue("userId", value.userId);
    setRealUserSelected(!value?.creatable);
  };

  return (
    <Grid container spacing={2}>
      {createForm && (
        <Grid item xs={12} md={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Typ uživatele</FormLabel>
            <FormControlLabel
              control={
                <Field component={Switch} type="checkbox" name="isVirtual" />
              }
              label={
                values.isVirtual ? "virtuální uživatel" : "skutečný uživatel"
              }
              // onChange={e => handleIsVirtualToggle(e, handleChange, setFieldValue)}
              // onClick={() =>
              //   handleIsVirtualToggle(values, setFieldValue)
              // }
            />
            <FormHelperText>
              {values.isVirtual
                ? "Virtuální uživatel (opak skutečného uživatele) je uživatel bez zadaného emailu. Tento uživatel může existovat pouze v tomto klubu a nebude moct být nikdy použit nikde mimo tento klub. Pokud neznáte email uživatele, je virtuální uživatel možnost, jak uživatele vytvořit. Jakoukoliv změnu profilu uživatele můžete udělat vy."
                : "Skutečný uživatel (opak virtuálního uživatele) je uživatel se zadaným emailem. Pomocí tohoto emailu se může tento uživatel přihlásit k jeho účtu. Uživatel může být dále používán i mimo klub. Jakékoliv členství (v klubu, akce) musí uživatel potvrdit. Jakoukoliv změnu profilu (netýká se funkcí klubu apod. si musí skutečný uživatel změnit sám."}
            </FormHelperText>
          </FormControl>
        </Grid>
      )}

      {createForm &&
        !values.isVirtual && [
          <Grid item xs={12} md={12} key={1}>
            <PersonSearchBox
              label={"Email"}
              // placeholder="Zde vložte jméno a příjmení hledané osoby"
              placeholder="Zde vložte email osoby."
              initialValues={{ fullname: values.email }}
              // onChange={value => setFieldValue("email", value)}
              onChange={handlePersonSelect}
              multiple={false}
              disabled={!createForm}
              baseParams={{ errors, formDiagnostics, touched, name: "email" }}
              findByEmail={true}
              helperText={
                values.email?.length
                  ? realUserSelected
                    ? "Tento existující uživatel bude pozván do klubu. Všechny další profilové informace si tento uživatel spravuje sám."
                    : "Uživatele jsme nenašli, vytvoříte nového uživatele a pozvete ho sem."
                  : "Vyhledejte již existujícího uživatele nebo vytvořte nového."
              }
            />
          </Grid>
        ]}

      {(values.isVirtual || !realUserSelected || !createForm) && [
        <Grid item xs={12} md={6} key={1}>
          <FirstNameField
            baseParams={{ errors, formDiagnostics, touched }}
            placeholder={"Zde vložte jméno osoby."}
            autoCompleteOff={true}
            disabled={!createForm && realUserSelected && !values.isVirtual}
          />
        </Grid>,
        <Grid item xs={12} md={6} key={2}>
          <LastNameField
            baseParams={{ errors, formDiagnostics, touched }}
            placeholder={"Zde vložte příjmení osoby."}
            autoCompleteOff={true}
            disabled={!createForm && realUserSelected && !values.isVirtual}
          />
        </Grid>,
        <Grid item xs={12} md={6} key={3}>
          <CityField
            baseParams={{ errors, formDiagnostics, touched }}
            autoCompleteOff={true}
            disabled={!createForm && realUserSelected && !values.isVirtual}
          />
        </Grid>,
        <Grid item xs={12} md={6} key={4}>
          <StreetField
            baseParams={{ errors, formDiagnostics, touched }}
            disabled={!createForm && realUserSelected && !values.isVirtual}
            autoCompleteOff={true}
          />
        </Grid>,
        <Grid item xs={12} md={12} key={5}>
          <ZipNumberField
            baseParams={{ errors, formDiagnostics, touched }}
            disabled={!createForm && realUserSelected && !values.isVirtual}
            autoCompleteOff={true}
          />
        </Grid>,
        <Grid item xs={12} md={6} key={6}>
          <PhoneNumberField
            baseParams={{ errors, formDiagnostics, touched }}
            disabled={!createForm && realUserSelected && !values.isVirtual}
            autoCompleteOff={true}
          />
        </Grid>,
        showBirthDate ? (
          <Grid item xs={12} md={6} key={7}>
            <BirthDateField
              value={values[BIRTH_DATE_FIELD_NAME]}
              setFieldValue={setFieldValue}
              baseParams={{ errors, formDiagnostics, touched }}
              disabled={!createForm && realUserSelected && !values.isVirtual}
            />
          </Grid>
        ) : null
      ]}
    </Grid>
  );
};

export default PersonCreateEditFormLayout;
