import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LONG,
  TOO_SHORT
} from "../../../../../../../constants/dictionary/validation";
// import { InputAdornment } from "@material-ui/core";
// import {Home, Person} from "@material-ui/icons";

const FIELD_NAME = "name";

export const YUP_NAME = Yup.string()
  .min(2, TOO_SHORT)
  .max(100, TOO_LONG);

export const YUP_NAME_REQUIRED = YUP_NAME.required(REQUIRED);

const NameField = ({ baseParams }) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      label="Jméno (bez CH.S.)"
      fullWidth={true}
      type="text"
      placeholder="Zde vložte jméno psa bez chov. stanice"
      // InputProps={{
      //   startAdornment: (
      //     <InputAdornment position="start">
      //       <Home />
      //     </InputAdornment>
      //   )
      // }}
      baseParams={baseParams}
    />
  );
};

export default NameField;
