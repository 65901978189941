import React from "react";
import * as Yup from "yup";
// import { REQUIRED } from "../../../../../../../../constants/dictionary/validation";
import SwitchFieldBase from "../../../../../../../../common/components/forms/switchFieldBase";

const FIELD_NAME = "isContactPerson";

export const YUP_IS_CONTACT_PERSON = Yup.boolean();
// export const YUP_IS_CONTACT_PERSON_REQUIRED = YUP_IS_CONTACT_PERSON.required(
//   REQUIRED
// );

const IsContactPersonField = ({ baseParams, disabled }) => {
  return (
    <SwitchFieldBase
      name={FIELD_NAME}
      label={"Kontaktní osoba"}
      disabled={disabled}
      baseParams={baseParams}
    />
  );
};

export default IsContactPersonField;
