export const diagnosticIdsToTextsDictionary = {
  unknownError: "Neznáma chyba.",
  user_already_exists:
    "Uživatel s touto emailovou adresou již existuje. Zkontrolujte prosím, jestli od nás nemáte doručený aktivační email. Jinak pokračujte prosím níže pomocí zapomenutého hesla.",
  user_does_not_exist: "Uživatel neexistuje.",
  activation_link_was_recently_sent:
    "Aktivační odkaz byl nedávno odeslán na Vaši emailovou adresu. Zkontrolujte prosím svoji emailovou schránku a postupujte podle instrukcí v emailu. Pokud Vám email nepřišel, zkuste registraci opakovat pozdeji.",
  user_already_verified: "Váš učet už je oveřený.",
  invalid_activation_hash: "Neplatný aktivační odkaz.",
  reset_password_was_recently_sent:
    "Odkaz pro obnovení hesla byl nedávno odeslán na Vaši emailovou adresu. Zkontrolujte prosím svoji emailovou schránku a postupujte podle instrukcí v emailu. Pokud Vám email nepřišel, zkuste obnovení hesla opakovat později.",
  invalid_value: "Nevalidní hodnota.",
  member_already_exists: "Tento člen již existuje.",
  invalid_parameter: "Neplatný vstup."
};
