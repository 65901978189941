import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LONG,
  TOO_SHORT
} from "../../../../../../../constants/dictionary/validation";

const FIELD_NAME = "name";

export const YUP_NAME = Yup.string()
  .min(2, TOO_SHORT)
  .max(100, TOO_LONG);

export const YUP_NAME_REQUIRED = YUP_NAME.required(REQUIRED);

const NameField = ({ baseParams }) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      label="Název"
      fullWidth={true}
      type="text"
      placeholder="Zde vložte název akce"
      baseParams={baseParams}
    />
  );
};

export default NameField;
