import { ThunkAction } from "redux-thunk";
import axios from "../../utils/axios";
import * as types from "./types";
import { enqueueSnackbar } from "../notification/notification-actions";
import {
  appendFormDiagnostics,
  closeModal,
  disableLoader,
  enableLoader,
  resetFormDiagnostics
} from "../common/actions";
import {
  CLUB_MEMBER_CREATE_FORM_MODAL_NAME,
  CLUB_MEMBER_EDIT_FORM_MODAL_NAME
} from "../../pages/contentPages/trainingClubPages/sub-pages/members/forms/editForm";
import { CLUB_CONTACT_EDIT_FORM_MODAL_NAME } from "../../pages/contentPages/trainingClubPages/sub-pages/contact/editFormModal";
import {datetime_from_server} from "../../utils/datetime";

export const GET_TRAINING_CLUB_PREVIEW_LOADER_NAME =
  "TRAINING_CLUB_GET_PREVIEW";

export const GET_TRAINING_CLUB_MEMBERS_LOADER_NAME =
  "GET_TRAINING_CLUB_MEMBERS_LOADER_NAME";

export const GET_TRAINING_CLUB_ABOUT_LOADER_NAME =
  "GET_TRAINING_CLUB_ABOUT_LOADER_NAME";

export const GET_TRAINING_CLUB_CONTACT_LOADER_NAME =
  "GET_TRAINING_CLUB_CONTACT_LOADER_NAME";

export const resetClubRedux = () => {
  return async (dispatch: ThunkAction) => {
    dispatch({
      type: types.RESET_CLUB_STATE
      // payload: { shown: shown }
    });
  };
};

/**
 * Get training clubs for given filter `POST /v1/clubs/`
 */
export const getTrainingClubs = (filter = null, displayOptions = null) => {
  return async (dispatch: ThunkAction) => {
    try {
      const response = await axios({
        url: "/v1/clubs/",
        method: "post",
        data: { filter: filter || {} },
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch({
        type: types.GET_TRAINING_CLUBS,
        payload:
          response.data.clubs.map(club => {
            return {
              id: club.id,
              name: club.name,
              shortDescription: club.shortDescription,
              imageUrl: club.image ? club.image.url : null
            };
          }) || []
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání chovatelských klubů došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

/**
 * Get training club summary `POST /v1/clubs/<id>/summary`
 */
export const getTrainingClub = (id, displayOptions = null) => {
  return async (dispatch: ThunkAction) => {
    try {
      // TODO: RESET CLUB if id changed... otherwise previous loaded data are show for the short time
      const response = await axios({
        url: "/v1/clubs/" + id + "/summary/",
        method: "get",
        // data: { filter: { id: id } },
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch({
        type: types.GET_TRAINING_CLUB,
        payload: {
          id: response.data.summary.id,
          name: response.data.summary.name,
          created: datetime_from_server(response.data.summary.created),
          creator: response.data.summary.creator,
          shortDescription: response.data.summary.shortDescription,
          longDescription: response.data.summary.longDescription,
          imageUrl: response.data.summary.image
            ? response.data.summary.image.url
            : null,
          websiteUrl: response.data.summary.websiteUrl,
          possibilities: response.data.summary.possibilities,
          parentOrganization: response.data.summary.parentOrganization,
          parentOrganizationId: response.data.summary.parentOrganizationId,
          basicOrganizationNumber: response.data.summary.basicOrganizationNumber
        }
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání chovatelského klubu došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

/**
 * GET about html content from `/v1/clubs/<id>/about`
 * @param {number} clubId id of training club to load about html content
 */
export const getAbout = (clubId: number) => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(GET_TRAINING_CLUB_ABOUT_LOADER_NAME));
    try {
      const response = await axios({
        url: "/v1/clubs/" + clubId + "/about",
        method: "get",
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch({
        type: types.GET_TRAINING_CLUB_ABOUT,
        payload: response.data.aboutCustomPage
          ? {
              id: response.data.aboutCustomPage.id,
              title: response.data.aboutCustomPage.title,
              sections: response.data.aboutCustomPage.sections.map(section => ({
                id: section.id,
                order: section.order,
                componentName: section.componentName,
                properties: JSON.parse(section.propertiesJson)
              }))
            }
          : null
      });
      // callback();
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání stránky o klubu došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(GET_TRAINING_CLUB_ABOUT_LOADER_NAME));
    }
  };
};

/**
 * GET preview custom page from `/v1/clubs/<id>/preview`
 * @param {number} clubId id of training club to load preview page
 */
export const getPreview = (clubId: number) => {
  return async (dispatch: ThunkAction) => {
    try {
      dispatch({ type: types.RESET_PREVIEW });
      dispatch(enableLoader(GET_TRAINING_CLUB_PREVIEW_LOADER_NAME));
      const response = await axios({
        url: "/v1/clubs/" + clubId + "/preview",
        method: "get",
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch({
        type: types.GET_TRAINING_CLUB_PREVIEW,
        payload: {
          id: response.data.previewPage.id,
          title: response.data.previewPage.title,
          topMenu: JSON.parse(response.data.previewPage.topMenuJson),
          sections: response.data.previewPage.sections.map(section => ({
            id: section.id,
            order: section.order,
            componentName: section.componentName,
            properties: JSON.parse(section.propertiesJson)
          }))
        }
      });
      // callback();
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání úvodní stránky došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(GET_TRAINING_CLUB_PREVIEW_LOADER_NAME));
    }
  };
};

/**
 * Get training club contact `GET /v1/clubs/<id>/contact`
 */
export const getTrainingClubContact = id => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(GET_TRAINING_CLUB_CONTACT_LOADER_NAME));
    try {
      const response = await axios({
        url: "/v1/clubs/" + id + "/contact/",
        method: "get",
        // data: { filter: { id: id } },
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch({
        type: types.GET_TRAINING_CLUB_CONTACT,
        payload: {
          address: response.data.contact.address,
          persons: response.data.contact.persons.map(person => {
            return {
              id: person.id,
              name: person.name,
              surname: person.surname,
              email: person.email,
              phoneNumber: person.phoneNumber,
              address: person.address,
              roles: person.roles,
              imageUrl: person.image ? person.image.url : null
            };
          })
        }
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání kontaktů klubu došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(GET_TRAINING_CLUB_CONTACT_LOADER_NAME));
    }
  };
};

/**
 * GET club members from `/v1/clubs/<id>/members`
 * @param {number} clubId id of training club to load members
 */
export const getTrainingClubMembers = (clubId: number) => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(GET_TRAINING_CLUB_MEMBERS_LOADER_NAME));
    try {
      const response = await axios({
        url: "/v1/clubs/" + clubId + "/members",
        method: "get",
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch({
        type: types.GET_TRAINING_CLUB_MEMBERS,
        payload: response.data.members.map(member => {
          return {
            id: member.id,
            imageUrl: member.image ? member.image.url : null,
            name: member.name,
            surname: member.surname,
            fullname: member.fullname,
            email: member.email,
            phoneNumber: member.phoneNumber,
            address: member.address,
            birthDate: member.birthDate,
            memberId: member.memberId,
            roles: member.roles,
            isAdmin: member.isAdmin,
            isContactPerson: member.isContactPerson
          };
        })
      });
      // callback();
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání členů klubu došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(GET_TRAINING_CLUB_MEMBERS_LOADER_NAME));
    }
  };
};

/**
 * Get events for given filter `POST /v1/events/`  TODO filter and rename endpoint to `events`
 */
export const getTrainingClubEvents = clubId => {
  return async (dispatch: ThunkAction) => {
    try {
      const response = await axios({
        url: "/v1/events/",
        method: "post",
        data: { filter: { clubId: clubId } },
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch({
        type: types.GET_TRAINING_CLUB_EVENTS,
        payload:
          response.data.events.map(event => {
            return {
              id: event.id,
              name: event.name,
              term: datetime_from_server(event.term),
              venue: event.venue,
              shortDescription: event.shortDescription,
              imageUrl: event.image ? event.image.url : null
            };
          }) || []
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání akcí klubu došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

/**
 * Removes member from club by given member id (id in club_user table) `DELETE /v1/clubs/<club_id>/members/<member_id>/`
 */
export const removeClubMember = (club_id, member_id, onSuccess) => {
  return async (dispatch: ThunkAction) => {
    try {
      await axios({
        url: `/v1/clubs/${club_id}/members/${member_id}/`,
        method: "delete",
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu odstraňování člena klubu došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

export const SUBMITTING_CLUB_MEMBER_EDIT_FORM_LOADER =
  "SUBMITTING_CLUB_MEMBER_EDIT_FORM_LOADER";

/**
 * Edit club member `POST /v1/clubs/<clubId>/members/<memberId>`
 */
export const submitClubMemberEditForm = ({
  clubId,
  memberId,
  userId,
  values,
  formName,
  modalName,
  onSuccess
}) => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(SUBMITTING_CLUB_MEMBER_EDIT_FORM_LOADER));
    dispatch(resetFormDiagnostics(formName));

    try {
      const response = await axios({
        url: `/v1/clubs/${clubId}/members/${memberId}/`,
        method: "post",
        data: {
          userId: userId,
          email: values.isVirtual ? undefined : values.email || undefined,
          firstName: values.isVirtual ? values.firstName : undefined,
          lastName: values.isVirtual ? values.lastName : undefined,
          city: values.isVirtual ? values.city : undefined,
          street: values.isVirtual ? values.street : undefined,
          zipNumber: values.isVirtual ? values.zipNumber : undefined,
          phoneNumber: values.isVirtual ? values.phoneNumber : undefined,
          birthDate:
            values.isVirtual && values.birthDate
              ? new Date(values.birthDate).toISOString()
              : undefined,
          // club member specific fields
          roles: values.roles,
          isAdmin: values.isVirtual ? false : values.isAdmin,
          isContactPerson: values.isContactPerson,
          imageId: values.imageId
        },
        validateStatus: status => {
          return (status >= 200 && status <= 304) || status === 400;
        }
      });
      if (response.status === 400) {
        dispatch(appendFormDiagnostics(formName, response.data.diagnostics));
      } else {
        dispatch(resetFormDiagnostics(formName));
        dispatch(closeModal(modalName));
        dispatch(
          enqueueSnackbar({
            message: "Údaje člena klubu byly úspěšně změněny.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success"
            }
          })
        );
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu úpravy člena klubu došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(SUBMITTING_CLUB_MEMBER_EDIT_FORM_LOADER));
    }
  };
};

export const SUBMITTING_CLUB_MEMBER_CREATE_FORM_LOADER =
  "SUBMITTING_CLUB_MEMBER_EDIT_FORM_LOADER";

/**
 * Create club member `PUT /v1/clubs/<clubId>/members/`
 */
export const submitClubMemberCreateForm = ({
  clubId,
  values,
  formName,
  modalName,
  onSuccess,
  realUserSelected
}) => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(SUBMITTING_CLUB_MEMBER_CREATE_FORM_LOADER));
    dispatch(resetFormDiagnostics(formName));

    try {
      const response = await axios({
        url: `/v1/clubs/${clubId}/members/`,
        method: "put",
        data: {
          userId: realUserSelected ? values.userId : undefined,
          email: values.isVirtual ? undefined : values.email || undefined,
          firstName: !realUserSelected ? values.firstName : undefined,
          lastName: !realUserSelected ? values.lastName : undefined,
          city: !realUserSelected ? values.city : undefined,
          street: !realUserSelected ? values.street : undefined,
          zipNumber: !realUserSelected ? values.zipNumber : undefined,
          phoneNumber: !realUserSelected ? values.phoneNumber : undefined,
          birthDate:
            !realUserSelected && values.birthDate
              ? new Date(values.birthDate).toISOString()
              : undefined,
          // club member specific fields
          roles: values.roles,
          isAdmin: values.isVirtual ? false : values.isAdmin,
          isContactPerson: values.isContactPerson,
          imageId: values.imageId
        },
        validateStatus: status => {
          return (status >= 200 && status <= 304) || status === 400;
        }
      });
      if (response.status === 400) {
        dispatch(appendFormDiagnostics(formName, response.data.diagnostics));
      } else {
        dispatch(resetFormDiagnostics(formName));
        dispatch(closeModal(modalName));
        dispatch(
          enqueueSnackbar({
            message: "Nový člen klubu byl úspěšně přidán ke klubu.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success"
            }
          })
        );
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu vytváření člena klubu došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(SUBMITTING_CLUB_MEMBER_CREATE_FORM_LOADER));
    }
  };
};

export const SUBMITTING_CLUB_SUMMARY_EDIT_FORM_LOADER =
  "SUBMITTING_CLUB_SUMMARY_EDIT_FORM_LOADER";

/**
 * Edit club summary `POST /v1/clubs/<clubId>/summary/`
 * OR it can CREATE new club!!!
 */
export const submitClubSummaryCreateEditForm = ({
  clubId,
  values,
  formName,
  modalName,
  onSuccess,
  createForm
}) => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(SUBMITTING_CLUB_SUMMARY_EDIT_FORM_LOADER));
    dispatch(resetFormDiagnostics(formName));

    const url = createForm ? `/v1/clubs/` : `/v1/clubs/${clubId}/summary/`;
    const method = createForm ? "put" : "post";

    try {
      const response = await axios({
        url: url,
        method: method,
        data: {
          imageId: values.imageId,
          name: values.name,
          shortDescription: values.shortDescription,
          websiteUrl: values.websiteUrl,
          parentOrganizationId: values.parentOrganizationId || null,
          basicOrganizationNumber: values.basicOrganizationNumber || null,
          possibilities: createForm ? undefined : values.possibilities
        },
        validateStatus: status => {
          return (status >= 200 && status <= 304) || status === 400;
        }
      });
      if (response.status === 400) {
        dispatch(appendFormDiagnostics(formName, response.data.diagnostics));
      } else {
        dispatch(resetFormDiagnostics(formName));
        if (modalName) dispatch(closeModal(modalName));
        dispatch(
          enqueueSnackbar({
            message: createForm
              ? "Nový klub byl úspěšně vytvořen."
              : "Údaje klubu byly úspěšně změněny.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success"
            }
          })
        );
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: createForm
            ? "V průběhu vytváření klubu došlo k chybě!"
            : "V průběhu úpravy klubu došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(SUBMITTING_CLUB_SUMMARY_EDIT_FORM_LOADER));
    }
  };
};

export const SUBMITTING_CLUB_CONTACT_EDIT_FORM_LOADER =
  "SUBMITTING_CLUB_CONTACT_EDIT_FORM_LOADER";

/**
 * Edit club contact `POST /v1/clubs/<clubId>/contact/`
 */
export const submitClubContactEditForm = (
  clubId,
  values,
  formName,
  onSuccess
) => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(SUBMITTING_CLUB_CONTACT_EDIT_FORM_LOADER));
    dispatch(resetFormDiagnostics(formName));

    const url = `/v1/clubs/${clubId}/contact/`;
    const method = "post";

    try {
      const response = await axios({
        url: url,
        method: method,
        data: {
          street: values.street,
          city: values.city,
          zipNumber: values.zipNumber,
          description: values.description,
          gps: values.gps
        },
        validateStatus: status => {
          return (status >= 200 && status <= 304) || status === 400;
        }
      });
      if (response.status === 400) {
        dispatch(appendFormDiagnostics(formName, response.data.diagnostics));
      } else {
        dispatch(resetFormDiagnostics(formName));
        dispatch(closeModal(CLUB_CONTACT_EDIT_FORM_MODAL_NAME));
        dispatch(
          enqueueSnackbar({
            message: "Kontaktní údaje klubu byly úspěšně změněny.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success"
            }
          })
        );
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu úpravy kontakních údajů klubu došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(SUBMITTING_CLUB_CONTACT_EDIT_FORM_LOADER));
    }
  };
};
