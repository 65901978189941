// @flow

import React, { useEffect, useState } from "react";

// material-ui
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

// components
import SearchBar from "../../../../common/components/search/searchBar";
import SearchCard from "pages/contentPages/trainingClubPages/search/searchCard";
import MainTemplate from "../../../main-page/menu-component/mainTemplate";
import MainContentContainer from "../../../../common/components/main-content-container";
import { useDispatch, useSelector } from "react-redux";
import { getTrainingClubs } from "../../../../redux/trainingClub/actions";
import Button from "@material-ui/core/Button";
import AuthorizedOnly from "../../../../common/components/authorization/authorizedOnly";
import { closeModal, openModal } from "../../../../redux/common/actions";
import ModalFormWrapper from "../../../../common/components/modalForm/wrapper";
import ClubSummaryCreateForm, {
  CLUB_SUMMARY_CREATE_FORM_MODAL_NAME
} from "../sub-pages/summary/forms/createForm";

const useStyles = makeStyles(theme => ({
  content: {
    marginTop: theme.spacing(2)
  },
  searchBar: {
    marginBottom: theme.spacing(1)
  },
  editButton: {
    position: "absolute",
    right: theme.spacing(3),
    top: theme.spacing(3)
  },
  editButtonSpace: {
    marginBottom: theme.spacing(7)
  }
}));

const TrainingClubSearch = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [search, setSearch] = useState<string>("");

  const reloadClubs = () => {
    dispatch(getTrainingClubs({})); // TODO  filter on the server: {name: search}
  };

  useEffect(() => {
    reloadClubs();
    // (async () => {
    //   await reloadClubs();
    // })();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch]);

  const events = useSelector(state => state.TrainingClubReducer.clubs);

  // Easy search all events by name in the browser.
  const events_to_show = events.filter(event =>
    search ? event.name.toLowerCase().includes(search.toLowerCase()) : true
  );

  const handleCreateClubModalOpen = () => {
    dispatch(openModal(CLUB_SUMMARY_CREATE_FORM_MODAL_NAME));
  };
  const handleCloseClubSummaryCreateModal = () => {
    dispatch(closeModal(CLUB_SUMMARY_CREATE_FORM_MODAL_NAME));
  };

  return (
    <MainTemplate>
      <MainContentContainer showCloser={true} relative={true}>
        <AuthorizedOnly expectedRole={"isSystemAdmin"}>
          <div className={classes.editButtonSpace}> </div>
          <ModalFormWrapper
            modalName={CLUB_SUMMARY_CREATE_FORM_MODAL_NAME}
            title={"Upravit informace klubu"}
          >
            <ClubSummaryCreateForm
              clubId={undefined}
              initialValues={{
                name: "",
                shortDescription: "",
                // longDescription: trainingClub.longDescription,
                websiteUrl: "",
                // possibilities: [],
                parentOrganizationId: 0,
                basicOrganizationNumber: ""
              }}
              onClose={handleCloseClubSummaryCreateModal}
              actualProfileImageUrl={null}
              onSubmitSuccessfully={reloadClubs}
            />
          </ModalFormWrapper>
          <Button
            className={classes.editButton}
            onClick={handleCreateClubModalOpen}
            color={"primary"}
            variant={"outlined"}
          >
            Přidat klub
          </Button>
        </AuthorizedOnly>
        <Grid container spacing={3} className={classes.content}>
          <Grid item xs={12} className={classes.searchBar}>
            <SearchBar
              search={search}
              setSearch={setSearch}
              placeholder={"Pro vyhledání zadejte jméno klubu"}
            />
          </Grid>
          {events_to_show.map(event => (
            <Grid item xs={12} lg={6} key={event.id}>
              <SearchCard
                id={event.id}
                title={event.name}
                subtitle={event.shortDescription}
                imageUrl={event.imageUrl}
              />
            </Grid>
          ))}
        </Grid>
      </MainContentContainer>
    </MainTemplate>
  );
};

export default TrainingClubSearch;
