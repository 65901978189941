import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LOW
} from "../../../../../../../constants/dictionary/validation";

const FIELD_NAME = "startNumber";

export const YUP_START_NUMBER = Yup.number()
  .nullable(true)
  .min(0, TOO_LOW);

export const YUP_START_NUMBER_REQUIRED = YUP_START_NUMBER.required(REQUIRED);

const StartNumberField = ({ baseParams }) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      label="Startovací číslo"
      fullWidth={true}
      type="number"
      baseParams={baseParams}
    />
  );
};

export default StartNumberField;
