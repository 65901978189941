import React from "react";
import { makeStyles } from "@material-ui/core";

import MainContentContainer from "../../main-content-container";
import SectionPreview from "../sectionPreview";
import { Carousel } from "../../carousel";
import CustomPageCarouselEditFormModal, {
  PREVIEW_PAGE_CAROUSEL_FORM_MODAL_NAME_PREFIX
} from "./editFormModal";
import { openModal } from "../../../../redux/common/actions";
import { useDispatch } from "react-redux";
import { deleteCustomPageSection } from "../../../../redux/customPage/actions";
import SectionAction from "../components/sectionAction";
import AuthorizedOnly from "../../authorization/authorizedOnly";

export const COMPONENT_NAME = "CarouselPreview";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative"
  },
  carouselContainer: {
    backgroundColor: props => props.backgroundColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    minHeight: "inherit"
  },
  editButton: {
    position: "absolute",
    right: 0,
    top: 0
  }
}));

const CarouselPreview = ({
  customPageSectionId,
  parameters,
  sectionOrder,
  resourceType,
  resourceId,
  onReload,
  minOrder,
  maxOrder
}) => {
  const classes = useStyles({
    backgroundColor: parameters.bodyBackgroundColor || "#FFFFFF00" //"#000000"
  });
  const dispatch = useDispatch();

  const handleEditModalOpen = () => {
    dispatch(
      openModal(
        PREVIEW_PAGE_CAROUSEL_FORM_MODAL_NAME_PREFIX + customPageSectionId.toString()
      )
    );
  };
  const handleRemoveSection = () => {
    dispatch(
      deleteCustomPageSection({
        customPageSectionId: customPageSectionId,
        resourceType,
        resourceId,
        onSuccess: onReload,
        loaderName: "TODO_LOADER_NAME",
        modalName: "TODO_MOADL_NAME"
      })
    );
  };

  return (
    <div className={classes.root}>
      <SectionPreview
        title={parameters.title}
        fullHeight={parameters.fullHeight || false}
        titleBackgroundColor={parameters.titleBackgroundColor}
        titleColor={parameters.titleColor}
        noMainContentContainerForContent
      >
        {customPageSectionId && (
          <AuthorizedOnly
            expectedRole={"isResourceAdmin"}
            resourceType={resourceType}
            resourceId={resourceId}
          >
            <CustomPageCarouselEditFormModal
              customPageSectionId={customPageSectionId}
              initialValues={{
                title: (parameters && parameters.title) || "",
                fullHeight: (parameters && parameters.fullHeight) || false,
                order: sectionOrder || ""
              }}
              actualImages={parameters.mediaItems.map(mediaItem => ({
                id: mediaItem.image.id,
                url: mediaItem.image.url
              }))}
              onSubmitSuccessfully={onReload}
              resourceType={resourceType}
              resourceId={resourceId}
              minOrder={minOrder}
              maxOrder={maxOrder}
            />
            <div className={classes.editButton}>
              <SectionAction
                title={"Akční možnosti této sekce"}
                onEdit={handleEditModalOpen}
                onRemove={handleRemoveSection}
              />
            </div>
          </AuthorizedOnly>
        )}
        <div className={classes.carouselContainer}>
          <MainContentContainer>
            <Carousel mediaItems={parameters.mediaItems} />
          </MainContentContainer>
        </div>
      </SectionPreview>
    </div>
  );
};

export default CarouselPreview;
