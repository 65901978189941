import React from "react";
import { Grid } from "@material-ui/core";

import { Form } from "formik";
import FormActions from "../../../../../../common/components/forms/actions";
import PersonSearchBox from "../../../../../../common/components/forms/PersonSearchBox";
import ClubNameField from "./fields/clubName";
import DogSearchBox from "../../../../../../common/components/forms/DogSearchBox";
import StartNumberField from "./fields/startNumber";
import PlaceField from "./fields/place";
import PointsAField from "./fields/pointsA";
import PointsBField from "./fields/pointsB";
import PointsCField from "./fields/pointsC";

const CompetitorCreateEditFormLayout = ({
  values,
  errors,
  formDiagnostics,
  touched,
  isSubmitting,
  onClose,
  setFieldValue,
  createForm
}) => {
  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <PersonSearchBox
            label={"Jméno a příjmení závodníka"}
            placeholder="Zde vložte jméno a příjmení závodníka"
            initialValues={values.participant}
            onChange={value => setFieldValue("participant", value)}
            multiple={false}
            disabled={!createForm}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <ClubNameField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={12}>
          <DogSearchBox
            label={"Jméno psa"}
            placeholder="Zde vložte jméno psa včetně názvu ch.s."
            initialValues={values.dog}
            onChange={value => setFieldValue("dog", value)}
            multiple={false}
            disabled={!createForm}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StartNumberField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <PlaceField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={4}>
          <PointsAField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={4}>
          <PointsBField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={4}>
          <PointsCField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
      </Grid>
      <FormActions
        isSubmitting={isSubmitting}
        errors={errors}
        onClose={onClose}
      />
      {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(errors, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(imageIds, null, 2)}</pre>*/}
    </Form>
  );
};

export default CompetitorCreateEditFormLayout;
