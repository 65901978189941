import React, { useEffect, useState } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import cs from "date-fns/locale/cs";

import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { SUBMITTING_PERSON_PROFILE_EDIT_FORM_LOADER } from "../../../../../../redux/person/actions";
import { resetFormDiagnostics } from "../../../../../../redux/common/actions";
import { submitClubMemberCreateForm } from "../../../../../../redux/trainingClub/actions";
import ClubMemberCreateEditFormLayout from "./formLayout";
import { personCreateEditFormDynamicValidation } from "../../../../../../common/components/forms/personFormLayout";
import { YUP_FIRST_NAME } from "../../../../../../common/components/forms/fields/firstName";
import { YUP_LAST_NAME } from "../../../../../../common/components/forms/fields/lastName";
import { YUP_CITY } from "../../../../../../common/components/forms/fields/city";
import { YUP_STREET } from "../../../../../../common/components/forms/fields/street";
import { YUP_ZIP_NUMBER } from "../../../../../../common/components/forms/fields/zipNumber";
import { YUP_PHONE_NUMBER } from "../../../../../../common/components/forms/fields/phoneNumber";
import { YUP_ROLES } from "./fields/roles";
import { YUP_IS_ADMIN } from "./fields/isAdmin";
import { YUP_IS_CONTACT_PERSON } from "./fields/isContactPerson";

export const CLUB_MEMBER_CREATE_FORM_NAME = "ClubMemberCreateForm";
export const CLUB_MEMBER_CREATE_FORM_MODAL_NAME = "ClubMemberCreateFormModal";

const validationSchema = Yup.object().shape({
  firstName: YUP_FIRST_NAME,
  lastName: YUP_LAST_NAME,
  city: YUP_CITY,
  street: YUP_STREET,
  zipNumber: YUP_ZIP_NUMBER,
  phoneNumber: YUP_PHONE_NUMBER,
  // birthDate: YUP_BIRTH_DATE,
  // Custom event staff fields
  roles: YUP_ROLES,
  isAdmin: YUP_IS_ADMIN,
  isContactPerson: YUP_IS_CONTACT_PERSON
});

const ClubMemberCreateForm = ({
  clubId,
  initialValues,
  onClose,
  onSubmitSuccessfully
}) => {
  const dispatch = useDispatch();

  const [realUserSelected, setRealUserSelected] = useState(false);

  useEffect(() => {
    (async () => {
      dispatch(resetFormDiagnostics(CLUB_MEMBER_CREATE_FORM_NAME));
    })();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const loaders = useSelector(state => state.CommonReducer.loaders);

  const isSubmitting =
    loaders[SUBMITTING_PERSON_PROFILE_EDIT_FORM_LOADER] || false;

  const formDiagnostics =
    useSelector(state => state.CommonReducer.formDiagnostics)[
      CLUB_MEMBER_CREATE_FORM_NAME
    ] || {};

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
        <Formik
          initialValues={initialValues}
          onSubmit={async values => {
            dispatch(
              submitClubMemberCreateForm({
                clubId,
                values,
                formName: CLUB_MEMBER_CREATE_FORM_NAME,
                modalName: CLUB_MEMBER_CREATE_FORM_MODAL_NAME,
                onSuccess: onSubmitSuccessfully,
                realUserSelected
              })
            );
          }}
          validationSchema={validationSchema}
          validate={values =>
            personCreateEditFormDynamicValidation({
              values,
              realUserSelected,
              createForm: true
            })
          }
          validateOnMount
        >
          {({ values, errors, touched, setFieldValue }) => {
            return (
              <ClubMemberCreateEditFormLayout
                values={values}
                errors={errors}
                formDiagnostics={formDiagnostics}
                touched={touched}
                isSubmitting={isSubmitting}
                onClose={onClose}
                setFieldValue={setFieldValue}
                createForm={true}
                realUserSelected={realUserSelected}
                setRealUserSelected={setRealUserSelected}
              />
            );
          }}
        </Formik>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default ClubMemberCreateForm;
