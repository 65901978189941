import React from "react";
import { makeStyles } from "@material-ui/core";
import { closeModal, openModal } from "../../../../redux/common/actions";
import { useDispatch, useSelector } from "react-redux";
import SectionPreview from "../sectionPreview";
import { deleteCustomPageSection } from "../../../../redux/customPage/actions";
import SectionAction from "../components/sectionAction";
import AuthorizedOnly from "../../authorization/authorizedOnly";
import Modal from "../../../components/modalForm/modal";
import CustomPageSponsorEditForm, {
  CUSTOM_PAGE_SPONSOR_FORM_MODAL_NAME_PREFIX
} from "./forms/editForm";
import Sponsor from "./sponsor";

export const COMPONENT_NAME = "SponsorPreview";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    position: "relative"
  },
  clear: {
    clear: "both"
  },
  editButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1)
  }
}));

const SponsorPreview = ({
  customPageSectionId,
  fullHeight,
  imageUrl,
  imageId,
  sponsorName,
  sponsorUrl,
  sectionOrder,
  resourceType,
  resourceId,
  onReload,
  minOrder,
  maxOrder,
  inPreviewPage
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleRemoveSection = () => {
    dispatch(
      deleteCustomPageSection({
        customPageSectionId,
        resourceType,
        resourceId,
        onSuccess: onReload,
        loaderName: "TODO_LOADER_NAME",
        modalName: "TODO_MOADL_NAME"
      })
    );
  };

  const modalName =
    CUSTOM_PAGE_SPONSOR_FORM_MODAL_NAME_PREFIX + customPageSectionId.toString();

  const isModalOpen =
    useSelector(state => state.CommonReducer.modals)[modalName] || false;

  const handleEditModalOpen = () => {
    dispatch(openModal(modalName));
  };

  const handleModalClose = () => {
    dispatch(closeModal(modalName));
  };

  return (
    <div className={classes.root}>
      <SectionPreview
        title={sponsorName}
        fullHeight={fullHeight || false}
        titleTextAlign={"left"}
      >
        {customPageSectionId && (
          <AuthorizedOnly
            expectedRole={"isResourceAdmin"}
            resourceType={resourceType}
            resourceId={resourceId}
          >
            <Modal
              open={isModalOpen}
              title={"Upravit sekci"}
              maxWidth={"md"}
              fullWidth={true}
              onClose={handleModalClose}
            >
              <CustomPageSponsorEditForm
                customPageSectionId={customPageSectionId}
                initialValues={{
                  sponsorName: sponsorName || "",
                  sponsorUrl: sponsorUrl || "",
                  fullHeight: fullHeight || false,
                  imageId: imageId || "",
                  order: sectionOrder || ""
                }}
                onClose={handleModalClose}
                actualImageUrl={imageUrl}
                onSubmitSuccessfully={onReload}
                resourceType={resourceType}
                resourceId={resourceId}
                minOrder={minOrder}
                maxOrder={maxOrder}
                modalName={modalName}
              />
            </Modal>
            <div className={classes.editButton}>
              <SectionAction
                title={"Akční možnosti této sekce"}
                onEdit={handleEditModalOpen}
                onRemove={handleRemoveSection}
                removeConfirmationTitle={"Odstranit sponzora"}
                removeConfirmationMessage={`Opravdu si přejete odebrat sponzora "${sponsorName}"?`}
              />
            </div>
          </AuthorizedOnly>
        )}
        <Sponsor name={sponsorName} url={sponsorUrl} imageUrl={imageUrl} />
        <div className={classes.clear} />
      </SectionPreview>
    </div>
  );
};

export default SponsorPreview;
