// @flow

// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";

// redux
import { useDispatch, useSelector } from "react-redux";
import { removeSnackbar } from "redux/notification/notification-actions";

// libs
import { useSnackbar } from "notistack";

let displayed = [];

const Notifier = () => {
  // redux connection
  const dispatch = useDispatch();
  const notifications = useSelector(
    store => store.notificationReducer.notifications || []
  );
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Add notification into `displayed`
   * @param {number} id notification
   */
  const storeDisplayed = (id: number) => {
    displayed = [...displayed, id];
  };

  /**
   * Remove notification from `displayed`
   * @param {number} id notification
   */
  const removeDisplayed = (id: number) => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  /**
   * Run when `notification-reducer.notifications` change
   */
  useEffect(() => {
    notifications.forEach(({ key, message, options = {} }) => {
      if (displayed.includes(key)) return;

      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          dispatch(removeSnackbar(myKey));
          removeDisplayed(myKey);
        }
      });
      storeDisplayed(key);
    });
  }, [notifications, enqueueSnackbar, dispatch]);

  return null;
};

export default Notifier;
