// @flow

import React from "react";
import clsx from "clsx";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.common.white,
    position: "relative",
    display: "flex"
  },
  heightWithoutToolbar: {
    height: "calc(100vh - 64px)"
  },
  heightWithToolbar: {
    height: "100vh"
  },
  notFullSize: {
    [theme.breakpoints.up("sm")]: {
      height: "60vh",
      minHeight: 300,
      maxHeight: 600
    }
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%"
  },
  // background: {
  //   position: "absolute",
  //   left: 0,
  //   right: 0,
  //   top: 0,
  //   bottom: 0,
  //   backgroundSize: "cover",
  //   backgroundRepeat: "no-repeat",
  //   zIndex: -2
  // },
  // backgroundImage: {
  //   backgroundImage: `url(${backgroundImage})`,
  //   backgroundColor: "#7fc7d9", // Average color of the background image.
  //   backgroundPosition: "center"
  // },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: -2
  },
  titleContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: props => props.titleBackgroundColor || "#00000065",
    textAlign: "center",
    width: "100%",
    position: "absolute",
    [theme.breakpoints.up("md")]: {
      bottom: "10%"
    },
    [theme.breakpoints.down("sm")]: {
      bottom: 0
    }
  },
  titleColor: {
    color: props => props.titleColor || "#FFF"
  },
  subtitleColor: {
    color: props => props.subtitleColor || "#FFFFFFDD"
  }
}));

const MainMultimedia = ({
  title,
  subtitle,
  image,
  fullSize,
  heightWithToolbar = false,
  titleBackgroundColor,
  titleColor,
  subtitleColor
}) => {
  const classes = useStyles({
    titleBackgroundColor,
    titleColor,
    subtitleColor
  });

  return (
    <section
      className={clsx(
        classes.root,
        !fullSize && classes.notFullSize,
        heightWithToolbar
          ? classes.heightWithToolbar
          : classes.heightWithoutToolbar
      )}
    >
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography variant="h2">
            <span className={classes.titleColor}>{title}</span>
          </Typography>
          <Typography variant="h5">
            <span className={classes.subtitleColor}>{subtitle}</span>
          </Typography>
        </div>
        <img className={classes.image} src={image} alt="page logo" />
        {/*<div className={clsx(classes.background, classes.backgroundImage)} />*/}
      </div>
    </section>
  );
};

export default MainMultimedia;
