import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getPreview } from "redux/trainingClub/actions";
import customPageSectionFactory from "../../../../../common/components/customPage/customPageSectionFactory";
import CustomPage from "../../../../../common/components/customPage/customPage";

// const useStyles = makeStyles(theme => ({
// }));

const TrainingClubPreview = ({ resourceId: clubId }) => {
  // const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      await dispatch(getPreview(clubId));
    })();
  }, [dispatch, clubId]);
  const { previewPage } = useSelector(store => store.TrainingClubReducer);

  const handleReload = () => {
    dispatch(getPreview(clubId));
  };

  const sectionsCount =
    (previewPage && previewPage.sections && previewPage.sections.length) || 0;

  return previewPage ? (
    <CustomPage
      customPageId={previewPage.id}
      title={null} // in preview page will never be title / title is included in first section
      resourceType={"clubs"}
      resourceId={clubId}
      onReload={handleReload}
      maxOrder={sectionsCount}
      inPreviewPage={true}
    >
      {previewPage &&
        previewPage.sections &&
        previewPage.sections.map((section, index) => (
          <div key={index}>
            {customPageSectionFactory({
              customPageSectionId: section.id,
              componentName: section.componentName,
              properties: section.properties,
              sectionOrder: section.order,
              resourceType: "clubs",
              resourceId: clubId,
              onReload: handleReload,
              inPreviewPage: true,
              minOrder: 2, // cannot move any section before main multimedia
              maxOrder: sectionsCount - 1 // cannot move any section after contact
            })}
          </div>
        ))}
    </CustomPage>
  ) : null;
};

export default TrainingClubPreview;
