import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LONG,
  TOO_SHORT
} from "../../../../constants/dictionary/validation";
import { InputAdornment } from "@material-ui/core";
import { Home } from "@material-ui/icons";

const FIELD_NAME = "zipNumber";

export const YUP_ZIP_NUMBER = Yup.string()
  .min(2, TOO_SHORT)
  .max(100, TOO_LONG)
  .matches(/^[0-9][0-9][0-9]\s?[0-9][0-9]$/, "Neplatný formát");

export const YUP_ZIP_NUMBER_REQUIRED = YUP_ZIP_NUMBER.required(REQUIRED);

const ZipNumberField = ({ baseParams }) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      autoComplete={"postal-code"}
      label="PSČ"
      fullWidth={true}
      type="text"
      placeholder="Zde vložte PSČ"
      inputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Home />
          </InputAdornment>
        )
      }}
      baseParams={baseParams}
    />
  );
};

export default ZipNumberField;
