import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import { REQUIRED, TOO_LONG, TOO_SHORT } from "constants/dictionary/validation";
import { InputAdornment } from "@material-ui/core";
import { Person } from "@material-ui/icons";

const FIELD_NAME = "firstName";

export const YUP_FIRST_NAME = Yup.string()
  .min(2, TOO_SHORT)
  .max(100, TOO_LONG);

export const YUP_FIRST_NAME_REQUIRED = YUP_FIRST_NAME.required(REQUIRED);

const FirstNameField = ({
  baseParams,
  label = "Jméno",
  placeholder = "Zde vložte Vaše jméno",
  autoCompleteOff = false,
  disabled
}) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      autoComplete="given-name"
      label={label}
      fullWidth={true}
      type="text"
      placeholder={placeholder}
      inputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Person />
          </InputAdornment>
        )
      }}
      baseParams={baseParams}
      disabled={disabled}
      autoCompleteOff={autoCompleteOff}
    />
  );
};

export default FirstNameField;
