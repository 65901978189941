// @flow

// import axios from "utils/axios";
import { ThunkAction } from "redux-thunk";

import axios from "../../utils/axios";
import { enqueueSnackbar } from "../notification/notification-actions";
import {
  appendFormDiagnostics,
  closeModal,
  disableLoader,
  enableLoader,
  resetFormDiagnostics
} from "../common/actions";
// import {
//   SUBMITTING_CUSTOM_PAGE_SECTION_EDIT_FORM_LOADER,
//   CUSTOM_PAGE_SECTION_EDIT_FORM_NAME
// } from "../../common/components/customPage/section/editForm";
// import { CUSTOM_PAGE_SECTION_FORM_MODAL_NAME_PREFIX } from "../../common/components/customPage/section/editFormModal";
import { COMPONENT_NAME as CUSTOM_PAGE_SECTION_COMPONENT_NAME } from "../../common/components/customPage/section";
import { COMPONENT_NAME as CUSTOM_PAGE_MEDIA_CARDS_COMPONENT_NAME } from "../../common/components/customPage/mediaCardsPreview";
import { COMPONENT_NAME as CUSTOM_PAGE_CAROUSEL_COMPONENT_NAME } from "../../common/components/customPage/carouselPreview";
import { COMPONENT_NAME as CUSTOM_PAGE_SPONSOR_COMPONENT_NAME } from "../../common/components/customPage/sponsorPreview";

/**
 * Get person `POST /v1/clubs|events/<id>/custom-pages/<id>/custom-page-sections/<id>?`
 */
export const submitCustomPageSectionEditForm = ({
  customPageSectionId,
  resourceType,
  resourceId,
  values,
  formName,
  onSuccess,
  // newImages,
  loaderName,
  modalName,
  componentName,
  imageIds,
  customPageId
}) => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(loaderName));
    dispatch(resetFormDiagnostics(formName));

    if (values.fullHeight === undefined) values.fullHeight = false;

    const formData = new FormData();
    // Update the formData object
    switch (componentName) {
      case CUSTOM_PAGE_SECTION_COMPONENT_NAME:
        formData.append("componentName", componentName);
        formData.append("title", values.title);
        formData.append("fullHeight", values.fullHeight);
        formData.append("content", values.content);
        formData.append("imageId", values.imageId);
        formData.append("order", values.order);
        break;
      case CUSTOM_PAGE_MEDIA_CARDS_COMPONENT_NAME:
        formData.append("componentName", componentName);
        formData.append("title", values.title);
        formData.append("order", values.order);
        formData.append("fullHeight", values.fullHeight);
        formData.append("title1", values.title1);
        formData.append("content1", values.content1);
        formData.append("imageId1", values.imageId1);
        formData.append("title2", values.title2);
        formData.append("content2", values.content2);
        formData.append("imageId2", values.imageId2);
        formData.append("title3", values.title3);
        formData.append("content3", values.content3);
        formData.append("imageId3", values.imageId3);
        break;
      case CUSTOM_PAGE_CAROUSEL_COMPONENT_NAME:
        formData.append("componentName", componentName);
        formData.append("title", values.title);
        formData.append("fullHeight", values.fullHeight);
        formData.append("order", values.order);
        if (imageIds && imageIds.constructor === Array) {
          imageIds.forEach((imageId, index) => {
            formData.append(`imageId${index}`, imageId);
          });
        }
        break;
      case CUSTOM_PAGE_SPONSOR_COMPONENT_NAME:
        formData.append("componentName", componentName);
        formData.append("sponsorName", values.sponsorName);
        formData.append("sponsorUrl", values.sponsorUrl);
        formData.append("fullHeight", values.fullHeight);
        formData.append("order", values.order);
        formData.append("imageId", values.imageId);
        break;
      default:
        throw Error("Unexpected component name: ", componentName);
    }

    const url = customPageSectionId
      ? `/v1/${resourceType}/${resourceId}/custom-page-sections/${customPageSectionId}`
      : `/v1/${resourceType}/${resourceId}/custom-pages/${customPageId}/custom-page-sections`;

    const method = customPageSectionId ? "post" : "put";

    try {
      const response = await axios({
        url: url,
        method: method,
        data: formData,
        validateStatus: status => {
          return (status >= 200 && status <= 304) || status === 400;
        }
      });
      if (response.status === 400) {
        dispatch(appendFormDiagnostics(formName, response.data.diagnostics));
      } else {
        dispatch(closeModal(modalName));
        dispatch(
          enqueueSnackbar({
            message: customPageSectionId
              ? "Sekce byla úspěšně změněna."
              : "Sekce byla úspěšně vytvořena.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success"
            }
          })
        );
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: customPageSectionId
            ? "V průběhu úpravy sekce došlo k chybě!"
            : "V průběhu vytváření sekce došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(loaderName));
    }
  };
};

/**
 * Get person `DELETE /v1/clubs|events/<id>/custom-pages/<id>/custom-page-sections/<id>`
 */
export const deleteCustomPageSection = ({
  customPageSectionId,
  resourceType,
  resourceId,
  onSuccess,
  loaderName,
  modalName
}) => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(loaderName));

    const url = `/v1/${resourceType}/${resourceId}/custom-page-sections/${customPageSectionId}`; //custom-pages/${customPageId}

    try {
      await axios({
        url: url,
        method: "delete",
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch(closeModal(modalName));
      dispatch(
        enqueueSnackbar({
          message: "Sekce byla úspěšně smazána.",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success"
          }
        })
      );
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu mazání sekce došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(loaderName));
    }
  };
};

/**
 * Get person `POST /v1/clubs|events/<id>/custom-page/<id>/`
 */
export const submitCustomPageEditForm = ({
  customPageId,
  resourceType,
  resourceId,
  values,
  formName,
  onSuccess,
  loaderName,
  modalName
}) => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(loaderName));
    dispatch(resetFormDiagnostics(formName));

    try {
      const response = await axios({
        url: `/v1/${resourceType}/${resourceId}/custom-pages/${customPageId}`,
        method: "post",
        data: {
          title: values.title
        },
        validateStatus: status => {
          return (status >= 200 && status <= 304) || status === 400;
        }
      });
      if (response.status === 400) {
        dispatch(appendFormDiagnostics(formName, response.data.diagnostics));
      } else {
        dispatch(closeModal(modalName));
        dispatch(
          enqueueSnackbar({
            message: "Stránka byla úspěšně změněna.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success"
            }
          })
        );
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu úpravy stránky došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(loaderName));
    }
  };
};
