import React from "react";
import { Carousel as ReactResponsiveCarousel } from "react-responsive-carousel";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {},
  image: {
    maxHeight: "calc(90vh - 64px)",
    width: "100%",
    objectFit: "cover",
    maxWidth: "1700px"
  }
}));

export const Carousel = ({ mediaItems }) => {
  const classes = useStyles();

  return (
    // https://www.npmjs.com/package/react-responsive-carousel
    <ReactResponsiveCarousel
      autoPlay
      interval={5000}
      infiniteLoop={true}
      dynamicHeight={false}
      stopOnHover={false}
      showStatus={false}
      showThumbs={false}
    >
      {mediaItems &&
        mediaItems.constructor === Array &&
        mediaItems.map((mediaItem, index) => (
          <div key={index}>
            {mediaItem.image && (
              <img alt="" src={mediaItem.image.url} className={classes.image} />
            )}
            {/*<p className="legend">Legend 1</p>*/}
          </div>
        ))}
    </ReactResponsiveCarousel>
  );
};
