import React from "react";
import { Grid } from "@material-ui/core";

import { Form } from "formik";
import FormActions from "../../../../../common/components/forms/actions";
import SponsorNameField from "./fields/sponsorName";
import SponsorUrlField from "./fields/sponsorUrl";
import OrderField from "../../forms/fields/order";
import FullHeightField from "../../forms/fields/fullHeight";
import ImageUploader from "../../../imageUploader";

const CustomPageSponsorEditFormLayout = ({
  values,
  errors,
  formDiagnostics,
  touched,
  isSubmitting,
  onClose,
  setFieldValue,
  //createForm = false, // actually edit and create are the same
  minOrder,
  maxOrder,
  actualImageUrl
}) => {
  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <OrderField
            baseParams={{ errors, formDiagnostics, touched }}
            minOrder={minOrder}
            maxOrder={maxOrder}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FullHeightField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <SponsorNameField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={12}>
          <SponsorUrlField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageUploader
            initImage={{
              url: actualImageUrl,
              id: values.imageId
            }}
            onChange={id => setFieldValue("imageId", id)}
            alt={"obrázek sponzora"}
          />
        </Grid>
      </Grid>
      <FormActions
        isSubmitting={isSubmitting}
        errors={errors}
        onClose={onClose}
      />
      {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(errors, null, 2)}</pre>*/}
    </Form>
  );
};

export default CustomPageSponsorEditFormLayout;
