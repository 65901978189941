// redux
import { combineReducers } from "redux";

// reducers
import AuthReducer from "redux/auth/reducer";
import notificationReducer from "redux/notification/notification-reducer";
import PhotoGalleryReducer from "./photo-gallery/reducer";
import EventReducer from "./event/reducer";
import TrainingClubReducer from "./trainingClub/reducer";
import MenuReducer from "./menu/reducer";
import CommonReducer from "./common/reducer";
import ArticleReducer from "./article/reducer";
import PersonReducer from "./person/reducer";

export default combineReducers({
  AuthReducer,
  notificationReducer,
  PhotoGalleryReducer,
  EventReducer,
  TrainingClubReducer,
  MenuReducer,
  CommonReducer,
  ArticleReducer,
  PersonReducer
});
