import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
import ImageGallery from "react-image-gallery";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  }
}));

const INITIAL_SLIDE_DURATION = 450;

const GalleryPreview = ({ images, index }) => {
  const classes = useStyles();

  const [slideDuration, setSlideDuration] = useState(INITIAL_SLIDE_DURATION);
  const refImg = useRef(null);

  useEffect(() => {
    // console.log("SetSlideDuration to zero");
    setSlideDuration(0);
  }, [index]);

  useEffect(() => {
    if (slideDuration === 0) {
      // Rus this call after previous useEffect, which reduces slideDuration.
      refImg.current.slideToIndex(index);
      setSlideDuration(INITIAL_SLIDE_DURATION);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [slideDuration]);

  return (
    <div className={classes.root}>
      <ImageGallery
        items={images}
        thumbnailPosition={"left"}
        ref={refImg}
        slideDuration={slideDuration}
        // startIndex={4}
      />
    </div>
  );
};

export default GalleryPreview;
