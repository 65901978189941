// event components
import PersonProfile from "pages/contentPages/personPages/subPages/profile";
import PersonMyDogs from "./myDogs";

import DvrIcon from "@material-ui/icons/Dvr";
import PetsIcon from "@material-ui/icons/Pets";

export const getRoutes = (id, linkPrefix, pathPrefix) => {
  return [
    {
      id: id,
      name: "Profil",
      link: `${linkPrefix}/profile`,
      icon: DvrIcon,
      exact: true,
      path: `${pathPrefix}/profile`,
      component: PersonProfile,
      disableMenu: false,
      mobileVersionMenu: false
    },
    {
      id: id,
      name: "Moji psi",
      link: `${linkPrefix}/my-dogs`,
      icon: PetsIcon,
      exact: true,
      path: `${pathPrefix}/my-dogs`,
      component: PersonMyDogs,
      disableMenu: false,
      mobileVersionMenu: false,
      isSystemAdmin: true
    }
  ];
};
