import React, { useCallback, useEffect } from "react";
import {
  CircularProgress,
  makeStyles,
  TextField,
  debounce
} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import axios from "../../../utils/axios";
import { enqueueSnackbar } from "../../../redux/notification/notification-actions";
import { useDispatch } from "react-redux";
import { combineFormErrorMessages } from "../../../utils/diagnostics";
import CustomErrorMessage from "./customErrorMessage";
import CustomHelperTextMessage from "./customHelperTextMessage";

const filter = createFilterOptions();

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  moreSpaceAround: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  }
}));

const toString = value => {
  return String(value);
};

const getOptionLabelDefault = option => {
  // console.log("getOptionLabelDefault: ", option);
  if (typeof option === "string") {
    return option;
  }
  if (option?.label) return toString(option.label);
  return toString(option.value) || "";
};
const renderOptionDefault = option => {
  // console.log("renderOptionDefault: ", option);
  if (typeof option === "string") return option;
  if (option?.selectableOptionLabel)
    return toString(option.selectableOptionLabel);
  if (option?.label) return toString(option.label);
  return toString(option.value);
};

const SearchBox = ({
  label,
  placeholder,
  getOptionSelected,
  getOptionLabel = getOptionLabelDefault,
  requestUrl,
  requestMethod,
  requestErrorMessage,
  transformResponse,
  onChange,
  noOptionsText = "nenalezen",
  loadingText = "Načítání...",
  creatable = false,
  renderOption = renderOptionDefault,
  freeSolo = false,
  multiple = false,
  defaultValue,
  // value,
  initialOptions,
  groupBy,
  transformRequestData,
  filterOptionsFilterEnabled = true,
  disabled = false,
  baseParams, // Here you have to pass attribute `name` additionally!!!
  helperText,
  updatableInternalState = false // this his hack for removing event category names (FieldArray)
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState(initialOptions || []);
  const [loading, setLoading] = React.useState(false);

  // const [inputValue, setInputValue] = React.useState('');

  // Tady to null je velice důležité, díky němu zde není prázdný string
  // a searchbox si nemyslí, že už je to hodnota.
  const [value, setValue] = React.useState(defaultValue || null);

  const [errorMessages, setErrorMessages] = React.useState("");

  useEffect(
    actualValue => {
      // This is used for removing item from array of fields. Without this,
      // items after the removed item is not changed (event category names in event summary edit/create form).
      // console.log("############ UPDATING DEFAULT VALUE for label: ", label);
      // if (actualValue !== defaultValue)
      if (updatableInternalState) setValue(defaultValue || null);
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    },
    [defaultValue, updatableInternalState]
  );

  useEffect(() => {
    // console.log("baseParams: ", baseParams);
    if (baseParams) {
      const { errors, formDiagnostics, name } = baseParams;
      // console.log("baseParam.errors: ", errors);
      // console.log("baseParam.name: ", name);
      setErrorMessages(
        // Touched is replaced with always true, because of this component cannot be never touched.
        combineFormErrorMessages(errors, formDiagnostics, name, {
          [name]: true
        })
      );
      // console.log("errorMessages: ", errorMessages);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [baseParams]);

  useEffect(() => {
    setOptions(initialOptions || []);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [initialOptions]);

  const _get_options = (findingEmail, onSuccess, transformResponse) => {
    setLoading(true);
    axios({
      url: requestUrl,
      method: requestMethod,
      data: transformRequestData
        ? transformRequestData(findingEmail)
        : {
            email: findingEmail
          }
    })
      .then(response => {
        if (onSuccess)
          onSuccess(
            transformResponse ? transformResponse(response.data) : response.data
          );
      })
      .catch(error => {
        dispatch(
          enqueueSnackbar({
            message: requestErrorMessage,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error"
            }
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRequestSuccess = options => {
    setOptions(options);
  };

  const load_options = findingEmail => {
    // await sleep(1e3); // For demo purposes.
    // const users = getUsers(findingEmail);
    _get_options(findingEmail, handleRequestSuccess, transformResponse);
  };

  // Empty options if closing dropdown.
  // TODO: je to potřeba? vymazava to initialOptions
  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  const delayedQuery = useCallback(
    debounce(q => load_options(q), 500),
    []
  );

  const handleInputChange = e => {
    if (requestUrl) {
      const findingEmail = e.target.value;
      if (findingEmail.length > 2) {
        delayedQuery(findingEmail);
      } else {
        setOptions([]);
      }
    }
  };

  return (
    <div className={classes.moreSpaceAround}>
      <Autocomplete
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        // onChange={onChange}
        onChange={(e, newValue) => {
          onChange(e, newValue);
          setValue(newValue);
        }}
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        options={options}
        loading={loading}
        loadingText={loadingText}
        noOptionsText={noOptionsText}
        freeSolo={freeSolo}
        filterOptions={
          creatable
            ? (options, params) => {
                const filtered = filterOptionsFilterEnabled
                  ? filter(options, params)
                  : options;

                // If not empty and not in options, we can add option dynamically.
                if (
                  params.inputValue !== "" &&
                  filtered.filter(option => option.value === params.inputValue)
                    .length === 0
                ) {
                  filtered.push({
                    value: params.inputValue,
                    selectableOptionLabel: `Přidat "${params.inputValue}"`,
                    creatable: true
                  });
                }

                return filtered;
              }
            : undefined
        }
        renderOption={renderOption}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            onChange={handleInputChange}
            inputProps={{
              ...params.inputProps,
              autoComplete: "chrome-off" //"new-password"
              // Na email, adresy apod by měl fungovat "chrome-off" a jinde zase jen "off"
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
        multiple={multiple}
        // defaultValue={defaultValue}
        value={value}
        groupBy={groupBy}
        disabled={disabled}
        // inputValue={inputValue}
        // onInputChange={(_, newInputValue) => {
        //   setInputValue(newInputValue);
        // }}
      />
      <CustomErrorMessage message={errorMessages} />
      <CustomHelperTextMessage message={helperText} />
    </div>
  );
};

export default SearchBox;
