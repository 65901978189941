import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import MainContentContainer from "../../../common/components/main-content-container";
import image from "./maintenance.png";

const useStyles = makeStyles(theme => ({
  flexContainer: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexGrow: 1
    }
  },
  verticalCenter: {
    marginTop: "auto",
    marginBottom: "auto"
  },
  imageContainer: {
    textAlign: "center",
    marginRight: theme.spacing(2)
  }
}));

const SorryPage = () => {
  const classes = useStyles();

  return (
    <MainContentContainer>
      <div className={classes.flexContainer}>
        <div className={classes.imageContainer}>
          <img src={image} alt={"error mark"} width={150} height={150} />
        </div>
        <div className={classes.verticalCenter}>
          <Typography variant={"h5"}>
            Omlouváme se, ale nastala neočekávaná chyba systému.
          </Typography>
          <Typography variant={"h6"}>
            Zkuste prosím akci opakovat za chvíli, případně nás prosím
            kontaktujte na emailu{" "}
            <Link
              component={"a"}
              href={"mailto:kynologove@gmail.com"}
              target={"_blank"}
            >
              kynologove@gmail.com
            </Link>
            .
          </Typography>
        </div>
      </div>
    </MainContentContainer>
  );
};

export default SorryPage;
