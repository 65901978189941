import React, { useEffect } from "react";
import {
  Grid,
  makeStyles,
  Button,
  LinearProgress,
  ButtonGroup,
  FormControlLabel
} from "@material-ui/core";
import { combineFormErrorMessages } from "../../../../utils/diagnostics";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import cs from "date-fns/locale/cs";

import { Switch, TextField } from "formik-material-ui";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { submitCustomPageSectionEditForm } from "../../../../redux/customPage/actions";
import { resetFormDiagnostics } from "../../../../redux/common/actions";
import {
  TOO_LONG,
  TOO_SHORT,
  REQUIRED,
  TOO_LOW,
  TOO_LARGE
} from "../../../../constants/dictionary/validation";
import { CUSTOM_PAGE_SECTION_FORM_MODAL_NAME_PREFIX } from "./editFormModal";
import { COMPONENT_NAME } from "./index";
import ImageUploader from "../../imageUploader";
import { SECTION_CREATE_FORM_MODAL_NAME_PREFIX } from "../sectionPreview/createFormModal";

import DraftJsEditor from "../../draftjsEditor";
import {
  draftJsStateToRawString,
  rawDraftJsStringToState
} from "../../draftjsEditor/utils";

export const CUSTOM_PAGE_SECTION_EDIT_FORM_NAME = "PreviewPageSectionEditForm";
export const SUBMITTING_CUSTOM_PAGE_SECTION_EDIT_FORM_LOADER =
  "SUBMITTING_CUSTOM_PAGE_SECTION_EDIT_FORM_LOADER";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(0, TOO_SHORT)
    .max(100, TOO_LONG),
  content: Yup.string()
    .min(1, TOO_SHORT)
    .max(65535, TOO_LONG)
    .required(REQUIRED),
  order: Yup.number()
    .min(1, TOO_LOW)
    .max(100, TOO_LARGE)
    .required(REQUIRED)
});

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  textInput: {
    paddingBottom: theme.spacing(2)
  },
  buttonGroup: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  lineProgress: {},
  hiddenInput: {
    display: "none"
  },
  centered: {
    textAlign: "center"
  },
  imageEditButton: {
    marginTop: theme.spacing(1)
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%"
  }
}));

const CustomPageSectionEditForm = ({
  customPageSectionId,
  initialValues,
  onClose,
  actualImageUrl,
  onSubmitSuccessfully,
  resourceType,
  resourceId,
  customPageId, // only for create
  minOrder = 1,
  maxOrder
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(resetFormDiagnostics(CUSTOM_PAGE_SECTION_EDIT_FORM_NAME));
    })();
  }, [dispatch]);

  // const loaders = useSelector(state => state.CommonReducer.loaders);

  const isSubmitting = false;
  // loaders[SUBMITTING_PERSON_PROFILE_EDIT_FORM_LOADER] || false;

  const formDiagnostics =
    useSelector(state => state.CommonReducer.formDiagnostics)[
      CUSTOM_PAGE_SECTION_EDIT_FORM_NAME
    ] || {};

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
        <Formik
          initialValues={{
            ...initialValues,
            content: rawDraftJsStringToState(initialValues["content"])
          }}
          onSubmit={async values => {
            dispatch(
              submitCustomPageSectionEditForm({
                customPageSectionId,
                resourceType,
                resourceId,
                values: {
                  ...values,
                  content: draftJsStateToRawString(values["content"])
                },
                formName: CUSTOM_PAGE_SECTION_EDIT_FORM_NAME,
                onSuccess: onSubmitSuccessfully,
                loaderName: SUBMITTING_CUSTOM_PAGE_SECTION_EDIT_FORM_LOADER,
                modalName: customPageSectionId
                  ? CUSTOM_PAGE_SECTION_FORM_MODAL_NAME_PREFIX +
                    customPageSectionId.toString()
                  : SECTION_CREATE_FORM_MODAL_NAME_PREFIX +
                    customPageId.toString(), // TODO: pass modalName to this component or handle modal outside
                componentName: COMPONENT_NAME,
                customPageId
              })
            );
          }}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, submitForm, setFieldValue }) => {
            const localCombineFormErrorMessages = field => {
              return combineFormErrorMessages(errors, formDiagnostics, field);
            };
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      name="title"
                      label="Nadpis"
                      className={classes.textInput}
                      fullWidth={true}
                      type="text"
                      placeholder="Zde vložte nadpis sekce."
                      helperText={localCombineFormErrorMessages("title")}
                      error={!!localCombineFormErrorMessages("title")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      name="order"
                      label="Číslo pořadí"
                      className={classes.textInput}
                      fullWidth={true}
                      type="number"
                      placeholder="Zde vložte číslo pořadí sekce."
                      helperText={localCombineFormErrorMessages("order")}
                      error={!!localCombineFormErrorMessages("order")}
                      inputProps={{ min: minOrder, max: maxOrder }} // TODO: FieldBase has min|maxValue
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControlLabel
                      control={
                        <Field
                          component={Switch}
                          type="checkbox"
                          name="fullHeight"
                        />
                      }
                      label="Na celou výšku"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DraftJsEditor
                      initialStateRaw={initialValues["content"]}
                      onEditorStateChange={value =>
                        setFieldValue("content", value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ImageUploader
                      initImage={{
                        url: actualImageUrl,
                        id: initialValues.imageId
                      }}
                      onChange={id => setFieldValue("imageId", id)}
                      alt={"obrázek sekce"}
                    />
                  </Grid>
                </Grid>
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                  className={classes.buttonGroup}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                    onClick={submitForm}
                  >
                    {!isSubmitting ? "Uložit" : "Ukládání"}
                  </Button>
                  <Button variant="contained" color="default" onClick={onClose}>
                    Zavřít
                  </Button>
                </ButtonGroup>
                {isSubmitting && (
                  <LinearProgress className={classes.lineProgress} />
                )}

                {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
                {/*<pre>{JSON.stringify(errors, null, 2)}</pre>*/}
              </Form>
            );
          }}
        </Formik>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default CustomPageSectionEditForm;
