// @flow

import * as types from "./types";

/**
 * Flow types
 */
type PhotoGalleryState = {
  photoGalleries: Array,
  photoGallery: Object
};

/**
 * Initial auth state
 */
const INITIAL_STATE = {
  photoGalleries: null,
  photoGallery: null
};

/**
 * Photo gallery reducer
 */
const PhotoGalleryReducer = (
  state: PhotoGalleryState = INITIAL_STATE,
  action: Object
) => {
  switch (action.type) {
    case types.LOAD_PHOTO_GALLERIES:
      return {
        ...state,
        photoGalleries: action.payload
      };
    case types.LOAD_PHOTO_GALLERY:
      return {
        ...state,
        photoGallery: action.payload
      };
    case types.RESET_PHOTO_GALLERY:
      return {
        ...state,
        photoGallery: null
      };
    case types.RESET_PHOTO_GALLERIES:
      return {
        ...state,
        photoGalleries: null
      };
    default:
      return state;
  }
};

export default PhotoGalleryReducer;
