import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import cs from "date-fns/locale/cs";

import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { submitCustomPageSectionEditForm } from "../../../../../redux/customPage/actions";
import { resetFormDiagnostics } from "../../../../../redux/common/actions";
import {
  REQUIRED,
  TOO_LOW,
  TOO_LARGE
} from "../../../../../constants/dictionary/validation";
import { COMPONENT_NAME } from "../index";

import CustomPageSponsorEditFormLayout from "./formLayout";
import { YUP_SPONSOR_NAME_REQUIRED } from "./fields/sponsorName";
import { YUP_SPONSOR_URL } from "./fields/sponsorUrl";

export const CUSTOM_PAGE_SPONSOR_EDIT_FORM_NAME = "CustomPageSponsorEditForm";
export const SUBMITTING_CUSTOM_PAGE_SPONSOR_EDIT_FORM_LOADER =
  "SUBMITTING_CUSTOM_PAGE_SPONSOR_EDIT_FORM_LOADER";

export const CUSTOM_PAGE_SPONSOR_FORM_MODAL_NAME_PREFIX =
  "CustomPageSponsorFormModal";

const validationSchema = Yup.object().shape({
  sponsorName: YUP_SPONSOR_NAME_REQUIRED,
  sponsorUrl: YUP_SPONSOR_URL,
  order: Yup.number()
    .min(1, TOO_LOW)
    .max(100, TOO_LARGE)
    .required(REQUIRED)
});

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  }
}));

const CustomPageSponsorEditForm = ({
  customPageSectionId,
  initialValues,
  onClose,
  actualImageUrl,
  onSubmitSuccessfully,
  resourceType,
  resourceId,
  customPageId, // only for create
  minOrder = 1,
  maxOrder,
  modalName
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(resetFormDiagnostics(CUSTOM_PAGE_SPONSOR_EDIT_FORM_NAME));
    })();
  }, [dispatch]);

  // const loaders = useSelector(state => state.CommonReducer.loaders);
  const isSubmitting = false;
  // loaders[SUBMITTING_PERSON_PROFILE_EDIT_FORM_LOADER] || false;

  const formDiagnostics =
    useSelector(state => state.CommonReducer.formDiagnostics)[
      CUSTOM_PAGE_SPONSOR_EDIT_FORM_NAME
    ] || {};

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
        <Formik
          initialValues={initialValues}
          onSubmit={async values => {
            dispatch(
              submitCustomPageSectionEditForm({
                customPageSectionId,
                resourceType,
                resourceId,
                values,
                formName: CUSTOM_PAGE_SPONSOR_EDIT_FORM_NAME,
                onSuccess: onSubmitSuccessfully,
                loaderName: SUBMITTING_CUSTOM_PAGE_SPONSOR_EDIT_FORM_LOADER,
                modalName: modalName,
                componentName: COMPONENT_NAME,
                customPageId
              })
            );
          }}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, setFieldValue }) => {
            return (
              <CustomPageSponsorEditFormLayout
                values={values}
                errors={errors}
                formDiagnostics={formDiagnostics}
                touched={touched}
                isSubmitting={isSubmitting}
                onClose={onClose}
                setFieldValue={setFieldValue}
                createForm={false}
                minOrder={minOrder}
                maxOrder={maxOrder}
                actualImageUrl={actualImageUrl}
              />
            );
          }}
        </Formik>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default CustomPageSponsorEditForm;
