// @flow

import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";

// material-ui
import { makeStyles } from "@material-ui/core/styles";

import Loader from "react-loader-spinner";

// components
import TopMenu from "pages/main-page/menu-component/top-menu/topMenu";
import EventSearch from "pages/contentPages/eventPages/search";
import EventPages from "pages/contentPages/eventPages/sub-pages";
import MainTemplate from "./menu-component/mainTemplate";
import MainPage from "./mainPage";
import TrainingClubSearch from "../contentPages/trainingClubPages/search";
import TrainingClubPages from "../contentPages/trainingClubPages/sub-pages";
import { loadCustomDomain } from "../../redux/common/actions";
import { useDispatch, useSelector } from "react-redux";
import NotFoundPage from "./notFound";
import PersonPages from "../contentPages/personPages/subPages";
import PersonSearch from "../contentPages/personPages/search";
import GdprPage from "../lawPages/gdpr";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  // menuButton: {
  //   marginRight: theme.spacing(2),
  //   [theme.breakpoints.up("sm")]: {
  //     display: "none"
  //   }
  // },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    margin: theme.spacing(2)
  },
  centered: {
    position: "fixed",
    top: "50%",
    left: "50%",
    /* bring your own prefixes */
    transform: "translate(-50%, -50%)"
  }
}));

const MainPageRouter = props => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const domain = window.location.hostname;

  // CUSTOM DOMAIN SWITCH
  const isCustomDomain =
    process.env.NODE_ENV === "production"
      ? domain !== "kynologove.cz"
      : process.env.REACT_APP_DEVELOPMENT_IS_CUSTOM_DOMAIN === "true";

  useEffect(() => {
    if (isCustomDomain) {
      document.title = domain;
      dispatch(loadCustomDomain(domain, props.history.push));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch]); // This effect should be called only once.

  const customDomain = useSelector(state => state.CommonReducer.customDomain);
  const loaders = useSelector(state => state.CommonReducer.loaders);

  /**
   * Handle drawer toggling
   */
  const handleDrawerToggle = (forcedValue = undefined) => {
    if (forcedValue === true || forcedValue === false)
      setMobileOpen(forcedValue);
    else setMobileOpen(!mobileOpen);
  };

  return isCustomDomain && (customDomain ? !customDomain.loaded : true) ? (
    loaders["customDomainLoader"] ? (
      <>
        <Helmet>
          <meta property={"og:title"} content={domain} />
          <meta property={"og:image"} content={""} />
          <meta property={"og:description"} content={""} />
        </Helmet>
        <Loader
          className={classes.centered}
          type="Oval" // "Circles" "Grid"
          color="#f50057"
          height={100}
          width={100}
          // timeout={3000} //3 secs
        />
      </>
    ) : null
  ) : (
    <div className={classes.root}>
      {isCustomDomain ? (
        <Helmet>
          <meta property={"og:title"} content={customDomain.title} />
          <meta property={"og:image"} content={customDomain.imageUrl || ""} />
          <meta
            property={"og:description"}
            content={customDomain.description || ""}
          />
        </Helmet>
      ) : null}
      <TopMenu handleDrawerToggle={handleDrawerToggle} />
      <Switch>
        <Route exact path="/gdpr" render={() => <GdprPage />} />
        {/* TRAINING CLUBS */}
        {!customDomain && [
          <Route
            exact
            path="/clubs"
            render={() => <TrainingClubSearch />}
            key={"1"}
          />,
          <Route
            path="/clubs/:id"
            render={() => (
              <TrainingClubPages
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
                isMainPage={false}
              />
            )}
            key={"2"}
          />
        ]}
        {/* EVENTS */}
        <Route exact path="/events" render={() => <EventSearch />} />
        <Route
          path="/events/:id"
          render={() => (
            <EventPages
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
            />
          )}
        />
        {/* PERSONS */}
        <Route exact path="/persons" render={() => <PersonSearch />} />
        <Route
          path="/persons/:id"
          render={() => (
            <PersonPages
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
            />
          )}
        />
        {/* CUSTOM DOMAIN */}
        <Route
          //exact
          path="/"
          render={() =>
            customDomain ? (
              customDomain.resourceType === "clubs" ? (
                <TrainingClubPages
                  mobileOpen={mobileOpen}
                  handleDrawerToggle={handleDrawerToggle}
                  isMainPage={true}
                />
              ) : customDomain.resourceType === "events" ? (
                <EventPages
                  mobileOpen={mobileOpen}
                  handleDrawerToggle={handleDrawerToggle}
                  isMainPage={true}
                />
              ) : null
            ) : (
              <MainTemplate>
                <MainPage />
              </MainTemplate>
            )
          }
        />
        {/* 404 */}
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </div>
  );
};

export default MainPageRouter;
