import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Form } from "formik";

import FormActions from "../../../../../../common/components/forms/actions";
import ImageUploader from "../../../../../../common/components/imageUploader";
import PersonCreateEditFormLayout from "../../../../../../common/components/forms/personFormLayout";
import RolesField from "./fields/roles";
import IsAdminField from "./fields/isAdmin";
import IsContactPersonField from "./fields/isContactPerson";
import CustomInputLabel from "../../../../../../common/components/forms/customInputLabel";

const ClubMemberCreateEditFormLayout = ({
  values,
  actualProfileImageUrl, // only for edit form
  errors,
  formDiagnostics,
  touched,
  isSubmitting,
  onClose,
  setFieldValue,
  createForm,
  realUserSelected,
  setRealUserSelected
}) => {
  useEffect(() => {
    if (values.isVirtual && values.isAdmin) setFieldValue("isAdmin", false);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [values]);

  return (
    <Form>
      <PersonCreateEditFormLayout
        values={values}
        errors={errors}
        formDiagnostics={formDiagnostics}
        touched={touched}
        setFieldValue={setFieldValue}
        createForm={createForm}
        realUserSelected={realUserSelected}
        setRealUserSelected={setRealUserSelected}
      />
      <Grid container spacing={2}>
        {/*Event staff specific fields*/}

        <Grid item xs={12} md={12}>
          <RolesField
            values={values}
            setFieldValue={setFieldValue}
            baseParams={{ errors, formDiagnostics, touched }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <IsAdminField
            baseParams={{ errors, formDiagnostics, touched }}
            disabled={values.isVirtual}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <IsContactPersonField
            baseParams={{ errors, formDiagnostics, touched }}
          />
        </Grid>

        {(values.isVirtual || (createForm && !realUserSelected)) && (
          <Grid item xs={12}>
            <CustomInputLabel label={"Profilový obrázek člena klubu"} />
            <ImageUploader
              initImage={{
                url: actualProfileImageUrl,
                id: values.imageId
              }}
              onChange={id => setFieldValue("imageId", id)}
              useCrop={true}
              alt={"Profilový obrázek člena klubu"}
            />
          </Grid>
        )}
      </Grid>
      <FormActions
        isSubmitting={isSubmitting}
        errors={errors}
        onClose={onClose}
      />
      {/*  ONLY FOR DEBUG PURPOSES  */}
      {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(errors, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(touched, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(imageIds, null, 2)}</pre>*/}
    </Form>
  );
};

export default ClubMemberCreateEditFormLayout;
