import React from "react";
import { Button, ButtonGroup, makeStyles } from "@material-ui/core";
import RawHTMLEditor from "./raw";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(7)
  }
  // TODO: move contentClass from all places here
}));

const ControlledHtmlEditor = ({
  editorShown,
  html,
  onSave,
  onEditorOpen,
  onEditorClose,
  onChange,
  contentClass,
  editable = false
}) => {
  const classes = useStyles();

  const editButtonElement = (
    <Button
      variant="contained"
      color="primary"
      onClick={() => onEditorOpen()}
      className={classes.button}
    >
      Upravit
    </Button>
  );

  const contentElement = (
    <>
      <div
        dangerouslySetInnerHTML={{ __html: html }}
        className={contentClass}
      />
      {editable ? editButtonElement : null}
    </>
  );

  const editorButtonElement = (
    <ButtonGroup
      variant="contained"
      color="primary"
      aria-label="contained primary button group"
    >
      <Button
        variant="contained"
        color="primary"
        onClick={() => onSave()}
        className={classes.button}
      >
        Uložit
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => onEditorClose()}
        className={classes.button}
      >
        Ukončit editaci
      </Button>
    </ButtonGroup>
  );
  const editorElement = (
    <div>
      {editable ? editorButtonElement : null}

      <RawHTMLEditor
        content={html}
        handleContentChange={newContent => onChange(newContent)}
      />

      {editable ? editorButtonElement : null}
    </div>
  );

  return <>{editorShown ? editorElement : contentElement}</>;
};

export default ControlledHtmlEditor;
