// @flow

// import axios from "utils/axios";
import { ThunkAction } from "redux-thunk";

import * as types from "./types";
import axios from "../../utils/axios";
import { enqueueSnackbar } from "../notification/notification-actions";

import { CONFIRMATION_MODAL_NAME } from "../../common/components/modals/confirmationModal";
// notification-actions
// import { enqueueSnackbar } from "redux/notification/notification-actions";
// import { useDispatch } from "react-redux";

export const enableLoader = actionType => {
  return (dispatch: ThunkAction) => {
    dispatch({
      type: types.ENABLE_LOADER,
      payload: actionType
    });
  };
};

export const disableLoader = actionType => {
  return (dispatch: ThunkAction) => {
    dispatch({
      type: types.DISABLE_LOADER,
      payload: actionType
    });
  };
};

export const setPageRoutes = (resourceType: string, routes: Array) => {
  return (dispatch: ThunkAction) => {
    dispatch({
      type: types.SET_PAGE_ROUTES,
      payload: {
        resourceType: resourceType,
        routes: routes
      }
    });
  };
};

export const loadCustomDomain = (domain: string, historyPushFunction) => {
  return async (dispatch: ThunkAction) => {
    try {
      dispatch(enableLoader("customDomainLoader"));

      const response = await axios({
        url: "/v1/domains/" + domain,
        method: "get",
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch({
        type: types.SET_CUSTOM_DOMAIN,
        payload: {
          domain: response.data.domain.domain,
          path: response.data.domain.path,
          title: response.data.domain.title,
          resourceType: response.data.domain.resourceType,
          resourceId: response.data.domain.resourceId,
          loaded: true
        }
      });
      // historyPushFunction(response.data.domain.path);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání domény klubu došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
      historyPushFunction("/sorry-page/");
    } finally {
      dispatch(disableLoader("customDomainLoader"));
    }
  };
};
//
// export const setFormInputValue = (
//   formName: string,
//   inputName: string,
//   inputValue: string
// ) => {
//   return (dispatch: ThunkAction) => {
//     dispatch({
//       type: types.SET_FORM_INPUT_VALUE,
//       payload: {
//         formName: formName,
//         inputName: inputName,
//         inputValue: inputValue
//       }
//     });
//   };
// };

export const appendFormDiagnostics = (formName: string, diagnostics: Array) => {
  return (dispatch: ThunkAction) => {
    // console.log("APPENDING DIAGNOSTICS: ", diagnostics);
    if (diagnostics) {
      dispatch({
        type: types.APPEND_FORM_DIAGNOSTICS,
        payload: {
          formName: formName,
          diagnostics: diagnostics
        }
      });
    }
  };
};

export const resetFormDiagnostics = (formName: string) => {
  return (dispatch: ThunkAction) => {
    dispatch({
      type: types.RESET_FORM_DIAGNOSTICS,
      payload: {
        formName: formName
      }
    });
  };
};

export const setFormInput = (
  formName: string,
  inputName: string,
  inputValue
) => {
  return (dispatch: ThunkAction) => {
    dispatch({
      type: types.SET_FORM_INPUT,
      payload: {
        formName: formName,
        inputName: inputName,
        inputValue: inputValue
      }
    });
  };
};

export const clearFormInputs = (formName: string) => {
  return (dispatch: ThunkAction) => {
    dispatch({
      type: types.CLEAR_FORM_INPUTS,
      payload: {
        formName: formName
      }
    });
  };
};

export const openModal = name => {
  return (dispatch: ThunkAction) => {
    dispatch({
      type: types.OPEN_MODAL,
      payload: name
    });
  };
};

export const closeModal = name => {
  return (dispatch: ThunkAction) => {
    dispatch({
      type: types.CLOSE_MODAL,
      payload: name
    });
  };
};

export const openConfirmationModal = (
  title: string,
  message: string,
  confirmCallback: function,
  maxWidth: ?string = null,
  fullWidth: boolean = true,
  refuseButtonText: ?string = null,
  confirmButtonText: ?string = null
) => {
  return (dispatch: ThunkAction) => {
    dispatch({
      type: types.SET_CONFIRMATION_MODAL,
      payload: {
        title,
        message,
        confirmCallback,
        maxWidth,
        fullWidth,
        refuseButtonText,
        confirmButtonText
      }
    });
    dispatch(openModal(CONFIRMATION_MODAL_NAME));
  };
};

export const closeConfirmationModal = () => {
  return (dispatch: ThunkAction) => {
    dispatch(closeModal(CONFIRMATION_MODAL_NAME));
  };
};
