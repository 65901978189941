import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  errorMessage: {
    color: "#f44336",
    fontSize: "0.75rem",
    marginTop: "3px"
  }
}));

const CustomErrorMessage = ({ message }) => {
  const classes = useStyles();

  return message ? (
    <Typography variant={"body2"} className={classes.errorMessage}>
      {message}
    </Typography>
  ) : null;
};

export default CustomErrorMessage;
