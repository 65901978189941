import React from "react";
import { Switch } from "formik-material-ui";
import { Field } from "formik";
import { combineFormErrorMessages } from "../../../utils/diagnostics";
import {
  FormControlLabel,
  FormLabel,
  FormControl,
  makeStyles
} from "@material-ui/core";
import CustomErrorMessage from "./customErrorMessage";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  moreSpaceAround: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  }
}));

const SwitchFieldBase = ({
  name,
  label,
  switchLabel,
  baseParams,
  disabled
}) => {
  const classes = useStyles();
  const { errors, formDiagnostics, touched } = baseParams;
  const localCombineFormErrorMessages = () => {
    return combineFormErrorMessages(errors, formDiagnostics, name, touched);
  };

  return (
    <div className={classes.moreSpaceAround}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <FormControlLabel
          control={
            <Field
              component={Switch}
              type="checkbox"
              name={name}
              disabled={disabled}
            />
          }
          label={switchLabel}
        />
      </FormControl>
      <CustomErrorMessage message={localCombineFormErrorMessages()} />
    </div>
  );
};

export default SwitchFieldBase;
