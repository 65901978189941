import { ThunkAction } from "redux-thunk";
import axios from "../../utils/axios";
import * as types from "./types";
import { enqueueSnackbar } from "../notification/notification-actions";
import {
  appendFormDiagnostics,
  closeModal,
  disableLoader,
  enableLoader,
  resetFormDiagnostics
} from "../common/actions";
import { PERSON_PROFILE_EDIT_FORM_MODAL_NAME } from "../../pages/contentPages/personPages/subPages/profile/forms/editForm";
import { EVENT_COMPETITOR_EDIT_FORM_MODAL_NAME_PREFIX } from "../../pages/contentPages/eventPages/sub-pages/competitor/forms/editFormModal";
import { PERSON_MY_DOGS_CREATE_FORM_MODAL_NAME } from "../../pages/contentPages/personPages/subPages/myDogs/forms/createForm";

/**
 * Get person `GET /v1/persons/<id>/`
 */
export const getPerson = (id, displayOptions = null, onUnauthorized) => {
  return async (dispatch: ThunkAction) => {
    try {
      // TODO: RESET CLUB if id changed... otherwise previous loaded data are show for the short time
      const response = await axios({
        url: `/v1/persons/${id}/`,
        method: "get",
        // data: { filter: { id: id } },
        validateStatus: status => {
          return (status >= 200 && status <= 304) || status === 401;
        }
      });
      if (response.status !== 401) {
        dispatch({
          type: types.GET_PERSON,
          payload: {
            id: response.data.person.id,
            email: response.data.person.email,
            username: response.data.person.username,
            firstName: response.data.person.firstName,
            lastName: response.data.person.lastName,
            fullName: response.data.person.fullName,
            address: {
              city: response.data.person.address
                ? response.data.person.address.city
                : undefined,
              street: response.data.person.address
                ? response.data.person.address.street
                : undefined,
              zipNumber: response.data.person.address
                ? response.data.person.address.zipNumber
                : undefined
            },
            birthDate: response.data.person.birthDate,
            phoneNumber: response.data.person.phoneNumber,
            created_at: new Date(response.data.person.created_at),
            image: {
              id: response.data.person.image
                ? response.data.person.image.id
                : undefined,
              url: response.data.person.image
                ? response.data.person.image.url
                : undefined
            },
            websiteUrl: response.data.person.websiteUrl
          }
        });
      } else {
        dispatch(
          enqueueSnackbar({
            message: "Nemáte oprávnění k získání informací o osobě!",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "warning"
            }
          })
        );
        if (onUnauthorized) onUnauthorized();
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání informací o osobě došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

/**
 * Get person dogs `GET /v1/persons/<id>/my-dogs/`
 */
export const getPersonDogs = (id, displayOptions = null, onUnauthorized) => {
  return async (dispatch: ThunkAction) => {
    try {
      // TODO: RESET DATA if id changed... otherwise previous loaded data are show for the short time
      const response = await axios({
        url: `/v1/persons/${id}/my-dogs/`,
        method: "get",
        // data: { filter: { id: id } },
        validateStatus: status => {
          return (status >= 200 && status <= 304) || status === 401;
        }
      });
      if (response.status !== 401) {
        dispatch({
          type: types.GET_PERSON_DOGS,
          payload: response.data.dogs.map(dog => ({
            id: dog.id,
            fullName: dog.fullName,
            // birthDate: dog.birthDate,
            image: {
              id: dog?.image?.id,
              url: dog?.image?.url
            }
          }))
        });
      } else {
        dispatch(
          enqueueSnackbar({
            message: "Nemáte oprávnění k získání informací o psech osoby!",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "warning"
            }
          })
        );
        if (onUnauthorized) onUnauthorized();
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání informací o psech osoby došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

export const SUBMITTING_PERSON_PROFILE_EDIT_FORM_LOADER =
  "SUBMITTING_PERSON_PROFILE_EDIT_FORM_LOADER";

/**
 * Get person `POST /v1/persons/<id>/`
 */
export const submitPersonProfileEditForm = ({
  personId,
  values,
  formName,
  modalName,
  onSuccess
}) => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(SUBMITTING_PERSON_PROFILE_EDIT_FORM_LOADER));
    dispatch(resetFormDiagnostics(formName));

    try {
      const response = await axios({
        url: `/v1/persons/${personId}/`,
        method: "post",
        data: {
          imageId: values.imageId,
          firstName: values.firstName,
          lastName: values.lastName,
          city: values.city,
          street: values.street,
          zipNumber: values.zipNumber,
          phoneNumber: values.phoneNumber,
          birthDate: values.birthDate
            ? new Date(values.birthDate).toISOString()
            : null
        },
        validateStatus: status => {
          return (status >= 200 && status <= 304) || status === 400;
        }
      });
      if (response.status === 400) {
        dispatch(appendFormDiagnostics(formName, response.data.diagnostics));
      } else {
        dispatch(resetFormDiagnostics(formName));
        dispatch(closeModal(modalName));
        dispatch(
          enqueueSnackbar({
            message: "Údaje osoby byly úspěšně změněny.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success"
            }
          })
        );
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu úpravy osoby došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(SUBMITTING_PERSON_PROFILE_EDIT_FORM_LOADER));
    }
  };
};

export const SUBMITTING_PERSON_MY_DOGS_EDIT_FORM_LOADER =
  "SUBMITTING_PERSON_MY_DOGS_EDIT_FORM_LOADER";

/**
 * Edit or create person my dogs `POST /v1/persons/<personId>/my-dogs/<myDogId>?/`
 */
export const submitPersonMyDogsEditForm = ({
  dogId,
  personId,
  values,
  formName,
  onSuccess,
  createForm
}) => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(SUBMITTING_PERSON_MY_DOGS_EDIT_FORM_LOADER));
    dispatch(resetFormDiagnostics(formName));

    const url = createForm
      ? `/v1/persons/${personId}/my-dogs/`
      : `/v1/persons/${personId}/my-dogs/${dogId}/`;
    const method = createForm ? "put" : "post";

    try {
      // const response =
      await axios({
        url: url,
        method: method,
        data: {
          name: values.name,
          kennel: values?.kennel?.creatable
            ? { name: values.kennel.value }
            : { id: values.kennel.kennelId },
          breed: { id: values?.breed?.breedId },
          sex: values.sex,
          birthDate: values.birthDate,
          registrationNumber: values.registrationNumber,
          chipNumber: values.chipNumber,
          tatooNumber: values.tatooNumber
        },
        validateStatus: status => {
          return status >= 200 && status <= 304; //|| status === 400;
        }
      });
      // if (response.status === 400) {
      //   dispatch(appendFormDiagnostics(formName, response.data.diagnostics));
      // } else {
      dispatch(resetFormDiagnostics(formName));
      dispatch(
        closeModal(
          createForm
            ? PERSON_MY_DOGS_CREATE_FORM_MODAL_NAME
            : // todo
              EVENT_COMPETITOR_EDIT_FORM_MODAL_NAME_PREFIX + dogId.toString()
        )
      );
      dispatch(
        enqueueSnackbar({
          message: createForm
            ? "Nový pes byl úspěšně přidán."
            : "Pes byl úspěšně změněn.",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success"
          }
        })
      );
      if (onSuccess) {
        onSuccess();
      }
      // }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: createForm
            ? "V průběhu vytváření psa došlo k chybě!"
            : "V průběhu úpravy psa došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(SUBMITTING_PERSON_MY_DOGS_EDIT_FORM_LOADER));
    }
  };
};
