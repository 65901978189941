import React from "react";
import { Select as FormikSelect } from "formik-material-ui";
import { Field } from "formik";
import { combineFormErrorMessages } from "../../../utils/diagnostics";
import { InputLabel, makeStyles, MenuItem } from "@material-ui/core";
import CustomErrorMessage from "./customErrorMessage";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  moreSpaceAround: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  }
}));

const SelectBox = ({ name, label, items, fullWidth = true, baseParams }) => {
  const classes = useStyles();
  const { errors, formDiagnostics, touched } = baseParams;
  const localCombineFormErrorMessages = () => {
    return combineFormErrorMessages(errors, formDiagnostics, name, touched);
  };

  const randomNumber = Math.round(Math.random() * 10);
  const inputId = "selectBox" + randomNumber;

  return (
    <div className={classes.moreSpaceAround}>
      <InputLabel htmlFor={inputId}>{label}</InputLabel>
      <Field
        required
        component={FormikSelect}
        name={name}
        fullWidth={fullWidth}
        inputProps={{
          id: inputId
        }}
        error={!!localCombineFormErrorMessages()}
      >
        {items?.length &&
          items.map((item, index) => (
            <MenuItem value={item.value} key={index}>
              {item.title}
            </MenuItem>
          ))}
      </Field>
      <CustomErrorMessage message={localCombineFormErrorMessages()} />
    </div>
  );
};

export default SelectBox;
