import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  fullWidth: {
    width: "100%"
  },
  closer: {
    [theme.breakpoints.up(1700)]: {
      marginLeft: theme.spacing(18),
      marginRight: theme.spacing(18)
    }
  },
  relative: {
    position: "relative"
  },
  noBottomPadding: {
    paddingBottom: 0
  }
}));

const MainContentContainer = ({
  children,
  showCloser = true,
  fullWidth = false,
  relative = false,
  noBottomPadding = false,
  customClasses
}) => {
  const classes = useStyles();
  return (
    <section
      className={clsx(
        classes.root,
        showCloser && classes.closer,
        fullWidth && classes.fullWidth,
        relative && classes.relative,
        noBottomPadding && classes.noBottomPadding,
        customClasses && customClasses
      )}
    >
      {children}
    </section>
  );
};

export default MainContentContainer;
