// @flow

// react + router
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { matchPath, withRouter } from "react-router";

// material-ui
import {
  List,
  ListItemIcon,
  ListItemText,
  // makeStyles,
  withStyles
} from "@material-ui/core";
import MuiListItem from "@material-ui/core/ListItem";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import Icon from "@material-ui/core/Icon";

// libs
import nextId from "react-id-generator";
import { useSelector } from "react-redux";
import { authorize } from "../../../../utils/authorize";

/**
 * Flow types
 */
type Route = {
  name: string,
  link: string
};

type Props = {
  items: Array<Route>,
  onClickItem: Function
};

// const useStyles = makeStyles(theme => ({
// }));

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#e2f2ffd9"
      // color: "white"
    },
    "&$selected:hover": {
      backgroundColor: "#e2f2ff"
      // color: "white"
    },
    "&:hover": {
      backgroundColor: "#e2f2ff"
      // color: "white"
    }
  },
  selected: {}
})(MuiListItem);

const DrawerItems = (props: Props) => {
  // const classes = useStyles();

  const prepareIcon = icon => {
    if (!icon) {
      return <InboxIcon />;
    }
    return <Icon component={icon} />;
  };

  const isActive = (path, exact = false) => {
    const result = matchPath(props.location.pathname, {
      path: path,
      exact: exact,
      strict: false
    });
    return result && exact === false
      ? true
      : result && result.isExact === exact;
  };

  const authorization = useSelector(state => state.AuthReducer);
  const authorized = authorize(authorization, "isSystemAdmin");

  return (
    <>
      <List>
        {props.items.map(route =>
          route?.isSystemAdmin === true && !authorized ? null : (
            <RouterLink
              key={nextId("drawer-link-")}
              to={route.link}
              style={{ color: "inherit", textDecoration: "inherit" }}
              onClick={props.onClickItem}
            >
              <ListItem
                button
                key={nextId("drawer-item-")}
                selected={isActive(route.path, route.exact)}
              >
                <ListItemIcon>{prepareIcon(route.icon)}</ListItemIcon>
                <ListItemText primary={route.name} />
              </ListItem>
            </RouterLink>
          )
        )}
      </List>
    </>
  );
};

export default withRouter(DrawerItems);
