import React from "react";

import { Typography, makeStyles, Link } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  image: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "960px"
    },
    display: "inline-block",
    maxWidth: "100%",
    height: "auto",
    maxHeight: "300px"
  },
  title: {
    marginBottom: theme.spacing(2),
    display: "inline-block"
  }
}));

const Sponsor = ({ name, url, imageUrl }) => {
  const classes = useStyles();

  return (
    <>
      <Link href={url} target={"_blank"} color={"inherit"}>
        <Typography variant={"h6"} className={classes.title}>
          {url}
        </Typography>
      </Link>
      <br />
      <a href={url || undefined} target={"_blank"} rel="noopener noreferrer">
        <img src={imageUrl} className={classes.image} alt={name} />
      </a>
    </>
  );
};

export default Sponsor;
