import React from "react";
import { Grid } from "@material-ui/core";
import { Form } from "formik";
import FormActions from "../../../../../../common/components/forms/actions";
import NameField from "./fields/name";
import ImageUploader from "../../../../../../common/components/imageUploader";
import ShortDescriptionField from "./fields/shortDescription";
import WebsiteUrlField from "./fields/websiteUrl";
import BasicOrganizationNumberField from "./fields/basicOrganizationNumber";
import ParentOrganizationIdField from "./fields/parentOrganizetionId";
import PossibilitiesField from "./fields/possibilities";
import CustomInputLabel from "../../../../../../common/components/forms/customInputLabel";

const ClubSummaryCreateEditFormLayout = ({
  values,
  actualProfileImageUrl, // only for edit form
  errors,
  formDiagnostics,
  touched,
  isSubmitting,
  onClose,
  setFieldValue,
  createForm
}) => {
  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomInputLabel label={"Hlavní obrázek klubu"} />
          <ImageUploader
            initImage={{
              url: actualProfileImageUrl,
              id: values.imageId
            }}
            onChange={id => setFieldValue("imageId", id)}
            useCrop={false}
            alt={"Hlavní obrázek klubu"}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <NameField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>

        <Grid item xs={12} md={6}>
          <ShortDescriptionField
            baseParams={{ errors, formDiagnostics, touched }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <WebsiteUrlField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ParentOrganizationIdField
            values={values}
            setFieldValue={setFieldValue}
            baseParams={{ errors, formDiagnostics, touched }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicOrganizationNumberField
            baseParams={{ errors, formDiagnostics, touched }}
          />
        </Grid>
        {!createForm && (
          <Grid item xs={12} md={12}>
            <PossibilitiesField
              values={values}
              baseParams={{ errors, formDiagnostics, touched }}
            />
          </Grid>
        )}
      </Grid>
      <FormActions
        isSubmitting={isSubmitting}
        errors={errors}
        onClose={onClose}
      />
      {/*  ONLY FOR DEBUG PURPOSES  */}
      {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(errors, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(imageIds, null, 2)}</pre>*/}
    </Form>
  );
};

export default ClubSummaryCreateEditFormLayout;
