import React from "react";
import { Grid } from "@material-ui/core";

import { Form } from "formik";
import MultipleImageUploader from "../../../imageUploader/multiple";
import TitleField from "./fields/title";
import ContentField from "./fields/content";
import FormActions from "../../../forms/actions";

const ArticleCreateEditFormLayout = ({
  values,
  errors,
  formDiagnostics,
  touched,
  isSubmitting,
  onSetImageIds,
  actualImages,
  onClose,
  setFieldValue
}) => {
  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TitleField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={12}>
          <ContentField
            values={values}
            setFieldValue={setFieldValue}
            baseParams={{ errors, formDiagnostics, touched }}
          />
        </Grid>
        <MultipleImageUploader
          actualImages={actualImages}
          onChangeImageIds={onSetImageIds}
        />
      </Grid>
      <FormActions
        isSubmitting={isSubmitting}
        errors={errors}
        onClose={onClose}
      />

      {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(errors, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(imageIds, null, 2)}</pre>*/}
    </Form>
  );
};

export default ArticleCreateEditFormLayout;
