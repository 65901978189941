// @flow

// react + router
import React, { useEffect } from "react";
import { withRouter, Switch, Route } from "react-router-dom"; // Redirect
// import PrivateRoute from "utils/private-route.js";

// redux
import { useDispatch, useSelector } from "react-redux";
import { getAuth } from "redux/auth/actions";

// utils
import * as types from "./types";

// libs
import { SnackbarProvider } from "notistack";

// components
import LoginPage from "pages/login-page";
import MainPageRouter from "pages/main-page";
import Notifier from "common/components/notifier";
import SorryPage from "./pages/main-page/sorryPage";

// https://www.npmjs.com/package/react-draft-wysiwyg
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// https://www.npmjs.com/package/react-responsive-carousel
import "react-image-crop/dist/ReactCrop.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// https://www.npmjs.com/package/react-image-gallery
import "react-image-gallery/styles/css/image-gallery.css";

import moment from "moment/min/moment-with-locales";
import Moment from "react-moment";
import ConfirmationModal from "./common/components/modals/confirmationModal";
import { Helmet } from "react-helmet";
// Sets the moment instance to use.
Moment.globalMoment = moment;
// Set the locale for every react-moment instance to French.
Moment.globalLocale = "cs";
// Set the output format for every react-moment instance.
// sobota 20. červen 2020 7:30 - one of multilocale format have to be set
// https://momentjs.com/
Moment.globalFormat = "LLLL";

const App = (history: Object) => {
  const dispatch = useDispatch();

  const { isAuthenticated, firstCheckDone } = useSelector(
    state => state.AuthReducer
  );

  useEffect(() => {
    if (isAuthenticated || !firstCheckDone) {
      const dispatchAuth = async () => {
        await dispatch(getAuth());
      };
      dispatchAuth();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, history]);

  return (
    <SnackbarProvider maxSnack={3} hideIconVariant={false}>
      <Notifier />
      <Helmet>
        <meta property={"og:title"} content={"Kynologove.cz"} />
        <meta property={"og:type"} content={"website"} />
        <meta
          property={"og:image"}
          content={"https://kynologove.cz/logo192.png"}
        />
        <meta
          property={"og:description"}
          content={"Kynologický informační systém pro správu klubů a akcí."}
        />
      </Helmet>

      <Switch>
        <Route
          path="/register"
          render={() => <LoginPage formType={types.REGISTER_PAGE} />}
        />
        <Route
          path="/login"
          render={() => <LoginPage formType={types.LOGIN_PAGE} />}
        />
        <Route
          path="/email-verification/:activationHash"
          render={() => <LoginPage formType={types.EMAIL_VERIFICATION} />}
        />
        <Route
          path="/reset-password-request"
          render={() => (
            <LoginPage formType={types.RESET_PASSWORD_REQUEST_PAGE} />
          )}
        />
        <Route
          path="/reset-password/:activationHash"
          render={() => <LoginPage formType={types.RESET_PASSWORD_PAGE} />}
        />
        <Route
          path="/account-invitation/:activationHash"
          render={() => <LoginPage formType={types.ACCOUNT_INVITATION_PAGE} />}
        />
        <Route path="/sorry-page" render={() => <SorryPage />} />
        {/*<Route path="/gdpr" render={() => <GdprPage />} />*/}

        <Route render={props => <MainPageRouter {...props} />} />
      </Switch>
      <ConfirmationModal />
    </SnackbarProvider>
  );
};

export default withRouter(App);
