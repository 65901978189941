//import React from "react";
// import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { authorize } from "../../../utils/authorize";

// const useStyles = makeStyles(theme => ({
//   root: {
//     padding: theme.spacing(0)
//   }
// }));

const AuthorizedOnly = ({
  children,
  expectedRole,
  resourceType = null,
  resourceId = null
}) => {
  // const classes = useStyles();

  const authorization = useSelector(state => state.AuthReducer);
  const authorized = authorize(
    authorization,
    expectedRole,
    resourceType,
    resourceId
  );

  return authorized ? children : null;
};

export default AuthorizedOnly;
