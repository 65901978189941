import React, { useEffect, useState } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import cs from "date-fns/locale/cs";
import { Form, Formik } from "formik";
import MultipleImageUploader from "../../imageUploader/multiple";
import FormActions from "../../forms/actions";
import { resetFormDiagnostics } from "../../../../redux/common/actions";
import NameField, { YUP_NAME_REQUIRED } from "./fields/name";
import { createPhotoGallery } from "../../../../redux/photo-gallery/actions";

const FORM_NAME = "PhotoGalleryCreateForm";
export const SUBMITTING_FORM_LOADER =
  "SUBMITTING_PHOTO_GALLERY_CREATE_FORM_LOADER";

const validationSchema = Yup.object().shape({
  name: YUP_NAME_REQUIRED
});

const useStyles = makeStyles(theme => ({}));

const PhotoGalleryCreateForm = ({
  initialValues,
  actualImages,
  onClose,
  onSubmitSuccessfully,
  resourceType,
  resourceId
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(resetFormDiagnostics(FORM_NAME));
    })();
  }, [dispatch]);

  const [imageIds, setImageIds] = useState(actualImages.map(image => image.id));

  const isSubmitting = false;
  const formDiagnostics =
    useSelector(state => state.CommonReducer.formDiagnostics)[FORM_NAME] || {};

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
        <Formik
          initialValues={initialValues}
          onSubmit={async values => {
            dispatch(
              createPhotoGallery({
                resourceType,
                resourceId,
                values,
                formName: FORM_NAME,
                onSuccess: onSubmitSuccessfully,
                imageIds: imageIds
              })
            );
          }}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <NameField
                      baseParams={{ errors, formDiagnostics, touched }}
                    />
                  </Grid>
                  <MultipleImageUploader
                    actualImages={actualImages}
                    onChangeImageIds={setImageIds}
                  />
                </Grid>
                <FormActions
                  isSubmitting={isSubmitting}
                  errors={errors}
                  onClose={onClose}
                />

                {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
                {/*<pre>{JSON.stringify(errors, null, 2)}</pre>*/}
                {/*<pre>{JSON.stringify(imageIds, null, 2)}</pre>*/}
              </Form>
            );
          }}
        </Formik>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default PhotoGalleryCreateForm;
