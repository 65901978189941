import React from "react";
import { TextField } from "formik-material-ui";
import { Field } from "formik";
import { combineFormErrorMessages } from "../../../utils/diagnostics";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  moreSpaceAround: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  fullWidth: {
    width: "100%"
  }
}));

const FieldBase = ({
  name,
  component,
  label,
  fullWidth = true,
  type = "text",
  placeholder,
  inputProps,
  baseParams,
  minValue,
  maxValue,
  autoCompleteOff = false,
  autoComplete,
  disabled
}) => {
  const classes = useStyles();
  const { errors, formDiagnostics, touched } = baseParams;
  const localCombineFormErrorMessages = () => {
    return combineFormErrorMessages(errors, formDiagnostics, name, touched);
  };

  const lowerInputProps =
    type === "number" ? { min: minValue, max: maxValue } : undefined;

  return (
    <div
      className={clsx(classes.moreSpaceAround, fullWidth && classes.fullWidth)}
    >
      <Field
        component={component || TextField}
        name={name}
        label={label}
        fullWidth={fullWidth}
        type={type}
        placeholder={placeholder}
        InputProps={inputProps}
        inputProps={lowerInputProps}
        helperText={localCombineFormErrorMessages(name)}
        error={!!localCombineFormErrorMessages(name)}
        autoComplete={autoCompleteOff ? "off" : autoComplete}
        disabled={disabled}
      />
    </div>
  );
};

export default FieldBase;
