import React, { useEffect } from "react";

import { Typography, Grid, makeStyles, Box } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import CakeIcon from "@material-ui/icons/Cake";

import MainContentContainer from "common/components/main-content-container";
import Section from "common/components/page/section";
import { useDispatch, useSelector } from "react-redux";
import { NOT_SET_PLACEHOLDER } from "../../../../../constants";
import { getPerson } from "../../../../../redux/person/actions";
import Moment from "react-moment";
// import { printAddressString } from "../../../../../utils/dataTransformation";
import { ContactMail, Home, Phone } from "@material-ui/icons";
import PersonProfileEditForm, {
  PERSON_PROFILE_EDIT_FORM_MODAL_NAME
} from "./forms/editForm";
import Button from "@material-ui/core/Button";
import ProfileImage from "../../../../../common/components/profileImage";
import ProfileImageWrapper from "../../../../../common/components/profileImage/wrapper";
import { closeModal, openModal } from "../../../../../redux/common/actions";
import ModalFormWrapper from "../../../../../common/components/modalForm/wrapper";

const useStyles = makeStyles(theme => ({
  icons: {
    marginRight: theme.spacing(1)
  },
  coloredLists: {
    color: theme.palette.secondary.main
  },
  editButton: {
    float: "right",
    marginTop: theme.spacing(4)
  }
}));

const PersonProfile = ({ resourceId: personId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    reloadPersons();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [personId]);

  const handleEditModalOpen = () => {
    dispatch(openModal(PERSON_PROFILE_EDIT_FORM_MODAL_NAME));
  };
  const handleEditModalClose = () => {
    dispatch(closeModal(PERSON_PROFILE_EDIT_FORM_MODAL_NAME));
  };

  const reloadPersons = () => {
    dispatch(getPerson(personId));
  };

  const handleEditFormSubmitSuccessfully = () => {
    reloadPersons();
  };
  const person = useSelector(state => state.PersonReducer.person);

  return person ? (
    <>
      <ModalFormWrapper
        modalName={PERSON_PROFILE_EDIT_FORM_MODAL_NAME}
        title={"Upravit informace osoby"}
      >
        <PersonProfileEditForm
          personId={personId}
          initialValues={{
            // profileImage: null,
            firstName: person.firstName || "",
            lastName: person.lastName || "",
            city: person.address.city || "",
            street: person.address.street || "",
            zipNumber: person.address.zipNumber || "",
            phoneNumber: person.phoneNumber || "",
            birthDate: person.birthDate
          }}
          onClose={handleEditModalClose}
          actualProfileImageUrl={person.image.url}
          onSubmitSuccessfully={handleEditFormSubmitSuccessfully}
        />
      </ModalFormWrapper>
      <MainContentContainer>
        <Button
          className={classes.editButton}
          onClick={handleEditModalOpen}
          color={"primary"}
          variant={"outlined"}
        >
          Upravit
        </Button>
        <Section
          title={`Profil uživatele ${person.fullName}`}
          bottomDivider={false}
        >
          <Grid container spacing={10} direction="row" alignItems="center">
            <Grid item xs={12} md={6} lg={4}>
              <ProfileImageWrapper>
                <ProfileImage imageUrl={person.image.url} circular/>
              </ProfileImageWrapper>
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              {/*alignItems="center"*/}
              <Grid container spacing={2} direction="row">
                <Grid item xs={12}>
                  <Typography variant={"h6"}>Základní informace:</Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <Typography variant={"body1"}>
                    <PersonIcon
                      color="secondary"
                      fontSize={"default"}
                      className={classes.icons}
                    />
                    <strong>Jméno:</strong> {person.firstName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <Typography variant={"body1"}>
                    <PersonIcon
                      color="secondary"
                      fontSize={"default"}
                      className={classes.icons}
                    />
                    <strong>Příjmení:</strong> {person.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <Typography variant={"body1"}>
                    <CakeIcon
                      color="secondary"
                      fontSize={"default"}
                      className={classes.icons}
                    />
                    <strong>Datum narození:</strong>{" "}
                    {person.birthDate ? (
                      <Moment format="DD.MM.YYYY" date={person.birthDate} />
                    ) : (
                      ""
                    )}
                  </Typography>
                </Grid>
                {/*<Grid item xs={12} md={12}>*/}
                {/*  <Typography variant={"body1"}>*/}
                {/*    <Home*/}
                {/*      color="secondary"*/}
                {/*      fontSize={"default"}*/}
                {/*      className={classes.icons}*/}
                {/*    />*/}
                {/*    <strong>Adresa:</strong>{" "}*/}
                {/*    {printAddressString(*/}
                {/*      person.address.street,*/}
                {/*      person.address.zipNumber,*/}
                {/*      person.address.city*/}
                {/*    )}*/}
                {/*  </Typography>*/}
                {/*</Grid>*/}
                <Grid item xs={12}>
                  <Box marginTop={2}>
                    <Typography variant={"h6"}>Adresa:</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <Typography variant={"body1"}>
                    <Home
                      color="secondary"
                      fontSize={"default"}
                      className={classes.icons}
                    />
                    <strong>Město:</strong>{" "}
                    {person?.address?.city || NOT_SET_PLACEHOLDER}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <Typography variant={"body1"}>
                    <Home
                      color="secondary"
                      fontSize={"default"}
                      className={classes.icons}
                    />
                    <strong>Ulice:</strong>{" "}
                    {person?.address?.street || NOT_SET_PLACEHOLDER}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <Typography variant={"body1"}>
                    <Home
                      color="secondary"
                      fontSize={"default"}
                      className={classes.icons}
                    />
                    <strong>PSČ:</strong>{" "}
                    {person?.address?.zipNumber || NOT_SET_PLACEHOLDER}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box marginTop={2}>
                    <Typography variant={"h6"}>Kontaktní informace:</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <Typography variant={"body1"}>
                    <ContactMail
                      color="secondary"
                      fontSize={"default"}
                      className={classes.icons}
                    />
                    <strong>Email</strong>{" "}
                    {person.email ? (
                      <a
                        href={`mailto:${person.email}`}
                        target={"_blank"}
                        rel="noopener noreferrer"
                      >
                        {person.email}
                      </a>
                    ) : (
                      NOT_SET_PLACEHOLDER
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <Typography variant={"body1"}>
                    <Phone
                      color="secondary"
                      fontSize={"default"}
                      className={classes.icons}
                    />
                    <strong>Telefonní číslo:</strong> {person.phoneNumber}
                  </Typography>
                </Grid>
                {/*<Grid item xs={6} md={12}>*/}
                {/*  <Typography variant={"body1"}>*/}
                {/*    <Web*/}
                {/*      color="secondary"*/}
                {/*      fontSize={"default"}*/}
                {/*      className={classes.icons}*/}
                {/*    />*/}
                {/*    <strong>Webové stránky</strong>{" "}*/}
                {/*    {person.websiteUrl ? (*/}
                {/*      <a href={person.websiteUrl} target={"_blank"}>*/}
                {/*        {person.websiteUrl}*/}
                {/*      </a>*/}
                {/*    ) : (*/}
                {/*      NOT_SET_PLACEHOLDER*/}
                {/*    )}*/}
                {/*  </Typography>*/}
                {/*</Grid>*/}
              </Grid>
            </Grid>
          </Grid>
        </Section>
      </MainContentContainer>
    </>
  ) : null;
};

export default PersonProfile;
