import React from "react";

import { makeStyles } from "@material-ui/core";
import MuiDivider from "@material-ui/core/Divider";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  color: {
    backgroundColor: props =>
      props.customColor ? props.customColor : theme.palette.secondary.main
  },
  margin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}));

const Divider = ({ noColor = false, noMargin = false, customColor = null }) => {
  const classes = useStyles({ customColor: customColor });

  return (
    <MuiDivider
      className={clsx(!noColor && classes.color, !noMargin && classes.margin)}
    />
  );
};

export default Divider;
