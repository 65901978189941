// @flow

import axios from "utils/axios";
import { ThunkAction } from "redux-thunk";

import * as types from "./types";

// notification-actions
import { enqueueSnackbar } from "redux/notification/notification-actions";
import {
  appendFormDiagnostics,
  closeModal,
  disableLoader,
  enableLoader,
  resetFormDiagnostics
} from "../common/actions";
import { EVENT_CONTACT_EDIT_FORM_MODAL_NAME } from "../../pages/contentPages/eventPages/sub-pages/contact/editFormModal";
import { EVENT_COMPETITOR_EDIT_FORM_MODAL_NAME_PREFIX } from "../../pages/contentPages/eventPages/sub-pages/competitor/forms/editFormModal";
import { EVENT_COMPETITOR_CREATE_IN_CATEGORY_FORM_MODAL_NAME_PREFIX } from "../../pages/contentPages/eventPages/sub-pages/competitor/forms/createForm";
import { datetime_from_server, datetime_to_server } from "../../utils/datetime";
import { LOAD_PROPOSITION_CUSTOM_PAGE } from "./types";

export const GET_EVENT_CONTACT_LOADER_NAME = "GET_EVENT_CONTACT_LOADER_NAME";
export const GET_EVENT_COMPETITOR_LOADER_NAME =
  "GET_EVENT_COMPETITOR_LOADER_NAME";

export const GET_EVENT_PROPOSITION_LOADER_NAME =
  "GET_EVENT_PROPOSITION_LOADER_NAME";

export const GET_EVENT_SPONSOR_LOADER_NAME = "GET_EVENT_SPONSOR_LOADER_NAME";

export const resetEventRedux = () => {
  return async (dispatch: ThunkAction) => {
    dispatch({
      type: types.RESET_EVENT_STATE
      // payload: { shown: shown }
    });
  };
};

/**
 * GET load images from `/v1/events/<id>/proposition`
 * @param {number} eventId id of event to load proposition
 */
export const loadProposition = (eventId: number) => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(GET_EVENT_PROPOSITION_LOADER_NAME));
    try {
      const response = await axios({
        url: "/v1/events/" + eventId + "/proposition",
        method: "get",
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch({
        type: types.LOAD_PROPOSITION_CUSTOM_PAGE,
        // payload: {
        //   id: response.data.proposition.id,
        //   html: response.data.proposition.html
        // }
        payload: response.data.propositionCustomPage
          ? {
              id: response.data.propositionCustomPage.id,
              title: response.data.propositionCustomPage.title,
              sections: response.data.propositionCustomPage.sections.map(
                section => ({
                  id: section.id,
                  order: section.order,
                  componentName: section.componentName,
                  properties: JSON.parse(section.propertiesJson)
                })
              )
            }
          : null
      });
      // callback();
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání propozic došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(GET_EVENT_PROPOSITION_LOADER_NAME));
    }
  };
};
//
// export const showPropositionEditor = shown => {
//   return async (dispatch: ThunkAction) => {
//     dispatch({
//       type: types.SHOW_PROPOSITION_EDITOR,
//       payload: { shown: shown }
//     });
//   };
// };
//
// export const changeProposition = (newContent: string) => {
//   return async (dispatch: ThunkAction) => {
//     dispatch({
//       type: types.CHANGE_PROPOSITION,
//       payload: newContent
//     });
//     //   try {
//     //     const response = await axios({
//     //       url: "/v1/events/" + eventId + "/proposition",
//     //       method: "get",
//     //       validateStatus: status => {
//     //         return status >= 200 && status <= 304;
//     //       }
//     //     });
//     //     dispatch({
//     //       type: types.LOAD_PROPOSITION,
//     //       payload: response.data.content
//     //     });
//     //     // callback();
//     //   } catch (error) {
//     //     dispatch(
//     //       enqueueSnackbar({
//     //         message: "V průběhu načítání propozic došlo k chybě!",
//     //         options: {
//     //           key: new Date().getTime() + Math.random(),
//     //           variant: "error"
//     //         }
//     //       })
//     //     );
//     //   }
//   };
// };
//
// /**
//  * GET load images from `/v1/events/<id>/proposition`
//  * @param {number} eventId id of event to load proposition
//  */
// export const saveProposition = (eventId: number) => {
//   return async (dispatch: ThunkAction) => {
//     try {
//       const response = await axios({
//         url: "/v1/events/" + eventId + "/proposition",
//         method: "post",
//         validateStatus: status => {
//           return status >= 200 && status <= 304;
//         }
//       });
//       dispatch({
//         type: types.SAVE_PROPOSITION,
//         payload: response.data.content
//       });
//       // callback();
//     } catch (error) {
//       dispatch(
//         enqueueSnackbar({
//           message: "V průběhu ukládání propozic došlo k chybě!",
//           options: {
//             key: new Date().getTime() + Math.random(),
//             variant: "error"
//           }
//         })
//       );
//     }
//   };
// };

/**
 * Get events for given filter `POST /v1/events/`  TODO filter and rename endpoint to `events`
 */
export const getEvents = ({ customDomain }) => {
  return async (dispatch: ThunkAction) => {
    try {
      const response = await axios({
        url: "/v1/events/",
        method: "post",
        data: { filter: {}, customDomain: customDomain },
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch({
        type: types.GET_EVENTS,
        payload:
          response.data.events.map(event => {
            return {
              id: event.id,
              name: event.name,
              term: datetime_from_server(event.term),
              termEnd: datetime_from_server(event.termEnd),
              venue: event.venue,
              shortDescription: event.shortDescription,
              imageUrl: event.image ? event.image.url : null
            };
          }) || []
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání závodů došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

/**
 * Get events for given filter `POST /v1/events/`  TODO filter and rename endpoint to `events`
 */
export const getEvent = (id, displayOptions = null) => {
  return async (dispatch: ThunkAction) => {
    try {
      // TODO: RESET EVENT if id changed... otherwise previous loaded data are show for the short time
      const response = await axios({
        url: "/v1/events/" + id + "/summary/",
        method: "get",
        // data: { filter: { id: id } },
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch({
        type: types.GET_EVENT,
        payload: {
          id: response.data.summary.id,
          name: response.data.summary.name,
          term: datetime_from_server(response.data.summary.term),
          termEnd: datetime_from_server(response.data.summary.termEnd),
          deadline: datetime_from_server(response.data.summary.deadline),
          address: response.data.summary.address
            ? {
                id: response.data.summary.address.id,
                city: response.data.summary.address.city,
                street: response.data.summary.address.street,
                zipNumber: response.data.summary.address.zipNumber,
                description: response.data.summary.address.description,
                gps: response.data.summary.address.gps
              }
            : null,
          shortDescription: response.data.summary.shortDescription,
          longDescription: response.data.summary.longDescription,
          imageUrl: response.data.summary.image
            ? response.data.summary.image.url
            : null,
          helpers: response.data.summary.helpers,
          judges: response.data.summary.judges,
          categories: response.data.summary.categories,
          price: response.data.summary.price,
          organizerClub: response.data.summary.organizerClub,
          organizerUser: response.data.summary.organizerUser
        }
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání akce došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

/**
 * Get event participants `POST /v1/events/<id>/participants`
 */
export const getEventParticipants = id => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(GET_EVENT_COMPETITOR_LOADER_NAME));
    try {
      // TODO: RESET participants if id changed... otherwise previous loaded data are show for the short time
      const response = await axios({
        url: "/v1/events/" + id + "/participants/",
        method: "post",
        data: { onlyCompetitors: true },
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch({
        type: types.GET_EVENT_PARTICIPANTS,
        payload: response.data.participants //.map(participant => {
        //   return {
        //     id: participant.id,
        //     breed: participant.breed,
        //     category: participant.category,
        //     startNumber: participant.startNumber,
        //     name: participant.name,
        //     surname: participant.surname,
        //     clubName: participant.clubName,
        //     dogName: participant.dogName
        //   };
        // })
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání dat akce došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(GET_EVENT_COMPETITOR_LOADER_NAME));
    }
  };
};

/**
 * Get event photo gallery `GET /v1/events/<id>/photo-gallery`
 */
export const getEventContact = id => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(GET_EVENT_CONTACT_LOADER_NAME));
    try {
      // TODO: RESET contact if id changed... otherwise previous loaded data are show for the short time
      const response = await axios({
        url: "/v1/events/" + id + "/contact/",
        method: "get",
        // data: { filter: { id: id } },
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch({
        type: types.GET_EVENT_CONTACT,
        payload: {
          address: response.data.contact.address,
          persons: response.data.contact.persons.map(person => {
            return {
              id: person.id,
              name: person.name,
              surname: person.surname,
              email: person.email,
              phoneNumber: person.phoneNumber,
              address: person.address,
              roles: person.roles,
              imageUrl: person?.image?.url || null
            };
          })
        }
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání kontaktů akce došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(GET_EVENT_CONTACT_LOADER_NAME));
    }
  };
};

/**
 * Get event sponsorss `GET /v1/events/<id>/sponsors`
 */
export const getEventSponsors = id => {
  return async (dispatch: ThunkAction) => {
    try {
      dispatch(enableLoader(GET_EVENT_SPONSOR_LOADER_NAME));
      // TODO: RESET data if id changed... otherwise previous loaded data are show for the short time
      const response = await axios({
        url: "/v1/events/" + id + "/sponsors/",
        method: "get",
        // data: { filter: { id: id } },
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch({
        type: types.GET_EVENT_SPONSORS,
        // payload: response.data.sponsors.map(sponsor => {
        //   return {
        //     id: sponsor.id,
        //     name: sponsor.name,
        //     url: sponsor.url,
        //     imageUrl: sponsor.image ? sponsor.image.url : null
        //   };
        payload: response.data.sponsorCustomPage
          ? {
              id: response.data.sponsorCustomPage.id,
              title: response.data.sponsorCustomPage.title,
              sections: response.data.sponsorCustomPage.sections.map(
                section => ({
                  id: section.id,
                  order: section.order,
                  componentName: section.componentName,
                  properties: JSON.parse(section.propertiesJson)
                })
              )
            }
          : null
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání sponzorů akce došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(GET_EVENT_SPONSOR_LOADER_NAME));
    }
  };
};

export const SUBMITTING_EVENT_SUMMARY_EDIT_FORM_LOADER =
  "SUBMITTING_EVENT_SUMMARY_EDIT_FORM_LOADER";

/**
 * Edit event summary `POST /v1/events/<eventId>/summary/`
 */
export const submitEventSummaryEditForm = ({
  eventId,
  values,
  formName,
  modalName,
  onSuccess
}) => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(SUBMITTING_EVENT_SUMMARY_EDIT_FORM_LOADER));
    dispatch(resetFormDiagnostics(formName));

    try {
      const response = await axios({
        url: `/v1/events/${eventId}/summary/`,
        method: "post",
        data: {
          imageId: values.imageId,
          name: values.name,
          shortDescription: values.shortDescription,
          // venue: values.venue,
          // term: values.term,
          term: datetime_to_server(values.term),
          deadline: datetime_to_server(values.deadline),
          price: values.price,
          helpers: values.helpers.map(helper =>
            helper?.creatable ? { fullName: helper.value } : helper
          ),
          judges: values.judges.map(judge =>
            judge?.creatable ? { fullName: judge.value } : judge
          ),
          categories: values.categories
        },
        validateStatus: status => {
          return (status >= 200 && status <= 304) || status === 400;
        }
      });
      if (response.status === 400) {
        dispatch(appendFormDiagnostics(formName, response.data.diagnostics));
      } else {
        dispatch(resetFormDiagnostics(formName));
        dispatch(closeModal(modalName));
        dispatch(
          enqueueSnackbar({
            message: "Údaje akce byly úspěšně změněny.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success"
            }
          })
        );
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu úpravy akce došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(SUBMITTING_EVENT_SUMMARY_EDIT_FORM_LOADER));
    }
  };
};

export const SUBMITTING_EVENT_SUMMARY_CREATE_FORM_LOADER =
  "SUBMITTING_EVENT_SUMMARY_CREATE_FORM_LOADER";

/**
 * Create event summary `PUT /v1/events/`
 */
export const submitEventSummaryCreateForm = ({
  values,
  formName,
  modalName,
  onSuccess
}) => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(SUBMITTING_EVENT_SUMMARY_CREATE_FORM_LOADER));
    dispatch(resetFormDiagnostics(formName));
    try {
      const response = await axios({
        url: "/v1/events/",
        method: "put",
        data: {
          imageId: values.imageId,
          name: values.name,
          shortDescription: values.shortDescription,
          // venue: values.venue,
          term: datetime_to_server(values.term),
          deadline: datetime_to_server(values.deadline),
          price: values.price,
          helpers: values.helpers.map(helper =>
            helper?.creatable ? { fullName: helper.value } : helper
          ),
          judges: values.judges.map(judge =>
            judge?.creatable ? { fullName: judge.value } : judge
          ),
          categories: values.categories
        },
        validateStatus: status => {
          return (status >= 200 && status <= 304) || status === 400;
        }
      });
      if (response.status === 400) {
        dispatch(appendFormDiagnostics(formName, response.data.diagnostics));
      } else {
        dispatch(resetFormDiagnostics(formName));
        dispatch(closeModal(modalName));
        dispatch(
          enqueueSnackbar({
            message: "Nová akce byla úspěšně vytvořena.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success"
            }
          })
        );
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu vytváření akce došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(SUBMITTING_EVENT_SUMMARY_CREATE_FORM_LOADER));
    }
  };
};

export const SUBMITTING_EVENT_CONTACT_EDIT_FORM_LOADER =
  "SUBMITTING_EVENT_CONTACT_EDIT_FORM_LOADER";

/**
 * Edit event contact `POST /v1/evetns/<eventId>/contact/`
 */
export const submitEventContactEditForm = (
  eventId,
  values,
  formName,
  onSuccess
) => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(SUBMITTING_EVENT_CONTACT_EDIT_FORM_LOADER));
    dispatch(resetFormDiagnostics(formName));

    const url = `/v1/events/${eventId}/contact/`;
    const method = "post";

    try {
      const response = await axios({
        url: url,
        method: method,
        data: {
          street: values.street,
          city: values.city,
          zipNumber: values.zipNumber,
          description: values.description,
          gps: values.gps
        },
        validateStatus: status => {
          return (status >= 200 && status <= 304) || status === 400;
        }
      });
      if (response.status === 400) {
        dispatch(appendFormDiagnostics(formName, response.data.diagnostics));
      } else {
        dispatch(resetFormDiagnostics(formName));
        dispatch(closeModal(EVENT_CONTACT_EDIT_FORM_MODAL_NAME));
        dispatch(
          enqueueSnackbar({
            message: "Kontaktní údaje akce byly úspěšně změněny.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success"
            }
          })
        );
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu úpravy kontakních údajů akce došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(SUBMITTING_EVENT_CONTACT_EDIT_FORM_LOADER));
    }
  };
};

export const SUBMITTING_EVENT_COMPETITOR_EDIT_FORM_LOADER =
  "SUBMITTING_EVENT_CONTACT_EDIT_FORM_LOADER";

/**
 * Edit event contact `POST /v1/evetns/<eventId>/contact/`
 */
export const submitEventCompetitorEditForm = ({
  competitorId,
  eventId,
  categoryName,
  values,
  formName,
  onSuccess,
  createForm
}) => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(SUBMITTING_EVENT_COMPETITOR_EDIT_FORM_LOADER));
    dispatch(resetFormDiagnostics(formName));

    const url = createForm
      ? `/v1/events/${eventId}/participants/`
      : `/v1/events/${eventId}/participants/${competitorId}/`;
    const method = createForm ? "put" : "post";

    try {
      const response = await axios({
        url: url,
        method: method,
        data: {
          participantTypeId: 3, // competitor
          categoryName: createForm ? categoryName : null,
          participant: createForm
            ? values.participant?.creatable
              ? { fullName: values.participant.value }
              : values.participant
            : null,
          dog: createForm
            ? values.dog?.creatable
              ? { fullName: values.dog.value }
              : values.dog
            : null,
          clubName: values.clubName,
          startNumber: values.startNumber,
          place: values.place,
          pointsA: values.pointsA,
          pointsB: values.pointsB,
          pointsC: values.pointsC
        },
        validateStatus: status => {
          return (status >= 200 && status <= 304) || status === 400;
        }
      });
      if (response.status === 400) {
        dispatch(appendFormDiagnostics(formName, response.data.diagnostics));
      } else {
        dispatch(resetFormDiagnostics(formName));
        dispatch(
          closeModal(
            createForm
              ? EVENT_COMPETITOR_CREATE_IN_CATEGORY_FORM_MODAL_NAME_PREFIX +
                  categoryName
              : EVENT_COMPETITOR_EDIT_FORM_MODAL_NAME_PREFIX +
                  competitorId.toString()
          )
        );
        dispatch(
          enqueueSnackbar({
            message: createForm
              ? "Nový závodník byl úspěšně přidán."
              : "Závodník byl úspěšně změněn.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success"
            }
          })
        );
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: createForm
            ? "V průběhu vytváření závodníka došlo k chybě!"
            : "V průběhu úpravy závodníka došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(SUBMITTING_EVENT_COMPETITOR_EDIT_FORM_LOADER));
    }
  };
};

/*
 * Removes given event competitor and dispatches doneDispatch if removing was completely finished.
 */
export const removeCompetitor = ({ participantId, eventId, onSuccess }) => {
  return async (dispatch: ThunkAction) => {
    try {
      await axios({
        url: `/v1/events/${eventId}/participants/${participantId}/`,
        method: "delete",
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch(
        enqueueSnackbar({
          message: "Smazání závodníka proběhlo úspěšně.",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success"
          }
        })
      );
      if (onSuccess) dispatch(onSuccess);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu mazání závodníka došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

/**
 * POST load all possible event categories `/v1/events/<id>/categories`
 * @param {number} eventId id of event to load all possible categories
 */
export const loadAllPossibleEventCategories = ({ eventId }) => {
  return async (dispatch: ThunkAction) => {
    // dispatch(enableLoader(GET_EVENT_PROPOSITION_LOADER_NAME));
    try {
      const response = await axios({
        url: "/v1/events/" + eventId + "/categories",
        method: "post",
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch({
        type: types.LOAD_ALL_POSSIBLE_EVENT_CATEGORIES,
        payload: response.data.allPossibleEventCategories.map(category => ({
          name: category.name,
          type: category.type
        }))
      });
      // callback();
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání možných katerorií akce došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      // dispatch(disableLoader(GET_EVENT_PROPOSITION_LOADER_NAME));
    }
  };
};

export const SUBMITTING_EVENT_STAFF_EDIT_FORM_LOADER =
  "SUBMITTING_EVENT_STAFF_EDIT_FORM_LOADER";

/**
 * Edit event staff `POST /v1/events/<eventId>/staffs/<staffId>`
 */
export const submitEventStaffEditForm = ({
  eventId,
  staffId,
  userId,
  values,
  formName,
  modalName,
  onSuccess
  // createForm
}) => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(SUBMITTING_EVENT_STAFF_EDIT_FORM_LOADER));
    dispatch(resetFormDiagnostics(formName));

    try {
      const response = await axios({
        url: `/v1/events/${eventId}/staffs/${staffId}/`,
        method: "post",
        data: {
          userId: userId,
          email: values.isVirtual ? undefined : values.email || undefined,
          firstName: values.isVirtual ? values.firstName : undefined,
          lastName: values.isVirtual ? values.lastName : undefined,
          city: values.isVirtual ? values.city : undefined,
          street: values.isVirtual ? values.street : undefined,
          zipNumber: values.isVirtual ? values.zipNumber : undefined,
          phoneNumber: values.isVirtual ? values.phoneNumber : undefined,
          birthDate:
            values.isVirtual && values.birthDate
              ? new Date(values.birthDate).toISOString()
              : undefined,
          // event staff specific fields
          roles: values.roles,
          isAdmin: values.isVirtual ? false : values.isAdmin,
          isContactPerson: values.isContactPerson,
          imageId: values.imageId
        },
        validateStatus: status => {
          return (status >= 200 && status <= 304) || status === 400;
        }
      });
      if (response.status === 400) {
        dispatch(appendFormDiagnostics(formName, response.data.diagnostics));
      } else {
        dispatch(resetFormDiagnostics(formName));
        dispatch(closeModal(modalName));
        dispatch(
          enqueueSnackbar({
            message: "Údaje pořadatele akce byly úspěšně změněny.",
            // message: createForm
            //   ? "Nový člen klubu byl úspěšně přidán ke klubu."
            //   : "Údaje člena klubu byly úspěšně změněny.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success"
            }
          })
        );
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu úpravy pořadatele akce došlo k chybě!",
          // message: createForm
          //   ? "V průběhu vytváření člena klubu došlo k chybě!"
          //   : "V průběhu úpravy člena klubu došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(SUBMITTING_EVENT_STAFF_EDIT_FORM_LOADER));
    }
  };
};

export const SUBMITTING_EVENT_STAFF_CREATE_FORM_LOADER =
  "SUBMITTING_EVENT_STAFF_CREATE_FORM_LOADER";

/**
 * Create event staff `PUT /v1/events/<eventId>/staffs/`
 */
export const submitEventStaffCreateForm = ({
  eventId,
  values,
  formName,
  modalName,
  onSuccess,
  realUserSelected
}) => {
  return async (dispatch: ThunkAction) => {
    dispatch(enableLoader(SUBMITTING_EVENT_STAFF_CREATE_FORM_LOADER));
    dispatch(resetFormDiagnostics(formName));

    try {
      const response = await axios({
        url: `/v1/events/${eventId}/staffs/`,
        method: "put",
        data: {
          userId: realUserSelected ? values.userId : undefined,
          email: values.isVirtual ? undefined : values.email || undefined,
          firstName: !realUserSelected ? values.firstName : undefined,
          lastName: !realUserSelected ? values.lastName : undefined,
          city: !realUserSelected ? values.city : undefined,
          street: !realUserSelected ? values.street : undefined,
          zipNumber: !realUserSelected ? values.zipNumber : undefined,
          phoneNumber: !realUserSelected ? values.phoneNumber : undefined,
          birthDate:
            !realUserSelected && values.birthDate
              ? new Date(values.birthDate).toISOString()
              : undefined,
          // event staff specific fields
          roles: values.roles,
          isAdmin: values.isVirtual ? false : values.isAdmin,
          isContactPerson: values.isContactPerson,
          imageId: values.imageId
        },
        validateStatus: status => {
          return (status >= 200 && status <= 304) || status === 400;
        }
      });
      if (response.status === 400) {
        dispatch(appendFormDiagnostics(formName, response.data.diagnostics));
      } else {
        dispatch(resetFormDiagnostics(formName));
        dispatch(closeModal(modalName));
        dispatch(
          enqueueSnackbar({
            message: "Nový pořadatel akce byl úspěšně přidán.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success"
            }
          })
        );
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu vytváření pořadatele akce došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(SUBMITTING_EVENT_STAFF_CREATE_FORM_LOADER));
    }
  };
};

/**
 * Get event staffs for given filter `GET /v1/events/<eventId>/staffs/`
 */
export const getEventStaffs = eventId => {
  return async (dispatch: ThunkAction) => {
    try {
      const response = await axios({
        url: `/v1/events/${eventId}/staffs/`,
        method: "get",
        // data: {},
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch({
        type: types.GET_EVENT_STAFFS,
        payload:
          response.data.eventStaffs.map(eventStaff => {
            return {
              id: eventStaff.id,
              eventId: eventStaff.eventId,
              user: {
                id: eventStaff.user.id,
                name: eventStaff.user.name,
                surname: eventStaff.user.surname,
                fullname: eventStaff.user.fullname,
                isVirtual: eventStaff.user.isVirtual,
                imageUrl: eventStaff.user.imageUrl || null,
                address: {
                  id: eventStaff.user.address.id,
                  city: eventStaff.user.address.city,
                  street: eventStaff.user.address.street,
                  zipNumber: eventStaff.user.address.zipNumber
                },
                phoneNumber: eventStaff.user.phoneNumber
                // birthDate: eventStaff.user.birthDate
              },
              category: eventStaff.category,
              isAdmin: eventStaff.isAdmin,
              roles: eventStaff.roles,
              isContactPerson: eventStaff.isContactPerson
            };
          }) || []
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání pořadatelů akce došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

/*
 * Removes given event staff and dispatches onSuccess if removing was completely finished.
 */
export const removeStaff = ({ staffId, eventId, onSuccess }) => {
  return async (dispatch: ThunkAction) => {
    try {
      await axios({
        url: `/v1/events/${eventId}/staffs/${staffId}/`,
        method: "delete",
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch(
        enqueueSnackbar({
          message: "Smazání pořadatele akce proběhlo úspěšně.",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success"
          }
        })
      );
      if (onSuccess) dispatch(onSuccess);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu mazání pořadatele akce došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};
