import React from "react";
import * as Yup from "yup";
// import { REQUIRED } from "../../../../../../../../constants/dictionary/validation";
import SwitchFieldBase from "../../../../../../../common/components/forms/switchFieldBase";

const FIELD_NAME = "isAdmin";

export const YUP_IS_ADMIN = Yup.boolean();
// export const YUP_IS_ADMIN_REQUIRED = YUP_IS_ADMIN.required(REQUIRED);

const IsAdminField = ({ baseParams, disabled }) => {
  return (
    <SwitchFieldBase
      name={FIELD_NAME}
      label={"Administrátor klubu"}
      disabled={disabled}
      baseParams={baseParams}
    />
  );
};

export default IsAdminField;
