import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import cs from "date-fns/locale/cs";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { SUBMITTING_PERSON_PROFILE_EDIT_FORM_LOADER } from "../../../../../../../redux/person/actions";
import { resetFormDiagnostics } from "../../../../../../../redux/common/actions";

import EventStaffCreateEditFormLayout from "./formLayout";
import { YUP_LAST_NAME } from "../../../../../../../common/components/forms/fields/lastName";
import { YUP_FIRST_NAME } from "../../../../../../../common/components/forms/fields/firstName";
import { YUP_CITY } from "../../../../../../../common/components/forms/fields/city";
import { YUP_STREET } from "../../../../../../../common/components/forms/fields/street";
import { YUP_ZIP_NUMBER } from "../../../../../../../common/components/forms/fields/zipNumber";
import { YUP_PHONE_NUMBER } from "../../../../../../../common/components/forms/fields/phoneNumber";
import { YUP_ROLES } from "./fields/roles";
// import { YUP_BIRTH_DATE } from "../../../../../../../common/components/forms/fields/birthDate";
import { YUP_IS_ADMIN } from "./fields/isAdmin";
import { YUP_IS_CONTACT_PERSON } from "./fields/isContactPerson";
import { personCreateEditFormDynamicValidation } from "../../../../../../../common/components/forms/personFormLayout";
import { submitEventStaffCreateForm } from "../../../../../../../redux/event/actions";
// import { YUP_EMAIL } from "../../../../../../../common/components/forms/fields/email";

export const EVENT_STAFF_CREATE_FORM_NAME = "EVENT_STAFF_CREATE_FORM_NAME";
export const EVENT_STAFF_CREATE_FORM_MODAL_NAME =
  "EVENT_STAFF_CREATE_FORM_MODAL_NAME";

const validationSchema = Yup.object().shape({
  // email: YUP_EMAIL,
  // userId:
  firstName: YUP_FIRST_NAME,
  lastName: YUP_LAST_NAME,
  city: YUP_CITY,
  street: YUP_STREET,
  zipNumber: YUP_ZIP_NUMBER,
  phoneNumber: YUP_PHONE_NUMBER,
  // birthDate: YUP_BIRTH_DATE,
  // Custom event staff fields
  roles: YUP_ROLES,
  isAdmin: YUP_IS_ADMIN,
  isContactPerson: YUP_IS_CONTACT_PERSON
});

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  }
}));

const EventStaffCreateForm = ({
  eventId,
  initialValues,
  onClose,
  onSubmitSuccessfully
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [realUserSelected, setRealUserSelected] = useState(false); // todo: stejne jako editForm, proc?

  useEffect(() => {
    (async () => {
      dispatch(resetFormDiagnostics(EVENT_STAFF_CREATE_FORM_NAME));
    })();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const loaders = useSelector(state => state.CommonReducer.loaders);

  const isSubmitting =
    loaders[SUBMITTING_PERSON_PROFILE_EDIT_FORM_LOADER] || false;

  const formDiagnostics =
    useSelector(state => state.CommonReducer.formDiagnostics)[
      EVENT_STAFF_CREATE_FORM_NAME
    ] || {};

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
        <Formik
          initialValues={initialValues}
          onSubmit={async values => {
            dispatch(
              submitEventStaffCreateForm({
                eventId,
                values,
                formName: EVENT_STAFF_CREATE_FORM_NAME,
                modalName: EVENT_STAFF_CREATE_FORM_MODAL_NAME,
                onSuccess: onSubmitSuccessfully,
                realUserSelected
              })
            );
          }}
          validationSchema={validationSchema}
          validate={values =>
            personCreateEditFormDynamicValidation({
              values,
              realUserSelected,
              createForm: true
            })
          }
          validateOnMount
        >
          {({ values, errors, touched, submitForm, setFieldValue }) => {
            return (
              <EventStaffCreateEditFormLayout
                values={values}
                errors={errors}
                formDiagnostics={formDiagnostics}
                touched={touched}
                isSubmitting={isSubmitting}
                onClose={onClose}
                setFieldValue={setFieldValue}
                createForm={true}
                realUserSelected={realUserSelected}
                setRealUserSelected={setRealUserSelected}
              />
            );
          }}
        </Formik>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default EventStaffCreateForm;
