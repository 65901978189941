import React from "react";
import { makeStyles } from "@material-ui/core";
import Modal from "../../../components/modalForm/modal";

import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../../redux/common/actions";
import CustomPageEditForm from "./editForm";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  }
}));

export const CUSTOM_PAGE_FORM_MODAL_NAME_PREFIX = "CustomPageFormModal";

const CustomPageEditFormModal = ({
  customPageId,
  initialValues,
  onSubmitSuccessfully,
  resourceType,
  resourceId,
  inPreviewPage = false,
  nameDisabled = false
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const open =
    useSelector(state => state.CommonReducer.modals)[
      CUSTOM_PAGE_FORM_MODAL_NAME_PREFIX + customPageId.toString()
    ] || false;

  const handleModalClose = () => {
    dispatch(
      closeModal(CUSTOM_PAGE_FORM_MODAL_NAME_PREFIX + customPageId.toString())
    );
  };

  return (
    <div className={classes.root}>
      <Modal
        open={open}
        title={"Upravit stránku"}
        maxWidth={"md"}
        fullWidth={true}
        onClose={handleModalClose}
      >
        <CustomPageEditForm
          customPageId={customPageId}
          initialValues={initialValues}
          onClose={handleModalClose}
          onSubmitSuccessfully={onSubmitSuccessfully}
          resourceType={resourceType}
          resourceId={resourceId}
          inPreviewPage={inPreviewPage}
          nameDisabled={nameDisabled}
        />
      </Modal>
    </div>
  );
};

export default CustomPageEditFormModal;
