export const printAddressString = (
  street,
  zipNumber,
  city,
  description = null
) => {
  let result = "";

  if (!street && !zipNumber && !city) {
    return result;
  }

  if (zipNumber || city) {
    const zipNumberAndCity = [zipNumber, city].join(" ");
    if (street) {
      result = `${street}, ${zipNumberAndCity}`;
    } else {
      result = zipNumberAndCity;
    }
  }

  if (description) {
    if (result) result = `${result} (${description})`;
    else result = description;
  }

  return result;
};
