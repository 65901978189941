import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import cs from "date-fns/locale/cs";

import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { resetFormDiagnostics } from "../../../../../../redux/common/actions";

import { SUBMITTING_EVENT_COMPETITOR_EDIT_FORM_LOADER } from "../../../../../../redux/event/actions";
import PersonMyDogsCreateEditFormLayout from "./formLayout";
import { YUP_NAME_REQUIRED } from "./fields/name";
import { YUP_SEX_REQUIRED } from "./fields/sex";
import { YUP_BIRTH_DATE_REQUIRED } from "../../../../../../common/components/forms/fields/birthDate";
import { YUP_REGISTRATION_NUMBER } from "./fields/registrationNumber";
import { YUP_CHIP_NUMBER } from "./fields/chipNumber";
import { YUP_TATOO_NUMBER } from "./fields/tatooNumber";
import { YUP_DOG_BREED_REQUIRED } from "../../../../../../common/components/forms/DogBreedSearchBox";
import { YUP_OWNERSHIP_APPROVE_REQUIRED } from "./fields/ownershipApprove";
import { submitPersonMyDogsEditForm } from "../../../../../../redux/person/actions";

const FORM_NAME = "PERSON_MY_DOGS_CREATE_FORM_NAME";
export const PERSON_MY_DOGS_CREATE_FORM_MODAL_NAME =
  "PersonMyDogsCreateFormModalName";

const validationSchema = Yup.object().shape({
  name: YUP_NAME_REQUIRED,
  breed: YUP_DOG_BREED_REQUIRED,
  sex: YUP_SEX_REQUIRED,
  birthDate: YUP_BIRTH_DATE_REQUIRED,
  registrationNumber: YUP_REGISTRATION_NUMBER,
  chipNumber: YUP_CHIP_NUMBER,
  tatooNumber: YUP_TATOO_NUMBER,
  ownershipApprove: YUP_OWNERSHIP_APPROVE_REQUIRED
});

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  }
}));

const PersonMyDogsCreateForm = ({
  personId,
  initialValues,
  onClose,
  onSubmitSuccessfully
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(resetFormDiagnostics(FORM_NAME));
    })();
  }, [dispatch]);

  const loaders = useSelector(state => state.CommonReducer.loaders);

  const isSubmitting =
    loaders[SUBMITTING_EVENT_COMPETITOR_EDIT_FORM_LOADER] || false;

  const formDiagnostics =
    useSelector(state => state.CommonReducer.formDiagnostics)[FORM_NAME] || {};

  return (
    <div className={classes.root}>
      {/*<Typography variant={"body1"}>Vytvořit bla bla</Typography>*/}
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
        <Formik
          initialValues={initialValues}
          onSubmit={async values => {
            dispatch(
              submitPersonMyDogsEditForm({
                personId,
                values,
                formName: FORM_NAME,
                onSuccess: onSubmitSuccessfully,
                createForm: true
              })
            );
          }}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, submitForm, setFieldValue }) => {
            return (
              <PersonMyDogsCreateEditFormLayout
                values={values}
                errors={errors}
                formDiagnostics={formDiagnostics}
                touched={touched}
                isSubmitting={isSubmitting}
                onClose={onClose}
                setFieldValue={setFieldValue}
                createForm={true}
              />
            );
          }}
        </Formik>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default PersonMyDogsCreateForm;
