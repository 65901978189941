import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LONG,
  TOO_SHORT
} from "../../../../../../constants/dictionary/validation";

const FIELD_NAME = "sponsorName";

export const YUP_SPONSOR_NAME = Yup.string()
  .min(2, TOO_SHORT)
  .max(100, TOO_LONG);

export const YUP_SPONSOR_NAME_REQUIRED = YUP_SPONSOR_NAME.required(REQUIRED);

const SponsorNameField = ({ baseParams }) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      label="Název sponzora"
      fullWidth={true}
      type="text"
      placeholder="Zde vložte název sponzora"
      baseParams={baseParams}
    />
  );
};

export default SponsorNameField;
