import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import cs from "date-fns/locale/cs";

import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { resetFormDiagnostics } from "../../../../../../redux/common/actions";

import {
  submitEventCompetitorEditForm,
  SUBMITTING_EVENT_COMPETITOR_EDIT_FORM_LOADER
} from "../../../../../../redux/event/actions";
import { YUP_CLUB_NAME } from "./fields/clubName";
import { YUP_START_NUMBER } from "./fields/startNumber";
import { YUP_PLACE } from "./fields/place";
import CompetitorCreateEditFormLayout from "./formLayout";

const FORM_NAME = "EVENT_COMPETITOR_EDIT_FORM_NAME";

const validationSchema = Yup.object().shape({
  clubName: YUP_CLUB_NAME,
  startNumber: YUP_START_NUMBER,
  place: YUP_PLACE
});

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  }
}));

const EventCompetitorEditForm = ({
  competitorId,
  eventId,
  initialValues,
  onClose,
  onSubmitSuccessfully
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(resetFormDiagnostics(FORM_NAME));
    })();
  }, [dispatch]);

  const loaders = useSelector(state => state.CommonReducer.loaders);

  const isSubmitting =
    loaders[SUBMITTING_EVENT_COMPETITOR_EDIT_FORM_LOADER] || false;

  const formDiagnostics =
    useSelector(state => state.CommonReducer.formDiagnostics)[FORM_NAME] || {};

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
        <Formik
          initialValues={initialValues}
          onSubmit={async values => {
            dispatch(
              submitEventCompetitorEditForm({
                competitorId,
                eventId,
                values,
                formName: FORM_NAME,
                onSuccess: onSubmitSuccessfully,
                createForm: false
              })
            );
          }}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, submitForm, setFieldValue }) => {
            return (
              <CompetitorCreateEditFormLayout
                values={values}
                errors={errors}
                formDiagnostics={formDiagnostics}
                touched={touched}
                isSubmitting={isSubmitting}
                onClose={onClose}
                setFieldValue={setFieldValue}
                createForm={false}
              />
            );
          }}
        </Formik>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default EventCompetitorEditForm;
