import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LONG,
  TOO_SHORT
} from "../../../../constants/dictionary/validation";
import { InputAdornment } from "@material-ui/core";
import { Phone } from "@material-ui/icons";

const FIELD_NAME = "phoneNumber";

export const YUP_PHONE_NUMBER = Yup.string()
  .min(2, TOO_SHORT)
  .max(100, TOO_LONG);

export const YUP_PHONE_NUMBER_REQUIRED = YUP_PHONE_NUMBER.required(REQUIRED);

const PhoneNumberField = ({ baseParams }) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      autoComplete={"tel"}
      label="Telefon"
      fullWidth={true}
      type="text"
      placeholder="Zde vložte telefon"
      inputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Phone />
          </InputAdornment>
        )
      }}
      baseParams={baseParams}
    />
  );
};

export default PhoneNumberField;
