export const ENABLE_LOADER = "ENABLE_LOADER";
export const DISABLE_LOADER = "DISABLE_LOADER";
export const SET_CUSTOM_DOMAIN = "SET_CUSTOM_DOMAIN";
export const SET_PAGE_ROUTES = "SET_PAGE_ROUTES";
export const RESET_FORM_DIAGNOSTICS = "RESET_FORM_DIAGNOSTICS";
export const APPEND_FORM_DIAGNOSTICS = "APPEND_FORM_DIAGNOSTICS";
export const SET_FORM_INPUT = "SET_FORM_INPUT";
export const CLEAR_FORM_INPUTS = "CLEAR_FORM_INPUTS";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const SET_CONFIRMATION_MODAL = "SET_CONFIRMATION_MODAL";
