import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LOW
} from "../../../../../../../constants/dictionary/validation";
// import { InputAdornment } from "@material-ui/core";
// import {Home, Person} from "@material-ui/icons";

const FIELD_NAME = "basicOrganizationNumber";

export const YUP_BASIC_ORGANIZATION_NUMBER = Yup.number()
  .min(0, TOO_LOW)
  .nullable(true);

export const YUP_BASIC_ORGANIZATION_NUMBER_REQUIRED = YUP_BASIC_ORGANIZATION_NUMBER.required(
  REQUIRED
);

const BasicOrganizationNumberField = ({ baseParams }) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      label="Číslo základní organizace"
      fullWidth={true}
      type="number"
      minValue={0}
      placeholder="Zde vložte číslo základní organizace"
      // InputProps={{
      //   startAdornment: (
      //     <InputAdornment position="start">
      //       <Home />
      //     </InputAdornment>
      //   )
      // }}
      baseParams={baseParams}
    />
  );
};

export default BasicOrganizationNumberField;
