// @flow

import React from "react";

// material-ui
import { Typography } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import SearchCardTemplate from "../../../../common/components/search/searchCardTemplate";
import EventIcon from "@material-ui/icons/Event";
import Moment from "react-moment";

// const useStyles = makeStyles(theme => ({
// }));

/**
 * Flow types
 */
type Props = {
  id: number,
  title: string,
  subtitle: string,
  eventDate: string,
  eventVenue: string,
  imageUrl: string,
  deleted: boolean
};

const SearchCard = (props: Props) => {
  // const classes = useStyles();
  const {
    id,
    title,
    subtitle,
    eventDate,
    eventVenue,
    imageUrl,
    deleted
  } = props;

  return (
    <SearchCardTemplate
      linkUrl={`/events/${id}/`}
      title={title}
      subtitle={subtitle}
      imageUrl={imageUrl}
      bottomPanelItems={[
        <Typography variant="body1" title={"místo konání"}>
          {eventVenue}
        </Typography>,
        <Typography variant="body1" title={"datum konání"}>
          {eventDate ? <Moment date={eventDate} /> : ""}
        </Typography>
      ]}
      defaultMediaComponent={EventIcon}
      deleted={deleted}
    />
  );
};

export default SearchCard;
