// @flow

import React from "react";

// material-ui
import { Grid, Typography, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EventIcon from "@material-ui/icons/Event";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import HelpIcon from "@material-ui/icons/Help";

const useStyles = makeStyles(theme => ({
  paper: {
    minHeight: 180,
    maxHeight: 250,
    padding: theme.spacing(1),
    overflow: "hidden"
  },
  avatar: {
    width: 120,
    height: 120
  },
  toolbar: theme.mixins.toolbar,
  name: {
    fontSize: 15,
    fontWeight: 900
  },
  account: {
    color: "grey",
    fontSize: 13,
    fontWeight: 700
  }
}));

/**
 * Flow types
 */
type Props = {
  name: string,
  info: string,
  avatar: string,
  resourceType: string
};

const DrawerItems = (props: Props) => {
  const classes = useStyles();
  const { avatar, resourceType } = props;

  let defaultComponent;
  switch (resourceType) {
    case "event":
      defaultComponent = EventIcon;
      break;
    case "trainingClub":
      defaultComponent = PeopleIcon;
      break;
    case "person":
      defaultComponent = PersonIcon;
      break;
    default:
      defaultComponent = HelpIcon;
  }

  return (
    <>
      <div className={classes.toolbar} />
      <Grid
        container
        className={classes.paper}
        justify="center"
        align="center"
        alignItems="center"
      >
        {/* USER AVATART */}
        <Grid item xs={12}>
          <Avatar
            alt="Avatar"
            src={avatar ? avatar : undefined}
            component={avatar ? undefined : defaultComponent}
            className={classes.avatar}
          />
        </Grid>
        {/* USER INFO */}
        <Grid item xs={12}>
          <Typography className={classes.name}>{props.name}</Typography>
          <Typography className={classes.account}>{props.info}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default DrawerItems;
