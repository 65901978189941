// @flow

import React from "react";
import { makeStyles } from "@material-ui/core";
import MediaCard from "../mediaCard";
import Moment from "react-moment";
import Button from "@material-ui/core/Button";
import MissingImage from "./images/undraw_blank_canvas_3rbb.svg";
import { openConfirmationModal } from "../../../redux/common/actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4)
  }
}));

const PhotoGalleryPreview = ({
  id,
  name,
  createDatetime,
  previewImages,
  imagesCount,
  onClick,
  editable,
  onDelete
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(
      openConfirmationModal(
        "Odstranit celou fotogalerii",
        `Opravdu si přejete odstranit celou fotogalerii "${name}"?`,
        onDelete
      )
    );
    // onDelete(id);
  };

  const handleOnClick = () => {
    onClick(id);
  };

  return (
    <div className={classes.root}>
      <MediaCard
        title={name}
        descriptionList={[
          <span>
            Vytvořeno: <Moment date={createDatetime} />
          </span>,
          `Počet obrázků: ${imagesCount}`
        ]}
        imageUrl={
          previewImages?.length > 0 ? previewImages[0].url : MissingImage
        }
        imageContain={previewImages?.length > 0 ? undefined : true}
        imageTitle={name}
        onClick={handleOnClick}
        actionList={
          editable && [
            <Button
              size="small"
              color="primary"
              key={"delete"}
              onClick={handleDelete}
            >
              Smazat
            </Button>
          ]
        }
      />
    </div>
  );
};

export default PhotoGalleryPreview;
