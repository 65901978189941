import React from "react";
import FieldBase from "../../../../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LONG,
  TOO_SHORT
} from "../../../../../../../constants/dictionary/validation";
import Typography from "@material-ui/core/Typography";
import { FieldArray } from "formik";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
// import { InputAdornment } from "@material-ui/core";
// import {Home, Person} from "@material-ui/icons";

const FIELD_NAME = "possibilities";

export const YUP_POSSIBILITIES = Yup.array()
  .of(
    Yup.string()
      .min(2, TOO_SHORT)
      .max(100, TOO_LONG)
      .required(REQUIRED)
  )
  .max(10, "Možností může být maximálně 10.");

export const YUP_POSSIBILITIES_REQUIRED = YUP_POSSIBILITIES.required(REQUIRED);

const useStyles = makeStyles(theme => ({
  arrayFieldWrapper: {
    display: "flex"
  }
}));

const PossibilitiesField = ({ values, baseParams }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant={"caption"} color={"textSecondary"}>
        Možnosti klubu
      </Typography>
      <FieldArray
        // label={"xxxxx"}
        name={FIELD_NAME}
        render={arrayHelpers => (
          <div>
            {values[FIELD_NAME] && values[FIELD_NAME].length > 0 ? (
              values[FIELD_NAME].map((possibility, index) => (
                <div key={index} className={classes.arrayFieldWrapper}>
                  <FieldBase
                    name={`${FIELD_NAME}.${index}`}
                    type="text"
                    placeholder="Zde vložte možnost klubu"
                    baseParams={baseParams}
                  />
                  <Button
                    title={"Odstranit tuto možnost"}
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    <DeleteIcon />
                  </Button>
                  <IconButton
                    title={"Přidat novou možnost za tuto"}
                    onClick={() => arrayHelpers.insert(index + 1, "")}
                    disabled={values[FIELD_NAME].length >= 10}
                  >
                    <AddIcon />
                  </IconButton>
                  {/*<IconButton*/}
                  {/*  title={"Posunout nahoru"}*/}
                  {/*  onClick={() =>*/}
                  {/*    arrayHelpers.move(index, index - 1)*/}
                  {/*  }*/}
                  {/*  disabled={index <= 0}*/}
                  {/*>*/}
                  {/*  <ArrowUpwardIcon />*/}
                  {/*</IconButton>*/}
                  {/*<IconButton*/}
                  {/*  title={"Posunout dolu"}*/}
                  {/*  onClick={() =>*/}
                  {/*    arrayHelpers.move(index, index + 1)*/}
                  {/*  }*/}
                  {/*  disabled={*/}
                  {/*    index >= values.possibilities.length - 1*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <ArrowDownwardIcon />*/}
                  {/*</IconButton>*/}
                </div>
              ))
            ) : (
              <Button onClick={() => arrayHelpers.push("")}>
                {/* show this when user has removed all possibilities from the list */}
                Přidat možnost
              </Button>
            )}
          </div>
        )}
      />
    </>
  );
};

export default PossibilitiesField;
