import React from "react";
import { CheckboxWithLabel } from "formik-material-ui";
import * as Yup from "yup";
import CheckboxFieldBase from "../../../../../common/components/forms/checkboxFieldBase";

const FIELD_NAME = "fullHeight";

export const YUP_FULL_HEIGHT = Yup.boolean();

const FullHeightField = ({ baseParams }) => {
  return (
    <CheckboxFieldBase
      component={CheckboxWithLabel}
      name={FIELD_NAME}
      label={"Na celou výšku"}
      type="checkbox"
      baseParams={baseParams}
    />
  );
};

export default FullHeightField;
