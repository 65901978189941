import React from "react";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LONG,
  TOO_SHORT
} from "../../../../../../constants/dictionary/validation";
import DraftJsEditor from "../../../../draftjsEditor";

const FIELD_NAME = "content";

export const YUP_CONTENT = Yup.string()
  .min(2, TOO_SHORT)
  .max(50000, TOO_LONG);

export const YUP_CONTENT_REQUIRED = YUP_CONTENT.required(REQUIRED);

const ContentField = ({ values, setFieldValue, baseParams }) => {
  return (
    <DraftJsEditor
      initialState={values[FIELD_NAME]}
      onEditorStateChange={value => setFieldValue(FIELD_NAME, value)}
    />
  );
};

export default ContentField;
