// @flow

import React from "react";

// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Footer from "../footer";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "100vw",
    flexGrow: 1,
    minHeight: "100vh",
    position: "relative"
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    paddingBottom: "140px"
  },
  footer: {
    backgroundColor: "white",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: "center",
    bottom: "0px",
    right: "0px",
    left: "0px",
    position: "absolute"
  },
  iconInText: {
    verticalAlign: "bottom"
  }
}));

const MainTemplate = ({ children, withoutToolbar = false }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        {!withoutToolbar && <div className={classes.toolbar} />}
        <div>{children}</div>
      </main>
      <Footer />
    </div>
  );
};

export default MainTemplate;
