import React from "react";

import { makeStyles } from "@material-ui/core";
import StarRateRoundedIcon from "@material-ui/icons/StarRateRounded";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ececec"
  },
  item: {
    color: "darkgray",
    flex: "1 1 100%",
    width: "60%",
    height: "60%"
  },
  firstItem: {
    alignSelf: "flex-end"
  },
  lastItem: {
    alignSelf: "flex-start"
  },
  activeItem: {
    color: "gold"
  }
}));

const PricesLogo = ({ type }) => {
  const classes = useStyles();
  let secondItem,
    thirdItem = false;

  switch (type) {
    case "minimum":
      break;
    case "optimum":
      secondItem = true;
      break;
    case "maximum":
      secondItem = true;
      thirdItem = true;
      break;
    default:
      throw Error("Missing type for component PricesLogo.");
  }

  return (
    <div className={classes.root}>
      <StarRateRoundedIcon
        className={clsx(classes.item, classes.firstItem, classes.activeItem)}
      />
      <StarRateRoundedIcon
        className={clsx(classes.item, secondItem && classes.activeItem)}
      />
      <StarRateRoundedIcon
        className={clsx(
          classes.item,
          thirdItem && classes.activeItem,
          classes.lastItem
        )}
      />
    </div>
  );
};

export default PricesLogo;
