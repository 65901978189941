// @flow

import React from "react";

// material-ui
import { Paper, InputBase, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  }
}));

const SearchBar = ({ search, setSearch, placeholder }) => {
  const classes = useStyles();

  const handleSetSearch = event => {
    setSearch(event.target.value);
  };

  return (
    <Paper component="form" className={classes.root}>
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
      >
        <SearchIcon color="secondary" />
      </IconButton>
      <InputBase
        value={search}
        className={classes.input}
        onChange={handleSetSearch}
        placeholder={placeholder}
        inputProps={{ "aria-label": "search event" }}
      />
    </Paper>
  );
};

export default SearchBar;
