// @flow

// react + router
import React, { useEffect } from "react";
import * as types from "types";
import { useParams, withRouter } from "react-router-dom";

// redux
import { useSelector } from "react-redux";

// material-ui
import { makeStyles, Grid, Paper, Hidden } from "@material-ui/core";

// components
import LoginForm from "pages/login-page/login-components/loginForm";
import RegisterForm from "pages/login-page/register-components/registerForm";
import EmailVerificationPage from "./emailVerification";

// static
import logo from "pages/login-page/img/login-picture.png";
import ResetPasswordRequestForm from "./resetPasswordRequest/form";
import ResetPasswordForm from "./resetPassword/form";
import AccountInvitationForm from "./accountInvivation/form";

/**
 * Flow types
 */
type Props = {
  formType: string,
  history: Object
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "100vh",
    backgroundColor: "#F8F8F8"
  },
  image: {
    // backgroundImage: `linear-gradient(135deg, #50A68480 30%, #115E6780 90%), url(${logo})`,
    backgroundImage: `url(${logo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: 700
  },
  paper: {
    display: "flex",
    alignItems: "center",
    width: 900
  }
}));

/**
 * Return proper form based on formType
 * @param {string} formType e.g. LOGIN_PAGE ...
 */
const setComponent = (formType: string, activationHash: string) => {
  switch (formType) {
    case types.LOGIN_PAGE:
      return <LoginForm />;
    case types.REGISTER_PAGE:
      return <RegisterForm />;
    case types.RESET_PASSWORD_REQUEST_PAGE:
      return <ResetPasswordRequestForm />;
    case types.RESET_PASSWORD_PAGE:
      return <ResetPasswordForm activationHash={activationHash} />;
    case types.EMAIL_VERIFICATION:
      return <EmailVerificationPage activationHash={activationHash} />;
    case types.ACCOUNT_INVITATION_PAGE:
      return <AccountInvitationForm activationHash={activationHash} />;
    default:
      return null;
  }
};

const LoginPage = (props: Props) => {
  const classes = useStyles();

  let { activationHash } = useParams();

  const isAuthenticated = useSelector(
    state => state.AuthReducer.isAuthenticated
  );

  /**
   * On `componentDidMount` check if authenticated`
   */
  useEffect(() => {
    if (isAuthenticated) {
      props.history.push("/");
    }
  }, [isAuthenticated, props.history]);

  return (
    <Grid
      className={classes.root}
      spacing={2}
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Paper className={classes.paper}>
        <Hidden xsDown>
          <Grid item xs={false} sm={4} md={4} className={classes.image} />
        </Hidden>
        <Grid item xs={12} sm={8} md={8}>
          {setComponent(props.formType, activationHash)}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default withRouter(LoginPage);
