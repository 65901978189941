import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import { REQUIRED, TOO_LONG, TOO_SHORT } from "constants/dictionary/validation";
import { InputAdornment } from "@material-ui/core";
import { Home } from "@material-ui/icons";

const FIELD_NAME = "street";

export const YUP_STREET = Yup.string()
  .min(2, TOO_SHORT)
  .max(100, TOO_LONG);

export const YUP_STREET_REQUIRED = YUP_STREET.required(REQUIRED);

const StreetField = ({
  placeholder,
  baseParams,
  autoCompleteOff = false,
  disabled
}) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      autoComplete={"street-address"}
      label="Ulice"
      fullWidth={true}
      type="text"
      placeholder={
        placeholder ? placeholder : "Zde vložte ulici a číslo popisné"
      }
      inputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Home />
          </InputAdornment>
        )
      }}
      baseParams={baseParams}
      autoCompleteOff={autoCompleteOff}
      disabled={disabled}
    />
  );
};

export default StreetField;
