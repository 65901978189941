import React from "react";

import MainContentContainer from "../../../../../common/components/main-content-container";
import MainPageTitle from "../../../../../common/components/page/mainPageTitle";
import Articles from "../../../../../common/components/customPage/articles";

const EventNews = ({ resourceId: eventId }) => {
  return (
    <MainContentContainer relative={true}>
      <MainPageTitle>Novinky</MainPageTitle>
      <Articles resourceType={"events"} resourceId={eventId} />
    </MainContentContainer>
  );
};

export default EventNews;
