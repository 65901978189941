import React from "react";
import * as Yup from "yup";
import { REQUIRED } from "../../../../../../../constants/dictionary/validation";
import DateTimeFieldBase from "../../../../../../../common/components/forms/dateTimeFieldBase";

const FIELD_NAME = "term";

// export const YUP_TERM = Yup.date("").nullable("");
export const YUP_TERM = Yup.date()
  .nullable()
  .default(undefined);

export const YUP_TERM_REQUIRED = YUP_TERM.required(REQUIRED);

const TermField = ({ values, setFieldValue, baseParams }) => {
  return (
    <DateTimeFieldBase
      name={FIELD_NAME}
      label="Termín akce"
      value={values[FIELD_NAME]}
      onChange={value => setFieldValue(FIELD_NAME, value)}
      baseParams={baseParams}
    />
  );
};

export default TermField;
