import React, { useEffect } from "react";

import { Typography } from "@material-ui/core";
import MainContentContainer from "../../../../../common/components/main-content-container";
import { useDispatch, useSelector } from "react-redux";
import {
  loadProposition,
  GET_EVENT_PROPOSITION_LOADER_NAME
} from "../../../../../redux/event/actions";
import CustomPage from "../../../../../common/components/customPage/customPage";
import customPageSectionFactory from "../../../../../common/components/customPage/customPageSectionFactory";

const EventProposition = ({ resourceId: eventId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    handleReload();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, eventId]);

  const handleReload = () => {
    dispatch(loadProposition(eventId));
  };

  const { propositionCustomPage } = useSelector(store => store.EventReducer);

  const loading =
    useSelector(store => store.CommonReducer.loaders)[
      GET_EVENT_PROPOSITION_LOADER_NAME
    ] || false;

  const sectionsCount = propositionCustomPage?.sections?.length || 0;

  return (
    <MainContentContainer showCloser={false}>
      {propositionCustomPage ? (
        <CustomPage
          customPageId={propositionCustomPage.id}
          title={propositionCustomPage.title || ""}
          resourceType={"events"}
          resourceId={eventId}
          onReload={handleReload}
          sectionsCount={sectionsCount}
        >
          {propositionCustomPage?.sections.constructor === Array &&
            propositionCustomPage.sections.map((section, index) => (
              <div key={index}>
                {customPageSectionFactory({
                  customPageSectionId: section.id,
                  componentName: section.componentName,
                  properties: section.properties,
                  sectionOrder: section.order,
                  resourceType: "events",
                  resourceId: eventId,
                  onReload: handleReload,
                  minOrder: 1,
                  maxOrder: sectionsCount
                })}
              </div>
            ))}
        </CustomPage>
      ) : (
        <Typography variant={"body1"}>
          {loading ? "načítání..." : "nebyly zadány žádné propozice akce"}
        </Typography>
      )}
    </MainContentContainer>
  );
};

export default EventProposition;
