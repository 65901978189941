import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LONG
} from "../../../../constants/dictionary/validation";
import { InputAdornment } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

const FIELD_NAME = "email";

export const YUP_EMAIL = Yup.string()
  .max(200, TOO_LONG)
  .email("Zadejte platnou emailovou adresu");

export const YUP_EMAIL_REQUIRED = YUP_EMAIL.required(REQUIRED);

const EmailField = ({ baseParams }) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      autoComplete="email"
      label="Email"
      fullWidth={true}
      type="text"
      placeholder="Zde vložte Váš email"
      inputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MailOutlineIcon />
          </InputAdornment>
        )
      }}
      baseParams={baseParams}
    />
  );
};

export default EmailField;
