// @flow

import * as types from "./types";

/**
 * Flow types
 */
type TrainingClubState = {
  clubs: Array,
  previewPage: ?Object,
  club: ?Object,
  clubEvents: Array,
  aboutCustomPage: ?Object,
  members: Array,
  contact: ?Object
};

/**
 * Initial event state
 */
const INITIAL_STATE = {
  clubs: [],
  previewPage: null,
  club: null,
  clubEvents: [],
  aboutCustomPage: null,
  members: [],
  contact: null
};

/**
 * Training club reducer
 */
const TrainingClubReducer = (
  state: TrainingClubState = INITIAL_STATE,
  action: Object
) => {
  switch (action.type) {
    case types.GET_TRAINING_CLUBS:
      return {
        ...state,
        clubs: action.payload
      };
    case types.GET_TRAINING_CLUB:
      return {
        ...state,
        club: action.payload
      };
    case types.GET_TRAINING_CLUB_ABOUT:
      return {
        ...state,
        aboutCustomPage: action.payload
      };
    case types.RESET_PREVIEW:
      return {
        ...state,
        previewPage: INITIAL_STATE.previewPage
      };
    case types.GET_TRAINING_CLUB_PREVIEW:
      return {
        ...state,
        previewPage: action.payload
      };
    case types.GET_TRAINING_CLUB_MEMBERS:
      return {
        ...state,
        members: action.payload
      };
    case types.GET_TRAINING_CLUB_CONTACT:
      return {
        ...state,
        contact: action.payload
      };
    case types.GET_TRAINING_CLUB_EVENTS:
      return {
        ...state,
        clubEvents: action.payload
      };
    case types.RESET_CLUB_STATE:
      return {
        ...state,
        club: INITIAL_STATE.club,
        aboutCustomPage: INITIAL_STATE.aboutCustomPage,
        members: INITIAL_STATE.members,
        contact: INITIAL_STATE.contact,
        clubEvents: INITIAL_STATE.clubEvents,
        previewPage: INITIAL_STATE.previewPage
      };
    default:
      return state;
  }
};

export default TrainingClubReducer;
