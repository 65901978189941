import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { rawDraftJsStringToHtml } from "../draftjsEditor/utils";
import ControlledHtmlEditor from "../htmlEditor/controled";

const useStyles = makeStyles({
  root: {},
  media: {
    width: "100%",
    height: 240
  },
  fullHeight: {
    height: "100%"
  },
  center: {
    textAlign: "center"
  },
  defaultCursor: {
    cursor: "default"
  },
  imageContain: {
    backgroundSize: "contain"
  },
  description: {
    fontSize: "1rem",
    lineHeight: "1.5"
  }
});

export default function MediaCard({
  title,
  descriptionList, // excluduje descriptionHtml
  descriptionHtml, // excluduje descriptionList
  imageUrl,
  imageTitle,
  onClick,
  actionList,
  fullHeight = false,
  cardMediaComponent = undefined,
  cardMediaChildren = undefined,
  cardMediaNo = false,
  centerTitle = false,
  imageContain = false // shows full image, not stretch
}) {
  const classes = useStyles();

  if (!cardMediaComponent && !cardMediaChildren && !imageUrl) {
    cardMediaNo = true;
  }

  return (
    <Card className={clsx(classes.root, fullHeight && classes.fullHeight)}>
      <CardActionArea
        onClick={onClick}
        className={clsx(classes.fullHeight, !onClick && classes.defaultCursor)}
      >
        {!cardMediaNo && (
          <CardMedia
            // className={classes.media}
            image={imageUrl}
            classes={{
              root: clsx(classes.media, imageContain && classes.imageContain)
            }}
            component={cardMediaComponent}
            title={imageTitle}
          >
            {cardMediaChildren}
          </CardMedia>
        )}
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={clsx(centerTitle && classes.center)}
          >
            {title}
          </Typography>
          {descriptionList &&
            descriptionList.map((description, index) => (
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                key={index}
              >
                {description}
              </Typography>
            ))}
          {descriptionHtml && (
            <ControlledHtmlEditor
              editorShown={false}
              html={rawDraftJsStringToHtml(descriptionHtml)}
              contentClass={classes.description}
            />
          )}
        </CardContent>
      </CardActionArea>
      {actionList &&
        actionList.constructor === Array &&
        actionList.length > 0 && <CardActions>{actionList}</CardActions>}
    </Card>
  );
}
