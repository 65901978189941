// @flow

import * as types from "./types";

/**
 * Flow types
 */
type EventState = {
  events: Array,
  event: ?Object,
  eventStaffs: Array,
  allPossibleEventCategories: Array,
  propositionCustomPage: ?Object,
  categoryParticipants: Array,
  sponsorCustomPage: ?Object,
  contact: ?Object
};

/**
 * Initial event state
 */
const INITIAL_STATE = {
  events: [],
  event: null,
  eventStaffs: [],
  allPossibleEventCategories: [],
  propositionCustomPage: null,
  categoryParticipants: [],
  sponsorCustomPage: null,
  contact: null
};

/**
 * Photo gallery reducer
 */
const EventReducer = (state: EventState = INITIAL_STATE, action: Object) => {
  switch (action.type) {
    case types.LOAD_PROPOSITION_CUSTOM_PAGE:
      return {
        ...state,
        propositionCustomPage: action.payload
      };
    case types.GET_EVENTS:
      return {
        ...state,
        events: action.payload
      };
    case types.GET_EVENT:
      return {
        ...state,
        event: action.payload
      };
    case types.GET_EVENT_PARTICIPANTS:
      return {
        ...state,
        categoryParticipants: action.payload
      };
    case types.GET_EVENT_CONTACT:
      return {
        ...state,
        contact: action.payload
      };
    case types.GET_EVENT_SPONSORS:
      return {
        ...state,
        sponsorCustomPage: action.payload
      };
    case types.LOAD_ALL_POSSIBLE_EVENT_CATEGORIES:
      return {
        ...state,
        allPossibleEventCategories: action.payload
      };
    case types.GET_EVENT_STAFFS:
      return {
        ...state,
        eventStaffs: action.payload
      };
    case types.RESET_EVENT_STATE:
      return {
        ...state,
        event: INITIAL_STATE.event,
        eventStaffs: INITIAL_STATE.eventStaffs,
        menuInfo: INITIAL_STATE.menuInfo,
        propositionCustomPage: INITIAL_STATE.propositionCustomPage,
        categoryParticipants: INITIAL_STATE.categoryParticipants,
        sponsorCustomPage: INITIAL_STATE.sponsorCustomPage,
        contact: INITIAL_STATE.contact,
        allPossibleEventCategories: INITIAL_STATE.allPossibleEventCategories
      };
    default:
      return state;
  }
};

export default EventReducer;
