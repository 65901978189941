import React from "react";
import { CheckboxWithLabel } from "formik-material-ui";
import * as Yup from "yup";
import CheckboxFieldBase from "../../../../common/components/forms/checkboxFieldBase";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const FIELD_NAME = "gdprApprove";

export const YUP_GDPR_APPROVE_REQUIRED = Yup.boolean()
  .required("Podmínky GDPR musí být odsouhlaseny!")
  .oneOf([true], "Podmínky GDPR musí být odsouhlaseny!");

const GdprApprove = ({ baseParams }) => {
  return (
    <CheckboxFieldBase
      component={CheckboxWithLabel}
      name={FIELD_NAME}
      label={
        <Typography variant={"body1"}>
          Souhlasím s podmínkami{" "}
          <Link component={"a"} href={"/gdpr"} target={"_blank"}>
            ochrany osobních údajů
          </Link>
          .
        </Typography>
      }
      type="checkbox"
      baseParams={baseParams}
    />
  );
};

export default GdprApprove;
