import React from "react";
import { Grid } from "@material-ui/core";
import { Form } from "formik";

import FormActions from "../../../../../../common/components/forms/actions";
import ImageUploader from "../../../../../../common/components/imageUploader";
import FirstNameField from "../../../../../../common/components/forms/fields/firstName";
import LastNameField from "../../../../../../common/components/forms/fields/lastName";
import CityField from "../../../../../../common/components/forms/fields/city";
import StreetField from "../../../../../../common/components/forms/fields/street";
import ZipNumberField from "../../../../../../common/components/forms/fields/zipNumber";
import PhoneNumberField from "../../../../../../common/components/forms/fields/phoneNumber";
import BirthDateField, {
  FIELD_NAME as BIRTH_DATE_FIELD_NAME
} from "../../../../../../common/components/forms/fields/birthDate";
import CustomInputLabel from "../../../../../../common/components/forms/customInputLabel";

const PersonProfileCreateEditFormLayout = ({
  values,
  actualProfileImageUrl, // only for edit form
  errors,
  formDiagnostics,
  touched,
  isSubmitting,
  onClose,
  setFieldValue,
  createForm = false
}) => {
  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomInputLabel label={"Profilový obrázek"} />
          <ImageUploader
            initImage={{
              url: actualProfileImageUrl,
              id: values.imageId
            }}
            onChange={id => setFieldValue("imageId", id)}
            useCrop={true}
            alt={"Profilový obrázek osoby"}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FirstNameField
            baseParams={{ errors, formDiagnostics, touched }}
            placeholder={"Zde vložte jméno osoby."}
            autoCompleteOff={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LastNameField
            baseParams={{ errors, formDiagnostics, touched }}
            placeholder={"Zde vložte příjmení osoby."}
            autoCompleteOff={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CityField
            baseParams={{ errors, formDiagnostics, touched }}
            autoCompleteOff={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StreetField
            baseParams={{ errors, formDiagnostics, touched }}
            autoCompleteOff={true}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <ZipNumberField
            baseParams={{ errors, formDiagnostics, touched }}
            autoCompleteOff={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PhoneNumberField
            baseParams={{ errors, formDiagnostics, touched }}
            autoCompleteOff={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BirthDateField
            value={values[BIRTH_DATE_FIELD_NAME]}
            setFieldValue={setFieldValue}
            baseParams={{ errors, formDiagnostics, touched }}
          />
        </Grid>
      </Grid>
      <FormActions
        isSubmitting={isSubmitting}
        errors={errors}
        onClose={onClose}
      />
      {/*  ONLY FOR DEBUG PURPOSES  */}
      {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(errors, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(touched, null, 2)}</pre>*/}
    </Form>
  );
};

export default PersonProfileCreateEditFormLayout;
