// @flow
import React from "react";

// material-ui
// import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";

// react-router
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";

// components
import DrawerMenu from "pages/main-page/menu-component/drawer-menu/drawerMenu";
import {
  isOneOfRoutesActive,
  RouteWithSubRoutes
} from "../../../../utils/routes";
// const useStyles = makeStyles(theme => ({
// }));

import { getRoutes } from "pages/contentPages/personPages/subPages/routes";
import NotFoundPage from "../../../main-page/notFound";

const PersonPages = ({
  location,
  mobileOpen,
  handleDrawerToggle,
  isMainPage
}) => {
  // const classes = useStyles();

  let { id: paramId } = useParams();
  paramId = parseInt(paramId);

  let id = paramId;
  // const customDomain = useSelector(state => state.CommonReducer.customDomain);
  //
  // let id = isMainPage && customDomain ? customDomain.resourceId : paramId;

  // const linkPrefix = isMainPage ? "" : `/clubs/${id}`;
  // const pathPrefix = isMainPage ? "" : "/clubs/:id";

  const linkPrefix = `/persons/${id}`;
  const pathPrefix = "/persons/:id";
  const routes = getRoutes(id, linkPrefix, pathPrefix);
  return isOneOfRoutesActive(location.pathname, routes) ? (
    <>
      <DrawerMenu
        resourceType={"person"}
        items={routes}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        useMobileVersion={isOneOfRoutesActive(
          location.pathname,
          routes.filter(route => route.mobileVersionMenu)
        )}
      />
      {/* CONTENT */}
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} linkPrefix={linkPrefix} />
        ))}
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </>
  ) : (
    <NotFoundPage />
  );
};

export default withRouter(PersonPages);
