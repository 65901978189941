import React from "react";
import SearchBox from "./searchBox";

const KennelSearchBox = ({
  initialValues,
  onChange,
  label,
  placeholder,
  multiple = false,
  disabled = false
}) => {
  const handleChange = (e, value, result) => {
    let changedValue;
    if (multiple) {
      changedValue = value.map(option => {
        if (typeof option === "string") {
          return { value: option, creatable: true };
        }
        return option;
      });
    } else {
      if (typeof value === "string") {
        changedValue = { value: value, creatable: true };
      } else {
        changedValue = value;
      }
    }
    onChange(changedValue);
  };

  return (
    <SearchBox
      label={label}
      placeholder={placeholder}
      getOptionSelected={(option, value) => {
        return option.value === value.value;
      }}
      requestUrl={`/v1/kennels/fulltext-search/`}
      requestMethod={"POST"}
      transformResponse={responseData =>
        responseData.kennels.map(kennel => ({
          value: `${kennel.name} (id: ${kennel.id})`,
          kennelId: kennel.id
        }))
      }
      transformRequestData={value => ({ fulltext: value })}
      requestErrorMessage={"V průběhu vyhledání ch. stanic došlo k chybě."}
      onChange={handleChange}
      creatable={true}
      freeSolo={true}
      multiple={multiple}
      defaultValue={
        multiple
          ? initialValues?.length
            ? initialValues.map(kennel => ({
                ...kennel,
                value: kennel.name
              }))
            : []
          : { ...initialValues, value: initialValues?.name }
      }
      filterOptionsFilterEnabled={false}
      disabled={disabled}
    />
  );
};

export default KennelSearchBox;
