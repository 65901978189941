import React from "react";
import SearchBox from "./searchBox";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LONG,
  TOO_SHORT
} from "../../../constants/dictionary/validation";

export const YUP_DOG_BREED = Yup.string()
  .min(2, TOO_SHORT)
  .max(80, TOO_LONG);

export const YUP_DOG_BREED_REQUIRED = YUP_DOG_BREED.required(REQUIRED);

const DogBreedSearchBox = ({
  initialValues,
  onChange,
  label,
  placeholder,
  multiple = false,
  disabled = false
}) => {
  const handleChange = (e, value, result) => {
    let changedValue;
    if (multiple) {
      changedValue = value.map(option => {
        if (typeof option === "string") {
          return { value: option, creatable: true };
        }
        return option;
      });
    } else {
      if (typeof value === "string") {
        changedValue = { value: value, creatable: true };
      } else {
        changedValue = value;
      }
    }
    onChange(changedValue);
  };

  return (
    <SearchBox
      label={label}
      placeholder={placeholder}
      getOptionSelected={(option, value) => {
        return option.value === value.value;
      }}
      requestUrl={`/v1/dogs/breeds/fulltext-search/`}
      requestMethod={"POST"}
      transformResponse={responseData =>
        responseData.breeds.map(breed => ({
          value: breed.name + (breed.shortcut ? ` (${breed.shortcut})` : ""),
          breedId: breed.id
        }))
      }
      transformRequestData={value => ({ fulltext: value })}
      requestErrorMessage={"V průběhu vyhledání plemen došlo k chybě."}
      onChange={handleChange}
      creatable={true}
      freeSolo={true}
      multiple={multiple}
      defaultValue={
        multiple
          ? initialValues?.length
            ? initialValues.map(breed => ({
                ...breed,
                value: breed.name
              }))
            : []
          : { ...initialValues, value: initialValues?.name }
      }
      filterOptionsFilterEnabled={false}
      disabled={disabled}
    />
  );
};

export default DogBreedSearchBox;
