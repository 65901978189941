import React from "react";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_SHORT
} from "../../../../../../../constants/dictionary/validation";
import SearchBox from "../../../../../../../common/components/forms/searchBox";

const FIELD_NAME = "roles";

export const YUP_ROLES = Yup.array()
  .of(Yup.string().min(2, TOO_SHORT))
  .min(1, TOO_SHORT);

export const YUP_ROLES_REQUIRED = YUP_ROLES.required(REQUIRED);

const RolesField = ({ values, setFieldValue, baseParams }) => {
  return (
    <SearchBox
      label={"Funkce"}
      placeholder={"Vyberte funkce člena klubu"}
      initialOptions={[
        { value: "předseda" },
        { value: "jednatel" },
        { value: "pokladník" },
        { value: "hospodář" },
        { value: "výcvikář" },
        { value: "figurant" },
        { value: "člen" }
      ]}
      getOptionSelected={(option, value) => {
        return option.value === value.value;
      }}
      onChange={(e, value, result) => {
        setFieldValue(
          FIELD_NAME,
          value?.length ? value.map(item => item.value) : []
        );
      }}
      defaultValue={
        values[FIELD_NAME]?.length > 0
          ? values[FIELD_NAME].map(value => ({
              value: value
            }))
          : []
      }
      multiple={true}
      baseParams={{ ...baseParams, name: FIELD_NAME }}
    />
  );
};

export default RolesField;
