import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import ControlledImage from "../controlledImage";
import {
  removeItemFromArrayByValue,
  removeItemFromArrayOfObjects
} from "../../../utils/state";

const useStyles = makeStyles(theme => ({
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%"
  },
  emptyImagePlaceholder: {
    height: "100%",
    minHeight: "200px"
  }
}));

const MultipleImageUploader = ({ actualImages, onChangeImageIds }) => {
  const classes = useStyles();

  const [images, setImages] = useState(actualImages ? actualImages : []);

  const handleImageAdd = (id, url) => {
    setImages(images => [
      ...images,
      {
        id: id,
        url: url
      }
    ]);
    onChangeImageIds(images => [...images, id]);
  };
  const handleImageDeleted = (index, id) => {
    setImages(images =>
      removeItemFromArrayOfObjects(images, image => image.id === id)
    );
    onChangeImageIds(images => removeItemFromArrayByValue(images, id));
  };

  return (
    <>
      {images.map((image, index) => (
        <Grid item xs={12} md={6} key={index}>
          <ControlledImage
            isControlled={true}
            onRemove={() => handleImageDeleted(index, image.id)}
          >
            <img
              src={image.url}
              className={classes.image}
              alt={"obrazek novinky"}
            />
          </ControlledImage>
        </Grid>
      ))}
      <Grid item xs={12} md={6}>
        <div className={classes.emptyImagePlaceholder}>
          <ControlledImage
            isControlled={true}
            onAdd={handleImageAdd}
            onAddUploadFiles={true}
            addMultiple={true}
          />
        </div>
        {/*<pre>{JSON.stringify(images, null, 2)}</pre>*/}
      </Grid>
    </>
  );
};

export default MultipleImageUploader;
