// @flow

import axios from "utils/axios";
import { ThunkAction } from "redux-thunk";

import * as types from "./types";

// notification-actions
import { enqueueSnackbar } from "redux/notification/notification-actions";
import { disableLoader, enableLoader } from "../common/actions";
import {datetime_from_server} from "../../utils/datetime";

export const LOAD_PHOTO_GALLERIES_LOADER_NAME =
  "PHOTO_GALLERY_LOAD_PHOTO_GALLERIES";
export const LOAD_PHOTO_GALLERY_LOADER_NAME =
  "PHOTO_GALLERY_LOAD_PHOTO_GALLERY";
export const CREATE_PHOTO_GALLERY_LOADER_NAME =
  "PHOTO_GALLERY_CREATE_PHOTO_GALLERY";

export const resetPhotoGalleryState = () => {
  return async (dispatch: ThunkAction) => {
    dispatch({ type: types.RESET_PHOTO_GALLERY });
    dispatch({ type: types.RESET_PHOTO_GALLERIES });
  };
};

/**
 * POST load images from `/v1/<resourceType>/<id>/`
 * @param {string} resourceType type of source, one of (event, trainingClub (TODO))
 * @param {number} resourceId id of source type (event id, club id, ...)
 */
export const loadPhotoGalleries = (
  resourceType: string,
  resourceId: number
) => {
  return async (dispatch: ThunkAction) => {
    try {
      dispatch(enableLoader(LOAD_PHOTO_GALLERIES_LOADER_NAME));

      const response = await axios({
        url: `/v1/${resourceType}/${resourceId}/photo-galleries`,
        method: "get",
        data: {},
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch({
        type: types.LOAD_PHOTO_GALLERIES,
        payload: response.data.photoGalleries.map(photoGallery => {
          return {
            id: photoGallery.id,
            name: photoGallery.name,
            createDatetime: datetime_from_server(photoGallery.createDatetime),
            images: photoGallery.images.map(image => {
              return {
                id: image.id,
                url: image.url
              };
            }),
            imagesCount: photoGallery.images.length || 0 // todo: get number of images from backend
          };
        })
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání fotogalerií došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(LOAD_PHOTO_GALLERIES_LOADER_NAME));
    }
  };
};

export const loadPhotoGallery = (
  photoGalleryId: number,
  limit: number = 16
) => {
  return async (dispatch: ThunkAction) => {
    try {
      dispatch({ type: types.RESET_PHOTO_GALLERY });
      dispatch(enableLoader(LOAD_PHOTO_GALLERY_LOADER_NAME));

      const response = await axios({
        url: `/v1/photo-galleries/${photoGalleryId}`,
        method: "get",
        data: {
          limit: limit
        },
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      const photoGallery = response.data.photoGallery;
      dispatch({
        type: types.LOAD_PHOTO_GALLERY,
        payload: {
          id: photoGallery.id,
          name: photoGallery.name,
          createDatetime: datetime_from_server(photoGallery.createDatetime),
          images: photoGallery.images.map(image => {
            return {
              id: image.id,
              url: image.url
            };
          })
        }
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání fotogalerie došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(LOAD_PHOTO_GALLERY_LOADER_NAME));
    }
  };
};

export const createPhotoGallery = ({
  resourceType,
  resourceId,
  values,
  formName,
  onSuccess,
  imageIds
}) => {
  return async (dispatch: ThunkAction) => {
    try {
      dispatch(enableLoader(CREATE_PHOTO_GALLERY_LOADER_NAME));
      await axios({
        url: `/v1/${resourceType}/${resourceId}/photo-galleries/`,
        method: "put",
        data: {
          name: values.name,
          imageIds: imageIds
        },
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch(
        enqueueSnackbar({
          message: "Vytvoření fotogalerie proběhlo úspěšně.",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success"
          }
        })
      );
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu vytváření fotogalerie došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(CREATE_PHOTO_GALLERY_LOADER_NAME));
    }
  };
};

/*
 * Edits photo gallery with given attributes and images and dispatches doneDispatch if editing was completely finished.
 */
export const editPhotoGallery = ({
  photoGalleryId,
  resourceType,
  resourceId,
  values,
  formName,
  onSuccess,
  imageIds
}) => {
  return async (dispatch: ThunkAction) => {
    try {
      await axios({
        url: `/v1/photo-galleries/${photoGalleryId}`,
        method: "post",
        data: {
          name: values.name,
          imageIds: imageIds
        },
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch(
        enqueueSnackbar({
          message: "Úprava fotogalerie proběhla úspěšně.",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success"
          }
        })
      );
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu úpravy fotogalerie došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

/*
 * Removes given photo gallery and dispatches doneDispatch if removing was completely finished.
 */
export const removePhotoGallery = (id, onSuccess) => {
  return async (dispatch: ThunkAction) => {
    try {
      await axios({
        url: `/v1/photo-galleries/${id}/`,
        method: "delete",
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch(
        enqueueSnackbar({
          message: "Smazání fotogalerie proběhlo úspěšně.",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success"
          }
        })
      );
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu mazání fotogallerie došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};
//
// export const loadNextPhotos = (
//   sourceType: string,
//   id: number,
//   limit: number = 16,
//   offset: number = 0
// ) => {
//   return async (dispatch: ThunkAction) => {
//     try {
//       const response = await axios({
//         url: "/v1/" + sourceType + "/" + id + "/",
//         method: "post",
//         data: {
//           limit: limit,
//           offset: offset
//         },
//         validateStatus: status => {
//           return status >= 200 && status <= 304;
//         }
//       });
//       dispatch({
//         type: types.LOAD_NEXT,
//         payload: response.data.images
//       });
//       // callback();
//     } catch (error) {
//       dispatch(
//         enqueueSnackbar({
//           message: "V průběhu načítání obrázků došlo k chybě!",
//           options: {
//             key: new Date().getTime() + Math.random(),
//             variant: "error"
//           }
//         })
//       );
//     }
//   };
// };
