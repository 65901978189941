import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LONG,
  TOO_SHORT
} from "../../../../../../constants/dictionary/validation";

const FIELD_NAME = "sponsorUrl";

export const YUP_SPONSOR_URL = Yup.string()
  .min(0, TOO_SHORT)
  .max(200, TOO_LONG);

export const YUP_SPONSOR_URL_REQUIRED = YUP_SPONSOR_URL.required(REQUIRED);

const SponsorUrlField = ({ baseParams }) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      label="Odkaz na web sponzora"
      fullWidth={true}
      type="text"
      placeholder="Zde vložte odkaz na web sponzora"
      baseParams={baseParams}
    />
  );
};

export default SponsorUrlField;
