import React, { useEffect, useState } from "react";

import { Typography, Grid, makeStyles, IconButton } from "@material-ui/core";

import Section from "common/components/page/section";
import { useDispatch, useSelector } from "react-redux";
import UserProfilePreview from "../../../../../../common/components/user-profile-preview";
import {
  closeModal,
  openConfirmationModal,
  openModal
} from "../../../../../../redux/common/actions";
import { authorize } from "../../../../../../utils/authorize";
import EventStaffEditForm, {
  EVENT_STAFF_EDIT_FORM_MODAL_NAME
} from "./forms/editForm";
import AuthorizedOnly from "../../../../../../common/components/authorization/authorizedOnly";
import ModalFormWrapper from "../../../../../../common/components/modalForm/wrapper";
import {
  getEventStaffs,
  removeStaff
} from "../../../../../../redux/event/actions";
import EventStaffCreateForm, {
  EVENT_STAFF_CREATE_FORM_MODAL_NAME
} from "./forms/createForm";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
  icons: {
    marginRight: theme.spacing(1)
  },
  coloredLists: {
    color: theme.palette.secondary.main
  },
  editButton: {
    position: "absolute",
    right: theme.spacing(3),
    top: theme.spacing(0)
  }
}));

const EventStaff = ({ eventId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [eventStaff, setEventStaff] = useState(null);

  const reloadEventStaffs = () => {
    dispatch(getEventStaffs(eventId));
  };

  useEffect(() => {
    (async () => {
      await reloadEventStaffs();
    })();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [eventId]);

  const eventStaffs = useSelector(state => state.EventReducer.eventStaffs);

  const authorization = useSelector(state => state.AuthReducer);
  const authorized = authorize(
    authorization,
    "isResourceAdmin",
    "events",
    eventId
  );

  const handleCreateModalOpen = index => {
    dispatch(openModal(EVENT_STAFF_CREATE_FORM_MODAL_NAME));
  };
  const handleCreateModalClose = () => {
    dispatch(closeModal(EVENT_STAFF_CREATE_FORM_MODAL_NAME));
  };
  const handleEditModalOpen = index => {
    setEventStaff(eventStaffs[index]);
    dispatch(openModal(EVENT_STAFF_EDIT_FORM_MODAL_NAME));
  };
  const handleEditModalClose = () => {
    dispatch(closeModal(EVENT_STAFF_EDIT_FORM_MODAL_NAME));
  };
  const handleRemoveModalOpen = (index, fullname) => {
    dispatch(
      openConfirmationModal(
        "Odstranit pořadatele",
        `Opravdu si přejete odstranit pořadatele ${fullname}?`,
        () => handleConfirmRemove(index)
      )
    );
  };
  const handleConfirmRemove = index => {
    dispatch(
      removeStaff({
        staffId: eventStaffs[index].id,
        eventId,
        onSuccess: reloadEventStaffs
      })
    );
  };

  return (
    <Section title={"Pořadatelé akce"} bottomDivider={false} relative={true}>
      <AuthorizedOnly
        expectedRole={"isResourceAdmin"}
        resourceType={"events"}
        resourceId={eventId}
      >
        <ModalFormWrapper
          modalName={EVENT_STAFF_EDIT_FORM_MODAL_NAME}
          title={"Upravit pořadatele akce"}
        >
          <EventStaffEditForm
            eventId={eventId}
            staffId={eventStaff?.id}
            userId={eventStaff?.user?.id}
            initialValues={{
              isVirtual: eventStaff?.user?.isVirtual,
              email: "", // email cannot be changed
              firstName: eventStaff?.user?.name || "",
              lastName: eventStaff?.user?.surname || "",
              city: eventStaff?.user?.address?.city || "",
              street: eventStaff?.user?.address?.street || "",
              zipNumber: eventStaff?.user?.address?.zipNumber || "",
              phoneNumber: eventStaff?.user?.phoneNumber || "",
              // birthDate: eventStaff?.user?.birthDate || null,
              // club eventStaff specific fields
              roles: eventStaff?.roles || [],
              isAdmin: eventStaff?.isAdmin || false,
              isContactPerson: eventStaff?.isContactPerson || false
            }}
            actualProfileImageUrl={eventStaff?.user?.imageUrl}
            onClose={handleEditModalClose}
            onSubmitSuccessfully={reloadEventStaffs}
          />
        </ModalFormWrapper>
        {/*formular pro vytváření*/}
        <ModalFormWrapper
          title={"Přidat pořadatele akce"}
          modalName={EVENT_STAFF_CREATE_FORM_MODAL_NAME}
        >
          <EventStaffCreateForm
            eventId={eventId}
            initialValues={{
              isVirtual: false,
              email: "",
              userId: null,
              firstName: "",
              lastName: "",
              city: "",
              street: "",
              zipNumber: "",
              phoneNumber: "",
              // birthDate: null,
              // event staff specific fields
              roles: ["pořadatel"],
              isAdmin: false,
              isContactPerson: false
            }}
            onClose={handleCreateModalClose}
            onSubmitSuccessfully={reloadEventStaffs}
          />
        </ModalFormWrapper>
        <div className={classes.editButton}>
          {/*<SectionAction*/}
          {/*  title={"Správa členů klubu"}*/}
          {/*  onAdd={handleAddNewMemberModalOpen}*/}
          {/*  onAddTitle={"Přidat člena"}*/}
          {/*/>*/}
          <IconButton
            color={"primary"}
            title={"Přidat pořadatele"}
            onClick={handleCreateModalOpen}
          >
            <AddIcon />
          </IconButton>
        </div>
      </AuthorizedOnly>
      <Grid container spacing={4}>
        {eventStaffs?.length > 0 ? (
          eventStaffs.map((eventStaff, index) => (
            <Grid item xs={12} lg={6} key={index}>
              <UserProfilePreview
                showContactFields={true}
                id={eventStaff.id}
                name={eventStaff?.user?.name}
                surname={eventStaff.user?.surname}
                // address={eventStaff.address}
                // email={eventStaff.email}
                phoneNumber={eventStaff.user?.phoneNumber}
                roles={eventStaff.roles}
                imageUrl={eventStaff?.user?.imageUrl}
                onEditButtonClick={
                  authorized ? () => handleEditModalOpen(index) : undefined
                }
                onRemoveButtonClick={
                  authorized
                    ? () =>
                        handleRemoveModalOpen(index, eventStaff?.user?.fullname)
                    : undefined
                }
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant={"body2"}>
              nebyli nalezeni žádní pořadatelé
            </Typography>
          </Grid>
        )}
      </Grid>
    </Section>
  );
};

export default EventStaff;
