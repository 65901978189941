import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  }
}));

const MainPageTitle = ({ variant, children }) => {
  const classes = useStyles();

  return (
    <Typography variant={variant || "h4"} className={classes.root}>
      {children}
    </Typography>
  );
};

export default MainPageTitle;
