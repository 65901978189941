import React from "react";
import { Typography } from "@material-ui/core"; // makeStyles
import MainContentContainer from "../../../common/components/main-content-container";
import MainTemplate from "../menu-component/mainTemplate";

// const useStyles = makeStyles(theme => ({
//   root: {
//     padding: theme.spacing(0)
//   }
// }));

const NotFoundPage = () => {
  // const classes = useStyles();

  return (
    <MainTemplate>
      <MainContentContainer>
        <Typography variant="h4">Stránka nebyla nalezena</Typography>
        <Typography variant="body1">
          Tato stránka nebyla nalezena. Možná se pokoušíte dostat na
          neexistující klub, akci a podobně. Zkuste prosím najít požadovaný
          obsah znovu.
        </Typography>
      </MainContentContainer>
    </MainTemplate>
  );
};

export default NotFoundPage;
