import React, { useEffect } from "react";

// import { useParams } from "react-router-dom";
import { Typography, makeStyles, Grid } from "@material-ui/core";
import MainContentContainer from "../../../../../common/components/main-content-container";
import Map from "common/components/map/";
import UserProfilePreview from "common/components/user-profile-preview";
import Section from "common/components/page/section";
import {
  GET_TRAINING_CLUB_CONTACT_LOADER_NAME,
  getTrainingClubContact
} from "../../../../../redux/trainingClub/actions";
import { useDispatch, useSelector } from "react-redux";
import { NOT_SET_PLACEHOLDER } from "../../../../../constants";
import Button from "@material-ui/core/Button";
import { openModal } from "../../../../../redux/common/actions";
import ClubContactEditFormModal, {
  CLUB_CONTACT_EDIT_FORM_MODAL_NAME
} from "./editFormModal";
import AuthorizedOnly from "../../../../../common/components/authorization/authorizedOnly";

const useStyles = makeStyles(theme => ({
  mapGrid: {
    height: "60vh"
  },
  sectionTitle: {
    marginBottom: theme.spacing(2)
  },
  editButton: {
    float: "right"
  }
}));

const TrainingClubContact = ({ resourceId: clubId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTrainingClubContact(clubId));
  }, [dispatch, clubId]);

  const contact = useSelector(state => state.TrainingClubReducer.contact);
  const address = contact ? contact.address : null;
  const contactPersons = contact ? contact.persons : [];

  const loading =
    useSelector(store => store.CommonReducer.loaders)[
      GET_TRAINING_CLUB_CONTACT_LOADER_NAME
    ] || false;

  const handleEditModalOpen = () => {
    dispatch(openModal(CLUB_CONTACT_EDIT_FORM_MODAL_NAME));
  };
  const handleEditFormSubmitSuccessfully = () => {
    dispatch(getTrainingClubContact(clubId));
  };

  return (
    <MainContentContainer>
      <Section>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <AuthorizedOnly
              expectedRole={"isResourceAdmin"}
              resourceType={"clubs"}
              resourceId={clubId}
            >
              <ClubContactEditFormModal
                clubId={clubId}
                initialValues={{
                  street: address?.street || "",
                  city: address?.city || "",
                  zipNumber: address?.zipNumber || "",
                  description: address?.description || "",
                  gps: address?.gps || ""
                }}
                // actualProfileImageUrl={trainingClub.imageUrl}
                onSubmitSuccessfully={handleEditFormSubmitSuccessfully}
              />
              <Button
                className={classes.editButton}
                onClick={handleEditModalOpen}
                color={"primary"}
                variant={"outlined"}
              >
                Upravit
              </Button>
            </AuthorizedOnly>
            <Typography variant={"h5"} className={classes.sectionTitle}>
              Addresa
            </Typography>
            {address ? (
              <>
                <Typography variant={"body1"}>{address.description}</Typography>
                <Typography variant="body1">
                  <strong>Ulice:</strong>{" "}
                  {address.street || NOT_SET_PLACEHOLDER}
                </Typography>
                <Typography variant="body1">
                  <strong>Město:</strong> {address.city || NOT_SET_PLACEHOLDER}
                </Typography>
                <Typography variant="body1">
                  <strong>PSČ:</strong>{" "}
                  {address.zipNumber || NOT_SET_PLACEHOLDER}
                </Typography>
                <Typography variant="body1">
                  <strong>GPS:</strong> {address.gps || NOT_SET_PLACEHOLDER}
                </Typography>
              </>
            ) : (
              <Typography variant={"body1"}>
                {loading
                  ? "načítání..."
                  : "kontaktní informace klubu nebyly nalezeny"}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={8} className={classes.mapGrid}>
            <Map
              gpsString={address ? address.gps : null}
              destinationDescription={address ? address.description || "" : ""}
            />
          </Grid>
        </Grid>
      </Section>
      <Section title={"Kontaktní osoby"} bottomDivider={false}>
        <Grid container spacing={4}>
          {contactPersons?.length > 0 ? (
            contactPersons.map((contactPerson, index) => (
              <Grid item xs={12} lg={6} key={index}>
                <UserProfilePreview
                  showContactFields={true}
                  id={contactPerson.id}
                  name={contactPerson.name}
                  surname={contactPerson.surname}
                  address={contactPerson.address}
                  email={contactPerson.email}
                  phoneNumber={contactPerson.phoneNumber}
                  roles={contactPerson.roles}
                  imageUrl={contactPerson.imageUrl}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              {loading
                ? "načítání..."
                : "kontaktní osoby klubu nebyly nalezeny"}
            </Grid>
          )}
        </Grid>
      </Section>
    </MainContentContainer>
  );
};

export default TrainingClubContact;
