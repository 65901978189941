import React, { useState, useEffect } from "react";
import { Box, Button, ButtonGroup, makeStyles } from "@material-ui/core";
import axios from "../../../utils/axios";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageCropper from "../imageCropper";
import { enqueueSnackbar } from "../../../redux/notification/notification-actions";
import { useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    width: "400px"
  },
  hiddenInput: {
    display: "none"
  },
  centered: {
    textAlign: "center"
  },
  imageEditButton: {
    marginTop: theme.spacing(1)
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "auto"
  },
  reduceMaximumImageWidth: {
    width: "400px"
  }
}));

const ImageUploader = ({
  initImage,
  onChange,
  onDelete,
  useCrop = false,
  alt = ""
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [newImage, setNewImage] = useState(null);
  const [newImageUploadClicked, setNewImageUploadClicked] = useState(false);
  const [crop, setCrop] = useState({
    aspect: 1,
    unit: "px",
    x: 0,
    y: 0,
    width: 200,
    height: 200
  });
  const [scale, setScale] = useState({ scaleX: 1, scaleY: 1 });

  useEffect(() => {
    if (newImage && !useCrop && !newImageUploadClicked) {
      // Automatically upload image, no wait for user click button as in crop mode.
      // newImageUploadClicked means, that image was already uploaded and prevents next automatic uploads
      handleUploadNewImage();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [newImage]);

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      // Nastav docasne obrazek k zobrazeni
      const newImageUrl = URL.createObjectURL(e.target.files[0]);
      setNewImage({
        url: newImageUrl,
        file: file
      });
    }
  };
  const handleResetImage = () => {
    setNewImage(null);
    setNewImageUploadClicked(false);
    if (onChange) onChange(initImage.id, initImage.url);
    // let { [index]: _, ...images } = newImages;
    // setNewImages(images);
  };

  const handleUploadNewImage = () => {
    setNewImageUploadClicked(true);
    uploadImage();
  };

  const uploadImage = () => {
    // Uploadujeme image
    const formData = new FormData();
    formData.append("image", newImage.file);
    if (useCrop) {
      formData.append(
        "crop",
        JSON.stringify({
          x: crop.x * scale.scaleX,
          y: crop.y * scale.scaleY,
          width: crop.width * scale.scaleX,
          height: crop.height * scale.scaleY
        })
      );
    }
    axios({
      url: `/v1/upload/images/`,
      method: "POST",
      data: formData
    })
      .then(response => {
        dispatch(
          enqueueSnackbar({
            message: "Nahrání obrázku proběhlo úspěšně.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success"
            }
          })
        );
        setNewImage(prevState => ({
          ...prevState,
          id: response.data.id,
          url: response.data.url
        }));
        if (onChange) onChange(response.data.id, response.data.url);
      })
      .catch(error => {
        handleResetImage();
        dispatch(
          enqueueSnackbar({
            message: "V průběhu nahrávání obrázku došlo k chybě!",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error"
            }
          })
        );
      });
  };

  const inputElementId = "image-uploader-" + Math.random();

  return (
    // <div className={classes.root}>
    <>
      <Box display={"flex"} justifyContent={"center"}>
        <div className={classes.reduceMaximumImageWidth}>
          {useCrop && newImage && !newImageUploadClicked ? (
            <ImageCropper
              src={newImage.url}
              crop={crop}
              onCropChanged={setCrop}
              setScale={setScale}
            />
          ) : (!newImage && initImage.url) || (newImage && newImage.url) ? (
            <img
              className={classes.image}
              src={!newImage ? initImage.url : newImage.url}
              alt={alt}
            />
          ) : null}
          {/*<pre>{JSON.stringify(newImage, null, 2)}</pre>*/}
        </div>
      </Box>
      <Box textAlign={"center"} marginTop={1}>
        {!newImage ? (
          <div className={classes.imageEditButton}>
            <input
              id={inputElementId}
              name="profileImage"
              className={classes.hiddenInput}
              type="file"
              onChange={onSelectFile}
            />
            <label htmlFor={inputElementId}>
              <Button variant={"outlined"} component={"span"}>
                Nahrát nový obrázek
              </Button>
            </label>
            {onDelete && (
              <Button
                variant={"outlined"}
                component={"span"}
                title={"smazat obrázek"}
                onClick={onDelete}
                color={"secondary"}
              >
                <DeleteIcon />
              </Button>
            )}
          </div>
        ) : (
          <ButtonGroup variant={"outlined"} component={"span"}>
            {useCrop && !newImageUploadClicked && (
              <Button color={"primary"} onClick={handleUploadNewImage}>
                Nahrát tento nový obrázek
              </Button>
            )}
            <Button
              // variant={"outlined"}
              // component={"span"}
              onClick={handleResetImage}
            >
              Zrušit změnu obrázku
            </Button>
          </ButtonGroup>
        )}
      </Box>
    </>
    // {/*</div>*/}
  );
};

export default ImageUploader;
