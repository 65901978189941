import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Modal from "../../../components/modalForm/modal";

import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../../redux/common/actions";
import CustomPageSectionEditForm from "../section/editForm";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CustomPageMediaCardsEditForm from "../mediaCardsPreview/editForm";
import CustomPageCarouselEditForm from "../carouselPreview/editForm";
import MediaCard from "../../mediaCard";
import CustomPageSponsorEditForm from "../sponsorPreview/forms/editForm";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  sectionSelectWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  sectionSelectHelperTextWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

export const SECTION_CREATE_FORM_MODAL_NAME_PREFIX = "SectionCreateFormModal";

const SectionCreateFormModal = ({
  customPageId,
  onSubmitSuccessfully,
  resourceType,
  resourceId,
  minOrder,
  maxOrder,
  allowSectionTypes = [
    "Section",
    "MediaCardsPreview",
    "CarouselPreview",
    "SponsorPreview"
  ]
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [sectionType, setSectionType] = useState(null);

  const modalName =
    SECTION_CREATE_FORM_MODAL_NAME_PREFIX + customPageId.toString();

  const open =
    useSelector(state => state.CommonReducer.modals)[modalName] || false;

  const handleModalClose = () => {
    setSectionType(null); // reset section type
    dispatch(closeModal(modalName));
  };

  const handleSubmitSuccessfully = () => {
    setSectionType(null); // reset section type
    onSubmitSuccessfully();
  };

  const getForm = sectionType => {
    switch (sectionType) {
      case null:
        return <Typography variant={"body1"}>null</Typography>;
      case "Section":
        return (
          <CustomPageSectionEditForm
            customPageSectionId={null}
            initialValues={{
              title: "",
              order: maxOrder,
              fullHeight: false,
              html: ""
            }}
            onClose={handleModalClose}
            actualImageUrl={undefined}
            onSubmitSuccessfully={handleSubmitSuccessfully}
            resourceType={resourceType}
            resourceId={resourceId}
            customPageId={customPageId}
            minOrder={minOrder}
            maxOrder={maxOrder}
          />
        );
      case "MediaCardsPreview":
        return (
          <CustomPageMediaCardsEditForm
            customPageSectionId={null}
            initialValues={{
              title: "",
              order: maxOrder,
              fullHeight: false,
              title1: "",
              content1: "",
              title2: "",
              content2: "",
              title3: "",
              content3: ""
            }}
            onClose={handleModalClose}
            actualImageUrl1={undefined}
            actualImageUrl2={undefined}
            actualImageUrl3={undefined}
            onSubmitSuccessfully={handleSubmitSuccessfully}
            resourceType={resourceType}
            resourceId={resourceId}
            customPageId={customPageId}
            minOrder={minOrder}
            maxOrder={maxOrder}
          />
        );
      case "CarouselPreview":
        return (
          <CustomPageCarouselEditForm
            customPageSectionId={null}
            initialValues={{
              title: "",
              order: maxOrder,
              fullHeight: false
            }}
            onClose={handleModalClose}
            actualImageUrl={undefined}
            onSubmitSuccessfully={handleSubmitSuccessfully}
            resourceType={resourceType}
            resourceId={resourceId}
            customPageId={customPageId}
            minOrder={minOrder}
            maxOrder={maxOrder}
          />
        );
      case "SponsorPreview":
        return (
          <CustomPageSponsorEditForm
            customPageSectionId={null}
            initialValues={{
              sponsorName: "",
              sponsorUrl: "",
              order: maxOrder,
              fullHeight: false
            }}
            onClose={handleModalClose}
            actualImageUrl={undefined}
            onSubmitSuccessfully={handleSubmitSuccessfully}
            resourceType={resourceType}
            resourceId={resourceId}
            customPageId={customPageId}
            minOrder={minOrder}
            maxOrder={maxOrder}
            modalName={modalName}
          />
        );
      default:
        return (
          <Typography variant={"body1"}>
            Vybrali jste neočekávaný typ sekce: {sectionType}.
          </Typography>
        );
    }
  };

  return (
    <div className={classes.root}>
      <Modal
        open={open}
        title={"Přidání nové sekce"}
        maxWidth={"md"}
        fullWidth={true}
        onClose={handleModalClose}
      >
        {sectionType ? (
          <div>
            <Button onClick={() => setSectionType(null)}>
              Zpět na výběr typu sekce
            </Button>
            {getForm(sectionType)}
          </div>
        ) : (
          <div className={classes.sectionSelectWrapper}>
            <Grid container spacing={2}>
              {allowSectionTypes.includes("Section") && (
                <Grid item xs={12} md={4}>
                  <MediaCard
                    onClick={() => setSectionType("Section")}
                    title={"Sekce"}
                    descriptionList={[
                      "zobrazení textu, do kterého lze volitelně vložit maximálně jeden obrázek"
                    ]}
                  />
                </Grid>
              )}
              {allowSectionTypes.includes("MediaCardsPreview") && (
                <Grid item xs={12} md={4}>
                  <MediaCard
                    onClick={() => setSectionType("MediaCardsPreview")}
                    title={"Multimediální karty"}
                    descriptionList={[
                      "zobrazení informací do sloupců, které lze doplnit obrázkem"
                    ]}
                  />
                </Grid>
              )}
              {allowSectionTypes.includes("CarouselPreview") && (
                <Grid item xs={12} md={4}>
                  <MediaCard
                    onClick={() => setSectionType("CarouselPreview")}
                    title={"Multimediální kolotoč"}
                    descriptionList={[
                      "zobrazení více obrázků, které se postupně střídají"
                    ]}
                  />
                </Grid>
              )}
              {allowSectionTypes.includes("SponsorPreview") && (
                <Grid item xs={12} md={4}>
                  <MediaCard
                    onClick={() => setSectionType("SponsorPreview")}
                    title={"Sponzor"}
                    descriptionList={[
                      "zobrazení názvu a loga sponzora s odkazem"
                    ]}
                  />
                </Grid>
              )}
            </Grid>
            <div className={classes.sectionSelectHelperTextWrapper}>
              <Typography variant={"body1"} color={"textSecondary"}>
                vyberte prosím jednu z možných zobrazení Vaší sekce
              </Typography>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default SectionCreateFormModal;
