// @flow

import React from "react";
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {},
  paper: {
    width: "100%",
    height: "350px"
  },
  photo: {
    objectFit: "cover",
    width: "100%",
    height: "100%"
  }
}));

const Photo = ({ imageUrl, onClick }) => {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.paper}>
      <a href={imageUrl} target={"_blank"} rel="noopener noreferrer">
        <img
          className={classes.photo}
          src={imageUrl}
          alt="gallery"
          onClick={onClick}
        />
      </a>
    </Paper>
  );
};

export default Photo;
