// @flow

// react + router
import React from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import { postLogout } from "redux/auth/actions";

// material-ui
import {
  AppBar,
  CssBaseline,
  IconButton,
  Toolbar,
  Typography,
  Button,
  Hidden,
  Menu,
  MenuItem,
  Link
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import {
  isPreviewPage as isPreviewPageFunction,
  isActive
} from "../../../../utils/routes";
import { GET_TRAINING_CLUB_PREVIEW_LOADER_NAME } from "../../../../redux/trainingClub/actions";

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: props => props.backgroundColor
  },
  menuButton: {
    marginRight: theme.spacing(2)
    // [theme.breakpoints.up("sm")]: {
    //   display: "none"
    // }
  },
  leftNavbar: {
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1)
    },
    paddingLeft: theme.spacing(5)
  },
  rightNavbar: {
    display: "flex"
    // flex: 1,
  },
  rightNavbarIcon: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    },
    marginLeft: theme.spacing(1)
  },
  linkWithoutUnderline: {
    textDecoration: "none"
  },
  isActive: {
    backgroundColor: "#AAA4"
  },
  iconBeforeText: {
    marginRight: theme.spacing(1)
  },
  menuItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(2)
  },
  menuItemIcon: {
    marginRight: theme.spacing(2)
  }
}));

/**
 * Flow types
 */
type Props = {
  history: Object,
  handleDrawerToggle: Function
};

const TopMenu = (props: Props) => {
  const dispatch = useDispatch();

  const customDomain = useSelector(state => state.CommonReducer.customDomain);
  const { previewPage } = useSelector(store => store.TrainingClubReducer);
  const { loaders } = useSelector(store => store.CommonReducer);
  const previewPageLoader =
    loaders[GET_TRAINING_CLUB_PREVIEW_LOADER_NAME] || false;
  const previewPageTopMenu = previewPage
    ? previewPage.topMenu || {
        backgroundColor: "#3f51b5cc" // topMenu is not set, set default color but transparent
      }
    : { backgroundColor: previewPageLoader ? "#00000000" : "#3f51b5cc" }; // fully transparent when preview page is loading

  const isPreviewPage = isPreviewPageFunction(
    customDomain,
    props.location.pathname
  );

  const classes = useStyles({
    // Change background color only if this is custom page and user is on preview page.
    backgroundColor: isPreviewPage ? previewPageTopMenu.backgroundColor : null // null for no change
  });

  /**
   * Run `authAction.logout`
   */
  const sendLogout = () => {
    dispatch(postLogout());
  };

  const { isAuthenticated, fullname, userId } = useSelector(
    store => store.AuthReducer
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [customClubMenuAnchorEl, setCustomClubMenuAnchorEl] = React.useState(
  //   null
  // );
  const open = Boolean(anchorEl);
  // const customClubMenuOpen = Boolean(customClubMenuAnchorEl);

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // const handleCustomClubMenuOpen = event => {
  //   setCustomClubMenuAnchorEl(event.currentTarget);
  // };
  //
  // const handleCustomClubMenuClose = () => {
  //   setCustomClubMenuAnchorEl(null);
  // };

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {/* DRAWER TOGGLE */}
          {/* Depends on the location, toggle menu button is rendered.*/}
          {(props.location &&
            props.location.pathname &&
            props.location.pathname.match(/^\/(clubs|events)\//g)) ||
          isPreviewPage ? (
            <div>
              <Hidden smUp>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={props.handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
              {isPreviewPage && (
                <Hidden xsDown>
                  <Button
                    variant={"outlined"}
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={props.handleDrawerToggle}
                    className={classes.menuButton}
                  >
                    Menu
                  </Button>
                </Hidden>
              )}
            </div>
          ) : null}
          <Link
            to={"/"} // customDomain ? customDomain.path : "/"
            component={RouterLink}
            underline="none"
            color="inherit"
          >
            <Button color={"inherit"}>
              <Hidden xsDown>
                <Typography variant="h6" noWrap>
                  {customDomain ? customDomain.title : "kynologove.cz"}
                </Typography>
              </Hidden>
              <Hidden smUp>
                <HomeIcon />
              </Hidden>
            </Button>
          </Link>
          {/* TOP MENU ROUTER */}
          <div className={classes.leftNavbar}>
            {/* do not display for custom domain */}
            {customDomain ? null : (
              // (
              //   <span>
              //     <Button
              //       aria-label="custom domain club menu"
              //       aria-controls="club-menu-appbar"
              //       aria-haspopup="true"
              //       onClick={handleCustomClubMenuOpen}
              //       color="inherit"
              //     >
              //       Klub
              //     </Button>
              //     <Menu
              //       id="club-menu-appbar"
              //       anchorEl={customClubMenuAnchorEl}
              //       anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              //       transformOrigin={{ vertical: "top", horizontal: "center" }}
              //       getContentAnchorEl={null}
              //       open={customClubMenuOpen}
              //       onClose={handleCustomClubMenuClose}
              //     >
              //       {routes &&
              //         routes.constructor === Array &&
              //         routes.map((route, index) => (
              //           <MenuItem
              //             onClick={handleCustomClubMenuClose}
              //             className={classes.menuItem}
              //             key={index}
              //           >
              //             <Link
              //               to={route.link}
              //               component={RouterLink}
              //               underline={"none"}
              //               color={"inherit"}
              //             >
              //               <Icon
              //                 component={route.icon}
              //                 className={classes.menuItemIcon}
              //               />{" "}
              //               {route.name}
              //             </Link>
              //           </MenuItem>
              //         ))}
              //   </Menu>
              // </span>
              // )
              <Link
                to="/clubs"
                component={RouterLink}
                underline="none"
                color="inherit"
              >
                <Button
                  color={"inherit"}
                  className={
                    isActive(props.location.pathname, "/clubs") &&
                    classes.isActive
                  }
                >
                  Kluby
                </Button>
              </Link>
            )}

            {/* do not display for custom domain of events */}
            {/* for custom domain of clubs it's special modified for showing events only for club */}
            {customDomain?.resourceType === "events" ? null : (
              <Link
                to="/events"
                component={RouterLink}
                underline="none"
                color="inherit"
              >
                <Button
                  color={"inherit"}
                  className={
                    isActive(props.location.pathname, "/events") &&
                    classes.isActive
                  }
                >
                  Akce
                </Button>
              </Link>
            )}
          </div>

          <div className={classes.rightNavbar}>
            {/*TODO: AUTHENTICATION*/}
            {isAuthenticated ? (
              <div className={classes.rightNavbarIcon}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                  color="inherit"
                >
                  <AccountCircleIcon />
                  <Typography variant={"body1"} style={{ marginLeft: "8px" }}>
                    {fullname}
                  </Typography>
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                  getContentAnchorEl={null}
                  open={open}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    component={RouterLink}
                    to={`/persons/${userId}/profile`}
                    onClick={handleMenuClose}
                  >
                    Profil
                  </MenuItem>
                  <MenuItem onClick={sendLogout}>Odhlásit se</MenuItem>
                </Menu>
              </div>
            ) : (
              <div className={classes.rightNavbarIcon}>
                <Link
                  to="/login"
                  component={RouterLink}
                  underline="none"
                  color="inherit"
                >
                  <Button color="inherit">Přihlásit</Button>
                </Link>
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default withRouter(TopMenu);
