import React from "react";

import { matchPath } from "react-router";
import { Route } from "react-router-dom";
import MainTemplate from "../pages/main-page/menu-component/mainTemplate";

// Checks if one of given routes is actually in use.
export const isOneOfRoutesActive = (activePathName, routes) => {
  return Object.keys(routes).some(key => {
    const exact = routes[key].exact;
    const result = matchPath(activePathName, {
      path: routes[key].path,
      exact: exact,
      strict: false
    });
    return result && exact === false
      ? true
      : result && result.isExact === exact;
  });
};

export const RouteWithSubRoutes = route => {
  return (
    <MainTemplate {...route.mainTemplateProps}>
      <Route
        path={route.path}
        exact={route.exact}
        render={props => (
          <route.component
            {...props}
            resourceId={route.id}
            linkPrefix={route.linkPrefix}
          />
        )}
      />
    </MainTemplate>
  );
};

export const isActive = (actualPathname, checkedPath, exact = false) => {
  const result = matchPath(actualPathname, {
    path: checkedPath,
    exact: exact,
    strict: false
  });
  return result && exact === false ? true : result && result.isExact === exact;
};

/*
 * Only resourceType `clubs` has custom page. For custom domain it's "/" and normally it's "/clubs/<id>/
 */
export const isPreviewPage = (customDomain, actualPathname) => {
  return (
    (customDomain?.resourceType === "clubs" &&
      isActive(actualPathname, "/", true)) ||
    (!customDomain && isActive(actualPathname, "/clubs/:id/", true))
  );
};
