import React from "react";
import { Map as LeafletMap, TileLayer, Marker, Popup } from "react-leaflet";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%"
  }
}));

const Map = ({ gpsString, destinationDescription, scrollWheelZoom = true }) => {
  const classes = useStyles();

  const position = gpsString
    ? gpsString.split(",").map(stringFloat => {
        return parseFloat(stringFloat);
      })
    : null;

  return position ? (
    <LeafletMap
      center={position}
      zoom="14"
      className={classes.root}
      scrollWheelZoom={scrollWheelZoom}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position}>
        <Popup>{destinationDescription}</Popup>
      </Marker>
    </LeafletMap>
  ) : null;
};

export default Map;
