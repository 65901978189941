import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Divider from "common/components/page/divider";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  section: {
    marginTop: theme.spacing(3)
  },
  sectionTitle: {
    marginBottom: theme.spacing(3)
  },
  relative: {
    position: "relative"
  }
}));

const Section = ({
  children,
  title,
  topDivider = false,
  bottomDivider = true,
  relative = false
}) => {
  const classes = useStyles();

  return (
    <section className={clsx(classes.root, relative && classes.relative)}>
      {topDivider ? <Divider /> : null}
      <div className={classes.section}>
        {title ? (
          <Typography variant={"h5"} className={classes.sectionTitle}>
            {title}
          </Typography>
        ) : null}
        {children}
      </div>
      {bottomDivider ? <Divider /> : null}
    </section>
  );
};

export default Section;
