import React, { useEffect } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import cs from "date-fns/locale/cs";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  submitPersonProfileEditForm,
  SUBMITTING_PERSON_PROFILE_EDIT_FORM_LOADER
} from "../../../../../../redux/person/actions";
import { resetFormDiagnostics } from "../../../../../../redux/common/actions";
import PersonProfileCreateEditFormLayout from "./formLayout";
import { YUP_FIRST_NAME_REQUIRED } from "../../../../../../common/components/forms/fields/firstName";
import { YUP_LAST_NAME_REQUIRED } from "../../../../../../common/components/forms/fields/lastName";
import { YUP_CITY } from "../../../../../../common/components/forms/fields/city";
import { YUP_STREET } from "../../../../../../common/components/forms/fields/street";
import { YUP_ZIP_NUMBER } from "../../../../../../common/components/forms/fields/zipNumber";
import { YUP_PHONE_NUMBER } from "../../../../../../common/components/forms/fields/phoneNumber";

export const PERSON_PROFILE_EDIT_FORM_NAME = "PERSON_PROFILE_EDIT_FORM_NAME";
export const PERSON_PROFILE_EDIT_FORM_MODAL_NAME =
  "PERSON_PROFILE_EDIT_FORM_MODAL_NAME";

const validationSchema = Yup.object().shape({
  firstName: YUP_FIRST_NAME_REQUIRED,
  lastName: YUP_LAST_NAME_REQUIRED,
  city: YUP_CITY,
  street: YUP_STREET,
  zipNumber: YUP_ZIP_NUMBER,
  phoneNumber: YUP_PHONE_NUMBER
});

const PersonProfileEditForm = ({
  personId,
  initialValues,
  onClose,
  actualProfileImageUrl,
  onSubmitSuccessfully
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(resetFormDiagnostics(PERSON_PROFILE_EDIT_FORM_NAME));
    })();
  }, [dispatch]);

  const loaders = useSelector(state => state.CommonReducer.loaders);

  const isSubmitting =
    loaders[SUBMITTING_PERSON_PROFILE_EDIT_FORM_LOADER] || false;

  const formDiagnostics =
    useSelector(state => state.CommonReducer.formDiagnostics)[
      PERSON_PROFILE_EDIT_FORM_NAME
    ] || {};

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
      <Formik
        initialValues={initialValues}
        onSubmit={async values => {
          dispatch(
            submitPersonProfileEditForm({
              personId,
              values,
              formName: PERSON_PROFILE_EDIT_FORM_NAME,
              modalName: PERSON_PROFILE_EDIT_FORM_MODAL_NAME,
              onSuccess: onSubmitSuccessfully
            })
          );
        }}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, submitForm, setFieldValue }) => {
          return (
            <PersonProfileCreateEditFormLayout
              values={values}
              actualProfileImageUrl={actualProfileImageUrl}
              errors={errors}
              formDiagnostics={formDiagnostics}
              touched={touched}
              isSubmitting={isSubmitting}
              onClose={onClose}
              setFieldValue={setFieldValue}
              createForm={false}
            />
          );
        }}
      </Formik>
    </MuiPickersUtilsProvider>
  );
};

export default PersonProfileEditForm;
