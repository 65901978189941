// @flow

import * as types from "./types";

/**
 * Flow types
 */
type MenuState = {
  info: Object
};

/**
 * Initial menu state
 */
const INITIAL_STATE = {
  info: null
};

/**
 * Menu reducer
 */
const MenuReducer = (state: MenuState = INITIAL_STATE, action: Object) => {
  switch (action.type) {
    case types.GET_MENU_INFO:
      return {
        ...state,
        info: action.payload
      };
    case types.RESET_MENU_STATE:
      return {
        ...state,
        info: INITIAL_STATE.info
      };
    default:
      return state;
  }
};

export default MenuReducer;
