import React from "react";
import { CheckboxWithLabel } from "formik-material-ui";
import { Field } from "formik";
import { combineFormErrorMessages } from "../../../utils/diagnostics";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  moreSpaceAround: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  errorMessage: {
    color: "red"
  }
}));

const CheckboxFieldBase = ({
  name,
  component,
  label,
  type = "text", // todo: asi checkbox default
  baseParams
}) => {
  const classes = useStyles();
  const { errors, formDiagnostics, touched } = baseParams;
  const errorMessages = combineFormErrorMessages(
    errors,
    formDiagnostics,
    name,
    touched
  );

  return (
    <div className={classes.moreSpaceAround}>
      <Field
        component={component || CheckboxWithLabel}
        name={name}
        Label={{ label: label }}
        type={type}
      />
      {errorMessages && (
        <Typography variant={"body2"} className={classes.errorMessage}>
          {errorMessages}
        </Typography>
      )}
    </div>
  );
};

export default CheckboxFieldBase;
