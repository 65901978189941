import React from "react";
import { makeStyles } from "@material-ui/core";
import Modal from "../../../../../common/components/modalForm/modal";

import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../../../redux/common/actions";
import EventContactEditForm from "./editForm";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  }
}));

export const EVENT_CONTACT_EDIT_FORM_MODAL_NAME = "EventContactEditFormModal";

const EventContactEditFormModal = ({
  eventId,
  initialValues,
  actualProfileImageUrl,
  onSubmitSuccessfully
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const open = useSelector(state => state.PersonReducer.editFormOpen);
  const open =
    useSelector(state => state.CommonReducer.modals)[
      EVENT_CONTACT_EDIT_FORM_MODAL_NAME
    ] || false;

  const handleModalClose = () => {
    dispatch(closeModal(EVENT_CONTACT_EDIT_FORM_MODAL_NAME));
  };

  return (
    <div className={classes.root}>
      <Modal
        open={open}
        title={"Upravit kontaktní informace akce"}
        maxWidth={"md"}
        fullWidth={true}
        onClose={handleModalClose}
      >
        <EventContactEditForm
          eventId={eventId}
          initialValues={initialValues}
          onClose={handleModalClose}
          actualProfileImageUrl={actualProfileImageUrl}
          onSubmitSuccessfully={onSubmitSuccessfully}
        />
      </Modal>
    </div>
  );
};

export default EventContactEditFormModal;
