import React from "react";

import MainContentContainer from "../../../../../common/components/main-content-container";
import MainPageTitle from "../../../../../common/components/page/mainPageTitle";
import Articles from "../../../../../common/components/customPage/articles";

const TrainingClubNews = ({ resourceId: clubId }) => {
  return (
    <MainContentContainer relative={true}>
      <MainPageTitle>Novinky</MainPageTitle>
      <Articles resourceType={"clubs"} resourceId={clubId} />
    </MainContentContainer>
  );
};

export default TrainingClubNews;
