import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  message: {
    // color: "#f44336",
    fontSize: "0.75rem",
    marginTop: "3px"
  }
}));

const CustomHelperTextMessage = ({ message }) => {
  const classes = useStyles();

  return message ? (
    <Typography
      variant={"body2"}
      color={"textSecondary"}
      className={classes.message}
    >
      {message}
    </Typography>
  ) : null;
};

export default CustomHelperTextMessage;
