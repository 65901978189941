// @flow

import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  ButtonGroup
} from "@material-ui/core";
import Photo from "./Photo";
import { loadPhotoGallery } from "../../../redux/photo-gallery/actions";
import { useDispatch, useSelector } from "react-redux";
import { LOAD_PHOTO_GALLERY_LOADER_NAME } from "../../../redux/photo-gallery/actions";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { authorize } from "../../../utils/authorize";
import { closeModal, openModal } from "../../../redux/common/actions";
import ModalFormWrapper from "../modalForm/wrapper";
import PhotoGalleryEditForm from "./forms/edit";
import GalleryPreview from "../galleryPreview";

const editPhotoGalleryModalName = "PHOTO_GALLERY_EDIT_MODAL_NAME"; // TODO: presunout asi do ModalFormWrapper nebo nekde do te slozky

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1)
  },
  photosContainer: {
    marginTop: theme.spacing(2)
  },
  buttonContainer: {
    marginBottom: theme.spacing(2)
  }
}));

const PhotoGallery = ({
  photoGalleryId,
  resourceType,
  resourceId,
  linkPrefix
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [clickedImageIndex, setClickedImageIndex] = useState(0);

  const reload = () => {
    dispatch(loadPhotoGallery(photoGalleryId));
  };

  useEffect(() => {
    reload();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, photoGalleryId]);

  const authorization = useSelector(store => store.AuthReducer);
  const authorized = authorize(
    authorization,
    "isResourceAdmin",
    resourceType,
    resourceId
  );

  const photoGallery = useSelector(
    state => state.PhotoGalleryReducer.photoGallery
  );
  const images = photoGallery ? photoGallery.images : null;

  const loading =
    useSelector(state => state.CommonReducer.loaders)[
      LOAD_PHOTO_GALLERY_LOADER_NAME
    ] || false;

  const handleEditFormClose = () => {
    dispatch(closeModal(editPhotoGalleryModalName));
  };

  const handleEditFormOpen = () => {
    dispatch(openModal(editPhotoGalleryModalName));
  };

  const handleNewPhotoGalleryEdited = () => {
    handleEditFormClose();
    reload();
  };

  const handlePhotoClick = (e, index) => {
    e.preventDefault();
    setClickedImageIndex(index);
    dispatch(openModal("galleryPreviewModalName"));
  };

  return (
    <div className={classes.root}>
      {photoGallery ? (
        <div>
          {authorized && (
            <ModalFormWrapper
              modalName={editPhotoGalleryModalName}
              title={"Upravit fotogalerii"}
            >
              <PhotoGalleryEditForm
                photoGalleryId={photoGalleryId}
                initialValues={{ name: photoGallery.name || "" }}
                actualImages={images || []}
                onClose={handleEditFormClose}
                onSubmitSuccessfully={handleNewPhotoGalleryEdited}
                resourceType={resourceType}
                resourceId={resourceId}
              />
            </ModalFormWrapper>
          )}
          <div className={classes.buttonContainer}>
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <Button component={Link} to={`${linkPrefix}/photo-galleries`}>
                Zpět
              </Button>
              {authorized && (
                <Button onClick={handleEditFormOpen}>
                  Upravit fotogalerii
                </Button>
              )}
            </ButtonGroup>
          </div>
          <Typography variant={"h6"}>
            Název galerie: {photoGallery.name}
          </Typography>
          <Typography variant={"body1"}>
            Vytvořeno: <Moment date={photoGallery.createDatetime} />
          </Typography>
          <ModalFormWrapper
            modalName={"galleryPreviewModalName"}
            // title={"Náhled fotografií"}
            maxWidth={"xl"}
            fullScreen={true}
            showCloseButton={true}
            paddingLeftRightOnly={true}
          >
            <GalleryPreview
              images={
                images?.length > 0 &&
                images.map(image => ({
                  original: image.url,
                  thumbnail: image.url
                }))
              }
              index={clickedImageIndex}
            />
          </ModalFormWrapper>
          <div className={classes.photosContainer}>
            <Grid container spacing={3}>
              {images?.length > 0 ? (
                images.map((image, index) => (
                  <Grid item xs={12} md={6} lg={4} key={index}>
                    <Photo
                      imageUrl={image.url}
                      onClick={e => handlePhotoClick(e, index)}
                    />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Typography variant={"body1"}>
                    {"žádné fotografie nebyly vloženy"}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      ) : (
        <Typography variant={"body1"}>
          {loading ? "načítání..." : "fotogalerie nebyla nalezena"}
        </Typography>
      )}
    </div>
  );
};

export default PhotoGallery;
