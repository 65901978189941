import React, { useEffect } from "react";

import { Typography } from "@material-ui/core";

import MainContentContainer from "common/components/main-content-container";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_EVENT_SPONSOR_LOADER_NAME,
  getEventSponsors
} from "redux/event/actions";
import CustomPage from "../../../../../common/components/customPage/customPage";
import customPageSectionFactory from "../../../../../common/components/customPage/customPageSectionFactory";

const EventSponsors = ({ resourceId: eventId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    handleReload();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, eventId]);

  const handleReload = () => {
    dispatch(getEventSponsors(eventId));
  };

  const { sponsorCustomPage } = useSelector(store => store.EventReducer);

  const loading =
    useSelector(store => store.CommonReducer.loaders)[
      GET_EVENT_SPONSOR_LOADER_NAME
    ] || false;

  const sectionsCount = sponsorCustomPage?.sections?.length || 0;

  return (
    <MainContentContainer showCloser={false}>
      {sponsorCustomPage ? (
        <CustomPage
          customPageId={sponsorCustomPage.id}
          title={sponsorCustomPage.title || ""}
          resourceType={"events"}
          resourceId={eventId}
          onReload={handleReload}
          sectionsCount={sectionsCount}
          noSectionsMessage={"žádný sponsor nebyl zadán"}
          editNameDisabled={true}
          allowSectionTypes={["SponsorPreview"]}
        >
          {sponsorCustomPage?.sections.constructor === Array &&
            sponsorCustomPage.sections.map((section, index) => (
              <div key={index}>
                {customPageSectionFactory({
                  customPageSectionId: section.id,
                  componentName: section.componentName,
                  properties: section.properties,
                  sectionOrder: section.order,
                  resourceType: "events",
                  resourceId: eventId,
                  onReload: handleReload,
                  minOrder: 1,
                  maxOrder: sectionsCount
                })}
              </div>
            ))}
        </CustomPage>
      ) : (
        <Typography variant={"body1"}>
          {loading ? "načítání..." : "Omlouváme se, došlo k chybě."}
        </Typography>
      )}
    </MainContentContainer>
  );
};

export default EventSponsors;
