// @flow

import React from "react";

// material-ui
// import { Typography } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import SearchCardTemplate from "../../../../common/components/search/searchCardTemplate";
import GroupIcon from "@material-ui/icons/Group";
// const useStyles = makeStyles(theme => ({
// }));

/**
 * Flow types
 */
type Props = {
  id: number,
  title: string,
  subtitle: string,
  imageUrl: string
};

const SearchCard = (props: Props) => {
  // const classes = useStyles();
  const { id, title, subtitle, imageUrl } = props;

  return (
    <SearchCardTemplate
      linkUrl={`/clubs/${id}/summary`}
      title={title}
      subtitle={subtitle}
      imageUrl={imageUrl}
      bottomPanelItems={
        [
          // <Typography variant="body1" title={"místo konání"}>
          //   {eventVenue}
          // </Typography>,
          // <Typography variant="body1" title={"datum konání"}>
          //   {eventDate.toLocaleString("cs")}
          // </Typography>
        ]
      }
      defaultMediaComponent={GroupIcon}
    />
  );
};

export default SearchCard;
