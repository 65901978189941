// @flow

import axios from "utils/axios";
import { ThunkAction } from "redux-thunk";

import * as types from "./types";

// notification-actions
import { enqueueSnackbar } from "redux/notification/notification-actions";
import { enableLoader, disableLoader } from "../common/actions";
import {datetime_from_server} from "../../utils/datetime";

export const GET_ARTICLES_LOADER_NAME = "getArticleLoader";

/**
 *
 */
export const getArticles = (
  resourceType: string,
  resourceId: number,
  limit: number = 16
) => {
  return async (dispatch: ThunkAction) => {
    let resourceTypeUrl = null;
    switch (resourceType) {
      case "events":
        resourceTypeUrl = "events";
        break;
      case "clubs":
        resourceTypeUrl = "clubs";
        break;
      default:
        throw Error("Unsupported resource type ", resourceType);
    }
    try {
      dispatch(enableLoader(GET_ARTICLES_LOADER_NAME));

      const response = await axios({
        url: "/v1/" + resourceTypeUrl + "/" + resourceId + "/articles",
        method: "get",
        // data: {
        //   limit: limit
        // },
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch({
        type: types.GET_ARTICLES,
        payload: response.data.articles.map(article => {
          return {
            id: article.id,
            title: article.title,
            content: article.content,
            created: datetime_from_server(article.created),
            author: article.author,
            isPublic: article.isPublic,
            images: article.images
          };
        })
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání článků došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    } finally {
      dispatch(disableLoader(GET_ARTICLES_LOADER_NAME));
    }
  };
};

export const resetArticleState = () => {
  return async (dispatch: ThunkAction) => {
    dispatch({
      type: types.RESET_ARTICLE_STATE
    });
  };
};

/*
 * Edits article with given attributes and images and dispatches doneDispatch if editing was completely finished.
 */
export const editArticle = ({
  articleId,
  resourceType,
  resourceId,
  values,
  formName,
  onSuccess,
  imageIds
}) => {
  return async (dispatch: ThunkAction) => {
    try {
      await axios({
        url: `/v1/articles/${articleId}`,
        method: "post",
        data: {
          title: values.title,
          content: values.content,
          imageIds: imageIds
        },
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch(
        enqueueSnackbar({
          message: "Úprava článku proběhla úspěšně.",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success"
          }
        })
      );
      if (onSuccess) dispatch(onSuccess);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu ukládání článku došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

/*
 * Creates article with given attributes and images and dispatches doneDispatch if creating was completely finished.
 */
export const createArticle = ({
  resourceType,
  resourceId,
  values,
  formName,
  onSuccess,
  imageIds
}) => {
  return async (dispatch: ThunkAction) => {
    try {
      await axios({
        url: `/v1/${resourceType}/${resourceId}/articles`,
        method: "put",
        data: {
          title: values.title,
          content: values.content,
          imageIds: imageIds
        },
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch(
        enqueueSnackbar({
          message: "Vytvoření článku proběhlo úspěšně.",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success"
          }
        })
      );
      if (onSuccess) dispatch(onSuccess);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu vytváření článku došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

/*
 * Removes given article and dispatches doneDispatch if removing was completely finished.
 */
export const removeArticle = (id, onSuccess) => {
  return async (dispatch: ThunkAction) => {
    try {
      await axios({
        url: `/v1/articles/${id}/`,
        method: "delete",
        validateStatus: status => {
          return status >= 200 && status <= 304;
        }
      });
      dispatch(
        enqueueSnackbar({
          message: "Smazání článku proběhlo úspěšně.",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success"
          }
        })
      );
      if (onSuccess) dispatch(onSuccess);
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu mazání článku došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};
