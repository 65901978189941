// @flow

import * as types from "./types";

/**
 * Flow types
 */
type State = {
  articles: Array
};

/**
 * Initial auth state
 */
const INITIAL_STATE = {
  articles: null
};

/**
 * Photo gallery reducer
 */
const ArticleReducer = (state: State = INITIAL_STATE, action: Object) => {
  switch (action.type) {
    case types.GET_ARTICLES:
      return {
        ...state,
        articles: action.payload
      };
    // case types.CHANGE_ARTICLE:
    //   newArticles = [...state.articles];
    //   const index = newArticles.findIndex(
    //     element => element.id === action.payload.articleId
    //   );
    //   newArticles[index].html = action.payload.newContent;
    //   return {
    //     ...state,
    //     articles: newArticles
    //   };
    // case types.SHOW_ARTICLE_EDITOR:
    //   let { articleId, shown } = action.payload;
    //   newState = {
    //     ...state,
    //     articleEditors: {
    //       ...state.articleEditors
    //     }
    //   };
    //   newState.articleEditors[articleId] = shown;
    //   return newState;
    // case types.CREATE_NEW_ARTICLE:
    //   if (
    //     state.articles.constructor === Array &&
    //     state.articles.length > 0 &&
    //     state.articles[0].id === 0
    //   )
    //     return state;
    //   newArticles = [...state.articles];
    //   newArticles.unshift({
    //     id: 0,
    //     author: { fullname: action.payload.author_fullname }
    //   });
    //   return {
    //     ...state,
    //     articles: newArticles
    //   };
    // case types.REMOVE_NEW_ARTICLE:
    //   if (
    //     state.articles.constructor === Array &&
    //     state.articles.length > 0 &&
    //     state.articles[0].id === 0
    //   ) {
    //     newArticles = [...state.articles];
    //     newArticles.shift();
    //     return {
    //       ...state,
    //       articles: newArticles
    //     };
    //   }
    //   return state;
    case types.RESET_ARTICLE_STATE:
      return {
        ...state,
        articles: INITIAL_STATE.articles
      };
    default:
      return state;
  }
};

export default ArticleReducer;
