import React from "react";
import {
  ClickAwayListener,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  IconButton
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AddIcon from "@material-ui/icons/Add";
import { openConfirmationModal } from "../../../../redux/common/actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    marginRight: theme.spacing(1)
  },
  popper: {
    zIndex: 1000
  },
  menuIcon: {
    marginRight: theme.spacing(1)
  },
  withBackground: {
    backgroundColor: "#EEEEEEAA"
  }
}));

/* Pozor tato SectionAction se pouziva i pro akce custom stránek, vlastně uplně všude. */
const SectionAction = ({
  inPreviewPage = false,
  title,
  onEdit,
  onRemove,
  removeConfirmationTitle,
  removeConfirmationMessage,
  onAdd,
  onAddTitle
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleMenuItemClick = callback => {
    callback();
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleRemove = () => {
    if (removeConfirmationTitle || removeConfirmationMessage) {
      dispatch(
        openConfirmationModal(
          removeConfirmationTitle || "Odstranit",
          removeConfirmationMessage || "Opravdu si to přejete odstranit?",
          onRemove
        )
      );
    } else {
      onRemove();
    }
  };

  return onEdit || onRemove || onAdd ? (
    <div className={classes.root}>
      <IconButton
        color={inPreviewPage ? "primary" : "default"}
        classes={inPreviewPage ? { root: classes.withBackground } : undefined}
        title={title}
        onClick={handleToggle}
        ref={anchorRef}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={"bottom-end"}
        className={classes.popper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "left top" : "left bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {onEdit && (
                    <MenuItem onClick={() => handleMenuItemClick(onEdit)}>
                      <EditIcon className={classes.menuIcon} />
                      Upravit
                    </MenuItem>
                  )}
                  {onRemove && (
                    <MenuItem onClick={() => handleMenuItemClick(handleRemove)}>
                      <DeleteIcon className={classes.menuIcon} /> Odstranit
                    </MenuItem>
                  )}
                  {onAdd && (
                    <MenuItem onClick={() => handleMenuItemClick(onAdd)}>
                      <AddIcon className={classes.menuIcon} />{" "}
                      {onAddTitle || "Přidat"}
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  ) : null;
};

export default SectionAction;
