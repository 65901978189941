// event components
import EventContact from "pages/contentPages/eventPages/sub-pages/contact";
import EventSummary from "pages/contentPages/eventPages/sub-pages/summary";
import EventCompetitor from "pages/contentPages/eventPages/sub-pages/competitor";
import EventPhotoGallery from "pages/contentPages/eventPages/sub-pages/photo-gallery";
import EventProposition from "pages/contentPages/eventPages/sub-pages/proposition";
import EventSponsors from "pages/contentPages/eventPages/sub-pages/sponsors";
import EventNews from "pages/contentPages/eventPages/sub-pages/news";
import PhotoIcon from "@material-ui/icons/Photo";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import PeopleIcon from "@material-ui/icons/People";
import DescriptionIcon from "@material-ui/icons/Description";
import DvrIcon from "@material-ui/icons/Dvr";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AnnouncementIcon from "@material-ui/icons/Announcement";

export const getRoutes = (id, linkPrefix, pathPrefix) => {
  return [
    {
      id: id,
      name: "Přehled",
      link: `${linkPrefix}/`,
      icon: DvrIcon,
      exact: true,
      path: `${pathPrefix}/`,
      component: EventSummary
    },
    {
      id: id,
      name: "Novinky",
      link: `${linkPrefix}/news`,
      icon: AnnouncementIcon,
      exact: true,
      path: `${pathPrefix}/news`,
      component: EventNews
    },
    {
      id: id,
      name: "Propozice",
      link: `${linkPrefix}/proposition`,
      icon: DescriptionIcon,
      exact: true,
      path: `${pathPrefix}/proposition`,
      component: EventProposition
    },
    {
      id: id,
      name: "Účastníci",
      link: `${linkPrefix}/competitor`,
      icon: PeopleIcon,
      exact: true,
      path: `${pathPrefix}/competitor`,
      component: EventCompetitor
    },
    {
      id: id,
      name: "Sponzoři",
      link: `${linkPrefix}/sponsors`,
      icon: AttachMoneyIcon,
      exact: true,
      path: `${pathPrefix}/sponsors`,
      component: EventSponsors
    },
    {
      id: id,
      name: "Fotogalerie",
      link: `${linkPrefix}/photo-galleries`,
      icon: PhotoIcon,
      exact: true,
      path: `${pathPrefix}/photo-galleries/:photoGalleryId?`,
      component: EventPhotoGallery
    },
    {
      id: id,
      name: "Kontakt",
      link: `${linkPrefix}/contact`,
      icon: ContactMailIcon,
      exact: true,
      path: `${pathPrefix}/contact`,
      component: EventContact
    }
  ];
};
