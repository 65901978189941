import React from "react";
import { Grid } from "@material-ui/core";

import { Form } from "formik";
import EmailField from "./fields/email";
import PasswordField from "./fields/password";
import FormActions from "../../../common/components/forms/actions";

const LoginFormLayout = ({
  values,
  errors,
  formDiagnostics,
  touched,
  isSubmitting,
}) => {
  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EmailField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12}>
          <PasswordField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
      </Grid>
      <FormActions
        isSubmitting={isSubmitting}
        errors={errors}
        onSubmitButtonText={"Přihlásit"}
        onSubmitButtonTextSubmitting={"Přihlašování"}
        // onClose={onClose}
      />

      {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(errors, null, 2)}</pre>*/}
    </Form>
  );
};

export default LoginFormLayout;
