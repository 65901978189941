// @flow

// react + router
import React, { useEffect } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";

// action
import { useDispatch, useSelector } from "react-redux";
import {
  putRegister,
  setVerificationEmailSentDialogOpen
} from "redux/auth/actions";

// material-ui
import { makeStyles, Typography, Container, Grid } from "@material-ui/core";

// components
import { resetFormDiagnostics } from "../../../redux/common/actions";
import VerificationEmailSentDialog from "./verificationEmailSentDialog";
import DateFnsUtils from "@date-io/date-fns";
import cs from "date-fns/locale/cs";
import { Formik } from "formik";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as Yup from "yup";
import { YUP_EMAIL_REQUIRED } from "../login-components/fields/email";
import { YUP_PASSWORD_REQUIRED } from "../login-components/fields/password";
import RegisterFormLayout from "./formLayout";
import { YUP_FIRST_NAME_REQUIRED } from "./fields/firstName";
import { YUP_LAST_NAME_REQUIRED } from "./fields/lastName";
import { YUP_CITY } from "./fields/city";
import { YUP_STREET } from "./fields/street";
import { YUP_ZIP_NUMBER } from "./fields/zipNumber";
import { YUP_PHONE_NUMBER } from "./fields/phoneNumber";
import { YUP_BIRTH_DATE } from "../../../common/components/forms/fields/birthDate";
import { YUP_GDPR_APPROVE_REQUIRED } from "./fields/gdprApprove";

const FORM_NAME = "RegisterForm";
export const SUBMITTING_FORM_LOADER = "SUBMITTING_REGISTER_FORM_LOADER";

const validationSchema = Yup.object().shape({
  email: YUP_EMAIL_REQUIRED,
  password: YUP_PASSWORD_REQUIRED,
  firstName: YUP_FIRST_NAME_REQUIRED,
  lastName: YUP_LAST_NAME_REQUIRED,
  city: YUP_CITY,
  street: YUP_STREET,
  zipNumber: YUP_ZIP_NUMBER,
  phoneNumber: YUP_PHONE_NUMBER,
  gdprApprove: YUP_GDPR_APPROVE_REQUIRED,
  birthDate: YUP_BIRTH_DATE
});

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: "900",
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    fontSize: 13,
    color: "grey"
  },
  header: {
    marginBottom: theme.spacing(1)
  },
  otherActionContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

type Props = {
  history: Object
};

const RegisterForm = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      await dispatch(resetFormDiagnostics("registerForm"));
    })();
  }, [dispatch]);

  const formDiagnostics =
    useSelector(state => state.CommonReducer.formDiagnostics)[FORM_NAME] || {};

  const isSubmitting = false;

  const verificationEmailSentDialogOpen = useSelector(
    state => state.AuthReducer.verificationEmailSentDialogOpen
  );

  const closeVerificationEmailSentDialog = () => {
    dispatch(setVerificationEmailSentDialogOpen(false));
    props.history.push("/login");
  };

  const handleRegisterSuccess = () => {
    dispatch(setVerificationEmailSentDialogOpen(true));
  };

  return (
    <Container component="main" maxWidth="md">
      <VerificationEmailSentDialog
        open={verificationEmailSentDialogOpen}
        onClose={closeVerificationEmailSentDialog}
      />
      <div className={classes.header}>
        <Typography className={classes.title} variant="h5">
          Registrace Kynologove.cz
        </Typography>
        <Typography className={classes.subtitle} variant="caption">
          Informační systém pro kynology, výcvikové kluby a jejich akce.
        </Typography>
      </div>

      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
        <Formik
          initialValues={{
            email: "",
            password: "",
            firstName: "",
            lastName: "",
            city: "",
            street: "",
            zipNumber: "",
            phoneNumber: "",
            birthDate: null,
            gdprApprove: false
          }}
          onSubmit={async values => {
            dispatch(
              putRegister({
                values,
                formName: FORM_NAME,
                onSuccess: handleRegisterSuccess
              })
            );
          }}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, setFieldValue }) => {
            return (
              <RegisterFormLayout
                errors={errors}
                isSubmitting={isSubmitting}
                touched={touched}
                formDiagnostics={formDiagnostics}
                values={values}
                setFieldValue={setFieldValue}
              />
            );
          }}
        </Formik>
      </MuiPickersUtilsProvider>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.otherActionContainer}>
            <Typography variant="body2">Nechcete se teď přihlásit?</Typography>
            <RouterLink to="/">
              <Typography variant="body2">pokračujte bez přihlášení</Typography>
            </RouterLink>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.otherActionContainer}>
            <Typography variant="body2">Už máte účet?</Typography>
            <RouterLink to="/login">
              <Typography variant="body2">přihlášte se</Typography>
            </RouterLink>
          </div>
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*  <div className={classes.otherActionContainer}>*/}
        {/*    <Typography variant="body2">*/}
        {/*      Zapomněli jste heslo nebo se nemůžete přihlásit?*/}
        {/*    </Typography>*/}
        {/*    <RouterLink to="/reset-password-request">*/}
        {/*      <Typography variant="body2">obnovte zapomenuté heslo</Typography>*/}
        {/*    </RouterLink>*/}
        {/*  </div>*/}
        {/*</Grid>*/}
      </Grid>
    </Container>
  );
};

export default withRouter(RegisterForm);
