import React, { useEffect } from "react";
import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import {
  GET_ARTICLES_LOADER_NAME,
  getArticles
} from "../../../../redux/article/actions";
import { useDispatch, useSelector } from "react-redux";
import Article from "./article";
import ModalFormWrapper from "../../modalForm/wrapper";
import ArticleCreateForm from "./forms/create";
// import SectionAction from "../components/sectionAction";
import AuthorizedOnly from "../../authorization/authorizedOnly";
import { closeModal, openModal } from "../../../../redux/common/actions";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  editButton: {
    position: "absolute",
    right: theme.spacing(3),
    top: theme.spacing(3)
  }
}));

const createArticleModalName = "ARTICLE_CREATE_MODAL_NAME"; // TODO: presunout asi do ModalFormWrapper nebo nekde do te slozky

const Articles = ({ resourceType, resourceId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    reload();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, resourceType, resourceId]);

  const articles = useSelector(store => store.ArticleReducer.articles);
  const loading =
    useSelector(store => store.CommonReducer.loaders)[
      GET_ARTICLES_LOADER_NAME
    ] || false;

  const handleCreateFormClose = () => {
    dispatch(closeModal(createArticleModalName));
  };

  const handleCreateFormOpen = () => {
    dispatch(openModal(createArticleModalName));
  };

  const handleNewArticleCreated = () => {
    handleCreateFormClose();
    reload();
  };

  const reload = () => {
    dispatch(getArticles(resourceType, resourceId));
  };

  return (
    <div className={classes.root}>
      <AuthorizedOnly
        expectedRole={"isResourceAdmin"}
        resourceType={resourceType}
        resourceId={resourceId}
      >
        <ModalFormWrapper
          modalName={createArticleModalName}
          title={"Přidat novinku"}
        >
          <ArticleCreateForm
            initialValues={{ title: "", content: "" }}
            actualImages={[]}
            onClose={handleCreateFormClose}
            onSubmitSuccessfully={handleNewArticleCreated}
            resourceType={resourceType}
            resourceId={resourceId}
          />
        </ModalFormWrapper>

        <div className={classes.editButton}>
          {/*<SectionAction*/}
          {/*  title={"Správa novinek"}*/}
          {/*  onAdd={handleCreateFormOpen}*/}
          {/*  onAddTitle={"Přidat novinku"}*/}
          {/*/>*/}
          <IconButton
            // variant={"outlined"}
            color={"primary"}
            title={"Přidat novinku"}
            onClick={handleCreateFormOpen}
          >
            <AddIcon />
          </IconButton>
        </div>
      </AuthorizedOnly>
      {articles?.length > 0 ? (
        articles.map((article, index) => (
          <Box marginBottom={5} key={index}>
            <Article
              id={article.id}
              title={article.title}
              content={article.content}
              created={article.created}
              author={article.author}
              images={article.images}
              resourceType={resourceType}
              resourceId={resourceId}
              onChange={reload}
            />
          </Box>
        ))
      ) : (
        <Typography variant={"body1"}>
          {loading ? "načítání..." : "žádná novinka zatím nebyla zveřejněna"}
        </Typography>
      )}
    </div>
  );
};

export default Articles;
