import React from "react";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import {
  Typography
  // makeStyles,
} from "@material-ui/core";

import { NOT_SET_PLACEHOLDER } from "../../../constants";
import SearchCardTemplate from "../search/searchCardTemplate";
import { printAddressString } from "../../../utils/dataTransformation";
// const useStyles = makeStyles(theme => ({
// }));

const UserProfilePreview = ({
  id,
  name,
  surname,
  address,
  email,
  phoneNumber,
  roles,
  imageUrl,
  showContactFields,
  onEditButtonClick,
  onRemoveButtonClick
}) => {
  // const classes = useStyles();

  return (
    <>
      <SearchCardTemplate
        linkUrl={null}
        title={`${name} ${surname}`}
        subtitle={null}
        imageUrl={imageUrl}
        bottomPanelItems={null}
        defaultMediaComponent={imageUrl ? undefined : PersonOutlineIcon}
        onEditButtonClick={onEditButtonClick}
        onRemoveButtonClick={onRemoveButtonClick}
      >
        {showContactFields && address ? (
          <Typography variant="body1">
            <strong>adresa:</strong>{" "}
            {address
              ? printAddressString(
                  address.street,
                  address.zipNumber,
                  address.city
                ) || NOT_SET_PLACEHOLDER
              : NOT_SET_PLACEHOLDER}
          </Typography>
        ) : null}
        {showContactFields && phoneNumber ? (
          <Typography variant="body1">
            <strong>telefon:</strong> {phoneNumber || NOT_SET_PLACEHOLDER}
          </Typography>
        ) : null}
        {showContactFields && email ? (
          <Typography variant="body1">
            <strong>email: </strong>
            {email ? (
              <a
                href="mailto:capounivo@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {email}
              </a>
            ) : (
              NOT_SET_PLACEHOLDER
            )}
          </Typography>
        ) : null}
        {roles && roles.constructor === Array && (
          <Typography variant="body1">
            <strong>funkce:</strong> {roles.join(", ")}
          </Typography>
        )}
      </SearchCardTemplate>
    </>
  );
};

export default UserProfilePreview;
