// @flow

import axios from "utils/axios";
import { ThunkAction } from "redux-thunk";

import * as types from "redux/auth/types";

// notification-actions
import { enqueueSnackbar } from "redux/notification/notification-actions";
import { appendFormDiagnostics, resetFormDiagnostics } from "../common/actions";
import {
  VERIFICATION_STATUS_NOT_VERIFIED,
  VERIFICATION_STATUS_VERIFIED_SUCCESSFULLY
} from "../../constants/verification_status";

/**
 * POST login request to `/v1/login`
 * @param {string} email user email from <LoginForm />
 * @param {string} password user password from <LoginForm />
 * @param {Function} onSuccess ...
 */
export const postLogin = ({ values, formName, onSuccess }) => {
  return async (dispatch: ThunkAction) => {
    dispatch(resetFormDiagnostics(formName));
    try {
      const response = await axios({
        url: "/v1/login",
        method: "post",
        data: {
          username: values.email,
          password: values.password
        },
        validateStatus: status => {
          return status >= 200 && status < 304;
        }
      });
      dispatch({
        type: types.SET_LOG_IN,
        payload: {
          userId: response.data.userId,
          fullname: response.data.fullname
        }
      });
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu přihlašování došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
      throw error;
    }
  };
};

/**
 * POST logout request to `/v1/logout`
 */
export const postLogout = (callback: Function = null) => {
  return async (dispatch: ThunkAction) => {
    try {
      await axios({
        url: "/v1/logout",
        method: "post",
        validateStatus: status => {
          return status >= 200 && status < 304;
        }
      });
      dispatch({
        type: types.SET_LOG_OUT
      });
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu odhlašování došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

/**
 * GET auth request from `/v1/auth`
 */
export const getAuth = (callback: ?Function) => {
  return async (dispatch: ThunkAction) => {
    try {
      const response = await axios({
        method: "get",
        url: "/v1/auth",
        validateStatus: status => {
          return (status >= 200 && status < 304) || status === 401;
        }
      });
      if (response.status === 401) {
        dispatch({
          type: types.SET_LOG_OUT
        });
      } else {
        if (!response.data.userId || !response.data.fullname)
          throw Error("Attribute userId or fullname is missing in response.");
        dispatch({
          type: types.SET_LOG_IN,
          payload: {
            userId: response.data.userId,
            fullname: response.data.fullname
          }
        });

        // Get roles...
        dispatch(authorizeGlobal());

        if (typeof callback == "function") {
          callback();
        }
      }
    } catch (error) {
      dispatch({
        type: types.SET_LOG_OUT
      });
      // dispatch(
      //   enqueueSnackbar({
      //     message: "Neplatné přihlášení, přihlašte se znovu!",
      //     options: {
      //       key: new Date().getTime() + Math.random(),
      //       variant: "info"
      //     }
      //   })
      // );
    } finally {
      dispatch(setFirstCheckDone());
    }
  };
};

/**
 * PUT register request to `authserver/v1/join/`
 * @param {Register} data new user data
 */
export const putRegister = ({ values, formName, onSuccess }) => {
  return async (dispatch: ThunkAction) => {
    dispatch(resetFormDiagnostics(formName));
    try {
      const response = await axios({
        method: "put",
        url: "/v1/register",
        validateStatus: status => {
          return (status >= 200 && status < 304) || status === 400;
        },
        data: {
          username: values.email,
          password: values.password,
          name: values.firstName,
          surname: values.lastName,
          street: values.street,
          city: values.city,
          zipNumber: values.zipNumber,
          phoneNumber: values.phoneNumber,
          birthDate: values.birthDate
            ? values.birthDate.toISOString().slice(0, 10)
            : null
        }
      });
      if (response.status !== 400) {
        if (onSuccess) onSuccess();
      }
      if (response.status === 400) {
        dispatch(appendFormDiagnostics(formName, response.data.diagnostics));
      } else {
        // dispatch(resetFormDiagnostics(formName)); // dela se na začátku, asi není potřeba tady
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu registrace došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

export const setFirstCheckDone = () => {
  return async (dispatch: ThunkAction) => {
    // try {
    dispatch({
      type: types.FIRST_CHECK_DONE
    });
    // } catch (error) {
    // dispatch(
    //   enqueueSnackbar({
    //     message: "V průběhu odhlašování došlo k chybě!",
    //     options: {
    //       key: new Date().getTime() + Math.random(),
    //       variant: "error"
    //     }
    //   })
    // );
    // }
  };
};

export const setVerificationEmailSentDialogOpen = open => {
  return async (dispatch: ThunkAction) => {
    await dispatch({
      type: types.SET_VERIFICATION_EMAIL_SENT_DIALOG_OPEN,
      payload: open
    });
  };
};

export const verifyEmail = activationHash => {
  return async (dispatch: ThunkAction) => {
    try {
      const response = await axios({
        method: "post",
        url: `/v1/email-verification`,
        validateStatus: status => {
          return (status >= 200 && status < 304) || status === 400;
        },
        data: {
          activationHash: activationHash
        }
      });
      dispatch(
        setEmailVerificationStatus(
          response.status === 200
            ? VERIFICATION_STATUS_VERIFIED_SUCCESSFULLY
            : VERIFICATION_STATUS_NOT_VERIFIED
        )
      );
    } catch (error) {
      dispatch(setEmailVerificationStatus(VERIFICATION_STATUS_NOT_VERIFIED));
      dispatch(
        enqueueSnackbar({
          message: "V průběhu ověřování emailu došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

export const resetPasswordRequest = ({ values, formName, onSuccess }) => {
  return async (dispatch: ThunkAction) => {
    dispatch(resetFormDiagnostics(formName));
    try {
      const response = await axios({
        method: "post",
        url: `/v1/reset-password-request/`,
        validateStatus: status => {
          return (status >= 200 && status < 304) || status === 400;
        },
        data: {
          email: values.email
        }
      });
      if (response.status !== 400) {
        dispatch(
          enqueueSnackbar({
            message: "Email pro obnovení hesla byl úspěsně odeslán.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success"
            }
          })
        );
        if (onSuccess) onSuccess();
      } else {
        dispatch(appendFormDiagnostics(formName, response.data.diagnostics));
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message:
            "V průběhu odesílání emailu pro obnovení hesla došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

export const resetPassword = ({
  values,
  activationHash,
  formName,
  onSuccess
}) => {
  return async (dispatch: ThunkAction) => {
    dispatch(resetFormDiagnostics(formName));
    try {
      const response = await axios({
        method: "post",
        url: `/v1/reset-password/`,
        validateStatus: status => {
          return (status >= 200 && status < 304) || status === 400;
        },
        data: {
          password: values.password,
          activationHash
        }
      });
      if (response.status !== 400) {
        dispatch(
          enqueueSnackbar({
            message: "Obnovení hesla proběhlo úspěsně.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success"
            }
          })
        );
        if (onSuccess) onSuccess();
      } else {
        dispatch(appendFormDiagnostics(formName, response.data.diagnostics));
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu obnovení hesla došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

export const finishAccountInvitation = ({
  values,
  activationHash,
  formName,
  onSuccess
}) => {
  return async (dispatch: ThunkAction) => {
    dispatch(resetFormDiagnostics(formName));
    try {
      const response = await axios({
        method: "post",
        url: `/v1/finish-account-invitation/`,
        validateStatus: status => {
          return (status >= 200 && status < 304) || status === 400;
        },
        data: {
          password: values.password,
          activationHash
        }
      });
      if (response.status !== 400) {
        dispatch(
          enqueueSnackbar({
            message: "Pozvání do aplikace proběhlo úspěsně.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success"
            }
          })
        );
        if (onSuccess) onSuccess();
      } else {
        dispatch(appendFormDiagnostics(formName, response.data.diagnostics));
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu dokončení pozvání do aplikace došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

export const setEmailVerificationStatus = status => {
  return async (dispatch: ThunkAction) => {
    await dispatch({
      type: types.SET_EMAIL_VERIFICATION_STATUS,
      payload: status
    });
  };
};

export const authorizeGlobal = () => {
  return async (dispatch: ThunkAction) => {
    try {
      const response = await axios({
        method: "get",
        url: `/v1/authorize/global`,
        validateStatus: status => {
          return status >= 200 && status < 304;
        },
        data: {}
      });
      await dispatch({
        type: types.SET_GLOBAL_AUTHORIZATION,
        payload: {
          isSystemAdmin: response.data.isSystemAdmin
        }
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu ověřování globálních práv došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};

export const authorizeResource = (
  resourceType: ?string,
  resourceId: ?number
) => {
  return async (dispatch: ThunkAction) => {
    try {
      const response = await axios({
        method: "get",
        url: `/v1/authorize/resource/${resourceType}/${resourceId}`,
        validateStatus: status => {
          return status >= 200 && status < 304;
        },
        data: {}
      });
      await dispatch({
        type: types.SET_RESOURCE_AUTHORIZATION,
        payload: {
          resourceType: response.data.resourceType,
          resourceId: response.data.resourceId,
          isResourceMember: response.data.isResourceMember,
          isResourceAdmin: response.data.isResourceAdmin
        }
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu ověřování práv k tomuto obsahu došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
  };
};
