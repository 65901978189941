import React, { useEffect } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MomentUtils from "@date-io/moment";
import cs from "date-fns/locale/cs";

import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { resetFormDiagnostics } from "../../../../../../redux/common/actions";
import {
  loadAllPossibleEventCategories,
  submitEventSummaryEditForm,
  SUBMITTING_EVENT_SUMMARY_EDIT_FORM_LOADER
} from "../../../../../../redux/event/actions";
import { YUP_SHORT_DESCRIPTION } from "./fields/shortDescription";
import { YUP_TERM_REQUIRED } from "./fields/term";
import { YUP_DEADLINE_REQUIRED } from "./fields/deadline";
import { YUP_PRICE } from "./fields/price";
import { YUP_NAME_REQUIRED } from "./fields/name";
import EventSummaryCreateEditFormLayout from "./formLayout";
import { YUP_CATEGORIES } from "./fields/categories";

export const EVENT_SUMMARY_EDIT_FORM_NAME = "EVENT_SUMMARY_EDIT_FORM_NAME";
export const EVENT_SUMMARY_EDIT_FORM_MODAL_NAME =
  "EVENT_SUMMARY_EDIT_FORM_MODAL_NAME";

const validationSchema = Yup.object().shape({
  name: YUP_NAME_REQUIRED,
  shortDescription: YUP_SHORT_DESCRIPTION,
  // venue: Yup.string()
  //   .min(0, TOO_SHORT)
  //   .max(100, TOO_LONG),
  term: YUP_TERM_REQUIRED,
  deadline: YUP_DEADLINE_REQUIRED,
  price: YUP_PRICE,
  // helpers:
  // judges:
  categories: YUP_CATEGORIES
});

const EventSummaryEditForm = ({
  eventId,
  initialValues,
  onClose,
  actualProfileImageUrl,
  onSubmitSuccessfully
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(resetFormDiagnostics(EVENT_SUMMARY_EDIT_FORM_NAME));
      // TODO: list categories by event type
      dispatch(loadAllPossibleEventCategories({ eventId }));
    })();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [eventId]);

  const allPossibleEventCategories = useSelector(
    state => state.EventReducer.allPossibleEventCategories
  );

  const loaders = useSelector(state => state.CommonReducer.loaders);
  const isSubmitting =
    loaders[SUBMITTING_EVENT_SUMMARY_EDIT_FORM_LOADER] || false;
  const formDiagnostics =
    useSelector(state => state.CommonReducer.formDiagnostics)[
      EVENT_SUMMARY_EDIT_FORM_NAME
    ] || {};

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
        <Formik
          initialValues={initialValues}
          onSubmit={async values => {
            dispatch(
              submitEventSummaryEditForm({
                eventId,
                values,
                formName: EVENT_SUMMARY_EDIT_FORM_NAME,
                modalName: EVENT_SUMMARY_EDIT_FORM_MODAL_NAME,
                onSuccess: onSubmitSuccessfully
              })
            );
          }}
          validationSchema={validationSchema}
          // validateOnChange={true}
          // validateOnBlur={}
        >
          {({ values, errors, touched, submitForm, setFieldValue }) => {
            return (
              <EventSummaryCreateEditFormLayout
                values={values}
                actualProfileImageUrl={actualProfileImageUrl}
                errors={errors}
                formDiagnostics={formDiagnostics}
                touched={touched}
                isSubmitting={isSubmitting}
                onClose={onClose}
                setFieldValue={setFieldValue}
                createForm={false}
                allPossibleEventCategories={allPossibleEventCategories}
              />
            );
          }}
        </Formik>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default EventSummaryEditForm;
