import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LONG,
  TOO_SHORT
} from "../../../../constants/dictionary/validation";
import { InputAdornment } from "@material-ui/core";
import { Person } from "@material-ui/icons";

const FIELD_NAME = "firstName";

export const YUP_FIRST_NAME = Yup.string()
  .min(2, TOO_SHORT)
  .max(100, TOO_LONG);

export const YUP_FIRST_NAME_REQUIRED = YUP_FIRST_NAME.required(REQUIRED);

const FirstNameField = ({ baseParams }) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      autoComplete="given-name"
      label="Jméno"
      fullWidth={true}
      type="text"
      placeholder="Zde vložte Vaše jméno"
      inputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Person />
          </InputAdornment>
        )
      }}
      baseParams={baseParams}
    />
  );
};

export default FirstNameField;
