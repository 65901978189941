import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { Drawer, Hidden, Divider } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
// components
import DrawerUser from "pages/main-page/menu-component/drawer-menu/drawerUser";
import DrawerItems from "pages/main-page/menu-component/drawer-menu/drawerItems";
import { getMenuInfo } from "../../../../redux/menu/actions";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: 250,
      flexShrink: 0
    }
  },
  drawerPaper: {
    width: 250
  }
}));

const DrawerMenu = ({
  items,
  mobileOpen,
  handleDrawerToggle,
  resourceType,
  useMobileVersion = false
}) => {
  const classes = useStyles();
  let { id } = useParams();
  const customDomain = useSelector(state => state.CommonReducer.customDomain);

  if (id !== undefined) {
    id = parseInt(id);
  } else {
    id = customDomain.resourceId;
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMenuInfo(resourceType, id));
  }, [dispatch, id, resourceType]);

  const menuInfo = useSelector(state => state.MenuReducer.info);
  const loader =
    useSelector(state => state.CommonReducer.loaders)["MENU_GET_INFO"] || false;

  const onClickItem = () => {
    handleDrawerToggle(false);
  };

  /**
   * Return <DraweItems />
   */
  const getDrawerItems = () => {
    return (
      <>
        <DrawerUser
          name={menuInfo && !loader ? menuInfo.title : null}
          info={menuInfo && !loader ? menuInfo.subtitle : null}
          avatar={menuInfo && !loader ? menuInfo.imageUrl : null}
          resourceType={resourceType}
        />
        <Divider />
        <DrawerItems items={items} onClickItem={onClickItem} />
        <Divider />
      </>
    );
  };

  const drawerItems = getDrawerItems();

  return (
    <nav
      className={!useMobileVersion ? classes.drawer : undefined}
      aria-label="preview profile"
    >
      {/* HIDDEN PART SHOW ONLY DESKTOP MODE*/}
      <Hidden smUp={!useMobileVersion} implementation="css">
        <Drawer
          open={mobileOpen}
          onClose={handleDrawerToggle}
          variant="temporary"
          ModalProps={{ keepMounted: true }}
          classes={{ paper: classes.drawerPaper }}
        >
          {drawerItems}
        </Drawer>
      </Hidden>
      {/* HIDDEN PART SHOW ONLY IN MOBILE MODE */}
      {!useMobileVersion && (
        <Hidden xsDown implementation="css">
          <Drawer
            open
            variant="permanent"
            classes={{ paper: classes.drawerPaper }}
          >
            {drawerItems}
          </Drawer>
        </Hidden>
      )}
    </nav>
  );
};

export default DrawerMenu;
