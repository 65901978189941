// @flow

import * as types from "redux/notification/types";

/**
 * Flow types
 */
type Options = {
  key: number,
  variant: "error" | "info" | "warning" | "error" | "success"
};
type Notification = {
  message: string,
  options: Options
};

/**
 * Push new notification into `notification.store`
 * @param {Notification} notification new notification to show
 */
export const enqueueSnackbar = (notification: Notification) => {
  const key = notification.options && notification.options.key;

  return {
    type: types.ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random()
    }
  };
};

/**
 * Remove notification from `notification.store`
 * @param {number} key notification id
 */
export const removeSnackbar = (key: number) => {
  return {
    type: types.REMOVE_SNACKBAR,
    key
  };
};
