import React, { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import ControlledHtmlEditor from "../../htmlEditor/controled";
import Moment from "react-moment";
import Image from "./image";
import { useDispatch } from "react-redux";
import { removeArticle } from "redux/article/actions";
import ModalFormWrapper from "../../modalForm/wrapper";
import SectionAction from "../components/sectionAction";
import AuthorizedOnly from "../../authorization/authorizedOnly";
import ArticleEditForm from "./forms/edit";
import { closeModal, openModal } from "../../../../redux/common/actions";
import { rawDraftJsStringToHtml } from "../../draftjsEditor/utils";
import GalleryPreview from "../../galleryPreview";
import MyPaper from "../../page/myPaper";

const editArticleModalNamePrefix = "ARTICLE_EDIT_MODAL_NAME_"; // TODO: presunout asi do ModalFormWrapper nebo nekde do te slozky

const useStyles = makeStyles(theme => ({
  editButton: {
    marginRight: theme.spacing(1)
  },
  content: {
    fontSize: "1rem",
    textAlign: "justify"
  },
  images: {
    display: "flex",
    flexFlow: "wrap",
    marginTop: theme.spacing(5)
  },
  footerItem: {
    marginRight: theme.spacing(3)
  }
}));

const Article = ({
  id,
  title,
  content,
  created,
  author,
  images,
  resourceType,
  resourceId,
  onChange
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const editArticleModalName = editArticleModalNamePrefix + id.toString();

  const photoGalleryName = `newsPhotoGalleryModalName${id}`;

  const handleEditFormClose = () => {
    dispatch(closeModal(editArticleModalName));
  };
  const handleEditFormOpen = () => {
    dispatch(openModal(editArticleModalName));
  };

  const handleSubmitSuccessfully = () => {
    handleEditFormClose();
    onChange();
  };

  const handleRemoveArticleConfirm = () => {
    dispatch(removeArticle(id, onChange));
  };

  const [clickedImageIndex, setClickedImageIndex] = useState(0);

  const handlePhotoClick = (e, index) => {
    e.preventDefault();
    setClickedImageIndex(index);
    dispatch(openModal(photoGalleryName));
  };

  return (
    <MyPaper
      title={title}
      actions={
        <>
          <AuthorizedOnly
            expectedRole={"isResourceAdmin"}
            resourceType={resourceType}
            resourceId={resourceId}
          >
            <ModalFormWrapper
              modalName={editArticleModalName}
              title={"Upravit novinku"}
            >
              <ArticleEditForm
                articleId={id}
                initialValues={{ title: title, content: content }}
                actualImages={images || []}
                onClose={handleEditFormClose}
                onSubmitSuccessfully={handleSubmitSuccessfully}
                resourceType={resourceType}
                resourceId={resourceId}
              />
            </ModalFormWrapper>

            <div className={classes.editButton}>
              <SectionAction
                title={"Správa novinky"}
                onEdit={handleEditFormOpen}
                onRemove={handleRemoveArticleConfirm}
                removeConfirmationTitle={"Odstranit novinku"}
                removeConfirmationMessage={`Opravdu si přejete odstranit novinku s nadpisem '${title}'?`}
              />
            </div>
          </AuthorizedOnly>
        </>
      }
      body={
        <>
          <ControlledHtmlEditor
            editorShown={false}
            html={rawDraftJsStringToHtml(content)}
            contentClass={classes.content}
          />
          {images?.length > 0 && (
            <div className={classes.images}>
              {images.map((image, index) => (
                <Image
                  key={index}
                  imageId={image.id}
                  url={image.url}
                  onClick={e => handlePhotoClick(e, index)}
                />
              ))}
              <ModalFormWrapper
                modalName={photoGalleryName}
                maxWidth={"xl"}
                fullScreen={true}
                showCloseButton={true}
                paddingLeftRightOnly={true}
              >
                <GalleryPreview
                  images={
                    images?.length > 0 &&
                    images.map(image => ({
                      original: image.url,
                      thumbnail: image.url
                    }))
                  }
                  index={clickedImageIndex}
                />
              </ModalFormWrapper>
            </div>
          )}
        </>
      }
      footer={[
        <Typography variant={"body2"} className={classes.footerItem} key={1}>
          Vytvořeno: {<Moment date={created} />}
        </Typography>,
        <Typography variant={"body2"} className={classes.footerItem} key={2}>
          Autor: {author.fullname}
        </Typography>
      ]}
      footerRight={true}
    />
  );
};

export default Article;
