// @flow

import React, { useEffect, useState } from "react";

// material-ui
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

// components
import SearchBar from "../../../../common/components/search/searchBar";
import SearchCard from "pages/contentPages/eventPages/search/searchCard";
import MainTemplate from "../../../main-page/menu-component/mainTemplate";
import MainContentContainer from "../../../../common/components/main-content-container";
import { useDispatch, useSelector } from "react-redux";
import { getEvents } from "../../../../redux/event/actions";
import ModalFormWrapper from "../../../../common/components/modalForm/wrapper";
import Button from "@material-ui/core/Button";
import AuthorizedOnly from "../../../../common/components/authorization/authorizedOnly";
import EventSummaryCreateForm, {
  EVENT_SUMMARY_CREATE_FORM_MODAL_NAME
} from "../sub-pages/summary/forms/createForm";
import { closeModal, openModal } from "../../../../redux/common/actions";

const useStyles = makeStyles(theme => ({
  content: {
    marginTop: theme.spacing(2)
  },
  searchBar: {
    marginBottom: theme.spacing(1)
  },
  editButton: {
    position: "absolute",
    right: theme.spacing(3),
    top: theme.spacing(3)
  },
  editButtonSpace: {
    marginBottom: theme.spacing(7)
  }
}));

const EventSearch = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [search, setSearch] = useState<string>("");

  const { customDomain } = useSelector(state => state.CommonReducer);
  const domain = customDomain ? customDomain.domain : null;

  const reloadEvents = () => {
    dispatch(getEvents({ customDomain: domain })); // TODO  filter on the server: {name: search}
  };

  useEffect(() => {
    reloadEvents();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [domain]);

  const events = useSelector(state => state.EventReducer.events);

  // Easy search all events by name in the browser.
  const events_to_show = events.filter(event =>
    search ? event.name.includes(search) : true
  );

  const handleCloseEventSummaryCreateModal = () => {
    dispatch(closeModal(EVENT_SUMMARY_CREATE_FORM_MODAL_NAME));
  };
  const handleOpenEventSummaryCreateModal = () => {
    dispatch(openModal(EVENT_SUMMARY_CREATE_FORM_MODAL_NAME));
  };

  return (
    <MainTemplate>
      <MainContentContainer showCloser={true} relative={true}>
        <AuthorizedOnly expectedRole={"isSystemAdmin"}>
          <div className={classes.editButtonSpace}> </div>
          <ModalFormWrapper
            modalName={EVENT_SUMMARY_CREATE_FORM_MODAL_NAME}
            title={"Vytvořit akci"}
          >
            <EventSummaryCreateForm
              eventId={undefined}
              initialValues={{
                name: "",
                shortDescription: "",
                // venue: event.venue,
                term: null,
                deadline: null,
                price: "",
                helpers: [],
                judges: [],
                categories: [],
                organizerClub: ""
              }}
              onClose={handleCloseEventSummaryCreateModal}
              actualProfileImageUrl={null}
              onSubmitSuccessfully={reloadEvents}
            />
          </ModalFormWrapper>
          <Button
            className={classes.editButton}
            onClick={handleOpenEventSummaryCreateModal}
            color={"primary"}
            variant={"outlined"}
          >
            Přidat akci
          </Button>
        </AuthorizedOnly>
        <Grid container spacing={3} className={classes.content}>
          <Grid item xs={12} className={classes.searchBar}>
            <SearchBar
              search={search}
              setSearch={setSearch}
              placeholder={"Pro vyhledání zadejte název akce"}
            />
          </Grid>
          {events_to_show.map((event, index) => (
            <Grid item xs={12} lg={6} key={index}>
              <SearchCard
                id={event.id}
                title={event.name}
                subtitle={event.shortDescription}
                eventDate={event.term}
                eventVenue={event.venue}
                imageUrl={event.imageUrl}
                deleted={event.term < Date.now()}
              />
            </Grid>
          ))}
        </Grid>
      </MainContentContainer>
    </MainTemplate>
  );
};

export default EventSearch;
