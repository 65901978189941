import React from "react";
// import { TextField } from "formik-material-ui";
// import FieldBase from "../../../../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LOW
} from "../../../../../../../constants/dictionary/validation";
import SearchBox from "../../../../../../../common/components/forms/searchBox";
// import { InputAdornment } from "@material-ui/core";
// import {Home, Person} from "@material-ui/icons";

const FIELD_NAME = "parentOrganizationId";

export const YUP_PARENT_ORGANIZATION_ID = Yup.number()
  .nullable(true)
  .min(0, TOO_LOW);

export const YUP_PARENT_ORGANIZATION_ID_REQUIRED = YUP_PARENT_ORGANIZATION_ID.required(
  REQUIRED
);

const ParentOrganizationIdField = ({ values, setFieldValue, baseParams }) => {
  return (
    <SearchBox
      label={"Nadřazená organizace"}
      placeholder={"Vyberte nadřazenou organizaci"}
      initialOptions={[
        { label: "žádná", value: 0 },
        { label: "MSKS", value: 3 }
      ]}
      getOptionSelected={(option, value) => {
        return option.value === value.value;
      }}
      onChange={(e, value, result) => {
        setFieldValue(FIELD_NAME, value?.value || 0);
      }}
      defaultValue={{
        value: values[FIELD_NAME] || 0,
        label: values[FIELD_NAME] === 3 ? "MSKS" : "žádná"
      }}
    />
    // {/*<InputLabel htmlFor="parentOrganizationInput">*/}
    // {/*  Nadřazená organizace*/}
    // {/*</InputLabel>*/}
    // {/*<Field*/}
    // {/*  required*/}
    // {/*  component={FormikSelect}*/}
    // {/*  name="parentOrganizationId"*/}
    // {/*  className={classes.textInput}*/}
    // {/*  fullWidth={true}*/}
    // {/*  inputProps={{*/}
    // {/*    id: "parentOrganizationInput"*/}
    // {/*  }}*/}
    // {/*  // helperText={localCombineFormErrorMessages("parentOrganizationInput")} // TODO: nezobrazuje se u tohoto selectboxu*/}
    // {/*  error={*/}
    // {/*    !!localCombineFormErrorMessages("parentOrganizationId")*/}
    // {/*  }*/}
    // {/*>*/}
    // {/*  <MenuItem value={0}>žádná</MenuItem>*/}
    // {/*  <MenuItem value={3}>MSKS</MenuItem>*/}
    // {/*</Field>*/}
  );
};

export default ParentOrganizationIdField;
