import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { rawDraftJsStringToState } from "./utils";

const DraftJsEditor = ({
  initialState,
  initialStateRaw,
  onEditorStateChange
}) => {
  const _initialState =
    initialState || rawDraftJsStringToState(initialStateRaw);

  return (
    <Editor
      editorStyle={{
        borderRadius: "2px",
        border: "1px solid #F1F1F1"
      }}
      defaultEditorState={_initialState}
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        options: [
          "inline",
          // "blockType",
          // "fontSize",
          // "fontFamily",
          "list",
          "textAlign",
          // "colorPicker",
          "link",
          // "embedded",
          // "emoji",
          // "image",
          "remove"
          // "history"
        ],
        inline: {
          options: [
            "bold",
            "italic",
            "underline",
            "strikethrough"
            // "monospace",
            // "superscript",
            // "subscript"
          ]
        },
        list: {
          options: ["unordered", "ordered"]
        },
        textAlign: { inDropdown: false },
        link: { inDropdown: false }
        // history: { inDropdown: true }
      }}
      localization={{
        translations: {
          "generic.add": "Přidat",
          "generic.cancel": "Zrušit",
          // inline
          "components.controls.inline.bold": "Tučně",
          "components.controls.inline.italic": "Kurzíva",
          "components.controls.inline.underline": "Podtrženě",
          "components.controls.inline.strikethrough": "Přeškrtnutě",
          // Odkazy
          "components.controls.link.linkTitle": "Zobrazovaný text odkazu",
          "components.controls.link.linkTarget": "cílová url",
          "components.controls.link.linkTargetOption":
            "Otevřít odkaz v novém okně",
          "components.controls.link.link": "Odkaz",
          "components.controls.link.unlink": "Zrušit odkaz",
          // List
          "components.controls.list.list": "Seznam",
          "components.controls.list.unordered": "Odrážky",
          "components.controls.list.ordered": "Číslované odrážky",
          // "components.controls.list.indent": "Indent",
          // "components.controls.list.outdent": "Outdent",

          // Remove
          "components.controls.remove.remove": "Vymazat formátování",

          // TextAlign
          "components.controls.textalign.textalign": "Zarovnání textu",
          "components.controls.textalign.left": "Vlevo",
          "components.controls.textalign.center": "Na střed",
          "components.controls.textalign.right": "Vpravo",
          "components.controls.textalign.justify": "Do bloku"
        }
      }}
    />
  );
};

export default DraftJsEditor;
