import React from "react";
import SearchBox from "./searchBox";

const DogSearchBox = ({
  initialValues,
  onChange,
  label,
  placeholder,
  multiple = true,
  disabled = false
}) => {
  const handleChange = (e, value, result) => {
    let changedValue;
    if (multiple) {
      changedValue = value.map(option => {
        if (typeof option === "string") {
          return { value: option, creatable: true };
        }
        return option;
      });
    } else {
      if (typeof value === "string") {
        changedValue = { value: value, creatable: true };
      } else {
        changedValue = value;
      }
    }
    onChange(changedValue);
  };

  return (
    <SearchBox
      label={label}
      placeholder={placeholder}
      getOptionSelected={(option, value) => {
        return option.value === value.value;
      }}
      requestUrl={`/v1/dogs/fulltext-search/`}
      requestMethod={"POST"}
      transformResponse={responseData =>
        responseData.dogs.map(dog => ({
          value: `${dog.fullName} (id: ${dog.id})`,
          dogId: dog.id
        }))
      }
      transformRequestData={value => ({ fulltext: value })}
      requestErrorMessage={"V průběhu vyhledání psů došlo k chybě."}
      onChange={handleChange}
      creatable={true}
      freeSolo={true}
      multiple={multiple}
      defaultValue={
        multiple
          ? initialValues?.length
            ? initialValues.map(dog => ({
                ...dog,
                value: dog.fullName
              }))
            : []
          : { ...initialValues, value: initialValues?.fullName }
      }
      filterOptionsFilterEnabled={false}
      disabled={disabled}
    />
  );
};

export default DogSearchBox;
