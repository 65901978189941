import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LONG,
  TOO_SHORT
} from "../../../../../../constants/dictionary/validation";

const FIELD_NAME = "title";

export const YUP_TITLE = Yup.string()
  .min(2, TOO_SHORT)
  .max(100, TOO_LONG);

export const YUP_TITLE_REQUIRED = YUP_TITLE.required(REQUIRED);

const TitleField = ({ baseParams }) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      label="Nadpis novinky"
      fullWidth={true}
      type="text"
      placeholder="Zde vložte nadpis novinky"
      baseParams={baseParams}
    />
  );
};

export default TitleField;
