import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { Grid, Typography } from "@material-ui/core";

import CategoryCard from "./categoryCard";
import SectionPreview from "../../../common/components/customPage/sectionPreview";

import eventLogo from "./images/event.jpg";
import trainingClubLogo from "./images/trainingClub.jpg";
import Link from "@material-ui/core/Link";
import CarouselPreview from "../../../common/components/customPage/carouselPreview";
import MediaCardsPreview from "../../../common/components/customPage/mediaCardsPreview";
import PricesLogo from "./pricesLogo";
import previewPreviewPage1 from "./images/preview-preview-page-1.png";
import previewPreviewPage2 from "./images/preview-preview-page-2.png";
import previewPreviewPage3 from "./images/preview-preview-page-3.png";
import previewPhotoGalleries from "./images/preview-photogalleries.png";
import previewPhotoGallery from "./images/preview-photogallery.png";
import previewContact from "./images/preview-contact.png";
import dataTransferImage from "./images/dataTransfer.svg";
import TimerIcon from "@material-ui/icons/Timer";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import UpdateIcon from "@material-ui/icons/Update";
import DevicesIcon from "@material-ui/icons/Devices";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import BuildIcon from "@material-ui/icons/Build";
import Section from "../../../common/components/customPage/section";

const useStyles = makeStyles(theme => ({
  root: {},
  media: {
    height: 300
  },
  mainText: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textAlign: "justify"
  },
  sectionsTitle: {
    marginBottom: theme.spacing(1)
  },
  fullWidth: {
    width: "100%"
  },
  mediaCardImageFullSize: {
    width: "60%",
    height: "60%"
  },
  mediaCardImageWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    flexDirection: "row"
  }
}));

const MainPage = () => {
  const classes = useStyles();

  return (
    <div>
      <SectionPreview
        title={"Kynologove.cz"}
        subtitle={
          "Informační systém pro kynology, výcvikové kluby a jejich akce."
        }
        fullHeight={true}
      >
        <Grid container spacing={4}>
          <Grid item md={6} className={classes.fullWidth}>
            <CategoryCard
              name={"Výcvikové kluby"}
              description={
                "Sekce výcvikových klubů Vám umožní nejen najít ten pravý cvičák ve Vašem okolím, " +
                "ale také Vám nabízí informace o Vašich oblíbených klubech na jednom místě."
              }
              imageUrl={trainingClubLogo}
              imageTitle={"výcvikové kluby"}
              navigationPath={"/clubs"}
            />
          </Grid>
          <Grid item md={6} className={classes.fullWidth}>
            <CategoryCard
              name={"Akce"}
              description={
                "Díky této sekci Vám už neunikne žádná akce! Všechny akce jsou na jednom místě."
                // a navíc snadné přihlašování.
              }
              imageUrl={eventLogo}
              imageTitle={"akce"}
              navigationPath={"/events"}
            />
          </Grid>
        </Grid>
      </SectionPreview>
      <SectionPreview
        title={"Líbí se Vám tento systém?"}
        subtitle={
          "Chtěli byste ho pro svůj klub nebo akci využít? Pokačujte dále!"
        }
        fullHeight={false}
      />

      <MediaCardsPreview
        parameters={{
          title: "Výhody, které rádi oceníte",
          fullHeight: true,
          mediaItems: [
            {
              title: "Rychlost a jednoduchost",
              centerTitle: true,
              description:
                "Rychlé a snadné vytvoření nových webových stránek klubů a akcí.",
              // cardMediaComponent: TimerIcon
              cardMediaChildren: (
                <div className={classes.mediaCardImageWrapper}>
                  <TimerIcon
                    color={"primary"}
                    className={classes.mediaCardImageFullSize}
                  />
                </div>
              )
            },
            {
              title: "Snadná údržba",
              centerTitle: true,
              description:
                "Snadná údržba stránek klubu bez jakýchkoliv IT znalostí.",
              cardMediaChildren: (
                <div className={classes.mediaCardImageWrapper}>
                  <BuildIcon
                    color={"primary"}
                    className={classes.mediaCardImageFullSize}
                  />
                </div>
              )
            },
            {
              title: "Nízká cena",
              centerTitle: true,
              description: "Nízká cena díky hromadnému řešení pro více klubů.",
              cardMediaChildren: (
                <div className={classes.mediaCardImageWrapper}>
                  <TrendingDownIcon
                    color={"primary"}
                    className={classes.mediaCardImageFullSize}
                  />
                </div>
              )
            },
            {
              title: "Automatické aktualizace",
              centerTitle: true,
              description:
                "Průběžné aktualizace systému bez Vašeho zásahu zajistí bezpečný provoz.",
              cardMediaChildren: (
                <div className={classes.mediaCardImageWrapper}>
                  <UpdateIcon
                    color={"primary"}
                    className={classes.mediaCardImageFullSize}
                  />
                </div>
              )
            },
            {
              title: "Responsivní design",
              centerTitle: true,
              description:
                "Má návštěvník velký monitor nebo malý telefon? Nevadí. Web se přizpůsobí všem velikostem obrazovek.",
              cardMediaChildren: (
                <div className={classes.mediaCardImageWrapper}>
                  <DevicesIcon
                    color={"primary"}
                    className={classes.mediaCardImageFullSize}
                  />
                </div>
              )
            },
            {
              title: "Se vším Vám rádi pomůžeme",
              centerTitle: true,
              description:
                "Plná uživatelská podpora v případě, že si s něčím nevíte rady.",
              cardMediaChildren: (
                <div className={classes.mediaCardImageWrapper}>
                  <HelpOutlineIcon
                    color={"primary"}
                    className={classes.mediaCardImageFullSize}
                  />
                </div>
              )
            }
          ]
        }}
      />

      <Section
        title={"Převod dat z Vašich původních stránek zdarma"}
        fullHeight={false}
        content={
          "<p>Důvodů k přechodu z Vašich původních webových stránek může být hned několik. " +
          "Jsou už starší, nemoderní, nevzhledné? Nesplňují už dnešní zabezpečení pomocí HTTPS protokolu? " +
          "Nebaví Vás kvůli každé změně se přihlašovat do administrace stránek a pracně měnit obsah?</p>" +
          "<p>Vytvořte si stránky zde a zbavte se všech problémů, zmíněných v přechozím odstavci. " +
          "Abychom společně docílili plynulého přechodu, s Vaším svolením překopírujeme některá data do nově vytvořeného webu zde. " +
          "Kopírujeme především fotografie, novinky, články a také stránky s Vašim obsahem.</p>" +
          "<p>Protože ne vždy dává smysl převádět všechna data z dávných let, převádíme jen ty nejnovější. " +
          "Tak, aby si návštěvník co nejméně všiml, že jste tyto stránky právě vytvořili.</p>" +
          "<p>Převod Vás nestojí žádný čas, o vše se postaráme my.</p>"
        }
        // imageUrl={"http://localhost:3000/v1/images/310.jpg"}
        imageUrl={dataTransferImage}
      />

      <SectionPreview
        title={"Máte zájem tento systém využít?"}
        subtitle={
          <span>
            Kontaktujte nás prosím na emailové adrese{" "}
            <Link
              component={"a"}
              href={"mailto:kynologove@gmail.com"}
              target={"_blank"}
            >
              kynologove@gmail.com
            </Link>
            .
          </span>
        }
        fullHeight={false}
      />

      {/*<SectionPreview title={"Popis systému"} fullHeight={true}>*/}
      {/*  <Typography variant="body1" className={classes.mainText}>*/}
      {/*    Vítejte v informačním systému Kynologové.cz. Na začátek bychom Vám*/}
      {/*    chtěli sdělit, proč tento systém vznikl, co aktuálně umí a jaký je náš*/}
      {/*    cíl.*/}
      {/*  </Typography>*/}
      {/*  <Typography variant="body1" className={classes.mainText}>*/}
      {/*    Nejprve jsem byl požádán o vytvoření stránek klubu, ve kterém jsem byl*/}
      {/*    členem. Dále jsem měl za úkol vytvořit stránky závodu jiného klubu a*/}
      {/*    nakonec byl požádán i o vytvoření stránek toho klubu. Problémem všech*/}
      {/*    těchto narychlo vytvořených webových stránek je příliš složitá údržba,*/}
      {/*    nejednotné zpracování a především to, že takto vytvořené stránky*/}
      {/*    nemohl spravovat nikdo bez pokročilejších znalostí tvorby webových*/}
      {/*    stránek. Tedy věškeré požadavky ze strany klubů jsem musel zpracovávat*/}
      {/*    já. Tyto a další negativa nás přivedly k myšlence vytvoření jednoho*/}
      {/*    informačního systému, který by všechny tyto negativa minimalizoval a*/}
      {/*    některá úplně odstranil. Pojďme se podívat na to, jaká pozitiva má*/}
      {/*    toto řešení pro nás i pro Vás. Nám odpadá nutnost řešit stejné potřeby*/}
      {/*    pro několik klubů stále dokola, v případě změny nebo chyby jsme*/}
      {/*    schopni ovlivnit webové stránky všech klubů najednou. Díky tomu se*/}
      {/*    můžeme více věnovat dalším rozšířením systému a proto je tento*/}
      {/*    jednotný systém mnohem lepší než několik samostatných. Hlavní výhody*/}
      {/*    má tento informační systém pro Vás uživatele. Největší benefity pro*/}
      {/*    kluby jsou:*/}
      {/*  </Typography>*/}
      {/*  <ul>*/}
      {/*    <li>*/}
      {/*      <Typography variant={"body1"}>*/}
      {/*        rychlé a snadné vytvoření nových webových stránek*/}
      {/*      </Typography>*/}
      {/*    </li>*/}
      {/*    <li>*/}
      {/*      <Typography variant={"body1"}>*/}
      {/*        snadná údržba kýmkoliv z členů klubu (dle oprávnění) bez*/}
      {/*        jakýchkoliv IT znalostí*/}
      {/*      </Typography>*/}
      {/*    </li>*/}
      {/*    <li>*/}
      {/*      <Typography variant={"body1"}>*/}
      {/*        nízká cena díky hromadnému řešení pro více klubů*/}
      {/*      </Typography>*/}
      {/*    </li>*/}
      {/*    <li>*/}
      {/*      <Typography variant={"body1"}>*/}
      {/*        průběžné aktualizace systému bez Vašeho zásahu*/}
      {/*      </Typography>*/}
      {/*    </li>*/}
      {/*    <li>*/}
      {/*      <Typography variant={"body1"}>*/}
      {/*        přizpůsobené pro mobilní zařízení (telefony, tablety)*/}
      {/*      </Typography>*/}
      {/*    </li>*/}
      {/*    <li>*/}
      {/*      <Typography variant={"body1"}>*/}
      {/*        plná podpora v případě, že si s něčím nevíte rady*/}
      {/*      </Typography>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*  <Typography variant="body1" className={classes.mainText}>*/}
      {/*    Chvíli jsme s tímto systémem měli pouze skromné plány. Systém měl*/}
      {/*    sloužit pouze těm dvěma klubům, které vytvoření stránek přímo po mně*/}
      {/*    požadovaly a pro jejich závody. Vytvořením systému pro dva kluby by*/}
      {/*    byly webové stránky klubů jednodušší, neinvestovalo by se do toho*/}
      {/*    tolik času a cena by byla pochopitelně také vyšší (za více peněz méně*/}
      {/*    možností). Po tom, co jsem si prošel několik webů klubů, rozhodl jsem*/}
      {/*    se pro aktuální řešení tohoto systému. Tedy umožnit všem kynologickým*/}
      {/*    klubům, které budou mít zájem, tento systém využít a vyřešit tak*/}
      {/*    navždy svoje problémy s webovými stránkami. Většina z aktuálních*/}
      {/*    klubových webových stránek je nefukční, nezabezpečená, nepodporují*/}
      {/*    zobrazení na mobilních zařízeních, hrají všemi barvami až nekdy nezle*/}
      {/*    přečíst samotný text, příliš komplikované vyhledání informací a tak*/}
      {/*    dále. Sami určitě znáte nějaké stránky s těmito problémy.*/}
      {/*  </Typography>*/}
      {/*  <Typography variant="body1" className={classes.mainText}>*/}
      {/*    Další oblastí, kterou se bude systém primárně zabývat je vytváření,*/}
      {/*    vyhledávání a přihlašování na kynologické akce. Tento problém už je v*/}
      {/*    několika kynologických odvětvích vyřešen, ale ve sportovní kynologii*/}
      {/*    jako takové stále řešení chybí. Věděli jste, že jedním z častých*/}
      {/*    důvodů proč se člověk nepřihlásí na závod (nebo také zkoušky,*/}
      {/*    víkendovky apod.) je, že tento člověk o akci nevěděl, ačkoliv se na ni*/}
      {/*    připravoval a měl v plánu tam jet. Není to škoda? Vytvořením závodu*/}
      {/*    pomocí tohoto systému byste tento problém minimalizovali. Úplné řešení*/}
      {/*    se zatím připravuje, ale už jen to, že bude několik akcí různých klubů*/}
      {/*    zde na jednom místě, usnadní lidem práci při hledání Vaší akce. Každý,*/}
      {/*    kdo již organizoval nějakou akci například závod, zkoušky nebo se na*/}
      {/*    tuto akci přihlašoval, ví kolik to všechno stojí práce. Ale proč? Proč*/}
      {/*    vyplňovat přihlašky v různých dokumentech? Proč má každý klub jinou*/}
      {/*    přihlášku? Proč ty přihlášky ručně zpracovávat? Proč ručně oznamovat*/}
      {/*    přijetí přihlášky a v horším případě neoznamovat vůbec? Pokud jste si*/}
      {/*    některé z těchto otázek položili, je čas začít přemýšlet o využití*/}
      {/*    tohoto systému.*/}
      {/*  </Typography>*/}
      {/*  <Typography variant="body1" className={classes.mainText}>*/}
      {/*    V této chvíli je tento systém ve fázi vývoje a testování, ale už nyní*/}
      {/*    ho můžete začít využívat i vy pro Váš klub. Už nyní Vám umožníme*/}
      {/*    prezentovat Vaše informace pomocí tohoto systému. Omezení je zatím*/}
      {/*    takové, že nemůžete sami spravovat (upravovat, vytvářet) stránky*/}
      {/*    Vašich akcí v tomto systému. Veškeré požadavky zatím děláme ručně za*/}
      {/*    Vás na základě emailové (případně telefonické) komunikace. Ale to Vám*/}
      {/*    nebrání zbavit se Vašich aktuálních webových stránek a přejít na nové*/}
      {/*    v našem systému. Prioritně pracujeme na odstranění techto omezení a*/}
      {/*    tešíme se na plnohodnotné využití systému. V této fázi je veškerý*/}
      {/*    provoz a používání systému ZDARMA, ale tento stav je pouze dočasný.*/}
      {/*    Podle plánu bychom chtěli v průběhu roku 2021 přejít do placeného*/}
      {/*    režimu. Také aktuálně nabízíme ZDARMA převedení starého webu k nám,*/}
      {/*    které uděláme my za Vás. Převedení se týká omezeného počtu dat z*/}
      {/*    Vašich aktuálních stránek na nové. Jaké množství dat Vám převedeme*/}
      {/*    řešíme individualně s každým klubem zvlášť. A teď už se pojďme podívat*/}
      {/*    na to, čím se můžete pomocí webových stránek vytvořených v tomto*/}
      {/*    systému prezentovat. Toto téma bude jednodušší ukázat přímo na již*/}
      {/*    vytvořených stránkách klubů, které si můžete projít na stránce*/}
      {/*    https://kynologove.cz/clubs. V případě akcí platí to stejné co u klubů*/}
      {/*    s menšími rozdíly a podívat se můžete opět sami na stránce*/}
      {/*    https://kynologove.cz/events.*/}
      {/*  </Typography>*/}
      {/*  <Typography variant="body1" className={classes.mainText}>*/}
      {/*    Hlavním cílem tohoto informačního systému je zjednodušení práce*/}
      {/*    kynologickým organizacím i kynologům samotným. Pomoci má převážně při*/}
      {/*    vyhledávání informací, které budou na jednom místě jednoduše a snadno*/}
      {/*    dostupné všem. Tento cíl nezáleží jen na nás, ale stojí hlavně na*/}
      {/*    spolupráci s ostatními organizacemi. My nabízíme nástroj, obsah*/}
      {/*    tvoříte vy. My se budeme snažit o to, aby byl nástroj co nejlepší a*/}
      {/*    Vás prosíme o to, abyste tento nástroj využívali, jak nejlépe to bude*/}
      {/*    možné, tvořili kvalitní obsah a společně tak dosáhneme požadovaného*/}
      {/*    cíle. K tomu Vám aktuálně poslouží možnost vytvoření kvalitních*/}
      {/*    webových stránek pro Váš klub snadno a rychle, vytváření klubových*/}
      {/*    akcí, s pomocí kterých usnadníte práci Vám i účastníkům akcí. Pokud*/}
      {/*    bude o tento systém zájem, můžeme se v budoucnu těšit na mnoho dalších*/}
      {/*    rozšíření.*/}
      {/*  </Typography>*/}
      {/*  <Typography variant="body1" className={classes.mainText}>*/}
      {/*    // V případě jakýchkoliv dotazů nás prosím kontaktujte na emailu{" "}*/}
      {/*    // <Link*/}
      {/*    //   component={"a"}*/}
      {/*    //   href={"mailto:kynologove@gmail.com"}*/}
      {/*    //   target={"_blank"}*/}
      {/*    // >*/}
      {/*    //   kynologove@gmail.com*/}
      {/*    // </Link>*/}
      {/*    // .*/}
      {/*  </Typography>*/}
      {/*</SectionPreview>*/}
      {/*<CarouselPreview*/}
      {/*  parameters={{*/}
      {/*    title: "Náhledy systému",*/}
      {/*    mediaItems: [*/}
      {/*      { image: { url: previewPreviewPage1 } },*/}
      {/*      { image: { url: previewPreviewPage2 } },*/}
      {/*      { image: { url: previewPreviewPage3 } },*/}
      {/*      { image: { url: previewPhotoGalleries } },*/}
      {/*      { image: { url: previewPhotoGallery } },*/}
      {/*      { image: { url: previewContact } }*/}
      {/*    ],*/}
      {/*    // bodyBackgroundColor: "#000000",*/}
      {/*    fullHeight: true*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<MediaCardsPreview*/}
      {/*  parameters={{*/}
      {/*    title: "Ceník",*/}
      {/*    fullHeight: true,*/}
      {/*    mediaItems: [*/}
      {/*      {*/}
      {/*        title: "Balíček MINIMUM",*/}
      {/*        centerTitle: true,*/}
      {/*        description: "aktuálně ZDARMA, připravujeme...",*/}
      {/*        cardMediaChildren: <PricesLogo type={"minimum"} />*/}
      {/*      },*/}
      {/*      {*/}
      {/*        title: "Balíček OPTIMUM",*/}
      {/*        centerTitle: true,*/}
      {/*        description: "aktuálně ZDARMA, připravujeme...",*/}
      {/*        cardMediaChildren: <PricesLogo type={"optimum"} />*/}
      {/*      },*/}
      {/*      {*/}
      {/*        title: "Balíček MAXIMUM",*/}
      {/*        centerTitle: true,*/}
      {/*        description: "aktuálně ZDARMA, připravujeme...",*/}
      {/*        cardMediaChildren: <PricesLogo type={"maximum"} />*/}
      {/*      }*/}
      {/*    ]*/}
      {/*  }}*/}
      {/*/>*/}
      <MediaCardsPreview
        parameters={{
          title: "Připravujeme pro Vás",
          fullHeight: false,
          mediaItems: [
            {
              title: "online přihlašování na akce",
              description: "připravujeme..."
              // cardMediaComponent: HourglassEmptyIcon
            },
            {
              title: "další typy akcí",
              description:
                "obrany, zkoušky, výcvikové pobyty apod. připravujeme..."
              // cardMediaComponent: HourglassEmptyIcon
            },
            {
              title: "hodnocení výcvikových klubů",
              description: "připravujeme..."
              // cardMediaComponent: HourglassEmptyIcon
            },
            {
              title: "webové stránky osob a psů",
              description: "připravujeme..."
              // cardMediaComponent: HourglassEmptyIcon
            }
            // {
            //   title: "",
            //   description: "připravujeme..."
            //   // cardMediaComponent: HourglassEmptyIcon
            // }
          ]
        }}
      />
      <MediaCardsPreview
        parameters={{
          title: "Nedávno dokončeno",
          fullHeight: false,
          mediaItems: [
            {
              title: "registrace a přihlašování uživatelů",
              description: "dokončeno"
              // cardMediaComponent: HourglassEmptyIcon
            },
            {
              title: "kompletní správa klubů",
              description: "dokončeno"
              // cardMediaComponent: HourglassEmptyIcon
            },
            {
              title: "kompletní správa akcí",
              description: "dokončeno"
              // cardMediaComponent: HourglassEmptyIcon
            },
            {
              title: "první zkušební kluby a závody",
              description: "dokončeno"
              // cardMediaComponent: HourglassEmptyIcon
            }
          ]
        }}
      />
    </div>
  );
};

export default MainPage;
