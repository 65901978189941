import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LARGE,
  TOO_LOW
} from "../../../../../../../constants/dictionary/validation";

const FIELD_NAME = "pointsA";

export const YUP_POINTS_A = Yup.number()
  .nullable(true)
  .min(0, TOO_LOW)
  .max(100, TOO_LARGE);

export const YUP_POINTS_A_REQUIRED = YUP_POINTS_A.required(REQUIRED);

const PointsAField = ({ baseParams }) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      label="Oddíl A (stopa)"
      fullWidth={true}
      type="number"
      baseParams={baseParams}
    />
  );
};

export default PointsAField;
