import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";

export const rawDraftJsStringToState = rawState => {
  return rawState
    ? // pokud zacina znakem "{" - je to nový draftjs format
      rawState[0] === "{"
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(rawState)))
      : // nezacina znakem "{" - je to původní "html/text"
        EditorState.createWithText(rawState)
    : // neni zadano nebo je prázdné, vytvoříme prázdný state
      EditorState.createEmpty();
};

export const draftJsStateToRawString = state => {
  return JSON.stringify(convertToRaw(state.getCurrentContent()));
};

export const rawDraftJsStringToHtml = rawStateString => {
  // Aktuálně parsuje nový draftjs string i původní "html/text"
  return rawStateString[0] === "{"
    ? draftToHtml(JSON.parse(rawStateString))
    : rawStateString;
};
