import React from "react";
import FieldBase from "../../../../../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LOW
} from "../../../../../../../../constants/dictionary/validation";

const FIELD_NAME = "maximumParticipants";

export const YUP_MAXIMUM_PARTICIPANTS = Yup.number().min(0, TOO_LOW);

export const YUP_MAXIMUM_PARTICIPANTS_REQUIRED = YUP_MAXIMUM_PARTICIPANTS.required(
  REQUIRED
);

const MaximumParticipantsField = ({ baseParams, index }) => {
  return (
    <FieldBase
      name={`categories.${index}.${FIELD_NAME}`}
      label="Max. účastníků"
      fullWidth={true}
      type="number"
      minValue={0}
      placeholder="Maximum účastníků"
      baseParams={baseParams}
    />
  );
};

export default MaximumParticipantsField;
