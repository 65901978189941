import React from "react";
import { useParams } from "react-router-dom";
import MainContentContainer from "common/components/main-content-container";
import PhotoGalleriesContainer from "common/components/photo-gallery/photoGalleriesContainer";
import PhotoGallery from "common/components/photo-gallery/photoGallery";
import MainPageTitle from "common/components/page/mainPageTitle";

const EventPhotoGallery = ({ history, resourceId: eventId, linkPrefix }) => {
  let { photoGalleryId } = useParams();

  return (
    <MainContentContainer showCloser={false}>
      <MainPageTitle>Fotogalerie</MainPageTitle>
      {!photoGalleryId ? (
        <PhotoGalleriesContainer
          resourceType={"events"}
          resourceId={eventId}
          history={history}
          linkPrefix={linkPrefix}
        />
      ) : (
        <PhotoGallery
          photoGalleryId={photoGalleryId}
          resourceType={"events"}
          resourceId={eventId}
          linkPrefix={linkPrefix}
        />
      )}
    </MainContentContainer>
  );
};

export default EventPhotoGallery;
