import React, { useEffect } from "react";

import MainContentContainer from "../../../../../common/components/main-content-container";
import CompetitorCategory from "./category";
import { Box, Typography } from "@material-ui/core";
import MainPageTitle from "common/components/page/mainPageTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_EVENT_COMPETITOR_LOADER_NAME,
  getEventParticipants
} from "../../../../../redux/event/actions";

const EventCompetitor = ({ resourceId: eventId }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    reloadCompetitors();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [eventId]);

  const reloadCompetitors = () => {
    dispatch(getEventParticipants(eventId));
  };

  const categoryParticipants = useSelector(
    state => state.EventReducer.categoryParticipants
  );

  const loading =
    useSelector(store => store.CommonReducer.loaders)[
      GET_EVENT_COMPETITOR_LOADER_NAME
    ] || false;

  return (
    <MainContentContainer showCloser={false}>
      <MainPageTitle>Účastníci</MainPageTitle>
      {categoryParticipants.constructor === Object &&
      Object.keys(categoryParticipants).length ? (
        Object.keys(categoryParticipants).map((categoryName, index) => {
          return (
            <Box marginBottom={8} key={index}>
              <CompetitorCategory
                eventId={eventId}
                categoryName={categoryName}
                participants={categoryParticipants[categoryName]}
                onReload={reloadCompetitors}
              />
            </Box>
          );
        })
      ) : (
        <Typography variant={"body1"}>
          {loading ? "načítání..." : "žádná kategorie nebyla zadána"}
        </Typography>
      )}
    </MainContentContainer>
  );
};

export default EventCompetitor;
