// react
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// import "typeface-roboto";

// redux
import { Provider } from "react-redux";
import store from "redux/store";

// component
import App from "./App";

import {
  MuiThemeProvider as ThemeProvider,
  CssBaseline,
  createMuiTheme,
  responsiveFontSizes
} from "@material-ui/core";

const theme = responsiveFontSizes(
  createMuiTheme({
    typography: {
      fontSize: 14,
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(","),
      subtitle1: {
        fontWeight: 800
      },
      subtitle2: {
        fontWeight: 800
      },
      h3: {
        fontSize: "2.4rem"
      }
    }
    // palette: {
    //   primary: "#1976d2",
    //   secondary: "#dc004e",
    //   error: red
    // }
  })
);

const root = document.getElementById("root");

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ThemeProvider>,
  root
);
