import React, { useRef } from "react";
import JoditEditor from "jodit-react";

const RawHTMLEditor = props => {
  const editor = useRef(null);
  let { content, handleContentChange } = props;

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    language: "cs_cz",
    autofocus: false,
    height: 600,
    image: {
      openOnDblClick: true,
      editSrc: true,
      useImageEditor: true,
      editTitle: true,
      editAlt: true,
      editLink: true,
      editSize: true,
      editBorderRadius: true,
      editMargins: true,
      editClass: false,
      editStyle: false,
      editId: false,
      editAlign: true,
      showPreview: true,
      selectImageAfterClose: true
    },
    inline: true
  };

  return (
    <div>
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        tabIndex={1} // tabIndex of textarea
        onBlur={newContent => handleContentChange(newContent)} // preferred to use only this option to update the content for performance reasons
        onChange={newContent => {}}
      />
    </div>
  );
};

export default RawHTMLEditor;
