import React from "react";
import { Hidden, Typography } from "@material-ui/core";

export default function TableItem({ header, value, isHeader, children }) {
  return (
    <div>
      <Hidden mdUp>
        <Typography variant="subtitle1" component="span">
          {header}
          {": "}
        </Typography>
      </Hidden>
      <Typography variant={isHeader ? "subtitle1" : "body1"} component="span">
        {children}
      </Typography>
    </div>
  );
}
