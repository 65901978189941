// @flow

import React from "react";

// react-router
import { Link as RouterLink } from "react-router-dom";

// material-ui
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Link
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import SectionAction from "../customPage/components/sectionAction";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    position: "relative", // for bottomPanel
    flexGrow: 1, // for full width
    overflow: "hidden",
    paddingBottom: "48px !important" // because of bottom panel
  },
  cover: {
    minWidth: 200,
    height: 200,
    color: "#AAAAAA50",
    marginLeft: "auto",
    marginRight: "auto"
  },
  link: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexGrow: 1
    }
    // maxWidth: "100vw"
  },
  lineClamp: {
    // display: "-webkit-box", // for multiline clamp
    // WebkitLineClamp: 2, // for multiline clamp
    // WebkitBoxOrient: "vertical", // for multiline clamp
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  bottomPanel: {
    display: "flex",
    position: "absolute",
    bottom: 0,
    right: 0,
    marginRight: theme.spacing(1)
  },
  bottomPanelItem: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  deleted: {
    opacity: 0.3
  },
  editButton: {
    float: "right",
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));

/**
 * Flow types
 */
type Props = {
  linkUrl: string,
  title: string,
  subtitle: string,
  imageUrl: string,
  bottomPanelItems: Array
};

const SearchCardTemplate = (props: Props) => {
  const classes = useStyles();
  const {
    linkUrl,
    title,
    subtitle,
    imageUrl,
    children,
    bottomPanelItems,
    defaultMediaComponent,
    deleted,
    onEditButtonClick,
    onRemoveButtonClick
  } = props;

  return (
    <Card className={clsx(classes.root, deleted && classes.deleted)}>
      <div className={classes.editButton}>
        <SectionAction
          title={"akce"}
          onEdit={onEditButtonClick}
          onRemove={onRemoveButtonClick}
        />
      </div>
      <Link
        component={linkUrl ? RouterLink : "div"}
        to={linkUrl}
        underline="none"
        color="inherit"
        className={classes.link}
      >
        <CardMedia
          className={classes.cover}
          image={imageUrl ? imageUrl : "none"} // none because of image or src or children is required, but component is primary!
          title={title}
          component={imageUrl ? undefined : defaultMediaComponent}
        />
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5" gutterBottom={true}>
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.lineClamp}
            title={subtitle && subtitle.length >= 80 ? subtitle : null}
          >
            {subtitle}
          </Typography>
          {children}
          <div className={classes.bottomPanel}>
            {bottomPanelItems
              ? bottomPanelItems.map((bottomPanelItem, index) => (
                  <div className={classes.bottomPanelItem} key={index}>
                    {bottomPanelItem}
                  </div>
                ))
              : null}
          </div>
        </CardContent>
      </Link>
    </Card>
  );
};

export default SearchCardTemplate;
