import MainMultimedia from "../main-multimedia";
import MediaCardsPreview from "./mediaCardsPreview";
import CarouselPreview from "./carouselPreview";
import ContactPreview from "./contactPreview";
import React from "react";
import Section from "./section";
import SponsorPreview from "./sponsorPreview";

const customPageSectionFactory = ({
  customPageSectionId,
  componentName,
  properties,
  sectionOrder,
  resourceType,
  resourceId,
  onReload,
  minOrder = 1,
  maxOrder,
  inPreviewPage = false
}) => {
  switch (componentName) {
    case "MainMultimedia":
      return (
        <MainMultimedia
          title={properties.title}
          subtitle={properties.subtitle}
          image={properties.imageUrl}
          fullSize={properties.fullSize}
          heightWithToolbar={true}
          titleBackgroundColor={properties.titleBackgroundColor}
          titleColor={properties.titleColor}
          subtitleColor={properties.subtitleColor}
        />
      );
    case "MediaCardsPreview":
      return (
        <MediaCardsPreview
          customPageSectionId={customPageSectionId}
          parameters={properties}
          sectionOrder={sectionOrder}
          resourceType={resourceType}
          resourceId={resourceId}
          onReload={onReload}
          minOrder={minOrder}
          maxOrder={maxOrder}
        />
      );
    case "CarouselPreview":
      return (
        <CarouselPreview
          customPageSectionId={customPageSectionId}
          parameters={properties}
          sectionOrder={sectionOrder}
          resourceType={resourceType}
          resourceId={resourceId}
          onReload={onReload}
          minOrder={minOrder}
          maxOrder={maxOrder}
        />
      );
    case "ContactPreview":
      return <ContactPreview parameters={properties} />;
    case "Section":
      return (
        <Section
          customPageSectionId={customPageSectionId}
          // TODO: pass properties as parameters instead of title, fullHeight etc
          title={properties.title || null}
          fullHeight={properties.fullHeight || null}
          imageUrl={properties.image ? properties.image.url : null}
          imageId={properties.image ? properties.image.id : null}
          content={properties.content}
          sectionOrder={sectionOrder}
          resourceType={resourceType}
          resourceId={resourceId}
          onReload={onReload}
          minOrder={minOrder}
          maxOrder={maxOrder}
          inPreviewPage={inPreviewPage}
        />
      );
    case "SponsorPreview":
      return (
        <SponsorPreview
          customPageSectionId={customPageSectionId}
          // TODO: pass properties as parameters instead of title, fullHeight etc
          sponsorName={properties.sponsorName || null}
          sponsorUrl={properties.sponsorUrl || null}
          fullHeight={properties.fullHeight || null}
          imageUrl={properties.image ? properties.image.url : null}
          imageId={properties.image ? properties.image.id : null}
          sectionOrder={sectionOrder}
          resourceType={resourceType}
          resourceId={resourceId}
          onReload={onReload}
          minOrder={minOrder}
          maxOrder={maxOrder}
          inPreviewPage={inPreviewPage}
        />
      );
    default:
      throw Error(`Unexpected component name ${componentName}.`);
  }
};

export default customPageSectionFactory;
