// @flow

// react + router
import React from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import { postLogin } from "redux/auth/actions";

// material-ui
import { makeStyles, Grid, Typography, Container } from "@material-ui/core";

// components
import DateFnsUtils from "@date-io/date-fns";
import cs from "date-fns/locale/cs";
import { Formik } from "formik";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import LoginFormLayout from "./formLayout";
import * as Yup from "yup";
import { YUP_EMAIL_REQUIRED } from "./fields/email";
import { YUP_PASSWORD_REQUIRED } from "./fields/password";

/**
 * Flow types
 */

const FORM_NAME = "LoginForm";
export const SUBMITTING_FORM_LOADER = "SUBMITTING_LOGIN_FORM_LOADER";

const validationSchema = Yup.object().shape({
  email: YUP_EMAIL_REQUIRED,
  password: YUP_PASSWORD_REQUIRED
});

type Props = {
  history: Object
};

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: "900",
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    fontSize: 13,
    color: "grey"
  },
  header: {
    marginBottom: theme.spacing(3)
  },
  otherActionContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const LoginForm = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formDiagnostics =
    useSelector(state => state.CommonReducer.formDiagnostics)[FORM_NAME] || {};

  const isSubmitting = false;

  const handleLoginSuccess = () => {
    props.history.push("/");
  };

  return (
    <Container component="main" maxWidth="md">
      <div className={classes.header}>
        <Typography className={classes.title} variant="h5">
          Kynologove.cz
        </Typography>
        <Typography className={classes.subtitle} variant="caption">
          Informační systém pro kynology, výcvikové kluby a jejich akce.
        </Typography>
      </div>

      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={async values => {
            dispatch(
              postLogin({
                values,
                formName: FORM_NAME,
                onSuccess: handleLoginSuccess
              })
            );
          }}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched }) => {
            return (
              <LoginFormLayout
                errors={errors}
                isSubmitting={isSubmitting}
                touched={touched}
                formDiagnostics={formDiagnostics}
                values={values}
              />
            );
          }}
        </Formik>
      </MuiPickersUtilsProvider>

      <Grid container>
        <Grid item xs={12}>
          <div className={classes.otherActionContainer}>
            <Typography variant="body2">Ještě nemáte účet?</Typography>
            <RouterLink to="register">
              <Typography variant="body2">vytvořte si ho</Typography>
            </RouterLink>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.otherActionContainer}>
            <Typography variant="body2">Nechcete se teď přihlásit?</Typography>
            <RouterLink to="/">
              <Typography variant="body2">pokračujte bez přihlášení</Typography>
            </RouterLink>
          </div>
          <div className={classes.otherActionContainer}>
            <Typography variant="body2">
              Zapomněli jste heslo nebo se nemůžete přihlásit?
            </Typography>
            <RouterLink to="/reset-password-request">
              <Typography variant="body2">obnovte zapomenuté heslo</Typography>
            </RouterLink>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withRouter(LoginForm);
