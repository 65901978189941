import React, { useEffect } from "react";
import {
  Grid,
  makeStyles,
  Button,
  LinearProgress,
  ButtonGroup,
  Typography,
  Link
} from "@material-ui/core";
import { combineFormErrorMessages } from "../../../../utils/diagnostics";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import cs from "date-fns/locale/cs";

import { TextField } from "formik-material-ui";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { submitCustomPageEditForm } from "../../../../redux/customPage/actions";
import {
  closeModal,
  resetFormDiagnostics
} from "../../../../redux/common/actions";
import {
  TOO_LONG,
  TOO_SHORT
} from "../../../../constants/dictionary/validation";
import { CUSTOM_PAGE_FORM_MODAL_NAME_PREFIX } from "./editFormModal";
import { Link as RouterLink } from "react-router-dom";

export const CUSTOM_PAGE_EDIT_FORM_NAME = "CustomPageEditForm";
export const SUBMITTING_CUSTOM_PAGE_EDIT_FORM_LOADER =
  "SUBMITTING_CUSTOM_PAGE_EDIT_FORM_LOADER";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(0, TOO_SHORT)
    .max(100, TOO_LONG)
});

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  textInput: {
    paddingBottom: theme.spacing(2)
  },
  buttonGroup: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  lineProgress: {},
  hiddenInput: {
    display: "none"
  },
  centered: {
    textAlign: "center"
  },
  imageEditButton: {
    marginTop: theme.spacing(1)
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%"
  }
}));

const CustomPageEditForm = ({
  customPageId,
  initialValues,
  onClose,
  onSubmitSuccessfully,
  resourceType,
  resourceId,
  inPreviewPage = false,
  nameDisabled = false
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(resetFormDiagnostics(CUSTOM_PAGE_EDIT_FORM_NAME));
    })();
  }, [dispatch]);

  // const loaders = useSelector(state => state.CommonReducer.loaders);

  const isSubmitting = false;
  // loaders[SUBMITTING_PERSON_PROFILE_EDIT_FORM_LOADER] || false;

  const formDiagnostics =
    useSelector(state => state.CommonReducer.formDiagnostics)[
      CUSTOM_PAGE_EDIT_FORM_NAME
    ] || {};

  const handleCloseModal = () => {
    dispatch(
      closeModal(CUSTOM_PAGE_FORM_MODAL_NAME_PREFIX + customPageId.toString())
    );
  };

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
        <Formik
          initialValues={initialValues}
          onSubmit={async values => {
            dispatch(
              submitCustomPageEditForm({
                customPageId,
                resourceType,
                resourceId,
                values,
                formName: CUSTOM_PAGE_EDIT_FORM_NAME,
                onSuccess: onSubmitSuccessfully,
                loaderName: SUBMITTING_CUSTOM_PAGE_EDIT_FORM_LOADER,
                modalName:
                  CUSTOM_PAGE_FORM_MODAL_NAME_PREFIX + customPageId.toString()
              })
            );
          }}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, submitForm, setFieldValue }) => {
            const localCombineFormErrorMessages = field => {
              return combineFormErrorMessages(errors, formDiagnostics, field);
            };
            return inPreviewPage ? (
              <div>
                <Typography variant={"body1"}>
                  Náhledovou stránku zatím nelze nijak upravovat. Nadpis,
                  podnadpis (krátký popisek) a obrázek můžete změnit v{" "}
                  <Link
                    to={`/${resourceType}/${resourceId}/summary`}
                    component={RouterLink}
                    onClick={handleCloseModal}
                  >
                    přehledu
                  </Link>
                  .
                </Typography>
              </div>
            ) : (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      name="title"
                      label="Nadpis"
                      className={classes.textInput}
                      fullWidth={true}
                      type="text"
                      placeholder="Zde vložte nadpis sekce."
                      helperText={localCombineFormErrorMessages("title")}
                      error={!!localCombineFormErrorMessages("title")}
                      disabled={nameDisabled}
                    />
                  </Grid>
                </Grid>
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                  className={classes.buttonGroup}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                    onClick={submitForm}
                  >
                    {!isSubmitting ? "Uložit" : "Ukládání"}
                  </Button>
                  <Button variant="contained" color="default" onClick={onClose}>
                    Zavřít
                  </Button>
                </ButtonGroup>
                {isSubmitting && (
                  <LinearProgress className={classes.lineProgress} />
                )}
                {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
                {/*<pre>{JSON.stringify(errors, null, 2)}</pre>*/}
              </Form>
            );
          }}
        </Formik>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default CustomPageEditForm;
