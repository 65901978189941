import React from "react";

import { makeStyles, Typography, Paper } from "@material-ui/core";
import Divider from "./divider";

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(2)
  },
  title: {
    paddingTop: theme.spacing(1)
  },
  header: {
    borderTopLeftRadius: theme.spacing(2),
    borderTopRightRadius: theme.spacing(2),
    padding: theme.spacing(1, 2),
    backgroundColor: "#e2f2ff",
    justifyContent: "space-between",
    flexDirection: "row",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  contentWrapper: {
    padding: theme.spacing(2)
  },
  footer: {
    padding: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      display: "flex"
    },
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  footerItem: {
    marginRight: theme.spacing(3)
  },
  right: {
    marginLeft: "auto"
  }
}));

const MyPaper = ({ title, actions, body, footer, footerRight = false }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      {/*Header with title and action buttons*/}
      <div className={classes.header}>
        <Typography variant={"h6"} className={classes.title}>
          {title}
        </Typography>
        {actions ? actions : null}
      </div>
      <Divider noMargin customColor={"#64baff"} />
      {/* Main text and images of article */}
      <div className={classes.contentWrapper}>{body}</div>
      <Divider noColor noMargin />
      {/* Article footer */}
      <div className={classes.footer}>
        {footerRight ? <span className={classes.right} /> : null}
        {footer}
      </div>
    </Paper>
  );
};

export default MyPaper;
