import React from "react";
import * as Yup from "yup";
import { REQUIRED } from "constants/dictionary/validation";
import DateFieldBase from "../../../../common/components/forms/dateFieldBase";

export const FIELD_NAME = "birthDate";

export const YUP_BIRTH_DATE = Yup.date()
  .nullable()
  .default(undefined);

export const YUP_BIRTH_DATE_REQUIRED = YUP_BIRTH_DATE.required(REQUIRED);

const BirthDateField = ({
  value,
  setFieldValue,
  baseParams,
  initialFocusedDate = "1990-01-01",
  disabled
}) => {
  return (
    <DateFieldBase
      name={FIELD_NAME}
      disableFuture
      initialFocusedDate={initialFocusedDate}
      label="Datum narození"
      value={value}
      onChange={newValue => setFieldValue(FIELD_NAME, newValue)}
      baseParams={baseParams}
      disabled={disabled}
    />
  );
};

export default BirthDateField;
