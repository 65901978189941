import React, { useEffect } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import cs from "date-fns/locale/cs";

import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { resetFormDiagnostics } from "../../../../../../redux/common/actions";
import {
  submitClubSummaryCreateEditForm,
  SUBMITTING_CLUB_SUMMARY_EDIT_FORM_LOADER
} from "../../../../../../redux/trainingClub/actions";
import ClubSummaryCreateEditFormLayout from "../forms/formLayout";
import { YUP_NAME_REQUIRED } from "./fields/name";
import { YUP_SHORT_DESCRIPTION } from "./fields/shortDescription";
import { YUP_WEBSITE_URL } from "./fields/websiteUrl";
import { YUP_BASIC_ORGANIZATION_NUMBER } from "./fields/basicOrganizationNumber";
import { YUP_PARENT_ORGANIZATION_ID } from "./fields/parentOrganizetionId";
import { YUP_POSSIBILITIES } from "./fields/possibilities";

const FORM_NAME = "CLUB_SUMMARY_CREATE_FORM_NAME";
export const CLUB_SUMMARY_CREATE_FORM_MODAL_NAME = "ClubSummaryCreateFormModal";

const validationSchema = Yup.object().shape({
  name: YUP_NAME_REQUIRED,
  shortDescription: YUP_SHORT_DESCRIPTION,
  websiteUrl: YUP_WEBSITE_URL,
  parentOrganizationId: YUP_PARENT_ORGANIZATION_ID,
  basicOrganizationNumber: YUP_BASIC_ORGANIZATION_NUMBER,
  possibilities: YUP_POSSIBILITIES
});

const ClubSummaryCreateForm = ({
  clubId,
  initialValues,
  onClose,
  actualProfileImageUrl,
  onSubmitSuccessfully
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(resetFormDiagnostics(FORM_NAME));
    })();
  }, [dispatch]);

  const loaders = useSelector(state => state.CommonReducer.loaders);

  const isSubmitting =
    loaders[SUBMITTING_CLUB_SUMMARY_EDIT_FORM_LOADER] || false;

  const formDiagnostics =
    useSelector(state => state.CommonReducer.formDiagnostics)[FORM_NAME] || {};

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
        <Formik
          initialValues={initialValues}
          onSubmit={async values => {
            dispatch(
              submitClubSummaryCreateEditForm({
                clubId,
                values,
                formName: FORM_NAME,
                modalName: CLUB_SUMMARY_CREATE_FORM_MODAL_NAME,
                onSuccess: onSubmitSuccessfully,
                createForm: true
              })
            );
          }}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, submitForm, setFieldValue }) => {
            return (
              <ClubSummaryCreateEditFormLayout
                values={values}
                actualProfileImageUrl={actualProfileImageUrl}
                errors={errors}
                formDiagnostics={formDiagnostics}
                touched={touched}
                isSubmitting={isSubmitting}
                onClose={onClose}
                setFieldValue={setFieldValue}
                createForm={false}
              />
            );
          }}
        </Formik>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default ClubSummaryCreateForm;
