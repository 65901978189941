import React, { useEffect, useState } from "react";
import { Grid, IconButton, makeStyles } from "@material-ui/core";
import MainContentContainer from "../../../../../common/components/main-content-container";
import UserProfilePreview from "../../../../../common/components/user-profile-preview";
import MainPageTitle from "../../../../../common/components/page/mainPageTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_TRAINING_CLUB_MEMBERS_LOADER_NAME,
  getTrainingClubMembers,
  removeClubMember
} from "../../../../../redux/trainingClub/actions";
import Typography from "@material-ui/core/Typography";
import {
  closeModal,
  openConfirmationModal,
  openModal
} from "../../../../../redux/common/actions";
import AuthorizedOnly from "../../../../../common/components/authorization/authorizedOnly";
import { authorize } from "../../../../../utils/authorize";
import AddIcon from "@material-ui/icons/Add";
import ClubMemberEditForm, {
  CLUB_MEMBER_EDIT_FORM_MODAL_NAME
} from "./forms/editForm";
import ModalFormWrapper from "../../../../../common/components/modalForm/wrapper";
import ClubMemberCreateForm, {
  CLUB_MEMBER_CREATE_FORM_MODAL_NAME
} from "./forms/createForm";

const useStyles = makeStyles(theme => ({
  editButton: {
    position: "absolute",
    right: theme.spacing(3),
    top: theme.spacing(3)
  }
}));

const TrainingClubMembers = ({ resourceId: clubId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [member, setMember] = useState(null);

  const reloadMembers = () => {
    dispatch(getTrainingClubMembers(clubId));
  };

  useEffect(() => {
    reloadMembers();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, clubId]);

  const members = useSelector(state => state.TrainingClubReducer.members);

  const loading =
    useSelector(store => store.CommonReducer.loaders)[
      GET_TRAINING_CLUB_MEMBERS_LOADER_NAME
    ] || false;

  const handleOpenClubMemberEditForm = index => {
    setMember(members[index]);
    dispatch(openModal(CLUB_MEMBER_EDIT_FORM_MODAL_NAME));
  };
  const handleConfirmRemove = index => {
    dispatch(removeClubMember(clubId, members[index].memberId, reloadMembers));
  };
  const handleRemoveModalOpen = (index, fullname) => {
    dispatch(
      openConfirmationModal(
        "Odstranit člena",
        `Opravdu si přejete odstranit člena ${fullname}?`,
        () => handleConfirmRemove(index)
      )
    );
  };

  const handleAddNewMemberModalOpen = () => {
    dispatch(openModal(CLUB_MEMBER_CREATE_FORM_MODAL_NAME));
  };

  const handleCloseClubMemberEditForm = () => {
    dispatch(closeModal(CLUB_MEMBER_EDIT_FORM_MODAL_NAME));
  };

  const handleCloseClubMemberCreateForm = () => {
    dispatch(closeModal(CLUB_MEMBER_CREATE_FORM_MODAL_NAME));
  };

  const authorization = useSelector(state => state.AuthReducer);
  const authorized = authorize(
    authorization,
    "isResourceAdmin",
    "clubs",
    clubId
  );

  return (
    <>
      <AuthorizedOnly
        expectedRole={"isResourceAdmin"}
        resourceType={"clubs"}
        resourceId={clubId}
      >
        <ModalFormWrapper
          modalName={CLUB_MEMBER_EDIT_FORM_MODAL_NAME}
          title={"Upravit informace člena klubu"}
        >
          <ClubMemberEditForm
            clubId={clubId}
            memberId={member?.memberId}
            userId={member?.id}
            initialValues={{
              isVirtual: !member?.email,
              email: member?.email || "",
              firstName: member ? member.name : "",
              lastName: member ? member.surname : "",
              city: member?.address?.city || "",
              street: member?.address?.street || "",
              zipNumber: member?.address?.zipNumber || "",
              phoneNumber: member?.phoneNumber || "",
              birthDate: member?.birthDate || "",
              // club member specific fields
              roles: member ? member.roles : [],
              isAdmin: member?.isAdmin || false,
              isContactPerson: member?.isContactPerson || false
            }}
            onClose={handleCloseClubMemberEditForm}
            actualProfileImageUrl={member?.imageUrl}
            onSubmitSuccessfully={reloadMembers}
          />
        </ModalFormWrapper>
        <ModalFormWrapper
          modalName={CLUB_MEMBER_CREATE_FORM_MODAL_NAME}
          title={"Přidat člena klubu"}
        >
          <ClubMemberCreateForm
            clubId={clubId}
            initialValues={{
              isVirtual: false,
              email: "",
              firstName: "",
              lastName: "",
              city: "",
              street: "",
              zipNumber: "",
              phoneNumber: "",
              birthDate: null,
              // club member specific fields
              roles: ["člen"],
              isAdmin: false,
              isContactPerson: false
            }}
            onClose={handleCloseClubMemberCreateForm}
            onSubmitSuccessfully={reloadMembers}
          />
        </ModalFormWrapper>
      </AuthorizedOnly>
      <MainContentContainer relative={true}>
        <MainPageTitle>Členové klubu</MainPageTitle>
        <AuthorizedOnly
          expectedRole={"isResourceAdmin"}
          resourceType={"clubs"}
          resourceId={clubId}
        >
          <div className={classes.editButton}>
            {/*<SectionAction*/}
            {/*  title={"Správa členů klubu"}*/}
            {/*  onAdd={handleAddNewMemberModalOpen}*/}
            {/*  onAddTitle={"Přidat člena"}*/}
            {/*/>*/}
            <IconButton
              color={"primary"}
              title={"Přidat člena"}
              onClick={handleAddNewMemberModalOpen}
            >
              <AddIcon />
            </IconButton>
          </div>
        </AuthorizedOnly>
        <Grid container spacing={4}>
          {members && members.length ? (
            members.map((member, index) => (
              <Grid item xs={12} lg={6} key={index}>
                <UserProfilePreview
                  showContactFields={false}
                  id={member.id}
                  name={member.name}
                  surname={member.surname}
                  address={member.address}
                  email={member.email}
                  phoneNumber={member.phoneNumber}
                  roles={member.roles}
                  imageUrl={member.imageUrl}
                  onEditButtonClick={
                    authorized
                      ? () => handleOpenClubMemberEditForm(index)
                      : undefined
                  }
                  onRemoveButtonClick={
                    authorized
                      ? () => handleRemoveModalOpen(index, member.fullname)
                      : undefined
                  }
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant={"body1"}>
                {loading ? "načítání..." : "členové klubu nebyli zadáni"}
              </Typography>
            </Grid>
          )}
        </Grid>
      </MainContentContainer>
    </>
  );
};

export default TrainingClubMembers;
