import React from "react";
import { makeStyles } from "@material-ui/core";
import MainPageTitle from "../../page/mainPageTitle";
import { openModal } from "../../../../redux/common/actions";
import { CUSTOM_PAGE_FORM_MODAL_NAME_PREFIX } from "./editFormModal";
import { useDispatch } from "react-redux";
import CustomPageEditFormModal from "./editFormModal";
import SectionCreateFormModal, {
  SECTION_CREATE_FORM_MODAL_NAME_PREFIX
} from "../sectionPreview/createFormModal";
import clsx from "clsx";
import SectionAction from "../components/sectionAction";
import AuthorizedOnly from "../../authorization/authorizedOnly";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    padding: theme.spacing(0)
  },
  toolbar: theme.mixins.toolbar,
  editButtonWrapperForPreviewPage: {
    opacity: 0.8,
    top: theme.spacing(1)
  },
  editButtonWrapperForOthers: {
    top: 0
  },
  editButtonWrapper: {
    position: "absolute",
    right: theme.spacing(1),
    zIndex: 1
  },
  titleWrapper: {
    textAlign: "center",
    fontWeight: "bold"
  }
}));

const CustomPage = ({
  customPageId,
  title, // if null, title div will not be rendered (empty string will)
  children,
  resourceType,
  resourceId,
  onReload,
  sectionsCount,
  inPreviewPage = false, // for preview page, there is some customizations (toolbar, buttons, no title)
  noSectionsMessage = "žádná sekce nebyla vytvořena",
  editNameDisabled = false,
  allowSectionTypes
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleEditModalOpen = () => {
    dispatch(
      openModal(CUSTOM_PAGE_FORM_MODAL_NAME_PREFIX + customPageId.toString())
    );
  };
  const handleAddNewSection = () => {
    dispatch(
      openModal(SECTION_CREATE_FORM_MODAL_NAME_PREFIX + customPageId.toString())
    );
  };

  const hasSections = () => {
    return children.constructor === Array ? children?.length > 0 : children;
  };

  return (
    <div className={classes.root}>
      {title !== null && (
        <div className={classes.titleWrapper}>
          {/* &nbsp; keeps element height if title is empty */}
          <MainPageTitle variant={"h3"}>{title}&nbsp;</MainPageTitle>
        </div>
      )}
      <AuthorizedOnly
        expectedRole={"isResourceAdmin"}
        resourceType={resourceType}
        resourceId={resourceId}
      >
        <CustomPageEditFormModal
          customPageId={customPageId}
          initialValues={{
            title: title || ""
          }}
          onSubmitSuccessfully={onReload}
          resourceType={resourceType}
          resourceId={resourceId}
          inPreviewPage={inPreviewPage}
          nameDisabled={editNameDisabled}
        />
        <SectionCreateFormModal
          customPageId={customPageId}
          onSubmitSuccessfully={onReload}
          resourceType={resourceType}
          resourceId={resourceId}
          minOrder={inPreviewPage ? 2 : 1}
          maxOrder={inPreviewPage ? sectionsCount : sectionsCount + 1}
          allowSectionTypes={allowSectionTypes}
        />
      </AuthorizedOnly>
      <div
        className={clsx(
          classes.editButtonWrapper,
          inPreviewPage
            ? classes.editButtonWrapperForPreviewPage
            : classes.editButtonWrapperForOthers
        )}
      >
        {inPreviewPage && <div className={classes.toolbar} />}
        <AuthorizedOnly
          expectedRole={"isResourceAdmin"}
          resourceType={resourceType}
          resourceId={resourceId}
        >
          <SectionAction
            title={"Akční možnosti této stránky"}
            inPreviewPage={inPreviewPage}
            onEdit={handleEditModalOpen}
            onAdd={handleAddNewSection}
            onAddTitle={"Přidat sekci"}
          />
        </AuthorizedOnly>
      </div>
      {hasSections() ? (
        children
      ) : (
        <Typography variant={"body1"}>{noSectionsMessage}</Typography>
      )}
    </div>
  );
};

export default CustomPage;
