import React from "react";
import * as Yup from "yup";
import { REQUIRED } from "../../../../../../../constants/dictionary/validation";
import DateTimeFieldBase from "../../../../../../../common/components/forms/dateTimeFieldBase";

const FIELD_NAME = "deadline";

// export const YUP_DEADLINE = Yup.date("").nullable("");
export const YUP_DEADLINE = Yup.date()
  .nullable()
  .default(undefined);

export const YUP_DEADLINE_REQUIRED = YUP_DEADLINE.required(REQUIRED);

const DeadlineField = ({ values, setFieldValue, baseParams }) => {
  return (
    <DateTimeFieldBase
      name={FIELD_NAME}
      label="Uzávěrka přihlášek"
      value={values[FIELD_NAME]}
      onChange={value => setFieldValue(FIELD_NAME, value)}
      baseParams={baseParams}
    />
  );
};

export default DeadlineField;
