// @flow

import * as types from "redux/person/types";

/**
 * Flow types
 */
type PersonState = {
  person: Object,
  editFormOpen: boolean
};

/**
 * Initial auth state
 */
const INITIAL_STATE = {
  person: null,
  editFormOpen: false
};

/**
 * Person reducer
 */
const PersonReducer = (state: PersonState = INITIAL_STATE, action: Object) => {
  switch (action.type) {
    case types.GET_PERSON:
      return {
        ...state,
        person: action.payload
      };
    case types.GET_PERSON_DOGS:
      return {
        ...state,
        dogs: action.payload
      };
    default:
      return state;
  }
};

export default PersonReducer;
