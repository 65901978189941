import React from "react";
import { CheckboxWithLabel } from "formik-material-ui";
import * as Yup from "yup";
import CheckboxFieldBase from "../../../../../../../common/components/forms/checkboxFieldBase";
import Typography from "@material-ui/core/Typography";

const FIELD_NAME = "ownershipApprove";

export const YUP_OWNERSHIP_APPROVE_REQUIRED = Yup.boolean()
  .required("Chybí potvrzení, že jste majitelem psa!")
  .oneOf([true], "Chybí potvrzení, že jste majitelem psa!");

const OwnershipApproveField = ({ baseParams }) => {
  return (
    <CheckboxFieldBase
      component={CheckboxWithLabel}
      name={FIELD_NAME}
      label={
        <Typography variant={"body1"}>
          Potvrzuji, že jsem majitelem psa zapsaný v průkazu původu psa.
        </Typography>
      }
      type="checkbox"
      baseParams={baseParams}
    />
  );
};

export default OwnershipApproveField;
