import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "500px",
    marginLeft: "auto",
    marginRight: "auto"
  }
}));

const ProfileImageWrapper = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default ProfileImageWrapper;
