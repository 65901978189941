import React, { useEffect } from "react";
import {
  Grid,
  makeStyles,
  Button,
  LinearProgress,
  ButtonGroup
} from "@material-ui/core";
import { combineFormErrorMessages } from "../../../../../utils/diagnostics";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import cs from "date-fns/locale/cs";

import { TextField } from "formik-material-ui";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { resetFormDiagnostics } from "../../../../../redux/common/actions";
import {
  TOO_LONG,
  TOO_SHORT
} from "../../../../../constants/dictionary/validation";
import {
  submitClubContactEditForm,
  SUBMITTING_CLUB_CONTACT_EDIT_FORM_LOADER
} from "../../../../../redux/trainingClub/actions";
import StreetField, {
  YUP_STREET
} from "../../../../../common/components/forms/fields/street";
import CityField, {
  YUP_CITY_REQUIRED
} from "../../../../../common/components/forms/fields/city";
import ZipNumberField, {
  YUP_ZIP_NUMBER
} from "../../../../../common/components/forms/fields/zipNumber";

const FORM_NAME = "CLUB_CONTACT_EDIT_FORM_NAME";

const validationSchema = Yup.object().shape({
  street: YUP_STREET,
  city: YUP_CITY_REQUIRED,
  zipNumber: YUP_ZIP_NUMBER,
  description: Yup.string()
    .min(2, TOO_SHORT)
    .max(100, TOO_LONG),
  gps: Yup.string()
    .min(10, TOO_SHORT)
    .max(30, TOO_LONG)
});

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  textInput: {
    paddingBottom: theme.spacing(2)
  },
  buttonGroup: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  lineProgress: {},
  hiddenInput: {
    display: "none"
  },
  centered: {
    textAlign: "center"
  },
  profileImageEditButton: {
    marginTop: theme.spacing(1)
  },
  arrayFieldWrapper: {
    display: "flex"
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%"
  }
}));

const ClubContactEditForm = ({
  clubId,
  initialValues,
  actualProfileImageUrl,
  onClose,
  onSubmitSuccessfully
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(resetFormDiagnostics(FORM_NAME));
    })();
  }, [dispatch]);

  const loaders = useSelector(state => state.CommonReducer.loaders);

  const isSubmitting =
    loaders[SUBMITTING_CLUB_CONTACT_EDIT_FORM_LOADER] || false;

  const formDiagnostics =
    useSelector(state => state.CommonReducer.formDiagnostics)[FORM_NAME] || {};

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
        <Formik
          initialValues={initialValues}
          onSubmit={async values => {
            dispatch(
              submitClubContactEditForm(
                clubId,
                values,
                FORM_NAME,
                onSubmitSuccessfully
              )
            );
          }}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, submitForm, setFieldValue }) => {
            const localCombineFormErrorMessages = field => {
              return combineFormErrorMessages(errors, formDiagnostics, field);
            };
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <StreetField
                      placeholder="Zde vložte ulici klubu, pokud existuje"
                      baseParams={{ errors, formDiagnostics, touched }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <CityField
                      placeholder="Zde vložte město klubu"
                      baseParams={{ errors, formDiagnostics, touched }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ZipNumberField
                      placeholder="Zde vložte PSČ klubu"
                      baseParams={{ errors, formDiagnostics, touched }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={TextField}
                      name="description"
                      // autoComplete={"street-address"}
                      label="Popis místa"
                      className={classes.textInput}
                      fullWidth={true}
                      type="text"
                      placeholder="Zde vložte popis místa (např. U střelnice)"
                      helperText={localCombineFormErrorMessages("description")}
                      error={!!localCombineFormErrorMessages("description")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      name="gps"
                      label="GPS souřadnice"
                      className={classes.textInput}
                      fullWidth={true}
                      type="text"
                      placeholder="Zde vložte GPS souřadnice klubu"
                      helperText={localCombineFormErrorMessages("gps")}
                      error={!!localCombineFormErrorMessages("gps")}
                    />
                  </Grid>
                </Grid>
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                  className={classes.buttonGroup}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                    onClick={submitForm}
                  >
                    {!isSubmitting ? "Uložit" : "Ukládání"}
                  </Button>
                  <Button variant="contained" color="default" onClick={onClose}>
                    Zavřít
                  </Button>
                </ButtonGroup>
                {isSubmitting && (
                  <LinearProgress className={classes.lineProgress} />
                )}

                {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
                {/*<pre>{JSON.stringify(errors, null, 2)}</pre>*/}
              </Form>
            );
          }}
        </Formik>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default ClubContactEditForm;
