// @flow
import React, { useEffect } from "react";

// material-ui
// import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";

// react-router
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";

// components
import DrawerMenu from "pages/main-page/menu-component/drawer-menu/drawerMenu";
import { useDispatch, useSelector } from "react-redux";
import { getRoutes } from "./routes";
import NotFoundPage from "../../../main-page/notFound";
import {
  isOneOfRoutesActive,
  RouteWithSubRoutes
} from "../../../../utils/routes";
import { resetArticleState } from "../../../../redux/article/actions";
import { resetPhotoGalleryState } from "../../../../redux/photo-gallery/actions";
import { resetMenuState } from "../../../../redux/menu/actions";
import { resetEventRedux } from "../../../../redux/event/actions";

const EventPages = ({
  location,
  mobileOpen,
  handleDrawerToggle,
  isMainPage
}) => {
  // const classes = useStyles();
  const dispatch = useDispatch();

  let { id: paramId } = useParams();
  paramId = parseInt(paramId);
  const customDomain = useSelector(state => state.CommonReducer.customDomain);
  let id = isMainPage && customDomain ? customDomain.resourceId : paramId;

  useEffect(() => {
    dispatch(resetEventRedux());
    dispatch(resetArticleState());
    dispatch(resetPhotoGalleryState());
    dispatch(resetMenuState());
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, id]);

  const linkPrefix = isMainPage ? "" : `/events/${id}`;
  const pathPrefix = isMainPage ? "" : "/events/:id";

  const routes = getRoutes(id, linkPrefix, pathPrefix);

  return isOneOfRoutesActive(location.pathname, routes) ? (
    <>
      {/* MENU */}
      <DrawerMenu
        resourceType={"event"}
        items={routes}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      {/* CONTENT */}

      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} linkPrefix={linkPrefix} />
        ))}
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </>
  ) : (
    <NotFoundPage />
  );
};

export default withRouter(EventPages);
