import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LOW
} from "../../../../../../../constants/dictionary/validation";

const FIELD_NAME = "price";

export const YUP_PRICE = Yup.number()
  .nullable(true)
  .min(0, TOO_LOW);

export const YUP_PRICE_REQUIRED = YUP_PRICE.required(REQUIRED);

const PriceField = ({ baseParams }) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      label="Cena (Kč)"
      fullWidth={true}
      type="number"
      baseParams={baseParams}
    />
  );
};

export default PriceField;
