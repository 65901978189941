import React from "react";
import { makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  image: {
    width: "100%",
    height: "100%"
  }
}));

const DEFAULT_VARIANT = "square";

const ProfileImage = ({
  imageUrl,
  square = false,
  rounded = false,
  circular = false
}) => {
  const classes = useStyles();

  const variant = square
    ? "square"
    : rounded
    ? "rounded"
    : circular
    ? "circular"
    : DEFAULT_VARIANT;

  return (
    <div className={classes.root}>
      <Avatar
        alt="profile"
        variant={variant}
        src={imageUrl ? imageUrl : undefined}
        component={imageUrl ? undefined : PersonIcon}
        className={classes.image}
        title={"profilový obrázek"}
      />
    </div>
  );
};

export default ProfileImage;
