import React from "react";
import Typography from "@material-ui/core/Typography";
import MainTemplate from "../../main-page/menu-component/mainTemplate";
import MainContentContainer from "../../../common/components/main-content-container";
import MainPageTitle from "../../../common/components/page/mainPageTitle";

const GdprPage = () => {
  return (
    <MainTemplate>
      <MainContentContainer showCloser={true}>
        <MainPageTitle variant={"h3"}>
          Zásady ochrany osobních údajů
        </MainPageTitle>
        <Typography variant={"h4"}>I.&nbsp;Základní ustanovení</Typography>
        <ol>
          <li>
            {/*je podnikatel Ivo Čapoun IČ XXX se sídlem*/}
            <Typography variant={"body1"}>
              Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského
              parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v
              souvislosti se zpracováním Vašich osobních údajů a o volném pohybu
              těchto údajů (dále jen: „GDPR”) je fyzická osoba Ivo Čapoun bytem
              Troskotovice 25, Troskotovice 671 78 (dále jen: „správce“).
            </Typography>
          </li>
          <li>
            <Typography variant={"body1"}>
              Kontaktní údaje správce jsou:
            </Typography>
            <ul>
              <li>
                <Typography variant={"body1"}>
                  adresa: Troskotovice 25, Troskotovice 671 78
                </Typography>
              </li>
              <li>
                <Typography variant={"body1"}>
                  email: kynologove@gmail.com
                </Typography>
              </li>
              <li>
                <Typography variant={"body1"}>
                  telefon: +420&nbsp;739&nbsp;311&nbsp;183
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography variant={"body1"}>
              Osobními údaji se rozumí veškeré informace o identifikované nebo
              identifikovatelné fyzické osobě; identifikovatelnou fyzickou
              osobou je fyzická osoba, kterou lze přímo či nepřímo
              identifikovat, zejména odkazem na určitý identifikátor, například
              jméno, identifikační číslo, lokační údaje, síťový identifikátor
              nebo na jeden či více zvláštních prvků fyzické, fyziologické,
              genetické, psychické, ekonomické, kulturní nebo společenské
              identity této fyzické osoby.
            </Typography>
          </li>
          <li>
            <Typography variant={"body1"}>
              Správce nejmenoval pověřence pro ochranu osobních údajů.
            </Typography>
          </li>
          <li>
            <Typography variant={"body1"}>Identifikátory:</Typography>
            <ul>
              <li>
                <Typography variant={"body1"}>jméno a příjmení</Typography>
              </li>
              <li>
                <Typography variant={"body1"}>adresa</Typography>
              </li>
              <li>
                <Typography variant={"body1"}>email a telefon</Typography>
              </li>
              <li>
                <Typography variant={"body1"}>fotografie</Typography>
              </li>
              <li>
                <Typography variant={"body1"}>IP adresa</Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography variant={"body1"}>
              Data jsou uložena na serverech hostingové společnosti WEDOS
              Internet, a.s., IČ 28115708, se sídlem Masarykova 1230, Hluboká
              nad Vltavou, 373 41, zapsaná ve veřejném rejstříku vedeném
              Krajským soudem v Českých Budějovicích v oddíle B, vložce 1886
              (Dále jen "WEDOS").
            </Typography>
          </li>
        </ol>
        {/*Zdroje a kategorie zpracovávaných osobních údajů*/}
        <Typography variant={"h4"}>
          II.&nbsp;Zdroje a kategorie zpracovávaných osobních údajů
        </Typography>
        <ol>
          <li>
            <Typography variant={"body1"}>
              Správce zpracovává osobní údaje, které jste mu poskytl/a nebo
              osobní údaje, které správce získal na základě plnění Vaší
              objednávky.
            </Typography>
          </li>
          <li>
            <Typography variant={"body1"}>
              Správce zpracovává Vaše identifikační a kontaktní údaje a údaje
              nezbytné pro plnění smlouvy.
            </Typography>
          </li>
        </ol>
        {/*Zákonný důvod a účel zpracování osobních údajů*/}
        <Typography variant={"h4"}>
          III.&nbsp;Zákonný důvod a účel zpracování osobních údajů
        </Typography>
        <ol>
          <li>
            <Typography variant={"body1"}>
              Zákonným důvodem zpracování osobních údajů je:
            </Typography>
            <ul>
              <li>
                <Typography variant={"body1"}>
                  plnění smlouvy mezi Vámi a&nbsp;správcem podle čl. 6 odst. 1
                  písm. b) GDPR
                </Typography>
              </li>
              <li>
                <Typography variant={"body1"}>
                  oprávněný zájem správce na poskytování přímého marketingu
                  (zejména pro zasílání obchodních sdělení a newsletterů) podle
                  čl. 6 odst. 1 písm. f) GDPR
                </Typography>
              </li>
              <li>
                <Typography variant={"body1"}>
                  Váš souhlas se zpracováním pro účely poskytování přímého
                  marketingu (zejména pro zasílání obchodních sdělení a
                  newsletterů) podle čl. 6 odst. 1 písm. a) GDPR ve spojení s §
                  7 odst. 2 zákona č. 480/2004 Sb., o některých službách
                  informační společnosti v&nbsp;případě, že nedošlo
                  k&nbsp;objednávce zboží nebo služby
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography variant={"body1"}>
              Účelem zpracování osobních údajů je:
            </Typography>
            <ul>
              <li>
                <Typography variant={"body1"}>
                  vyřízení Vaší objednávky a výkon práv a povinností
                  vyplývajících ze smluvního vztahu mezi Vámi a správcem; při
                  objednávce jsou vyžadovány osobní údaje, které jsou nutné pro
                  úspěšné vyřízení objednávky (jméno a adresa, kontakt),
                  poskytnutí osobních údajů je nutným požadavkem pro uzavření a
                  plnění smlouvy, bez poskytnutí osobních údajů není možné
                  smlouvu uzavřít či jí ze strany správce plnit
                </Typography>
              </li>
              <li>
                <Typography variant={"body1"}>
                  zasílání obchodních sdělení a činění dalších marketingových
                  aktivit
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography variant={"body1"}>
              Ze strany správce nedochází k&nbsp;automatickému individuálnímu
              rozhodování ve&nbsp;smyslu čl. 22 GDPR. S&nbsp;takovým zpracováním
              jste poskytl/a svůj výslovný souhlas.
            </Typography>
          </li>
        </ol>
        {/*Doba uchovávání údajů*/}
        <Typography variant={"h4"}>IV.&nbsp;Doba uchovávání údajů</Typography>
        <ol>
          <li>
            <Typography variant={"body1"}>
              Správce uchovává osobní údaje:
            </Typography>
            <ul>
              <li>
                <Typography variant={"body1"}>
                  po dobu nezbytnou k výkonu práv a povinností vyplývajících ze
                  smluvního vztahu mezi Vámi a správcem a uplatňování nároků z
                  těchto smluvních vztahů (po dobu 15 let od ukončení smluvního
                  vztahu)
                </Typography>
              </li>
              <li>
                <Typography variant={"body1"}>
                  po dobu, než je odvolán souhlas se zpracováním osobních údajů
                  pro účely marketingu, nejdéle 5 let, jsou-li osobní údaje
                  zpracovávány na základě souhlasu
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography variant={"body1"}>
              Po uplynutí doby uchovávání osobních údajů správce osobní údaje
              vymaže.
            </Typography>
          </li>
        </ol>
        {/*Příjemci osobních údajů (subdodavatelé správce)*/}
        <Typography variant={"h4"}>
          V.&nbsp;Příjemci osobních údajů (subdodavatelé správce)
        </Typography>
        <ol>
          <li>
            <Typography variant={"body1"}>
              Příjemci osobních údajů jsou osoby:
            </Typography>
            <ul>
              <li>
                <Typography variant={"body1"}>
                  podílející se na dodání zboží / služeb / realizaci plateb na
                  základě smlouvy
                </Typography>
              </li>
              <li>
                <Typography variant={"body1"}>
                  podílející se na zajištění provozu služeb
                </Typography>
              </li>
              {/*  <li>*/}
              {/*  <Typography variant={"body1"}>*/}
              {/*    zajišťující marketingové služby*/}
              {/*  </Typography>*/}
              {/*</li>*/}
            </ul>
          </li>
          <li>
            <Typography variant={"body1"}>
              Správce nemá v úmyslu předat osobní údaje do třetí země (do země
              mimo EU) nebo mezinárodní organizaci. Příjemci osobních údajů ve
              třetích zemích jsou poskytovatelé mailingových služeb / cloudových
              služeb.
            </Typography>
          </li>
        </ol>
        {/*Vaše práva*/}
        <Typography variant={"h4"}>VI.&nbsp;Vaše práva</Typography>
        <ol>
          <li>
            <Typography variant={"body1"}>
              Za podmínek stanovených v&nbsp;GDPR máte:
            </Typography>
            <ul>
              <li>
                <Typography variant={"body1"}>
                  právo na přístup ke svým osobním údajům dle čl. 15 GDPR
                </Typography>
              </li>
              <li>
                <Typography variant={"body1"}>
                  právo opravu osobních údajů dle čl. 16 GDPR, popřípadě omezení
                  zpracování dle čl. 18 GDPR
                </Typography>
              </li>
              <li>
                <Typography variant={"body1"}>
                  právo na výmaz osobních údajů dle čl. 17 GDPR
                </Typography>
              </li>
              <li>
                <Typography variant={"body1"}>
                  právo vznést námitku proti zpracování dle čl. 21 GDPR a
                </Typography>
              </li>
              <li>
                <Typography variant={"body1"}>
                  právo na přenositelnost údajů dle čl. 20 GDPR
                </Typography>
              </li>
              <li>
                <Typography variant={"body1"}>
                  právo odvolat souhlas se zpracováním písemně nebo elektronicky
                  na adresu nebo email správce uvedený v&nbsp;čl. III těchto
                  podmínek
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography variant={"body1"}>
              Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů
              v případě, že se domníváte, že bylo porušeno Vaše právo na ochranu
              osobních údajů.
            </Typography>
          </li>
        </ol>
        {/*Podmínky zabezpečení osobních údajů*/}
        <Typography variant={"h4"}>
          VII.&nbsp;Podmínky zabezpečení osobních údajů
        </Typography>
        <ol>
          <li>
            <Typography variant={"body1"}>
              Správce prohlašuje, že přijal veškerá vhodná technická a
              organizační opatření k&nbsp;zabezpečení osobních údajů.
            </Typography>
          </li>
          <li>
            <Typography variant={"body1"}>
              Správce přijal technická opatření k&nbsp;zabezpečení datových
              úložišť a úložišť osobních údajů v&nbsp;listinné podobě, zejména
              silná hesla, šifrovanou https komunikaci.
            </Typography>
          </li>
          <li>
            <Typography variant={"body1"}>
              Správce prohlašuje, že k&nbsp;osobním údajům mají přístup pouze
              jím pověřené osoby.
            </Typography>
          </li>
        </ol>
        {/*Závěrečná ustanovení*/}
        <Typography variant={"h4"}>VIII.&nbsp;Vaše práva</Typography>
        <ol>
          <li>
            <Typography variant={"body1"}>
              Registrací v tomto systému potvrzujete, že jste seznámen/a s
              podmínkami ochrany osobních údajů a že je v celém rozsahu
              přijímáte.
            </Typography>
          </li>
          <li>
            <Typography variant={"body1"}>
              S&nbsp;těmito podmínkami souhlasíte zaškrtnutím souhlasu
              prostřednictvím internetového formuláře. Zaškrtnutím souhlasu
              potvrzujete, že jste seznámen/a s podmínkami ochrany osobních
              údajů a že je v celém rozsahu přijímáte.
            </Typography>
          </li>
          <li>
            <Typography variant={"body1"}>
              Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek
              ochrany osobních údajů zveřejní na svých internetových stránkách,
              případně Vám zašle novou verzi těchto podmínek na e-mailovou
              adresu, kterou jste správci poskytl/a.
            </Typography>
          </li>
        </ol>
        <Typography variant={"body1"}>
          Tyto podmínky nabývají účinnosti dnem 18.08.2020.
        </Typography>
        <br />
        <br />
        <br />
        <br />
      </MainContentContainer>
    </MainTemplate>
  );
};

export default GdprPage;
