// @flow

// react + router
import React from "react";
import { withRouter } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "redux/auth/actions";

// material-ui
import { makeStyles, Typography, Container } from "@material-ui/core";

// components
import DateFnsUtils from "@date-io/date-fns";
import cs from "date-fns/locale/cs";
import { Formik } from "formik";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as Yup from "yup";
import { YUP_PASSWORD_REQUIRED } from "../login-components/fields/password";
import ResetPasswordLayout from "./formLayout";

/**
 * Flow types
 */

const FORM_NAME = "ResetPasswordForm";
export const SUBMITTING_FORM_LOADER = "SUBMITTING_RESET_PASSWORD_FORM_LOADER";

const validationSchema = Yup.object().shape({
  password: YUP_PASSWORD_REQUIRED
});

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: "900",
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    fontSize: 13,
    color: "grey"
  },
  header: {
    marginBottom: theme.spacing(3)
  },
  otherActionContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const ResetPasswordForm = ({ activationHash, history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formDiagnostics =
    useSelector(state => state.CommonReducer.formDiagnostics)[FORM_NAME] || {};

  const isSubmitting = false;

  const handleSuccess = () => {
    history.push("/login");
  };

  return (
    <Container component="main" maxWidth="md">
      <div className={classes.header}>
        <Typography className={classes.title} variant="h5">
          Obnovení zapomenutého hesla
        </Typography>
        <Typography className={classes.subtitle} variant="caption">
          Poslední krok pro obnovení hesla. Zadejte prosím nové heslo. Heslo
          nikdy nikomu nesdelujte a uchovejte si ho v bezpečí.
        </Typography>
      </div>

      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
        <Formik
          initialValues={{ password: "" }}
          onSubmit={async values => {
            dispatch(
              resetPassword({
                values,
                activationHash: activationHash,
                formName: FORM_NAME,
                onSuccess: handleSuccess
              })
            );
          }}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched }) => {
            return (
              <ResetPasswordLayout
                errors={errors}
                isSubmitting={isSubmitting}
                touched={touched}
                formDiagnostics={formDiagnostics}
                values={values}
              />
            );
          }}
        </Formik>
      </MuiPickersUtilsProvider>
    </Container>
  );
};

export default withRouter(ResetPasswordForm);
