// @flow

import axios from "utils/axios";
import { ThunkAction } from "redux-thunk";

import * as types from "./types";

// notification-actions
import { enqueueSnackbar } from "redux/notification/notification-actions";
// import { useDispatch } from "react-redux";
import { enableLoader, disableLoader } from "../common/actions";

/**
 * Get menu information for specific event `GET /v1/events/<id>/menu-info` TODO: maybe move into the global actions for many types (event|training-club|...)
 */
export const getMenuInfo = (resource_type, resource_id, onUnauthorized) => {
  return async (dispatch: ThunkAction) => {
    let sourceTypeUrl = null;
    switch (resource_type) {
      case "event":
        sourceTypeUrl = "events";
        break;
      case "trainingClub":
        sourceTypeUrl = "clubs";
        break;
      case "person":
        sourceTypeUrl = "persons";
        break;
      default:
        throw Error("Unsupported resource type ", resource_type);
    }

    try {
      // TODO: RESET EVENT if id changed... otherwise previous loaded data are show for the short time
      dispatch(enableLoader(types.GET_MENU_INFO));
      const response = await axios({
        url: "/v1/" + sourceTypeUrl + "/" + resource_id + "/menu-info/",
        method: "get",
        // data: { filter: { id: id } },
        validateStatus: status => {
          return (status >= 200 && status <= 304) || status === 401;
        }
      });
      if (response.status !== 401) {
        dispatch({
          type: types.GET_MENU_INFO,
          payload: {
            id: response.data.menuInfo.id,
            imageUrl: response.data.menuInfo.image
              ? response.data.menuInfo.image.url
              : null,
            title: response.data.menuInfo.title,
            subtitle: response.data.menuInfo.subtitle
          }
        });
      } else {
        dispatch(
          enqueueSnackbar({
            message: "Nemáte oprávnění k získání informací menu!",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "warning"
            }
          })
        );
        if (onUnauthorized) onUnauthorized();
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: "V průběhu načítání dat pro menu došlo k chybě!",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
    }
    dispatch(disableLoader(types.GET_MENU_INFO));
  };
};


export const resetMenuState = () => {
  return async (dispatch: ThunkAction) => {
    dispatch({
      type: types.RESET_MENU_STATE
    });
  };
};