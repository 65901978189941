import React, { useEffect } from "react";
import * as Yup from "yup";
import { Button, Grid, IconButton, makeStyles } from "@material-ui/core";
import { FieldArray } from "formik";
import DeleteIcon from "@material-ui/icons/Delete";
import NameField, { YUP_NAME } from "./name";
import MaximumParticipantsField, {
  YUP_MAXIMUM_PARTICIPANTS
} from "./maximumPatricipants";
import NoteField, { YUP_NOTE } from "./note";

const FIELD_NAME = "categories";

export const YUP_CATEGORIES = Yup.array()
  .of(
    Yup.object({
      name: YUP_NAME,
      maximumParticipants: YUP_MAXIMUM_PARTICIPANTS,
      note: YUP_NOTE
    })
  )
  .max(30, "Kategorií může být maximálně 30.");

// export const YUP_CATEGORIES_REQUIRED = YUP_CATEGORIES.required(REQUIRED);

const useStyles = makeStyles(theme => ({
  removeButtonWrapper: {
    display: "grid"
  }
}));

const CategoriesField = ({
  baseParams,
  values,
  allPossibleEventCategories,
  setFieldValue
}) => {
  const classes = useStyles();

  return (
    <FieldArray
      name={FIELD_NAME}
      render={arrayCategories => (
        <div>
          {values.categories.map((_, index) => (
            <div key={index}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <NameField
                    baseParams={baseParams}
                    values={values}
                    setFieldValue={setFieldValue}
                    index={index}
                    allPossibleEventCategories={allPossibleEventCategories}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <MaximumParticipantsField
                    baseParams={baseParams}
                    index={index}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NoteField baseParams={baseParams} index={index} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={1}
                  classes={{
                    root: classes.removeButtonWrapper
                  }}
                >
                  {/*<IconButton onClick={() => arrayCategories.remove(index)}>*/}
                  <IconButton
                    onClick={() =>
                      setFieldValue(
                        "categories",
                        values.categories.filter(
                          (_, realIndex) => realIndex !== index
                        )
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          ))}
          <Button
            onClick={() =>
              // arrayCategories.push({
              setFieldValue("categories", [
                ...values.categories,
                { id: null, name: "", note: "", maximumParticipants: "" }
              ])
            }
          >
            Přidat kategorii
          </Button>
        </div>
      )}
    />
  );
};

export default CategoriesField;
