// @flow

import * as types from "./types";

/**
 * Flow types
 */
type CommonState = {
  loaders: Object,
  customDomain: Object,
  forms: Object,
  formDiagnostics: Object,
  modals: Object,
  confirmationModal: Object
};

/**
 * Initial menu state
 */
const INITIAL_STATE = {
  loaders: {},
  customDomain: null, // { title: "kk kostice", clubId: 2 }
  forms: {},
  formDiagnostics: {},
  modals: {},
  confirmationModal: {}
};

/**
 * Common reducer
 */
const CommonReducer = (state: CommonState = INITIAL_STATE, action: Object) => {
  let loaderType, newState;
  const payload = action.payload;

  switch (action.type) {
    case types.ENABLE_LOADER:
      loaderType = action.payload;
      newState = {
        ...state,
        loaders: {
          ...state.loaders
        }
      };
      newState.loaders[loaderType] = true;
      return newState;

    case types.DISABLE_LOADER:
      loaderType = action.payload;
      newState = {
        ...state,
        loaders: {
          ...state.loaders
        }
      };
      newState.loaders[loaderType] = false;
      return newState;

    case types.SET_CUSTOM_DOMAIN:
      return {
        ...state,
        customDomain: action.payload
      };
    case types.SET_PAGE_ROUTES:
      return {
        ...state,
        resourceType: action.payload.resourceType,
        routes: action.payload.routes
      };
    case types.SET_FORM_INPUT:
      newState = {
        ...state,
        forms: {
          ...state.forms
        }
      };
      if (!newState.forms[payload.formName]) {
        newState.forms[payload.formName] = {};
      }
      // if (!newState.forms[payload.formName][payload.inputName]) {
      //   !newState.forms[payload.formName][payload.inputName] = {};
      // }
      newState.forms[payload.formName] = {
        ...newState.forms[payload.formName],
        [payload.inputName]: payload.inputValue
      };
      // newState.forms[payload.formName][payload.inputName] = payload.inputValue;
      return newState;

    case types.CLEAR_FORM_INPUTS:
      newState = {
        ...state,
        forms: {
          ...state.forms
        }
      };
      delete newState.forms[payload.formName];
      return newState;

    case types.APPEND_FORM_DIAGNOSTICS:
      newState = {
        ...state,
        formDiagnostics: {
          ...state.formDiagnostics
        }
      };
      if (!newState.formDiagnostics[payload.formName]) {
        newState.formDiagnostics[payload.formName] = {};
      }
      newState.formDiagnostics[payload.formName] = {
        ...newState.formDiagnostics[payload.formName]
      };
      payload.diagnostics.forEach(item => {
        if (item.field) {
          if (!newState.formDiagnostics[payload.formName][item.field])
            newState.formDiagnostics[payload.formName][item.field] = [];
          newState.formDiagnostics[payload.formName][item.field].push(item.id);
        } else {
          if (!newState.formDiagnostics[payload.formName].nonField)
            newState.formDiagnostics[payload.formName].nonField = [];
          newState.formDiagnostics[payload.formName].nonField.push(item.id);
        }
      });
      return newState;
    case types.RESET_FORM_DIAGNOSTICS:
      newState = {
        ...state,
        formDiagnostics: {
          ...state.formDiagnostics,
          [payload.formName]: undefined
        }
      };
      return newState;
    case types.OPEN_MODAL:
      // loaderType = action.payload;
      newState = {
        ...state,
        modals: {
          ...state.modals
        }
      };
      newState.modals[payload] = true;
      return newState;

    case types.CLOSE_MODAL:
      // loaderType = action.payload;
      newState = {
        ...state,
        modals: {
          ...state.modals
        }
      };
      newState.modals[payload] = false;
      return newState;
    case types.SET_CONFIRMATION_MODAL:
      newState = {
        ...state,
        confirmationModal: {
          title: payload.title,
          message: payload.message,
          confirmCallback: payload.confirmCallback,
          maxWidth: payload.maxWidth,
          fullWidth: payload.fullWidth,
          refuseButtonText: payload.refuseButtonText,
          confirmButtonText: payload.confirmButtonText
        }
      };
      return newState;
    default:
      return state;
  }
};

export default CommonReducer;
