import React from "react";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LONG,
  TOO_SHORT
} from "../../../../../../../constants/dictionary/validation";
import SelectBox from "../../../../../../../common/components/forms/selectBox";

const FIELD_NAME = "sex";

export const YUP_SEX = Yup.string()
  .min(2, TOO_SHORT)
  .max(10, TOO_LONG);

export const YUP_SEX_REQUIRED = YUP_SEX.required(REQUIRED);

const SexField = ({ baseParams }) => {
  return (
    <SelectBox
      name={FIELD_NAME}
      label={"Pohlaví"}
      items={[
        { value: "fena", title: "Fena" },
        { value: "pes", title: "Pes" }
      ]}
      baseParams={baseParams}
    />
  );
};

export default SexField;
