export const removeItemFromArrayOfObjects = (prevArray, conditionCallback) => {
  const newArray = [...prevArray]; // make a separate copy of the newArray
  const index = newArray.findIndex(conditionCallback);
  if (index !== -1) {
    newArray.splice(index, 1);
    return newArray;
  } else return prevArray;
};

export const removeItemFromArrayByValue = (prevArray, value) => {
  const newArray = [...prevArray]; // make a separate copy of the newArray
  const index = newArray.indexOf(value);
  if (index !== -1) {
    newArray.splice(index, 1);
    return newArray;
  } else return prevArray;
};
