import React from "react";
import { makeStyles } from "@material-ui/core";

import MainContentContainer from "../../main-content-container";
import { Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  fullHeight: {
    minHeight: "100vh"
  },
  sectionTitleContainer: {
    width: "100%",
    backgroundColor: props => props.titleBackgroundColor //"#00000008",
  },
  sectionTitle: {
    marginTop: theme.spacing(2),
    flex: 1,
    textAlign: props => props.titleTextAlign,
    color: props => props.titleColor
  },
  sectionSubtitle: {
    marginTop: theme.spacing(1),
    flex: 1,
    textAlign: props => props.titleTextAlign,
    color: props => props.titleColor
  },
  contentWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center"
  }
}));

const SectionPreview = ({
  children,
  title,
  subtitle,
  fullHeight = false,
  titleBackgroundColor = "#00000000", // TODO: in preview page it should be some other color #00000008, but in custom_page
  titleColor = "#000000",
  titleTextAlign = "center",
  noMainContentContainerForContent = false,
  noMainContentContainerForTitle = false
}) => {
  const classes = useStyles({
    titleBackgroundColor,
    titleColor,
    titleTextAlign
  });

  const header = [
    <Typography variant={"h4"} className={classes.sectionTitle} key={"title"}>
      {title}
    </Typography>,
    subtitle && (
      <Typography
        variant={"h6"}
        className={classes.sectionSubtitle}
        key={"subtitle"}
      >
        {subtitle}
      </Typography>
    )
  ];

  const centeredChildren = <div>{children}</div>;

  return (
    <div className={clsx(classes.root, fullHeight && classes.fullHeight)}>
      {title && (
        <div className={classes.sectionTitleContainer}>
          {noMainContentContainerForTitle ? (
            { header }
          ) : (
            <MainContentContainer noBottomPadding={true}>
              {header}
            </MainContentContainer>
          )}
        </div>
      )}
      {noMainContentContainerForContent ? (
        centeredChildren
      ) : (
        <MainContentContainer customClasses={classes.contentWrapper}>
          {centeredChildren}
        </MainContentContainer>
      )}
    </div>
  );
};

export default SectionPreview;
