import React, { useEffect } from "react";

import { Typography, Grid, makeStyles, IconButton } from "@material-ui/core";

import MainContentContainer from "common/components/main-content-container";
import Section from "common/components/page/section";
import { useDispatch, useSelector } from "react-redux";
import MainTemplate from "../../../../main-page/menu-component/mainTemplate";
import { getPersonDogs } from "../../../../../redux/person/actions";
import { closeModal, openModal } from "../../../../../redux/common/actions";
import DogCard from "./dogCard";
import AddIcon from "@material-ui/icons/Add";
import PersonMyDogsCreateForm, {
  PERSON_MY_DOGS_CREATE_FORM_MODAL_NAME
} from "./forms/createForm";
import ModalFormWrapper from "../../../../../common/components/modalForm/wrapper";
import AuthorizedOnly from "../../../../../common/components/authorization/authorizedOnly";

const useStyles = makeStyles(theme => ({
  icons: {
    marginRight: theme.spacing(1)
  },
  coloredLists: {
    color: theme.palette.secondary.main
  },
  editButton: {
    position: "absolute",
    right: theme.spacing(3),
    top: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const PersonMyDogs = ({ resourceId: personId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    reload();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [personId]);

  const handleCreateModalOpen = () => {
    dispatch(openModal(PERSON_MY_DOGS_CREATE_FORM_MODAL_NAME));
  };
  const handleCreateModalClose = () => {
    dispatch(closeModal(PERSON_MY_DOGS_CREATE_FORM_MODAL_NAME));
  };

  const reload = () => {
    dispatch(getPersonDogs(personId));
  };

  const dogs = useSelector(state => state.PersonReducer.dogs);

  return dogs?.length ? (
    <MainTemplate>
      <MainContentContainer relative>
        <AuthorizedOnly
          expectedRole={"isResourceAdmin"}
          resourceType={"person"}
          resourceId={personId}
        >
          <ModalFormWrapper
            modalName={PERSON_MY_DOGS_CREATE_FORM_MODAL_NAME}
            title={"Přidat mého psa"}
          >
            <PersonMyDogsCreateForm
              personId={personId}
              initialValues={{
                name: "",
                breed: "",
                sex: "",
                birthDate: null,
                registrationNumber: "",
                chipNumber: "",
                tatooNumber: "",
                ownershipApprove: false
              }}
              onClose={handleCreateModalClose}
              onSubmitSuccessfully={reload}
            />
          </ModalFormWrapper>
          <div className={classes.editButton}>
            {/*<SectionAction*/}
            {/*  title={"Správa novinek"}*/}
            {/*  onAdd={handleCreateFormOpen}*/}
            {/*  onAddTitle={"Přidat novinku"}*/}
            {/*/>*/}
            <IconButton
              // variant={"outlined"}
              color={"primary"}
              title={"Přidat psa"}
              onClick={handleCreateModalOpen}
            >
              <AddIcon />
            </IconButton>
          </div>
        </AuthorizedOnly>
        <Section title={"Moji psi"} bottomDivider={false}>
          <Grid container spacing={2} className={classes.content}>
            {dogs.map(dog => (
              <Grid item xs={12} lg={6} key={dog.id}>
                <DogCard
                  id={dog.id}
                  title={dog.fullName}
                  subtitle={""}
                  imageUrl={dog.image.url}
                />
              </Grid>
            ))}
          </Grid>
        </Section>
      </MainContentContainer>
    </MainTemplate>
  ) : (
    <Typography variant={"body1"}>psi nebyli nalezeni</Typography>
  );
};

export default PersonMyDogs;
