// @flow

import React from "react";

// material-ui
// import { makeStyles } from "@material-ui/core/styles";
// import { Grid } from "@material-ui/core";

// components
// import SearchBar from "../../../../common/components/search/searchBar";
// import SearchCard from "pages/contentPages/trainingClubPages/search/searchCard";
import MainTemplate from "../../../main-page/menu-component/mainTemplate";
import MainContentContainer from "../../../../common/components/main-content-container";
// import { useDispatch, useSelector } from "react-redux";
// import { getTrainingClubs } from "../../../../redux/trainingClub/actions";

// const useStyles = makeStyles(theme => ({
//   content: {
//     marginTop: theme.spacing(2)
//   },
//   searchBar: {
//     marginBottom: theme.spacing(1)
//   }
// }));

const PersonSearch = () => {
  // const classes = useStyles();
  // const dispatch = useDispatch();

  // const [search, setSearch] = useState<string>("");

  // useEffect(() => {
  //   (async () => {
  //     await dispatch(getTrainingClubs({})); // TODO  filter on the server: {name: search}
  //   })();
  // }, [dispatch]);

  // const events = useSelector(state => state.TrainingClubReducer.clubs);

  // Easy search all events by name in the browser.
  // const events_to_show = events.filter(event =>
  //   search ? event.name.toLowerCase().includes(search.toLowerCase()) : true
  // );

  return (
    <MainTemplate>
      <MainContentContainer showCloser={true}>
        {/*<Grid container spacing={2} className={classes.content}>*/}
        {/*  <Grid item xs={12} className={classes.searchBar}>*/}
        {/*    <SearchBar*/}
        {/*      search={search}*/}
        {/*      setSearch={setSearch}*/}
        {/*      placeholder={"Pro vyhledání zadejte jméno klubu"}*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*  {events_to_show.map(event => (*/}
        {/*    <Grid item xs={12} lg={6} key={event.id}>*/}
        {/*      <SearchCard*/}
        {/*        id={event.id}*/}
        {/*        title={event.name}*/}
        {/*        subtitle={event.shortDescription}*/}
        {/*        imageUrl={event.imageUrl}*/}
        {/*      />*/}
        {/*    </Grid>*/}
        {/*  ))}*/}
        {/*</Grid>*/}
        Not implemented
      </MainContentContainer>
    </MainTemplate>
  );
};

export default PersonSearch;
