import React from "react";
import Modal from "./modal";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../redux/common/actions";

const ModalFormWrapper = ({
  children,
  modalName,
  title,
  maxWidth = "md",
  fullScreen = false,
  showCloseButton = false,
  paddingLeftRightOnly = false
}) => {
  const dispatch = useDispatch();

  const open =
    useSelector(state => state.CommonReducer.modals)[modalName] || false;

  const handleModalClose = () => {
    dispatch(closeModal(modalName));
  };

  return (
    <div>
      <Modal
        open={open}
        title={title}
        maxWidth={maxWidth}
        fullWidth={true}
        onClose={handleModalClose}
        fullScreen={fullScreen}
        showCloseButton={showCloseButton}
        paddingLeftRightOnly={paddingLeftRightOnly}
      >
        {children}
      </Modal>
    </div>
  );
};

export default ModalFormWrapper;
