export const authorize = (
  authReducer,
  expectedRole: string,
  resourceType: ?string = null,
  resourceId: ?number = null
) => {
  switch (expectedRole) {
    case "isSystemAdmin":
      return authReducer.isSystemAdmin;
    case "isResourceMember":
      return (
        authReducer.isSystemAdmin ||
        (resourceType !== null &&
          resourceId !== null &&
          authReducer.resourceType === resourceType &&
          authReducer.resourceId === resourceId &&
          authReducer.isResourceMember)
      );
    case "isResourceAdmin":
      return (
        authReducer.isSystemAdmin ||
        (resourceType !== null &&
          resourceId !== null &&
          authReducer.resourceType === resourceType &&
          authReducer.resourceId === resourceId &&
          authReducer.isResourceAdmin)
      );
    default:
      throw Error("Unexpected role in authorize().");
  }
};
