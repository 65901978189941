import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import TableItem from "./tableItem";
import clsx from "clsx";
import {
  openConfirmationModal,
  openModal
} from "../../../../../redux/common/actions";
import EventCompetitorEditFormModal, {
  EVENT_COMPETITOR_EDIT_FORM_MODAL_NAME_PREFIX
} from "./forms/editFormModal";
import { useDispatch } from "react-redux";
import SectionAction from "../../../../../common/components/customPage/components/sectionAction";
import { removeCompetitor } from "../../../../../redux/event/actions";
import AuthorizedOnly from "../../../../../common/components/authorization/authorizedOnly";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  item: {
    textAlign: "left"
  },
  headerItem: {
    fontWeight: 700
  },
  stripped: {
    // backgroundColor: "rgba(226, 242, 255, 0.3)"
    backgroundColor: "#e6f4ff7d",
    borderRadius: "15px"
  }
}));

export default function CompetitorsCategoryTable({
  data,
  isHeader,
  headerData,
  isEvenItem,
  onReload
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleEditFormOpen = () => {
    dispatch(
      openModal(
        EVENT_COMPETITOR_EDIT_FORM_MODAL_NAME_PREFIX + data.id.toString()
      )
    );
  };

  const handleRemoveCompetitorConfirm = () => {
    dispatch(
      removeCompetitor({
        participantId: data.id,
        eventId: data.eventId,
        onSuccess: onReload
      })
    );
  };

  const handleRemoveModalOpen = () => {
    dispatch(
      openConfirmationModal(
        "Odstranit závodníka",
        `Opravdu si přejete odstranit závodníka '${data.name +
          " " +
          data.surname}'?`,
        handleRemoveCompetitorConfirm
      )
    );
  };

  return (
    <>
      {isHeader ? null : (
        <EventCompetitorEditFormModal
          competitorId={data.id}
          eventId={data.eventId}
          // TODO: doplnit initValues a přepsat editForm na tento!
          initialValues={{
            participant: {
              id: data.id,
              fullname: data.name + " " + data.surname
            }, // TODO: fullName together with ParsonSearchBox
            clubName: data.clubName,
            dog: { id: data?.dog?.id, fullName: data?.dog?.name },
            startNumber: data.startNumber || "",
            place: data.place || "",
            pointsA: data.eventResult?.track || "",
            pointsB: data.eventResult?.obedience || "",
            pointsC: data.eventResult?.defense || ""
          }}
          onSubmitSuccessfully={onReload}
        />
      )}
      <Grid
        container
        className={clsx(classes.root, isEvenItem && classes.stripped)}
        alignItems="center"
      >
        <Grid item xs={12} lg={6}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={2}>
              <TableItem header={headerData.startNumber} isHeader={isHeader}>
                {data.startNumber}
              </TableItem>
            </Grid>
            <Grid item xs={12} md={2}>
              <TableItem header={headerData.place} isHeader={isHeader}>
                {data.place}
              </TableItem>
            </Grid>
            <Grid item xs={12} md={4}>
              <TableItem header={headerData.name} isHeader={isHeader}>
                {data.name + " " + data.surname}
              </TableItem>
            </Grid>
            <Grid item xs={12} md={4}>
              <TableItem header={headerData.clubName} isHeader={isHeader}>
                {data.clubName}
              </TableItem>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={5}>
              <TableItem header={headerData.dogName} isHeader={isHeader}>
                {isHeader ? "Jméno psa" : data?.dog?.name || ""}
              </TableItem>
            </Grid>
            <Grid item xs={12} md={2}>
              <TableItem header={headerData.breed} isHeader={isHeader}>
                {data.breed}
              </TableItem>
            </Grid>
            <Grid item xs={12} md={1}>
              <TableItem header={headerData.pointsA} isHeader={isHeader}>
                {isHeader ? data.pointsA : data.eventResult?.track}
              </TableItem>
            </Grid>
            <Grid item xs={12} md={1}>
              <TableItem header={headerData.pointsB} isHeader={isHeader}>
                {isHeader ? data.pointsB : data.eventResult?.obedience}
              </TableItem>
            </Grid>
            <Grid item xs={12} md={1}>
              <TableItem header={headerData.pointsC} isHeader={isHeader}>
                {isHeader ? data.pointsC : data.eventResult?.defense}
              </TableItem>
            </Grid>
            <Grid item xs={12} md={2}>
              <AuthorizedOnly
                expectedRole={"isResourceAdmin"}
                resourceType={"events"}
                resourceId={data.eventId}
              >
                <TableItem header={""} isHeader={isHeader}>
                  {isHeader ? (
                    "Akce"
                  ) : (
                    <SectionAction
                      title={"Upravit závodníka"}
                      onEdit={handleEditFormOpen}
                      onRemove={handleRemoveModalOpen}
                    />
                  )}
                </TableItem>
              </AuthorizedOnly>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
