import React from "react";
import { Grid } from "@material-ui/core";

import { Form } from "formik";
import FormActions from "../../../../../../common/components/forms/actions";
import NameField from "./fields/name";
import BirthDateField, {
  FIELD_NAME as BIRTH_DATE_FIELD_NAME
} from "../../../../../../common/components/forms/fields/birthDate";
import RegistrationNumberField from "./fields/registrationNumber";
import ChipNumberField from "./fields/chipNumber";
import TatooNumberField from "./fields/tatooNumber";
import SexField from "./fields/sex";
import OwnershipApproveField from "./fields/ownershipApprove";
import KennelSearchBox from "../../../../../../common/components/forms/KennelSearchBox";
import DogBreedSearchBox from "../../../../../../common/components/forms/DogBreedSearchBox";
import ImageUploader from "../../../../../../common/components/imageUploader";
import CustomInputLabel from "../../../../../../common/components/forms/customInputLabel";

const PersonMyDogsCreateEditFormLayout = ({
  values,
  actualProfileImageUrl, // only for edit form
  errors,
  formDiagnostics,
  touched,
  isSubmitting,
  onClose,
  setFieldValue,
  createForm
}) => {
  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomInputLabel label={"Profilový obrázek psa"} />
          <ImageUploader
            initImage={{
              url: actualProfileImageUrl,
              id: values.imageId
            }}
            onChange={id => setFieldValue("imageId", id)}
            useCrop={true}
            alt={"Profilový obrázek"}
          />
        </Grid>
        {/*<Grid item xs={12} md={6}>*/}
        {/*  <PersonSearchBox*/}
        {/*    label={"Jméno a příjmení závodníka"}*/}
        {/*    placeholder="Zde vložte jméno a příjmení závodníka"*/}
        {/*    initialValues={values.participant}*/}
        {/*    onChange={value => setFieldValue("participant", value)}*/}
        {/*    multiple={false}*/}
        {/*    disabled={!createForm}*/}
        {/*    // baseParams={{ errors, formDiagnostics, touched }}*/}
        {/*  />*/}
        {/*</Grid>*/}

        {/*<Grid item xs={12} md={6}>*/}
        {/*  <ClubNameField baseParams={{ errors, formDiagnostics, touched }} />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} md={12}>*/}
        {/*  <DogSearchBox*/}
        {/*    label={"Jméno psa"}*/}
        {/*    placeholder="Zde vložte jméno psa včetně názvu ch.s."*/}
        {/*    initialValues={values.dog}*/}
        {/*    onChange={value => setFieldValue("dog", value)}*/}
        {/*    multiple={false}*/}
        {/*    disabled={!createForm}*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item xs={12} md={6}>
          <NameField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <KennelSearchBox
            label={"Chovatelská stanice"}
            placeholder="Zde vložte název chovatelské stanice psa"
            initialValues={values.kennel}
            onChange={value => setFieldValue("kennel", value)}
            multiple={false}
            disabled={!createForm}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DogBreedSearchBox
            label={"Plemeno"}
            placeholder="Zde vložte plemeno psa"
            initialValues={values.breed}
            onChange={value => setFieldValue("breed", value)}
            multiple={false}
            disabled={!createForm}
          />
          {/*<BreedField baseParams={{ errors, formDiagnostics, touched }} />*/}
        </Grid>
        <Grid item xs={12} md={6}>
          <SexField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <BirthDateField
            value={values[BIRTH_DATE_FIELD_NAME]}
            setFieldValue={setFieldValue}
            baseParams={{ errors, formDiagnostics, touched }}
            initialFocusedDate={"2018-01-01"}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RegistrationNumberField
            baseParams={{ errors, formDiagnostics, touched }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ChipNumberField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TatooNumberField baseParams={{ errors, formDiagnostics, touched }} />
        </Grid>
        {createForm ? (
          <Grid item xs={12} md={12}>
            <OwnershipApproveField
              baseParams={{ errors, formDiagnostics, touched }}
            />
          </Grid>
        ) : null}
      </Grid>
      <FormActions
        isSubmitting={isSubmitting}
        errors={errors}
        onClose={onClose}
      />
      {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(errors, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(imageIds, null, 2)}</pre>*/}
    </Form>
  );
};

export default PersonMyDogsCreateEditFormLayout;
