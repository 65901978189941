import React, { useEffect } from "react";

import MainMultimedia from "common/components/main-multimedia";
import { Typography, Grid, makeStyles } from "@material-ui/core";
import PlaceIcon from "@material-ui/icons/Place";
import ScheduleIcon from "@material-ui/icons/Schedule";
import EventIcon from "@material-ui/icons/Event";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CategoryIcon from "@material-ui/icons/Category";

import MainContentContainer from "common/components/main-content-container";
import Section from "common/components/page/section";
import { useDispatch, useSelector } from "react-redux";
import {
  getTrainingClub,
  getTrainingClubEvents
} from "redux/trainingClub/actions";
import { NOT_SET_PLACEHOLDER } from "../../../../../constants";
import Moment from "react-moment";
import SearchCard from "../../../eventPages/search/searchCard";
import Button from "@material-ui/core/Button";
import ClubSummaryEditForm, {
  CLUB_SUMMARY_EDIT_FORM_MODAL_NAME
} from "./forms/editForm";
import { closeModal, openModal } from "../../../../../redux/common/actions";
import AuthorizedOnly from "../../../../../common/components/authorization/authorizedOnly";
import ModalFormWrapper from "../../../../../common/components/modalForm/wrapper";

const useStyles = makeStyles(theme => ({
  icons: {
    marginRight: theme.spacing(1)
  },
  coloredLists: {
    color: theme.palette.secondary.main
  },
  editButton: {
    float: "right",
    marginTop: theme.spacing(4)
  }
}));

const TrainingClubSummary = ({ resourceId: clubId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const reloadClub = () => {
    dispatch(getTrainingClub(clubId));
  };

  useEffect(() => {
    (async () => {
      await reloadClub();
      await dispatch(getTrainingClubEvents(clubId));
    })();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, clubId]);

  const trainingClub = useSelector(state => state.TrainingClubReducer.club);
  const clubEvents = useSelector(state => state.TrainingClubReducer.clubEvents);

  const handleEditFormSubmitSuccessfully = () => {
    reloadClub();
  };
  const handleEditModalOpen = () => {
    dispatch(openModal(CLUB_SUMMARY_EDIT_FORM_MODAL_NAME));
  };
  const handleCloseClubSummaryEditModal = () => {
    dispatch(closeModal(CLUB_SUMMARY_EDIT_FORM_MODAL_NAME));
  };

  return trainingClub ? (
    <>
      <MainMultimedia
        title={trainingClub.name}
        subtitle={trainingClub.shortDescription}
        image={trainingClub.imageUrl}
      />
      <MainContentContainer>
        <AuthorizedOnly
          expectedRole={"isResourceAdmin"}
          resourceType={"clubs"}
          resourceId={clubId}
        >
          <ModalFormWrapper
            modalName={CLUB_SUMMARY_EDIT_FORM_MODAL_NAME}
            title={"Upravit informace klubu"}
          >
            <ClubSummaryEditForm
              clubId={clubId}
              initialValues={{
                name: trainingClub.name,
                shortDescription: trainingClub.shortDescription,
                // longDescription: trainingClub.longDescription,
                websiteUrl: trainingClub.websiteUrl,
                possibilities: trainingClub.possibilities || [],
                parentOrganizationId: trainingClub.parentOrganizationId || 0,
                basicOrganizationNumber:
                  trainingClub.basicOrganizationNumber || ""
              }}
              onClose={handleCloseClubSummaryEditModal}
              actualProfileImageUrl={trainingClub.imageUrl}
              onSubmitSuccessfully={handleEditFormSubmitSuccessfully}
            />
          </ModalFormWrapper>
          <Button
            className={classes.editButton}
            onClick={handleEditModalOpen}
            color={"primary"}
            variant={"outlined"}
          >
            Upravit
          </Button>
        </AuthorizedOnly>
        <Section title={"Základní informace"}>
          <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant={"body1"}>
                <PlaceIcon
                  color="secondary"
                  fontSize={"default"}
                  className={classes.icons}
                />
                <strong>Nadřazená organizace:</strong>{" "}
                {trainingClub.parentOrganization
                  ? trainingClub.parentOrganization
                  : "žádná"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={"body1"}>
                <EventIcon
                  color="secondary"
                  fontSize={"default"}
                  className={classes.icons}
                />
                <strong>Číslo základní organizace:</strong>{" "}
                {trainingClub.basicOrganizationNumber
                  ? trainingClub.basicOrganizationNumber
                  : NOT_SET_PLACEHOLDER}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={"body1"}>
                <ScheduleIcon
                  color="secondary"
                  fontSize={"default"}
                  className={classes.icons}
                />
                <strong>Datum založení stránky:</strong>{" "}
                {trainingClub.created ? (
                  <Moment date={trainingClub.created} />
                ) : (
                  ""
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={"body1"}>
                <PersonAddIcon
                  color="secondary"
                  fontSize={"default"}
                  className={classes.icons}
                />
                <strong>Stránky založil:</strong>{" "}
                {trainingClub.creator
                  ? `${trainingClub.creator.name} ${trainingClub.creator.surname}`
                  : NOT_SET_PLACEHOLDER}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={"body1"}>
                <LocalOfferIcon
                  color="secondary"
                  fontSize={"default"}
                  className={classes.icons}
                />
                <strong>Webové stránky</strong>{" "}
                {trainingClub.websiteUrl ? (
                  <a
                    href={trainingClub.websiteUrl}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    {trainingClub.websiteUrl}
                  </a>
                ) : (
                  NOT_SET_PLACEHOLDER
                )}
              </Typography>
            </Grid>
          </Grid>
        </Section>
        {trainingClub?.possibilities?.length > 0 && (
          <Section bottomDivider={true}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography variant={"body1"}>
                  <CategoryIcon
                    color="secondary"
                    fontSize={"default"}
                    className={classes.icons}
                  />
                  <strong>Možnosti klubu:</strong>{" "}
                </Typography>
                <ul>
                  {trainingClub.possibilities.map((possibility, index) => (
                    <li className={classes.coloredLists} key={index}>
                      <Typography variant={"body1"} color={"textPrimary"}>
                        {possibility}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Grid>
            </Grid>
          </Section>
        )}
        <Section bottomDivider={false} title={"Akce klubu"}>
          <Grid container spacing={4}>
            {clubEvents?.length > 0 ? (
              clubEvents.map((event, index) => (
                <Grid item xs={12} lg={6} key={index}>
                  <SearchCard
                    id={event.id}
                    title={event.name}
                    subtitle={event.shortDescription}
                    eventDate={event.term}
                    eventVenue={event.venue}
                    imageUrl={event.imageUrl}
                    deleted={event.term < Date.now()}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography variant={"body2"}>
                  pro tento klub nebyly nalezeny žádné akce
                </Typography>
              </Grid>
            )}
          </Grid>
        </Section>
      </MainContentContainer>
    </>
  ) : null;
};

export default TrainingClubSummary;
