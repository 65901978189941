import React from "react";
import { makeStyles } from "@material-ui/core";
import ReactCrop from "react-image-crop";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  }
}));

const ImageCropper = ({ src, crop, onCropChanged, setScale }) => {
  const classes = useStyles();

  // If you setState the crop in here you should return false.
  const onImageLoaded = image => {
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    setScale({ scaleX: scaleX, scaleY: scaleY });
    onCropChanged({
      ...crop,
      x: image.width / 2 - 150,
      y: image.height / 2 - 150
    });
  };

  return (
    <div className={classes.root}>
      <ReactCrop
        src={src}
        crop={crop}
        onChange={newCrop => onCropChanged(newCrop)}
        circularCrop={false} // pokud bude nekdy potreba, prida se do props
        onImageLoaded={onImageLoaded}
        minWidth={50}
        minHeight={50}
        maxWidth={800}
        mmaxHeight={800}
      />
    </div>
  );
};

export default ImageCropper;
