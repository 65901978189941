import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
  root: {},
  paddingLeftRightOnly: {
    padding: "0px 24px"
  },
  dialogPaperForCloseButton: {
    position: "relative"
  },
  closeButtonWrapper: {
    position: "absolute",
    right: theme.spacing(4),
    top: theme.spacing(2),
    zIndex: 99999
  },
  closeButtonIcon: {
    color: "red"
  }
}));

export default function Modal({
  title,
  open,
  onClose,
  contentText,
  buttonsProperties,
  children,
  maxWidth,
  fullWidth,
  fullScreen = false,
  showCloseButton = false,
  paddingLeftRightOnly = false
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
        classes={{
          paper: showCloseButton && classes.dialogPaperForCloseButton
        }}
      >
        {showCloseButton && (
          <div className={classes.closeButtonWrapper}>
            <IconButton onClick={onClose}>
              <CloseIcon className={classes.closeButtonIcon} />
            </IconButton>
          </div>
        )}
        {title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
        <DialogContent
          classes={{
            root: paddingLeftRightOnly && classes.paddingLeftRightOnly
          }}
        >
          {contentText}
          {children}
        </DialogContent>
        {buttonsProperties &&
          buttonsProperties.constructor === Array &&
          buttonsProperties.length > 0 && (
            <DialogActions>
              {buttonsProperties.map((properties, index) => (
                <Button
                  onClick={properties.onClick}
                  color="primary"
                  key={index}
                >
                  {properties.text}
                </Button>
              ))}
            </DialogActions>
          )}
      </Dialog>
    </div>
  );
}
