import React from "react";
import {
  Button,
  ButtonGroup,
  LinearProgress,
  makeStyles
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  buttonGroup: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  buttonGroupDense: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  lineProgress: {}
}));

const FormActions = ({
  isSubmitting,
  errors,
  onClose,
  onSubmitButtonText = "Uložit",
  onSubmitButtonTextSubmitting = "Ukládání",
  dense = false
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ButtonGroup
        color="primary"
        aria-label="outlined primary button group"
        className={dense ? classes.buttonGroupDense : classes.buttonGroup}
      >
        <Button
          type={"submit"}
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          // onClick={onSubmit}  // DO NOT UNCOMMENT - submit is called be type=submit
        >
          {!isSubmitting
            ? onSubmitButtonText
            : onSubmitButtonTextSubmitting || onSubmitButtonText}
        </Button>
        {onClose && (
          <Button variant="contained" color="default" onClick={onClose}>
            Zavřít
          </Button>
        )}
      </ButtonGroup>
      {isSubmitting ? (
        <LinearProgress className={classes.lineProgress} />
      ) : null}
    </div>
  );
};

export default FormActions;
