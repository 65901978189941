import React from "react";
import { makeStyles, Hidden, Typography, Divider } from "@material-ui/core";
import CompetitorsCategoryTable from "./table";
import MyPaper from "../../../../../common/components/page/myPaper";
import AuthorizedOnly from "../../../../../common/components/authorization/authorizedOnly";
import ModalFormWrapper from "../../../../../common/components/modalForm/wrapper";
import SectionAction from "../../../../../common/components/customPage/components/sectionAction";
import { closeModal, openModal } from "../../../../../redux/common/actions";
import { useDispatch } from "react-redux";
import EventCompetitorCreateForm, {
  EVENT_COMPETITOR_CREATE_IN_CATEGORY_FORM_MODAL_NAME_PREFIX
} from "./forms/createForm";

const useStyles = makeStyles(theme => ({}));

const headerData = {
  startNumber: "Startovní číslo",
  place: "Pořadí",
  name: "Jméno psovoda",
  surname: "",
  clubName: "Název klubu",
  dogName: "Jméno psa",
  breed: "Plemeno",
  pointsA: "A",
  pointsB: "B",
  pointsC: "C"
};

export default function CompetitorCategory({
  eventId,
  categoryName,
  participants,
  onReload
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const createCompetitorModalName =
    EVENT_COMPETITOR_CREATE_IN_CATEGORY_FORM_MODAL_NAME_PREFIX + categoryName;

  const handleCreateFormOpen = () => {
    dispatch(openModal(createCompetitorModalName));
  };
  const handleCreateFormClose = () => {
    dispatch(closeModal(createCompetitorModalName));
  };

  return (
    <>
      <MyPaper
        title={<span>Kategorie: {categoryName}</span>}
        actions={
          <>
            <AuthorizedOnly
              expectedRole={"isResourceAdmin"}
              resourceType={"events"}
              resourceId={eventId}
            >
              <ModalFormWrapper
                modalName={createCompetitorModalName}
                title={`Přidat závodníka do kategorie ${categoryName}`}
              >
                <EventCompetitorCreateForm
                  competitorId={undefined}
                  eventId={eventId}
                  categoryName={categoryName}
                  initialValues={{
                    participant: { fullname: "" },
                    clubName: "",
                    dog: { fullName: "" },
                    startNumber: "",
                    place: "",
                    pointsA: "",
                    pointsB: "",
                    pointsC: ""
                  }}
                  onClose={handleCreateFormClose}
                  onSubmitSuccessfully={onReload}
                />
              </ModalFormWrapper>

              <div className={classes.editButton}>
                <SectionAction
                  title={`Přidat závodníka do kategorie ${categoryName}`}
                  onAdd={handleCreateFormOpen}
                  onAddTitle={"Přidat závodníka"}
                />
              </div>
            </AuthorizedOnly>
          </>
        }
        body={
          participants?.length ? (
            <div>
              <Hidden smDown>
                <CompetitorsCategoryTable
                  data={headerData}
                  headerData={headerData}
                  isHeader={true}
                  onReload={onReload}
                />
                <Divider />
              </Hidden>
              {participants.map((participant, index) => (
                <CompetitorsCategoryTable
                  data={participant}
                  headerData={headerData}
                  key={index}
                  isEvenItem={(index + 1) % 2 === 0}
                  onReload={onReload}
                />
              ))}
            </div>
          ) : (
            <Typography variant={"body2"}>
              v této kategorii zatím není žádný závodník
            </Typography>
          )
        }
        footer={
          <Typography variant={"body2"}>
            Celkem v katerogii: {participants?.length || 0}
          </Typography>
        }
      />
    </>
  );
}
