// event components
import TrainingClubContact from "pages/contentPages/trainingClubPages/sub-pages/contact";
import TrainingClubSummary from "pages/contentPages/trainingClubPages/sub-pages/summary";
import TrainingClubMembers from "pages/contentPages/trainingClubPages/sub-pages/members";
import TrainingClubPhotoGallery from "pages/contentPages/trainingClubPages/sub-pages/photo-gallery";
import TrainingClubAbout from "pages/contentPages/trainingClubPages/sub-pages/about";
import TrainingClubNews from "pages/contentPages/trainingClubPages/sub-pages/news";

import DvrIcon from "@material-ui/icons/Dvr";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import DescriptionIcon from "@material-ui/icons/Description";
import PeopleIcon from "@material-ui/icons/People";
import PhotoIcon from "@material-ui/icons/Photo";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TrainingClubPreview from "./preview";

export const getRoutes = (id, linkPrefix, pathPrefix) => {
  return [
    {
      disableMenu: true,
      id: id,
      name: "Úvod",
      link: `${linkPrefix}/`, // `/clubs/${id}` OR "/"
      icon: VisibilityIcon,
      exact: true,
      path: `${pathPrefix}/`, // "/clubs/:id" OR "/"
      component: TrainingClubPreview,
      mobileVersionMenu: true,
      mainTemplateProps: { withoutToolbar: true }
    },
    {
      id: id,
      name: "Přehled",
      link: `${linkPrefix}/summary`,
      icon: DvrIcon,
      exact: true,
      path: `${pathPrefix}/summary`,
      component: TrainingClubSummary
    },
    {
      id: id,
      name: "Novinky",
      link: `${linkPrefix}/news`,
      icon: AnnouncementIcon,
      exact: true,
      path: `${pathPrefix}/news`,
      component: TrainingClubNews
    },
    {
      id: id,
      name: "O klubu",
      link: `${linkPrefix}/about`,
      icon: DescriptionIcon,
      exact: true,
      path: `${pathPrefix}/about`,
      component: TrainingClubAbout
    },
    {
      id: id,
      name: "Členové",
      link: `${linkPrefix}/members`,
      icon: PeopleIcon,
      exact: true,
      path: `${pathPrefix}/members`,
      component: TrainingClubMembers
    },
    {
      id: id,
      name: "Fotogalerie",
      link: `${linkPrefix}/photo-galleries`,
      icon: PhotoIcon,
      exact: true,
      path: `${pathPrefix}/photo-galleries/:photoGalleryId?`,
      component: TrainingClubPhotoGallery
    },
    {
      id: id,
      name: "Kontakt",
      link: `${linkPrefix}/contact`,
      icon: ContactMailIcon,
      exact: true,
      path: `${pathPrefix}/contact`,
      component: TrainingClubContact
    }
  ];
};
