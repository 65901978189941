import React, { useEffect } from "react";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LONG,
  TOO_SHORT
} from "../../../../../../../../constants/dictionary/validation";
import SearchBox from "../../../../../../../../common/components/forms/searchBox";

const FIELD_NAME = "name";

export const YUP_NAME = Yup.string()
  .min(2, TOO_SHORT)
  .max(60, TOO_LONG);

export const YUP_NAME_REQUIRED = YUP_NAME.required(REQUIRED);

const NameField = ({
  baseParams,
  values,
  setFieldValue,
  index,
  allPossibleEventCategories
}) => {

  return (
    <SearchBox
      label={"Název kategorie"}
      placeholder={"Vyberte název kategorie"}
      initialOptions={allPossibleEventCategories.map(category => ({
        value: category.name,
        type: category.type
      }))}
      groupBy={option => option.type}
      getOptionSelected={(option, value) => {
        // console.log("getOptionSelected: ", option, value);
        return option.value === value.value;
      }}
      onChange={(e, value, result) => {
        // console.log("XXXX value: ", value);
        setFieldValue(`categories.${index}.${FIELD_NAME}`, value?.value || "");
      }}
      // creatable={true}
      // freeSolo={true}
      defaultValue={
        // Tady to null je velice důležité, díky němu zde není prázdný strink
        // a searchbox si nemyslí, že už je to hodnota.
        values.categories[index][FIELD_NAME]
          ? { value: values.categories[index][FIELD_NAME] }
          : null
      }
      creatable={true}
      freeSolo={true}
      updatableInternalState={true}
    />
  );
};

export default NameField;
