import React from "react";
import { TextField } from "formik-material-ui";
import FieldBase from "../../../../../../../common/components/forms/fieldBase";
import * as Yup from "yup";
import {
  REQUIRED,
  TOO_LOW
} from "../../../../../../../constants/dictionary/validation";

const FIELD_NAME = "place";

export const YUP_PLACE = Yup.number()
  .nullable(true)
  .min(0, TOO_LOW);

export const YUP_PLACE_REQUIRED = YUP_PLACE.required(REQUIRED);

const PlaceField = ({ baseParams }) => {
  return (
    <FieldBase
      component={TextField}
      name={FIELD_NAME}
      label="Pořadí"
      fullWidth={true}
      type="number"
      baseParams={baseParams}
    />
  );
};

export default PlaceField;
